var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "elevation-0" },
        [
          _c("v-card-title", { staticClass: "font-weight-bold" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$vuetify.lang.t("$vuetify.profile_basic_tab")) +
                " "
            ),
          ]),
          _c("v-divider"),
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.project_name_header_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _vm._v(" " + _vm._s(_vm.selectedProject.name) + " "),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.language_header_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.selectedProject.language
                            ? _vm.selectedProject.language
                            : "N/A"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.solution_header_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "v-list-item-content",
                    { staticClass: "align-end" },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _vm.selectedProject.segment
                            ? _c(
                                "v-chip",
                                {
                                  staticClass: "ma-0",
                                  attrs: {
                                    color: _vm.selectedProject.segment.color,
                                    label: "",
                                    outlined: "",
                                    pill: "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.capitalizeFirstLetter(
                                          _vm.selectedProject.segment.name
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.industry_header_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.selectedProject.industry
                            ? _vm.selectedProject.industry.name
                            : "N/A"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t("$vuetify.created_at_txt")}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.selectedProject.created_at
                            ? _vm.formatDateTime(_vm.selectedProject.created_at)
                            : "N/A"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "elevation-0" },
        [
          _c("v-card-title", { staticClass: "font-weight-bold" }, [
            _vm._v(
              " " +
                _vm._s(
                  `${_vm.$vuetify.lang.t(
                    "$vuetify.enabled_solution_label_txt"
                  )}`
                ) +
                " "
            ),
          ]),
          _c("v-divider"),
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold align-self-start" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.enabled_solution_label_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "v-list-item-content",
                    { staticClass: "align-end" },
                    _vm._l(
                      _vm.selectedProject.solutions,
                      function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "py-2" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-1",
                                attrs: {
                                  color: _vm.selectedProject.segment.color,
                                  size: "21",
                                },
                              },
                              [_vm._v(" task_alt ")]
                            ),
                            _vm._v(" " + _vm._s(item.name) + " "),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "elevation-0" },
        [
          _c("v-card-title", { staticClass: "font-weight-bold" }, [
            _vm._v(
              " " +
                _vm._s(
                  `${_vm.$vuetify.lang.t(
                    "$vuetify.project_app_infra_step_txt"
                  )}:`
                ) +
                " "
            ),
          ]),
          _c("v-divider"),
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.cloud_provider_label_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _vm._v(" Amazon Web Services "),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.cloud_provider_label_txt"
                            )} ${_vm.$vuetify.lang.t(
                              "$vuetify.region_label_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.selectedProject.region
                            ? `${_vm.selectedProject.region.timezone} (${_vm.selectedProject.region.cloud_provider_region})`
                            : "N/A"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.auto_scaling_label_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.selectedProject.auto_scaling
                            ? "Enabled"
                            : "Disabled"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.selectedProject.subscription_type
        ? _c(
            "v-card",
            { staticClass: "elevation-0" },
            [
              _c("v-card-title", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      `${_vm.$vuetify.lang.t("$vuetify.subs_label_txt")}`
                    ) +
                    " "
                ),
              ]),
              _c("v-divider"),
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "font-weight-bold" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                `${_vm.$vuetify.lang.t(
                                  "$vuetify.subs_type_label_txt"
                                )}:`
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "v-list-item-content",
                        { staticClass: "align-end" },
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-chip",
                                {
                                  staticClass: "ma-0",
                                  attrs: {
                                    color: _vm.getSubscriptionTypeColor(
                                      _vm.selectedProject.subscription_type
                                    ),
                                    label: "",
                                    outlined: "",
                                    pill: "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.selectedProject
                                          .subscription_type === "trial"
                                          ? _vm.capitalizeFirstLetter(
                                              _vm.selectedProject
                                                .subscription_type
                                            )
                                          : "Standard"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.selectedProject.subscription_type === "paid"
                    ? _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            { staticClass: "font-weight-bold" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    `${_vm.$vuetify.lang.t(
                                      "$vuetify.billing_cycle_label_txt"
                                    )}:`
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-list-item-content",
                            { staticClass: "align-end" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.selectedProject.current_billing_cycle
                                      ? _vm.formatDateTime(
                                          _vm.selectedProject
                                            .current_billing_cycle.start_date
                                        ) +
                                          " ~ " +
                                          _vm.formatDateTime(
                                            _vm.selectedProject
                                              .current_billing_cycle.end_date
                                          )
                                      : "N/A"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.selectedProject.subscription_type === "paid"
                    ? _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            { staticClass: "font-weight-bold" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    `${_vm.$vuetify.lang.t(
                                      "$vuetify.est_total_cost_label_txt"
                                    )}:`
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-list-item-content",
                            { staticClass: "align-end" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatNum(
                                      _vm.selectedProject.billing_amount
                                    )
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm.selectedProject.currency
                                      ? _vm.selectedProject.currency
                                      : "USD"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.selectedProject.subscription_type === "trial"
                    ? _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            { staticClass: "font-weight-bold" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    `${_vm.$vuetify.lang.t(
                                      "$vuetify.trial_subs_expire_date_label"
                                    )}:`
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-list-item-content",
                            { staticClass: "align-end" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatDateTime(
                                      _vm.selectedProject.trial_end_date
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.selectedProject.subscription_type === "paid"
                    ? _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            { staticClass: "font-weight-bold" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    `${_vm.$vuetify.lang.t(
                                      "$vuetify.next_invoice_issue_label_txt"
                                    )}:`
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-list-item-content",
                            { staticClass: "align-end" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.selectedProject.next_invoice_date
                                      ? _vm.formatDateTime(
                                          _vm.selectedProject.next_invoice_date,
                                          "",
                                          "True"
                                        )
                                      : "N/A"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.selectedProject.subscription_type === "paid"
                    ? _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            { staticClass: "font-weight-bold" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    `${_vm.$vuetify.lang.t(
                                      "$vuetify.next_invoice_due_label_txt"
                                    )}:`
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-list-item-content",
                            { staticClass: "align-end" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.selectedProject.next_invoice_due_date
                                      ? _vm.formatDateTime(
                                          _vm.selectedProject
                                            .next_invoice_due_date,
                                          "",
                                          "True"
                                        )
                                      : "N/A"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.selectedProject.segment &&
                  _vm.selectedProject.segment.name !==
                    _vm.PROJECT_SEGMENTS.Maira
                    ? _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            { staticClass: "font-weight-bold" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    `${_vm.$vuetify.lang.t(
                                      "$vuetify.item_limit_label_txt"
                                    )}:`
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-list-item-content",
                            { staticClass: "align-end" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.selectedProject.subscription_type ===
                                      "trial"
                                      ? _vm.formatNum(10000)
                                      : _vm.formatNum(50000)
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "font-weight-bold" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                `${_vm.$vuetify.lang.t(
                                  "$vuetify.subs_api_rate_limit"
                                )}:`
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _vm.selectedProject.segment &&
                      _vm.selectedProject.segment.name !=
                        _vm.PROJECT_SEGMENTS.Maira
                        ? _c(
                            "v-list-item-content",
                            { staticClass: "align-end" },
                            [
                              _c("ul", [
                                _c("li", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.ai_assistant_txt"
                                      )
                                    ) +
                                      ": " +
                                      _vm._s(
                                        _vm.selectedProject
                                          .gpt_ask_calls_per_period
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.calls_txt"
                                        )
                                      ) +
                                      "/900 " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.seconds_txt"
                                        )
                                      )
                                  ),
                                ]),
                                _c("li", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$vuetify.lang.t("$vuetify.search_txt")
                                    ) +
                                      ": " +
                                      _vm._s(
                                        _vm.selectedProject
                                          .search_calls_per_period
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.calls_txt"
                                        )
                                      ) +
                                      "/900 " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.seconds_txt"
                                        )
                                      )
                                  ),
                                ]),
                                _c("li", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.recommendation_txt"
                                      )
                                    ) +
                                      ": " +
                                      _vm._s(
                                        _vm.selectedProject
                                          .recommend_calls_per_period
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.calls_txt"
                                        )
                                      ) +
                                      "/900 " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.seconds_txt"
                                        )
                                      )
                                  ),
                                ]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.selectedProject.segment &&
                      _vm.selectedProject.segment.name ===
                        _vm.PROJECT_SEGMENTS.Maira
                        ? _c(
                            "v-list-item-content",
                            { staticClass: "align-end" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$vuetify.lang.t(
                                      "$vuetify.ai_assistant_txt"
                                    )
                                  ) +
                                  ": " +
                                  _vm._s(
                                    _vm.selectedProject.gpt_ask_calls_per_period
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm.$vuetify.lang.t("$vuetify.calls_txt")
                                  ) +
                                  "/900 " +
                                  _vm._s(
                                    _vm.$vuetify.lang.t("$vuetify.seconds_txt")
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c("br"),
                  _vm.selectedProject.subscription_type === "paid"
                    ? _c("v-list-item", [
                        _vm._v(
                          " " +
                            _vm._s(
                              `${_vm.$vuetify.lang.t(
                                "$vuetify.customer_support_message"
                              )}`
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _vm.selectedProject.subscription_type === "trial"
                            ? _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "text-capitalize font-weight-bold",
                                  attrs: {
                                    color: "primary",
                                    large: "",
                                    loading: _vm.isLoading,
                                    disabled: _vm.isLoading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.proceedUnlockClick()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.subs_upgrade_btn_txt"
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-2",
                                      attrs: { right: "" },
                                    },
                                    [_vm._v(" unlocked ")]
                                  ),
                                  _vm.isLoading
                                    ? _c("v-progress-circular", {
                                        attrs: {
                                          indeterminate: "",
                                          color: "white",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { width: "60%" },
          model: {
            value: _vm.confirmDialog,
            callback: function ($$v) {
              _vm.confirmDialog = $$v
            },
            expression: "confirmDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline primary white--text",
                  attrs: { "primary-title": "" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$vuetify.lang.t("$vuetify.subs_upgrade_modal_title")
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "v-layout",
                { staticClass: "px-5 py-5" },
                [
                  _c("v-row", { staticClass: "mx-2 my-2" }, [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$vuetify.lang.t(
                              "$vuetify.subs_upgrade_modal_message"
                            )
                          ) +
                          " "
                      ),
                      _c("ol", [
                        _c("li", [
                          _vm._v(
                            _vm._s(
                              _vm.$vuetify.lang.t(
                                "$vuetify.subscription_fee_header_txt"
                              )
                            ) +
                              ": $3,500/" +
                              _vm._s(_vm.$vuetify.lang.t("$vuetify.month_txt"))
                          ),
                        ]),
                        _vm.selectedProject.segment &&
                        _vm.selectedProject.segment.name !=
                          _vm.PROJECT_SEGMENTS.Maira
                          ? _c("li", [
                              _vm._v(
                                _vm._s(
                                  _vm.$vuetify.lang.t(
                                    "$vuetify.item_limit_label_txt"
                                  )
                                ) + " : 50,000"
                              ),
                            ])
                          : _vm._e(),
                        _c("li", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t(
                                  "$vuetify.subs_api_rate_limit"
                                )
                              ) +
                              " : "
                          ),
                          _c("ul", [
                            _c("li", [
                              _vm._v(
                                _vm._s(
                                  _vm.$vuetify.lang.t(
                                    "$vuetify.ai_assistant_txt"
                                  )
                                ) +
                                  " : 600 " +
                                  _vm._s(
                                    _vm.$vuetify.lang.t("$vuetify.calls_txt")
                                  ) +
                                  "/900 " +
                                  _vm._s(
                                    _vm.$vuetify.lang.t("$vuetify.seconds_txt")
                                  )
                              ),
                            ]),
                            _vm.selectedProject.segment &&
                            _vm.selectedProject.segment.name !=
                              _vm.PROJECT_SEGMENTS.Maira
                              ? _c("li", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$vuetify.lang.t("$vuetify.search_txt")
                                    ) +
                                      ": 3600 " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.calls_txt"
                                        )
                                      ) +
                                      "/900 " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.seconds_txt"
                                        )
                                      )
                                  ),
                                ])
                              : _vm._e(),
                            _vm.selectedProject.segment &&
                            _vm.selectedProject.segment.name !=
                              _vm.PROJECT_SEGMENTS.Maira
                              ? _c("li", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.recommendation_txt"
                                      )
                                    ) +
                                      ": 7200 " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.calls_txt"
                                        )
                                      ) +
                                      "/900 " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.seconds_txt"
                                        )
                                      )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                      _c("br"),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$vuetify.lang.t(
                              "$vuetify.subs_upgrade_modal_message_continued"
                            )
                          )
                      ),
                      _c("br"),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$vuetify.lang.t(
                              "$vuetify.subs_upgrade_modal_message_last"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c("Button", {
                    attrs: {
                      buttonType: _vm.TEXT_BUTTON,
                      buttonText: _vm.$vuetify.lang.t(
                        "$vuetify.subs_upgrade_modal_cancel_btn_text"
                      ),
                      color: "primary",
                    },
                    on: {
                      onClick: function ($event) {
                        _vm.confirmDialog = false
                      },
                    },
                  }),
                  _c("Button", {
                    attrs: {
                      buttonType: _vm.RAISED_BUTTON,
                      buttonText: _vm.$vuetify.lang.t(
                        "$vuetify.subs_upgrade_modal_confirm_btn_text"
                      ),
                      color: "primary",
                    },
                    on: {
                      onClick: function ($event) {
                        return _vm.proceedClick()
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }