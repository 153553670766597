var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading
    ? _c("v-skeleton-loader", {
        staticClass: "mr-2 mt-6",
        attrs: { type: "list-item-three-line" },
      })
    : _c(
        "div",
        [
          _c(
            "v-card",
            { staticClass: "elevation-0" },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { xs: "12", md: "6" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "px-4 font-weight-bold",
                        staticStyle: { "font-size": "1.25rem" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$vuetify.lang.t(
                                "$vuetify.gpt_profile.settings"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "px-6", attrs: { xs: "12", md: "6" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "float-right",
                          attrs: { color: "primary", dark: "" },
                          on: {
                            click: function ($event) {
                              _vm.gptSettingModal = true
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.isSettingAvailable
                                  ? _vm.$vuetify.lang.t(
                                      "$vuetify.gpt_profile.update_settings"
                                    )
                                  : _vm.$vuetify.lang.t(
                                      "$vuetify.gpt_profile.create_settings"
                                    )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "font-weight-bold" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t(
                                  "$vuetify.gpt_profile.default_profile"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c("v-list-item-content", { staticClass: "align-end" }, [
                        _vm._v(
                          " " + _vm._s(_vm.gptSetting.default_profile) + " "
                        ),
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "float-right",
                          attrs: {
                            disabled: !_vm.gptSetting.default_profile,
                            color: "red",
                            dark: !!_vm.gptSetting.default_profile,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.deleteSetting("default_profile")
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t("$vuetify.tooltip.delete")
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "font-weight-bold" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t(
                                  "$vuetify.gpt_profile.bot_mode_strict"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c("v-list-item-content", { staticClass: "align-end" }, [
                        _vm._v(
                          " " + _vm._s(_vm.gptSetting.bot_mode_strict) + " "
                        ),
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "float-right",
                          attrs: {
                            disabled: _vm.gptSetting.bot_mode_strict == null,
                            color: "red",
                            dark: _vm.gptSetting.bot_mode_strict != null,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.deleteSetting("bot_mode_strict")
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t("$vuetify.tooltip.delete")
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "font-weight-bold" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t(
                                  "$vuetify.gpt_profile.default_vision_profile"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c("v-list-item-content", { staticClass: "align-end" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.gptSetting.default_vision_profile) +
                            " "
                        ),
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "float-right",
                          attrs: {
                            disabled: !_vm.gptSetting.default_vision_profile,
                            color: "red",
                            dark: !!_vm.gptSetting.default_vision_profile,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.deleteSetting("default_vision_profile")
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t("$vuetify.tooltip.delete")
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "font-weight-bold" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t(
                                  "$vuetify.gpt_profile.max_feedback_rating"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c("v-list-item-content", { staticClass: "align-end" }, [
                        _vm._v(
                          " " + _vm._s(_vm.gptSetting.max_feedback_rating) + " "
                        ),
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "float-right",
                          attrs: {
                            disabled: !_vm.gptSetting.max_feedback_rating,
                            color: "red",
                            dark: !!_vm.gptSetting.max_feedback_rating,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.deleteSetting("max_feedback_rating")
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t("$vuetify.tooltip.delete")
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "font-weight-bold" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t(
                                  "$vuetify.gpt_profile.summary_settings"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "float-right mx-4",
                          attrs: {
                            disabled: !_vm.gptSetting.summary_settings,
                            color: "primary",
                            dark: !!_vm.gptSetting.summary_settings,
                          },
                          on: {
                            click: function ($event) {
                              _vm.summaryModal = true
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t(
                                  "$vuetify.gpt_profile.view_details"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "float-right",
                          attrs: {
                            disabled: !_vm.gptSetting.summary_settings,
                            color: "red",
                            dark: !!_vm.gptSetting.summary_settings,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.deleteSetting("summary_settings")
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t("$vuetify.tooltip.delete")
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("DialogModal", {
                attrs: {
                  title: _vm.$vuetify.lang.t(
                    "$vuetify.confirm_alert_title_txt"
                  ),
                  message: _vm.$vuetify.lang.t(
                    "$vuetify.gpt_profile.confirm_alert_msg_gpt_setting"
                  ),
                  confirmButtonText: _vm.$vuetify.lang.t(
                    "$vuetify.confirm_btn_txt"
                  ),
                  cancelButtonText: _vm.$vuetify.lang.t(
                    "$vuetify.cancel_btn_txt"
                  ),
                },
                on: {
                  onConfirmation: function ($event) {
                    return _vm.deleteGptSetting()
                  },
                  onCancel: function ($event) {
                    _vm.deleteDialog = false
                  },
                },
                model: {
                  value: _vm.deleteDialog,
                  callback: function ($$v) {
                    _vm.deleteDialog = $$v
                  },
                  expression: "deleteDialog",
                },
              }),
              _c(
                "v-dialog",
                {
                  attrs: { persistent: "", "max-width": "100%" },
                  model: {
                    value: _vm.gptSettingModal,
                    callback: function ($$v) {
                      _vm.gptSettingModal = $$v
                    },
                    expression: "gptSettingModal",
                  },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "d-flex flex-column",
                      staticStyle: {
                        "overflow-y": "scroll",
                        "overflow-x": "hidden",
                      },
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { xs: "12", md: "6" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-card-title",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.isSettingAvailable
                                                  ? _vm.$vuetify.lang.t(
                                                      "$vuetify.gpt_profile.update_settings"
                                                    )
                                                  : _vm.$vuetify.lang.t(
                                                      "$vuetify.gpt_profile.create_settings"
                                                    )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c(
                                    "h3",
                                    { staticClass: "font-weight-bold mb-5" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$vuetify.lang.t(
                                              "$vuetify.gpt_profile.available_gpt_models"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("p", {
                                    staticClass: "models-text mb-10",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.highlightedModels),
                                    },
                                  }),
                                  _c("v-divider"),
                                  _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "font-weight-bold mb-10 mt-10",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$vuetify.lang.t(
                                              "$vuetify.gpt_profile.summary_settings_example"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("v-divider"),
                                  _c("div", [
                                    _c("p", { staticClass: "mt-5" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.gpt_profile.summary_settings_explanation"
                                          )
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "p",
                                      { staticClass: "font-weight-bold" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.gpt_profile.required_fields"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("p", [
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-medium" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.gpt_profile.units"
                                              )
                                            )
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "red--text text--accent-4",
                                            },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v(
                                            _vm._s(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.gpt_profile.List_str"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(
                                        _vm._s(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.gpt_profile.units_text"
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("p", [
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-medium" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.gpt_profile.interval"
                                              )
                                            )
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "red--text text--accent-4",
                                            },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v(
                                            _vm._s(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.gpt_profile.List_str"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(
                                        _vm._s(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.gpt_profile.interval_text"
                                          )
                                        )
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "model-highlight font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.gpt_profile.daily"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("p", [
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-medium" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.gpt_profile.prompt"
                                              )
                                            )
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "red--text text--accent-4",
                                            },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v(": "),
                                        ]
                                      ),
                                      _vm._v(
                                        _vm._s(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.gpt_profile.prompt_text"
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                    _c("p", [
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-medium" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.gpt_profile.model"
                                              )
                                            )
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "red--text text--accent-4",
                                            },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v(": "),
                                        ]
                                      ),
                                      _vm._v(
                                        _vm._s(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.gpt_profile.model_text"
                                          )
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "p",
                                      { staticClass: "font-weight-bold" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.gpt_profile.optional"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("p", [
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-medium" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.gpt_profile.exclude_filters"
                                              )
                                            ) + " "
                                          ),
                                        ]
                                      ),
                                      _vm._v(
                                        _vm._s(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.gpt_profile.exclude_filters_text"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "mt-10" }, [
                                    _c("pre", [
                                      _c("code", { staticClass: "json-text" }, [
                                        _vm._v(_vm._s(_vm.defaultText)),
                                      ]),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          !_vm.gptSetting && !_vm.gptProfiles
                            ? _c("v-skeleton-loader", {
                                staticClass: "mr-2 mt-6",
                                attrs: { type: "list-item-three-line" },
                              })
                            : _c(
                                "v-form",
                                { ref: "gpt_setting", staticClass: "mt-4" },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            md: "12",
                                            xs: "12",
                                          },
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.gptProfiles,
                                              label: "Default Profile",
                                              outlined: "",
                                              "item-text": "name",
                                              "item-id": "id",
                                              "item-value": "id",
                                              rules: _vm.defaultProfileRules,
                                            },
                                            model: {
                                              value:
                                                _vm.gptSetting.default_profile,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.gptSetting,
                                                  "default_profile",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "gptSetting.default_profile",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: [true, false],
                                              label: "Bot Mode Strict",
                                              outlined: "",
                                            },
                                            model: {
                                              value:
                                                _vm.gptSetting.bot_mode_strict,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.gptSetting,
                                                  "bot_mode_strict",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "gptSetting.bot_mode_strict",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              rules: _vm.maxFeedbackRules,
                                              label: "Max Feedback Rating",
                                              outlined: "",
                                              type: "number",
                                            },
                                            model: {
                                              value:
                                                _vm.gptSetting
                                                  .max_feedback_rating,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.gptSetting,
                                                  "max_feedback_rating",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "gptSetting.max_feedback_rating",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "12" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.visionProfiles,
                                              disabled:
                                                _vm.visionProfiles &&
                                                _vm.visionProfiles.length < 1,
                                              label: "Default Vision Profile",
                                              outlined: "",
                                              "item-text": "name",
                                              "item-id": "id",
                                              "item-value": "id",
                                            },
                                            model: {
                                              value:
                                                _vm.gptSetting
                                                  .default_vision_profile,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.gptSetting,
                                                  "default_vision_profile",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "gptSetting.default_vision_profile",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "12" } },
                                        [
                                          _c("v-textarea", {
                                            attrs: {
                                              outlined: "",
                                              label: "Summary Settings",
                                              rules: _vm.summarySettingRules,
                                            },
                                            model: {
                                              value: _vm.summaryValue,
                                              callback: function ($$v) {
                                                _vm.summaryValue = $$v
                                              },
                                              expression: "summaryValue",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                          _c("v-divider"),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-card-actions",
                                    { staticClass: "justify-end" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "error",
                                            outlined: "",
                                            text: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.gptSettingModal = false
                                              _vm.getGptSetting()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.common.close"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "primary", dark: "" },
                                          on: {
                                            click: function ($event) {
                                              _vm.isSettingAvailable
                                                ? _vm.updateGptSetting()
                                                : _vm.createGptSetting()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.isSettingAvailable
                                                  ? _vm.$vuetify.lang.t(
                                                      "$vuetify.gpt_profile.update_settings"
                                                    )
                                                  : _vm.$vuetify.lang.t(
                                                      "$vuetify.gpt_profile.create_settings"
                                                    )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { persistent: "", "max-width": "100%" },
                  model: {
                    value: _vm.summaryModal,
                    callback: function ($$v) {
                      _vm.summaryModal = $$v
                    },
                    expression: "summaryModal",
                  },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "d-flex flex-column",
                      staticStyle: {
                        "overflow-y": "scroll",
                        "overflow-x": "hidden",
                      },
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { xs: "12", md: "6" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-card-title",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$vuetify.lang.t(
                                                  "$vuetify.gpt_profile.summary_settings"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _c("strong", [_vm._v(" Model:")]),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "response-details-text",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.gptSetting &&
                                                    _vm.gptSetting
                                                      .summary_settings &&
                                                    _vm.gptSetting
                                                      .summary_settings.model
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _c("strong", [_vm._v(" Prompt:")]),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "response-details-text",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.gptSetting &&
                                                    _vm.gptSetting
                                                      .summary_settings &&
                                                    _vm.gptSetting
                                                      .summary_settings.prompt
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _c("strong", [_vm._v(" Interval:")]),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "response-details-text",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.gptSetting &&
                                                    _vm.gptSetting
                                                      .summary_settings &&
                                                    _vm.gptSetting
                                                      .summary_settings.interval
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _c("strong", [
                                          _vm._v(" Exclude Filters:"),
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "response-details-text",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.gptSetting &&
                                                    _vm.gptSetting
                                                      .summary_settings &&
                                                    _vm.gptSetting
                                                      .summary_settings
                                                      .exclude_filters
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _c("strong", [_vm._v(" Units:")]),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "response-details-text",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.gptSetting &&
                                                    _vm.gptSetting
                                                      .summary_settings &&
                                                    _vm.gptSetting
                                                      .summary_settings.units
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-card-actions",
                                    { staticClass: "justify-end" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "error",
                                            outlined: "",
                                            text: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.summaryModal = false
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.common.close"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }