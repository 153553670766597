var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "elevation-0" },
        [
          _c("v-card-title", { staticClass: "font-weight-bold" }, [
            _vm._v(
              _vm._s(`${_vm.$vuetify.lang.t("$vuetify.details_header_txt")}`)
            ),
          ]),
          _c("v-divider"),
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.solution_name_label_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _c("p", { staticClass: "pb-4" }, [
                      _vm._v(" " + _vm._s(_vm.solutions.name) + " "),
                      _c(
                        "span",
                        { staticClass: "pa-1 primary white--text rounded" },
                        [_vm._v("English")]
                      ),
                    ]),
                    _c("p", {}, [
                      _vm._v(" " + _vm._s(_vm.solutions.name_ja) + " "),
                      _c(
                        "span",
                        { staticClass: "pa-1 primary white--text rounded" },
                        [_vm._v("日本語")]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.solution_header_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "v-list-item-content",
                    { staticClass: "align-end" },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _c(
                            "v-chip",
                            {
                              staticClass: "text-capitalize",
                              attrs: {
                                color:
                                  _vm.solutions.type.color &&
                                  _vm.solutions.type.color,
                                label: "",
                                outlined: "",
                                pill: "",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.solutions.type.name
                                      ? _vm.solutions.type.name
                                      : "N/A"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-sheet", { staticClass: "pt-3" }, [
                        _c(
                          "p",
                          { staticClass: "text-subtitle-1 font-weight-bold" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  `${_vm.$vuetify.lang.t(
                                    "$vuetify.solution_overview_label_txt"
                                  )}:`
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _vm.solutions && _vm.solutions.overview
                        ? _c(
                            "fieldset",
                            { staticClass: "rounded" },
                            [
                              _c(
                                "legend",
                                {
                                  staticClass:
                                    "primary white--text rounded py-1 px-2 ml-2",
                                },
                                [_vm._v("English")]
                              ),
                              _c("show-rich-text-data", {
                                attrs: {
                                  editorId: "reviewOverview",
                                  editorData: _vm.solutions.overview,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.solutions &&
                      _vm.solutions.overview_ja &&
                      _vm.solutions.overview_ja != {}
                        ? _c(
                            "fieldset",
                            { staticClass: "rounded mt-2" },
                            [
                              _c(
                                "legend",
                                {
                                  staticClass:
                                    "primary white--text rounded py-1 px-2 ml-2",
                                },
                                [_vm._v("日本語")]
                              ),
                              _c("show-rich-text-data", {
                                attrs: {
                                  editorId: "reviewOverviewJa",
                                  editorData: _vm.solutions.overview_ja,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-sheet", { staticClass: "pt-3" }, [
                        _c(
                          "p",
                          { staticClass: "text-subtitle-1 font-weight-bold" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  `${_vm.$vuetify.lang.t(
                                    "$vuetify.solution_description_placeholder_txt"
                                  )}:`
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _vm.solutions && _vm.solutions.description
                        ? _c(
                            "fieldset",
                            { staticClass: "rounded" },
                            [
                              _c(
                                "legend",
                                {
                                  staticClass:
                                    "primary white--text rounded py-1 px-2 ml-2",
                                },
                                [_vm._v("English")]
                              ),
                              _c("show-rich-text-data", {
                                attrs: {
                                  editorId: "reviewDescription",
                                  editorData: _vm.solutions.description,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.solutions && _vm.solutions.description_ja
                        ? _c(
                            "fieldset",
                            { staticClass: "rounded mt-2" },
                            [
                              _c(
                                "legend",
                                {
                                  staticClass:
                                    "primary white--text rounded py-1 px-2 ml-2",
                                },
                                [_vm._v("日本語")]
                              ),
                              _c("show-rich-text-data", {
                                attrs: {
                                  editorId: "reviewDescriptionJa",
                                  editorData: _vm.solutions.description_ja,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold align-self-start" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.industry_label_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-wrap" },
                      _vm._l(_vm.solutions.industry, function (element, index) {
                        return _c("div", { key: index, staticClass: "pa-1" }, [
                          _vm._v(" " + _vm._s(element.name) + ", "),
                        ])
                      }),
                      0
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-sheet", { staticClass: "pt-3" }, [
                        _c(
                          "p",
                          { staticClass: "text-subtitle-1 font-weight-bold" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  `${_vm.$vuetify.lang.t(
                                    "$vuetify.use_case_placeholder_txt"
                                  )}:`
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _vm.solutions && _vm.solutions.use_cases
                        ? _c(
                            "fieldset",
                            { staticClass: "rounded" },
                            [
                              _c(
                                "legend",
                                {
                                  staticClass:
                                    "primary white--text rounded py-1 px-2 ml-2",
                                },
                                [_vm._v("English")]
                              ),
                              _c("show-rich-text-data", {
                                attrs: {
                                  editorId: "reviewUseCases",
                                  editorData: _vm.solutions.use_cases,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.solutions && _vm.solutions.use_cases_ja
                        ? _c(
                            "fieldset",
                            { staticClass: "rounded mt-2" },
                            [
                              _c(
                                "legend",
                                {
                                  staticClass:
                                    "primary white--text rounded py-1 px-2 ml-2",
                                },
                                [_vm._v("日本語")]
                              ),
                              _c("show-rich-text-data", {
                                attrs: {
                                  editorId: "reviewUseCasesJa",
                                  editorData: _vm.solutions.use_cases_ja,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold align-self-start" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.linked_solution_label_txt_2"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "v-list-item-content",
                    { staticClass: "align-end" },
                    _vm._l(
                      _vm.solutions.linked_solution,
                      function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "py-2 d-flex align-center",
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-2 align-self-start",
                                attrs: {
                                  color:
                                    _vm.solutions.type.color &&
                                    _vm.solutions.type.color,
                                  size: "21",
                                },
                              },
                              [_vm._v(" task_alt ")]
                            ),
                            _c("div", [_vm._v(_vm._s(item.name))]),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold align-self-start" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.language_label_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-wrap" },
                      _vm._l(_vm.solutions.language, function (element, index) {
                        return _c("div", { key: index, staticClass: "pa-1" }, [
                          _vm._v(" " + _vm._s(element.name) + ", "),
                        ])
                      }),
                      0
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "elevation-0" },
        [
          _c("v-card-title", { staticClass: "font-weight-bold" }, [
            _vm._v(
              " " +
                _vm._s(`${_vm.$vuetify.lang.t("$vuetify.pricing_step_txt")}`) +
                " "
            ),
          ]),
          _c("v-divider"),
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t("$vuetify.cpu_label_txt")}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.solutions.min_cpu
                            ? _vm.formatNum(_vm.solutions.min_cpu)
                            : "N/A"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.memory_label_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.solutions.min_memory
                            ? _vm.formatNum(_vm.solutions.min_memory)
                            : "N/A"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t("$vuetify.gpu_label_txt")}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.solutions.min_gpu
                            ? _vm.formatNum(_vm.solutions.min_gpu)
                            : "N/A"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.benchmark_request_label_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.solutions.page_view
                            ? _vm.formatNum(_vm.solutions.page_view)
                            : "N/A"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.benchmark_rps_label_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.solutions.rps
                            ? _vm.formatNum(_vm.solutions.rps)
                            : "N/A"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.base_price_label_txt_1"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.solutions.price
                            ? _vm.formatNum(_vm.solutions.price)
                            : "N/A"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.additional_price_label_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.solutions.additional_price
                            ? _vm.formatNum(_vm.solutions.additional_price)
                            : "N/A"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "elevation-0" },
        [
          _c("v-card-title", { staticClass: "font-weight-bold" }, [
            _vm._v(
              " " +
                _vm._s(`${_vm.$vuetify.lang.t("$vuetify.metadata_step_txt")}`) +
                " "
            ),
          ]),
          _c("v-divider"),
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold align-self-start" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.cover_img_label_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _c(
                      "div",
                      [
                        _c("v-img", {
                          attrs: {
                            src: _vm.solutionImg,
                            width: "150",
                            height: "100",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.version_label_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.solutions.version ? _vm.solutions.version : "N/A"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.source_code_label_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.solutions.source_url
                            ? _vm.solutions.source_url
                            : "N/A"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.sandbox_label_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.solutions.sanbdbox_url
                            ? _vm.solutions.sanbdbox_url
                            : "N/A"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.api_docs_label_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.solutions.document_url
                            ? _vm.solutions.document_url
                            : "N/A"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.tutorial_label_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.solutions.tutorial_location
                            ? _vm.solutions.tutorial_location
                            : "N/A"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }