var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { "fill-height": "", fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { xs: "12", md: "12" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "mx-auto px-2", attrs: { outlined: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { xs: "12", md: "6" } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "px-2 primary--text font-weight-bold",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.wallet.account_details"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", xs: "12" } },
                            [
                              !_vm.accountDetails || _vm.detailsLoading
                                ? _c(
                                    "v-row",
                                    {
                                      staticClass: "pt-16",
                                      staticStyle: { height: "200px" },
                                      attrs: { justify: "center" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold text-h4 d-block",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.common.loading"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c("v-progress-circular", {
                                        staticClass: "mt-16",
                                        staticStyle: {
                                          transform: "translateX(-80px)",
                                        },
                                        attrs: {
                                          indeterminate: "",
                                          color: "primary",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : [
                                    _c(
                                      "v-row",
                                      { staticClass: "pa-2" },
                                      [
                                        _c("v-col", { attrs: { cols: "3" } }, [
                                          _c(
                                            "div",
                                            { staticClass: "font-weight-bold" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$vuetify.lang.t(
                                                    "$vuetify.wallet.balance"
                                                  )
                                                ) + ":"
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "9" } },
                                          [
                                            _vm.accountDetails === "inactive"
                                              ? _c(
                                                  "v-row",
                                                  {
                                                    attrs: { "no-gutters": "" },
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "d-flex flex-wrap",
                                                        attrs: {
                                                          cols: "6",
                                                          md: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-item-avatar",
                                                          [
                                                            _c(
                                                              "v-avatar",
                                                              {
                                                                attrs: {
                                                                  left: "",
                                                                  size: "35px",
                                                                  color:
                                                                    "lighten-2",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "font-weight-bold",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-img",
                                                                      {
                                                                        attrs: {
                                                                          "max-height":
                                                                            "35px",
                                                                          "max-width":
                                                                            "35px",
                                                                          src: _vm.xlmIcon,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-list-item-content",
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _vm._v(" XLM "),
                                                                _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      top: "",
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "activator",
                                                                            fn: function ({
                                                                              on,
                                                                              attrs,
                                                                            }) {
                                                                              return [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        staticClass:
                                                                                          "ml-2",
                                                                                        attrs:
                                                                                          {
                                                                                            small:
                                                                                              "",
                                                                                          },
                                                                                      },
                                                                                      "v-icon",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _vm._v(
                                                                                      " mdi-information "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        false,
                                                                        582069497
                                                                      ),
                                                                  },
                                                                  [
                                                                    _c("div", [
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "font-weight-bold",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$vuetify.lang.t(
                                                                                    "$vuetify.wallet.buying_liabilities"
                                                                                  )
                                                                                ) +
                                                                                  ":"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " 0.00 "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "font-weight-bold",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$vuetify.lang.t(
                                                                                    "$vuetify.wallet.selling_liabilities"
                                                                                  )
                                                                                ) +
                                                                                  ":"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " 0.00 "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-list-item-subtitle",
                                                              [
                                                                _c("Price", {
                                                                  attrs: {
                                                                    amount:
                                                                      "0.00",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "v-row",
                                                  {
                                                    attrs: { "no-gutters": "" },
                                                  },
                                                  _vm._l(
                                                    _vm.accountDetails.balances,
                                                    function (asset, index) {
                                                      return _c(
                                                        "v-col",
                                                        {
                                                          key: index,
                                                          staticClass:
                                                            "d-flex flex-wrap",
                                                          attrs: {
                                                            cols: "6",
                                                            md: "3",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-item-avatar",
                                                            [
                                                              _c(
                                                                "v-avatar",
                                                                {
                                                                  attrs: {
                                                                    left: "",
                                                                    size: "35px",
                                                                    color:
                                                                      _vm.allAsset.find(
                                                                        (a) =>
                                                                          a.asset_code ===
                                                                          asset.asset_code
                                                                      ) &&
                                                                      _vm.allAsset.filter(
                                                                        (a) =>
                                                                          a.asset_code ===
                                                                          asset.asset_code
                                                                      ).length >
                                                                        0 &&
                                                                      _vm.allAsset.filter(
                                                                        (a) =>
                                                                          a.asset_code ===
                                                                          asset.asset_code
                                                                      )[0]
                                                                        .asset_icon
                                                                        ? "lighten-2"
                                                                        : "primary lighten-2",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "font-weight-bold",
                                                                    },
                                                                    [
                                                                      _vm.allAsset.find(
                                                                        (a) =>
                                                                          a.asset_code ===
                                                                          asset.asset_code
                                                                      ) &&
                                                                      _vm.allAsset.filter(
                                                                        (a) =>
                                                                          a.asset_code ===
                                                                          asset.asset_code
                                                                      ).length >
                                                                        0 &&
                                                                      _vm.allAsset.filter(
                                                                        (a) =>
                                                                          a.asset_code ===
                                                                          asset.asset_code
                                                                      )[0]
                                                                        .asset_icon
                                                                        ? _c(
                                                                            "v-img",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  "max-height":
                                                                                    "35px",
                                                                                  "max-width":
                                                                                    "35px",
                                                                                  src: _vm.allAsset.filter(
                                                                                    (
                                                                                      a
                                                                                    ) =>
                                                                                      a.asset_code ===
                                                                                      asset.asset_code
                                                                                  )[0]
                                                                                    .asset_icon,
                                                                                },
                                                                            }
                                                                          )
                                                                        : _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "fix-asset-font",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    asset.asset_code
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-list-item-content",
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        asset.asset_code
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                  _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        top: "",
                                                                      },
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "activator",
                                                                              fn: function ({
                                                                                on,
                                                                                attrs,
                                                                              }) {
                                                                                return [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    _vm._g(
                                                                                      _vm._b(
                                                                                        {
                                                                                          staticClass:
                                                                                            "ml-2",
                                                                                          attrs:
                                                                                            {
                                                                                              small:
                                                                                                "",
                                                                                            },
                                                                                        },
                                                                                        "v-icon",
                                                                                        attrs,
                                                                                        false
                                                                                      ),
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _vm._v(
                                                                                        " mdi-information "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              },
                                                                            },
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "font-weight-bold",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$vuetify.lang.t(
                                                                                        "$vuetify.wallet.buying_liabilities"
                                                                                      )
                                                                                    ) +
                                                                                      ":"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    asset.buying_liabilities &&
                                                                                      Number.parseFloat(
                                                                                        asset.buying_liabilities
                                                                                      ).toFixed(
                                                                                        2
                                                                                      )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "font-weight-bold",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$vuetify.lang.t(
                                                                                        "$vuetify.wallet.selling_liabilities"
                                                                                      )
                                                                                    ) +
                                                                                      ":"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    asset.selling_liabilities &&
                                                                                      Number.parseFloat(
                                                                                        asset.selling_liabilities
                                                                                      ).toFixed(
                                                                                        2
                                                                                      )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-list-item-subtitle",
                                                                [
                                                                  _c("Price", {
                                                                    attrs: {
                                                                      amount:
                                                                        asset.balance,
                                                                    },
                                                                  }),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-divider"),
                                    _c(
                                      "v-row",
                                      { staticClass: "pa-2" },
                                      [
                                        _c("v-col", { attrs: { cols: "3" } }, [
                                          _c(
                                            "div",
                                            { staticClass: "font-weight-bold" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$vuetify.lang.t(
                                                    "$vuetify.wallet.reserve"
                                                  )
                                                ) + ":"
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "9" } },
                                          [
                                            _c(
                                              "v-row",
                                              { attrs: { "no-gutters": "" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "12" } },
                                                  [
                                                    _c(
                                                      "v-list",
                                                      [
                                                        _c(
                                                          "v-list-item",
                                                          {
                                                            staticClass:
                                                              "item-height",
                                                          },
                                                          [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  top: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u([
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                        attrs,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-list-item-content",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  staticClass:
                                                                                    "font-weight-bold py-0",
                                                                                },
                                                                                "v-list-item-content",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$vuetify.lang.t(
                                                                                    "$vuetify.wallet.base_network_reserve"
                                                                                  )
                                                                                ) +
                                                                                  ": "
                                                                              ),
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  staticClass:
                                                                                    "mx-2",
                                                                                  staticStyle:
                                                                                    {
                                                                                      transform:
                                                                                        "translateY(-15px)",
                                                                                    },
                                                                                  attrs:
                                                                                    {
                                                                                      "x-small":
                                                                                        "",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " mdi-information "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ]),
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$vuetify.lang.t(
                                                                        "$vuetify.wallet.base_network_reserve_tooltip"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "align-end py-0",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.accountDetails &&
                                                                      _vm
                                                                        .accountDetails
                                                                        .min_reserve
                                                                      ? _vm
                                                                          .accountDetails
                                                                          .min_reserve
                                                                          .base_net_reserve
                                                                      : "0.00"
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-list-item",
                                                          {
                                                            staticClass:
                                                              "item-height",
                                                          },
                                                          [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  top: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u([
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                        attrs,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-list-item-content",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  staticClass:
                                                                                    "font-weight-bold py-0",
                                                                                },
                                                                                "v-list-item-content",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                "Doozie " +
                                                                                  _vm._s(
                                                                                    _vm.$vuetify.lang.t(
                                                                                      "$vuetify.wallet.reserve"
                                                                                    )
                                                                                  ) +
                                                                                  ": "
                                                                              ),
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-2",
                                                                                  staticStyle:
                                                                                    {
                                                                                      transform:
                                                                                        "translateY(-15px)",
                                                                                    },
                                                                                  attrs:
                                                                                    {
                                                                                      "x-small":
                                                                                        "",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " mdi-information "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ]),
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$vuetify.lang.t(
                                                                        "$vuetify.wallet.doozie_reserve_tooltip"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "align-end py-0",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.accountDetails &&
                                                                      _vm
                                                                        .accountDetails
                                                                        .min_reserve
                                                                      ? _vm
                                                                          .accountDetails
                                                                          .min_reserve
                                                                          .doozie_reserve
                                                                      : "0.00"
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-list-item",
                                                          {
                                                            staticClass:
                                                              "item-height",
                                                          },
                                                          [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  top: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u([
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                        attrs,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-list-item-content",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  staticClass:
                                                                                    "font-weight-bold py-0",
                                                                                },
                                                                                "v-list-item-content",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$vuetify.lang.t(
                                                                                    "$vuetify.wallet.per_entry_reserve"
                                                                                  )
                                                                                ) +
                                                                                  ": "
                                                                              ),
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-2",
                                                                                  staticStyle:
                                                                                    {
                                                                                      transform:
                                                                                        "translateY(-15px)",
                                                                                    },
                                                                                  attrs:
                                                                                    {
                                                                                      "x-small":
                                                                                        "",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " mdi-information "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ]),
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "P" +
                                                                      _vm._s(
                                                                        _vm.$vuetify.lang.t(
                                                                          "$vuetify.wallet.per_entry_reserve_tooltip"
                                                                        )
                                                                      )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "align-end py-0",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.accountDetails &&
                                                                      _vm
                                                                        .accountDetails
                                                                        .min_reserve
                                                                      ? _vm
                                                                          .accountDetails
                                                                          .min_reserve
                                                                          .per_entry_reserve
                                                                      : "0.00"
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-list-item",
                                                          {
                                                            staticClass:
                                                              "item-height",
                                                          },
                                                          [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  top: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u([
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                        attrs,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-list-item-content",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  staticClass:
                                                                                    "font-weight-bold py-0",
                                                                                },
                                                                                "v-list-item-content",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$vuetify.lang.t(
                                                                                    "$vuetify.wallet.entry_count"
                                                                                  )
                                                                                ) +
                                                                                  ": "
                                                                              ),
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-2",
                                                                                  staticStyle:
                                                                                    {
                                                                                      transform:
                                                                                        "translateY(-15px)",
                                                                                    },
                                                                                  attrs:
                                                                                    {
                                                                                      "x-small":
                                                                                        "",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " mdi-information "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ]),
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$vuetify.lang.t(
                                                                        "$vuetify.wallet.entry_count_tooltip"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "align-end py-0",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.accountDetails &&
                                                                      _vm
                                                                        .accountDetails
                                                                        .min_reserve
                                                                      ? _vm
                                                                          .accountDetails
                                                                          .min_reserve
                                                                          .entry_count
                                                                      : "0.00"
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-list-item",
                                                          {
                                                            staticClass:
                                                              "item-height",
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "font-weight-bold py-0",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$vuetify.lang.t(
                                                                      "$vuetify.wallet.calculation"
                                                                    )
                                                                  ) + ":"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "align-end py-0",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.accountDetails &&
                                                                      _vm
                                                                        .accountDetails
                                                                        .min_reserve
                                                                      ? _vm
                                                                          .accountDetails
                                                                          .min_reserve
                                                                          .calculation
                                                                      : "0.00"
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c("v-divider"),
                                                        _c(
                                                          "v-list-item",
                                                          {
                                                            staticClass:
                                                              "item-height",
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "font-weight-bold py-0",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$vuetify.lang.t(
                                                                      "$vuetify.wallet.total_reserve"
                                                                    )
                                                                  ) + ":"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "align-end py-0",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.accountDetails &&
                                                                      _vm
                                                                        .accountDetails
                                                                        .min_reserve
                                                                      ? _vm
                                                                          .accountDetails
                                                                          .min_reserve
                                                                          .total_reserve
                                                                      : "0.00"
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-divider"),
                                    _c(
                                      "v-row",
                                      { staticClass: "pa-2" },
                                      [
                                        _c("v-col", { attrs: { cols: "3" } }, [
                                          _c(
                                            "div",
                                            { staticClass: "font-weight-bold" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$vuetify.lang.t(
                                                    "$vuetify.wallet.wallet_account"
                                                  )
                                                ) + ":"
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "9" } },
                                          [
                                            _c(
                                              "v-row",
                                              { attrs: { "no-gutters": "" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "12" } },
                                                  [
                                                    _c(
                                                      "v-list",
                                                      [
                                                        _c(
                                                          "v-list-item",
                                                          {
                                                            staticClass:
                                                              "item-height",
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "font-weight-bold py-0",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$vuetify.lang.t(
                                                                      "$vuetify.common.name"
                                                                    )
                                                                  ) + ":"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "align-end py-0",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.item
                                                                        .account_name
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-list-item",
                                                          {
                                                            staticClass:
                                                              "item-height",
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "font-weight-bold py-0",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$vuetify.lang.t(
                                                                      "$vuetify.wallet.wallet_account_status"
                                                                    )
                                                                  ) + ":"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "align-end py-0",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "v-chip",
                                                                      {
                                                                        staticClass:
                                                                          "text-capitalize",
                                                                        attrs: {
                                                                          color:
                                                                            _vm
                                                                              .item
                                                                              .status ===
                                                                            "active"
                                                                              ? "primary"
                                                                              : "error",
                                                                          small:
                                                                            "",
                                                                          outlined:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm
                                                                                .item
                                                                                .status
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-list-item",
                                                          {
                                                            staticClass:
                                                              "item-height",
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "font-weight-bold py-0",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$vuetify.lang.t(
                                                                      "$vuetify.wallet.public_key"
                                                                    )
                                                                  ) + ":"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "align-end py-0",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.passChar(
                                                                            _vm
                                                                              .item
                                                                              .public_key
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                    _c(
                                                                      "v-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          top: "",
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "activator",
                                                                                fn: function ({
                                                                                  on,
                                                                                }) {
                                                                                  return [
                                                                                    _c(
                                                                                      "v-btn",
                                                                                      _vm._g(
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              icon: "",
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.copyKeyOnClick(
                                                                                                  _vm
                                                                                                    .item
                                                                                                    .public_key
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _c(
                                                                                          "v-icon",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "mdi-content-copy"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ]
                                                                          ),
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$vuetify.lang.t(
                                                                                "$vuetify.wallet.wallet_click_for_copy"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-list-item",
                                                          {
                                                            staticClass:
                                                              "item-height",
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "font-weight-bold py-0",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$vuetify.lang.t(
                                                                      "$vuetify.wallet.secret_key"
                                                                    )
                                                                  ) + ":"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "align-end py-0",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _vm._v(
                                                                      " S***************** "
                                                                    ),
                                                                    _c(
                                                                      "v-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          top: "",
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "activator",
                                                                                fn: function ({
                                                                                  on,
                                                                                }) {
                                                                                  return [
                                                                                    _c(
                                                                                      "v-btn",
                                                                                      _vm._g(
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              icon: "",
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.showSecretModal()
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _c(
                                                                                          "v-icon",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "mdi-eye"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ]
                                                                          ),
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$vuetify.lang.t(
                                                                                "$vuetify.wallet.click_to_show_secret"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-list-item",
                                                          {
                                                            staticClass:
                                                              "item-height",
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "font-weight-bold py-0",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$vuetify.lang.t(
                                                                      "$vuetify.common.actions"
                                                                    )
                                                                  ) + ":"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "align-end my-2",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "my-2",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.editAccountAction()
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                small:
                                                                                  "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " mdi-pencil "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$vuetify.lang.t(
                                                                                "$vuetify.wallet.edit_account"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "error",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.deleteAccountAction()
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                small:
                                                                                  "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " mdi-delete "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$vuetify.lang.t(
                                                                                "$vuetify.wallet.delete_account"
                                                                              )
                                                                            )
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c("v-spacer"),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500", persistent: "" },
          model: {
            value: _vm.editAccountModal,
            callback: function ($$v) {
              _vm.editAccountModal = $$v
            },
            expression: "editAccountModal",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$vuetify.lang.t("$vuetify.wallet.edit_account")
                    ) +
                    " "
                ),
              ]),
              _c(
                "ValidationObserver",
                { ref: "firstStep" },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "12" } },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: _vm.$vuetify.lang.t(
                                    "$vuetify.wallet.account_name"
                                  ),
                                  rules: "required|is_numeric",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: _vm.$vuetify.lang.t(
                                              "$vuetify.wallet.account_name"
                                            ),
                                            outlined: "",
                                            "error-messages": errors,
                                          },
                                          model: {
                                            value: _vm.account_name,
                                            callback: function ($$v) {
                                              _vm.account_name = $$v
                                            },
                                            expression: "account_name",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error" },
                      on: {
                        click: function ($event) {
                          return _vm.cancelClick()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$vuetify.lang.t("$vuetify.common.cancel"))
                      ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.continueEdit()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$vuetify.lang.t("$vuetify.common.continue"))
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("PasscodeCheck", {
        attrs: {
          isCancelBtn: _vm.isCancelBtn,
          isLoading: _vm.loading,
          message: _vm.message,
        },
        on: { confirm: _vm.confirm, cancel: _vm.cancel },
        model: {
          value: _vm.passcode,
          callback: function ($$v) {
            _vm.passcode = $$v
          },
          expression: "passcode",
        },
      }),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.timeout },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.common.copied")))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }