var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": false,
        transition: "scale-transition",
        "offset-y": "",
        "max-width": "290px",
        "min-width": "290px",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-text-field",
                _vm._g(
                  {
                    attrs: {
                      label: _vm.label,
                      hint: _vm.hint,
                      "persistent-hint": "",
                      readonly: "",
                    },
                    model: {
                      value: _vm.inputVal,
                      callback: function ($$v) {
                        _vm.inputVal = $$v
                      },
                      expression: "inputVal",
                    },
                  },
                  on
                )
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.showPicker,
        callback: function ($$v) {
          _vm.showPicker = $$v
        },
        expression: "showPicker",
      },
    },
    [
      _c("v-date-picker", {
        attrs: { "no-title": "", type: _vm.type },
        on: { input: _vm.onInput },
        model: {
          value: _vm.inputVal,
          callback: function ($$v) {
            _vm.inputVal = $$v
          },
          expression: "inputVal",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }