var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { app: "", "clipped-left": "", dark: "", color: "primary" } },
        [
          _c(
            "v-toolbar-title",
            [
              _c(
                "router-link",
                {
                  staticClass: "headline",
                  staticStyle: { cursor: "pointer" },
                  attrs: { to: "/", tag: "span" },
                },
                [
                  _vm.$vuetify.breakpoint.name === "xs" ||
                  _vm.$vuetify.breakpoint.name === "sm"
                    ? _c("v-app-bar-nav-icon", {
                        class: {
                          "d-none": this.$route.path === "/setup-accounts",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            _vm.sidebar = !_vm.sidebar
                          },
                        },
                      })
                    : _c("v-app-bar-nav-icon", {
                        class: {
                          "d-none": this.$route.path === "/setup-accounts",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            _vm.mini = !_vm.mini
                          },
                        },
                      }),
                  _c("img", {
                    staticClass: "logo",
                    attrs: {
                      src: require("@/assets/images/logo_white_horizontal.png"),
                      alt: "Gigalogy",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-spacer"),
          _c("switch-language"),
          _c(
            "v-menu",
            {
              attrs: { "offset-y": "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _c(
                        "div",
                        _vm._g({ staticClass: "d-flex align-center" }, on),
                        [
                          _c(
                            "v-avatar",
                            {
                              staticClass: "ml-2",
                              staticStyle: { cursor: "pointer" },
                              attrs: {
                                size: "35px",
                                color: "primary lighten-2",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "font-weight-bold logo-text" },
                                [_vm._v(_vm._s(_vm.nameFirstCharacter))]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "hidden-sm-and-down" },
                            [
                              _c(
                                "v-btn",
                                { staticClass: "pa-1", attrs: { text: "" } },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "text-capitalize" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.organization.status === "active"
                                            ? _vm.organization.first_name
                                            : _vm.organization.status ===
                                              "created"
                                            ? _vm.account.account_name
                                            : "Hello"
                                        ) + " "
                                      ),
                                    ]
                                  ),
                                  _c("v-icon", { staticClass: "white--text" }, [
                                    _vm._v("expand_more"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "v-list",
                _vm._l(_vm.menuItems, function (item) {
                  return _c(
                    "v-list-item",
                    {
                      key: item.text,
                      attrs: { router: "", dense: "", to: item.route },
                      on: {
                        click: function ($event) {
                          return _vm.menuActionClick(item.action)
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        { staticClass: "mr-2" },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v(_vm._s(item.icon)),
                          ]),
                        ],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v(_vm._s(item.text))]),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      Object.keys(_vm.organization).length && !_vm.organization.email_verified
        ? _c(
            "v-banner",
            {
              staticClass: "mx-0 banner-style",
              staticStyle: { "z-index": "5" },
              attrs: { app: "", outlined: "", "single-line": "" },
            },
            [
              _c(
                "v-row",
                { attrs: { align: "center", justify: "center" } },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mx-2",
                      attrs: {
                        slot: "icon",
                        color: "rgba(241, 101, 8, 0.986)",
                        size: "30",
                      },
                      slot: "icon",
                    },
                    [_vm._v(" warning_amber ")]
                  ),
                  _c("h4", { staticClass: "mr-2" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$vuetify.lang.t(
                          "$vuetify.resend_verification_email_msg_txt_2"
                        )
                      )
                    ),
                  ]),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        small: "",
                        outlined: "",
                        loading: _vm.emailSendLoading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmitClick()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$vuetify.lang.t(
                            "$vuetify.resend_verification_email_btn_txt"
                          )
                        )
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "side-navbar", class: { "navbar-toggle": _vm.mini } },
        [
          _c(
            "div",
            [
              _c(
                "v-card",
                { class: { "d-none": this.$route.path === "/setup-accounts" } },
                [
                  _c(
                    "v-navigation-drawer",
                    {
                      staticClass: "fixed hidden-sm-and-down",
                      class: {
                        "mt-13":
                          _vm.isVisible() || !_vm.organization.email_verified,
                      },
                      attrs: { "mini-variant": _vm.mini, permanent: "" },
                      model: {
                        value: _vm.drawer,
                        callback: function ($$v) {
                          _vm.drawer = $$v
                        },
                        expression: "drawer",
                      },
                    },
                    [
                      _c(
                        "v-list",
                        _vm._l(_vm.navItems, function (item) {
                          return _c(
                            "div",
                            { key: item.text },
                            [
                              _vm.authorize(item.auth) &&
                              (!item.children || item.children.length === 0)
                                ? _c(
                                    "v-list-item",
                                    {
                                      attrs: {
                                        to: item.route,
                                        link: "",
                                        exact: true,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { medium: "" } },
                                            [_vm._v(_vm._s(item.icon))]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(_vm._s(item.text)),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm.authorize(item.auth) &&
                                  item.children &&
                                  item.children.length > 0
                                ? _c(
                                    "v-list-group",
                                    {
                                      attrs: {
                                        color: "secondary",
                                        "no-action": "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "v-list-item-icon",
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { medium: "" } },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.icon)
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c("v-list-item-title", [
                                                      _vm._v(_vm._s(item.text)),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.navSelectedChild,
                                        callback: function ($$v) {
                                          _vm.navSelectedChild = $$v
                                        },
                                        expression: "navSelectedChild",
                                      },
                                    },
                                    _vm._l(item.children, function (child, i) {
                                      return _c(
                                        "v-list-item",
                                        {
                                          key: i,
                                          class: {
                                            "navbar-active": child.active,
                                          },
                                          attrs: { link: "", to: child.route },
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { medium: "" } },
                                                [_vm._v(_vm._s(child.icon))]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(_vm._s(child.text)),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _c("v-divider"),
                      _c(
                        "v-list",
                        _vm._l(_vm.actionItems, function (item, index) {
                          return _c(
                            "div",
                            { key: item.text },
                            [
                              _vm.authorize(item.auth)
                                ? _c(
                                    "v-list-item",
                                    {
                                      attrs: {
                                        to: item.route ? item.route : null,
                                        href: item.href ? item.href : null,
                                        target: item.href
                                          ? "_blank"
                                          : undefined,
                                        link: "",
                                        exact: true,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { medium: "" } },
                                            [_vm._v(_vm._s(item.icon))]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              " " + _vm._s(item.text) + " "
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      item.launch &&
                                      index === _vm.actionItems.length - 1
                                        ? _c(
                                            "v-list-item-icon",
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    medium: "",
                                                    color:
                                                      _vm.launch_icon_color,
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.launch))]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-navigation-drawer",
                    {
                      staticClass: "hidden-md-and-up mt-12",
                      attrs: { app: "", left: "", "offset-y": "" },
                      model: {
                        value: _vm.sidebar,
                        callback: function ($$v) {
                          _vm.sidebar = $$v
                        },
                        expression: "sidebar",
                      },
                    },
                    [
                      _c(
                        "v-list",
                        _vm._l(_vm.navItems, function (item) {
                          return _c(
                            "div",
                            { key: item.text },
                            [
                              _vm.authorize(item.auth) &&
                              (!item.children || item.children.length === 0)
                                ? _c(
                                    "v-list-item",
                                    {
                                      attrs: {
                                        to: item.route,
                                        link: "",
                                        exact: true,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { medium: "" } },
                                            [_vm._v(_vm._s(item.icon))]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(_vm._s(item.text)),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm.authorize(item.auth) &&
                                  item.children &&
                                  item.children.length > 0
                                ? _c(
                                    "v-list-group",
                                    {
                                      attrs: {
                                        color: "secondary",
                                        "no-action": "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "v-list-item-icon",
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { medium: "" } },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.icon)
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c("v-list-item-title", [
                                                      _vm._v(_vm._s(item.text)),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.navSelectedChild,
                                        callback: function ($$v) {
                                          _vm.navSelectedChild = $$v
                                        },
                                        expression: "navSelectedChild",
                                      },
                                    },
                                    _vm._l(item.children, function (child, i) {
                                      return _c(
                                        "v-list-item",
                                        {
                                          key: i,
                                          class: {
                                            "navbar-active": child.active,
                                          },
                                          attrs: { link: "", to: child.route },
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { medium: "" } },
                                                [_vm._v(_vm._s(child.icon))]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(_vm._s(child.text)),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _c("v-divider"),
                      _c(
                        "v-list",
                        _vm._l(_vm.actionItems, function (item, index) {
                          return _c(
                            "div",
                            { key: item.text },
                            [
                              _vm.authorize(item.auth)
                                ? _c(
                                    "v-list-item",
                                    {
                                      attrs: {
                                        to: item.route ? item.route : null,
                                        href: item.href ? item.href : null,
                                        target: item.href
                                          ? "_blank"
                                          : undefined,
                                        link: "",
                                        exact: true,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { medium: "" } },
                                            [_vm._v(_vm._s(item.icon))]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              " " + _vm._s(item.text) + " "
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      item.launch &&
                                      index === _vm.actionItems.length - 1
                                        ? _c(
                                            "v-list-item-icon",
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    medium: "",
                                                    color:
                                                      _vm.launch_icon_color,
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.launch))]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._t("default"),
        ],
        2
      ),
      _c("ProgressBar"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }