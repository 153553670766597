var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      attrs: { color: _vm.alert.color, top: "" },
      model: {
        value: _vm.alert.show,
        callback: function ($$v) {
          _vm.$set(_vm.alert, "show", $$v)
        },
        expression: "alert.show",
      },
    },
    [
      _c(
        "v-row",
        { staticClass: "py-0 my-0" },
        [
          _c(
            "v-col",
            { staticClass: "py-0 my-0", attrs: { cols: "12", md: "11" } },
            [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]
          ),
          _c(
            "v-col",
            { staticClass: "py-0 my-0", attrs: { cols: "12", md: "1" } },
            [
              _c(
                "v-icon",
                {
                  attrs: { small: "", dark: "" },
                  on: {
                    click: function ($event) {
                      _vm.alert.show = false
                    },
                  },
                },
                [_vm._v(" close ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }