import { render, staticRenderFns } from "./AccountInformation.vue?vue&type=template&id=3503a4fe"
import script from "./AccountInformation.vue?vue&type=script&lang=js"
export * from "./AccountInformation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3503a4fe')) {
      api.createRecord('3503a4fe', component.options)
    } else {
      api.reload('3503a4fe', component.options)
    }
    module.hot.accept("./AccountInformation.vue?vue&type=template&id=3503a4fe", function () {
      api.rerender('3503a4fe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/MyProfile/AccountInformation.vue"
export default component.exports