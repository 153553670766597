var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "pl-12 align-self-center",
              attrs: { cols: "12", md: "2" },
            },
            [
              _c("v-card", { staticClass: "pa-2", attrs: { flat: "" } }, [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      `${_vm.$vuetify.lang.t("$vuetify.project.items_train")}`
                    )
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "12", md: "8" } }),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "2" } },
            [
              !this.inProgress
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.startTraining },
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-play")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t("$vuetify.tooltip.start")}`
                          ) +
                          " "
                      ),
                    ],
                    1
                  )
                : _c(
                    "v-btn",
                    {
                      staticClass: "mr-4 secondary-disabled-button",
                      attrs: { color: "primary" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.project.training"
                            )}`
                          ) +
                          " "
                      ),
                      _c("v-progress-circular", {
                        attrs: {
                          indeterminate: "",
                          size: 15,
                          width: 2,
                          color: "white",
                        },
                      }),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "pl-12" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", xs: "12", md: "3" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.filterTrainItemOptions,
                      label: _vm.$vuetify.lang.t(
                        "$vuetify.project.train_options"
                      ),
                      "item-text": "name",
                      "item-value": "value",
                      outlined: "",
                      dense: "",
                    },
                    model: {
                      value: _vm.filterTrainItemType,
                      callback: function ($$v) {
                        _vm.filterTrainItemType = $$v
                      },
                      expression: "filterTrainItemType",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.filterTrainItemType === "train_item_within_specified_range"
            ? _c(
                "v-form",
                { ref: "itemTrainForm" },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "mb-0 pb-0",
                      attrs: { cols: "12", md: "6" },
                    },
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("span", [_vm._v("Train From")]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "6", md: "4" } },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: "trainFromItemMenu",
                              attrs: {
                                "close-on-content-click": false,
                                "return-value": _vm.trainFromDate,
                                transition: "scale-transition",
                                "offset-y": "",
                                "min-width": "auto",
                              },
                              on: {
                                "update:returnValue": function ($event) {
                                  _vm.trainFromDate = $event
                                },
                                "update:return-value": function ($event) {
                                  _vm.trainFromDate = $event
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                ref: "startDateField",
                                                attrs: {
                                                  placeholder:
                                                    "input start date...",
                                                  clearable: "",
                                                  rules: [
                                                    _vm.validationRules
                                                      .required,
                                                    _vm.validationRules
                                                      .startDateLessThanEndDate,
                                                  ],
                                                  "prepend-inner-icon":
                                                    "mdi-calendar",
                                                  dense: "",
                                                  outlined: "",
                                                },
                                                model: {
                                                  value: _vm.trainFromDate,
                                                  callback: function ($$v) {
                                                    _vm.trainFromDate = $$v
                                                  },
                                                  expression: "trainFromDate",
                                                },
                                              },
                                              "v-text-field",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3218546250
                              ),
                              model: {
                                value: _vm.trainFromManue,
                                callback: function ($$v) {
                                  _vm.trainFromManue = $$v
                                },
                                expression: "trainFromManue",
                              },
                            },
                            [
                              _c(
                                "v-date-picker",
                                {
                                  attrs: { "no-title": "", scrollable: "" },
                                  model: {
                                    value: _vm.trainFromDate,
                                    callback: function ($$v) {
                                      _vm.trainFromDate = $$v
                                    },
                                    expression: "trainFromDate",
                                  },
                                },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.trainFromManue = false
                                        },
                                      },
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.trainFromItemMenu.save(
                                            _vm.trainFromDate
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" OK ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "mb-0 pb-0",
                      attrs: { cols: "12", md: "6" },
                    },
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("span", [_vm._v("Train To")]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "6", md: "4" } },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: "trainToMenu",
                              attrs: {
                                "close-on-content-click": false,
                                "return-value": _vm.trainToDate,
                                transition: "scale-transition",
                                "offset-y": "",
                                "min-width": "auto",
                              },
                              on: {
                                "update:returnValue": function ($event) {
                                  _vm.trainToDate = $event
                                },
                                "update:return-value": function ($event) {
                                  _vm.trainToDate = $event
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                ref: "endDateField",
                                                attrs: {
                                                  placeholder:
                                                    "input end date...",
                                                  clearable: "",
                                                  "prepend-inner-icon":
                                                    "mdi-calendar",
                                                  dense: "",
                                                  outlined: "",
                                                  rules: [
                                                    _vm.validationRules
                                                      .required,
                                                    _vm.validationRules
                                                      .endDateGreaterThanStartDate,
                                                  ],
                                                },
                                                model: {
                                                  value: _vm.trainToDate,
                                                  callback: function ($$v) {
                                                    _vm.trainToDate = $$v
                                                  },
                                                  expression: "trainToDate",
                                                },
                                              },
                                              "v-text-field",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1837353980
                              ),
                              model: {
                                value: _vm.trainToManue,
                                callback: function ($$v) {
                                  _vm.trainToManue = $$v
                                },
                                expression: "trainToManue",
                              },
                            },
                            [
                              _c(
                                "v-date-picker",
                                {
                                  attrs: { "no-title": "", scrollable: "" },
                                  model: {
                                    value: _vm.trainToDate,
                                    callback: function ($$v) {
                                      _vm.trainToDate = $$v
                                    },
                                    expression: "trainToDate",
                                  },
                                },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.trainToManue = false
                                        },
                                      },
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.trainToMenu.save(
                                            _vm.trainToDate
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" OK ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.updateResult !== ""
        ? _c(
            "v-row",
            { staticClass: "pl-11" },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _vm.updateStatus === "success"
                    ? _c("v-icon", { attrs: { color: "#C8E6C9" } }, [
                        _vm._v("mdi-check-circle"),
                      ])
                    : _vm.updateStatus === "failed"
                    ? _c("v-icon", { attrs: { color: "error" } }, [
                        _vm._v("mdi-alert-circle"),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c("v-col", [
                _c("p", [
                  _c(
                    "span",
                    { class: { "error--text": _vm.updateStatus === "failed" } },
                    [_vm._v(" " + _vm._s(_vm.updateResult) + " ")]
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }