var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c(
        "v-container",
        { attrs: { "fill-height": "", fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { align: "center", justify: "center" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "mx-5",
                  attrs: {
                    "align-self": "center",
                    sm: "8",
                    md: "6",
                    lg: "4",
                    xl: "4",
                  },
                },
                [
                  !_vm.validLink
                    ? _c("v-alert", { attrs: { type: "info" } }, [
                        _c("span", { staticClass: "text-h6" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$vuetify.lang.t("$vuetify.verify_msg_txt")
                            )
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.validLink
                    ? _c(
                        "v-card",
                        {
                          staticClass: "elevation-2 mx-auto pa-10",
                          attrs: { outlined: "" },
                        },
                        [
                          _c("Logo"),
                          _c(
                            "v-card-title",
                            { staticClass: "font-weight-bold" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$vuetify.lang.t(
                                      "$vuetify.reset_password_msg_txt_1"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c("v-card-subtitle", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$vuetify.lang.t(
                                    "$vuetify.reset_password_msg_txt_2"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-form",
                                { ref: "passwordForm" },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      "prepend-inner-icon": "lock",
                                      placeholder: _vm.$vuetify.lang.t(
                                        "$vuetify.password_label_txt"
                                      ),
                                      outlined: "",
                                      "append-icon": _vm.showPassword
                                        ? "mdi-eye"
                                        : "mdi-eye-off",
                                      type: _vm.showPassword
                                        ? "text"
                                        : "password",
                                      rules: [
                                        _vm.required(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.password_label_txt"
                                          )
                                        ),
                                        _vm.password(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.password_label_txt"
                                          )
                                        ),
                                        _vm.passwordWithEmail(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.password_label_txt"
                                          ),
                                          _vm.userInfo.email
                                        ),
                                        _vm.validatePasswordWithName(),
                                      ],
                                    },
                                    on: {
                                      "click:append": function ($event) {
                                        _vm.showPassword = !_vm.showPassword
                                      },
                                    },
                                    model: {
                                      value: _vm.passwordForm.password,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.passwordForm,
                                          "password",
                                          $$v
                                        )
                                      },
                                      expression: "passwordForm.password",
                                    },
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      "prepend-inner-icon": "lock",
                                      placeholder: _vm.$vuetify.lang.t(
                                        "$vuetify.enter_password_label_txt"
                                      ),
                                      outlined: "",
                                      "append-icon": _vm.showRePassword
                                        ? "mdi-eye"
                                        : "mdi-eye-off",
                                      type: _vm.showRePassword
                                        ? "text"
                                        : "password",
                                      rules: [
                                        _vm.required(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.enter_password_label_txt"
                                          )
                                        ),
                                        _vm.match(_vm.passwordForm.password),
                                      ],
                                    },
                                    on: {
                                      "click:append": function ($event) {
                                        _vm.showRePassword = !_vm.showRePassword
                                      },
                                    },
                                    model: {
                                      value: _vm.passwordForm.repassword,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.passwordForm,
                                          "repassword",
                                          $$v
                                        )
                                      },
                                      expression: "passwordForm.repassword",
                                    },
                                  }),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        large: "",
                                        block: "",
                                        loading: _vm.loading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleSubmitClick()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.change_password_btn_txt"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("v-divider", { staticClass: "my-5" }),
                              _c(
                                "router-link",
                                {
                                  staticClass: "text-decoration-none",
                                  attrs: { to: "/login" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.login_link_txt"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }