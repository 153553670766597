var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "elevation-0" },
        [
          _c("v-card-title", { staticClass: "font-weight-bold" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$vuetify.lang.t("$vuetify.details_step_txt")) +
                " "
            ),
          ]),
          _c("v-divider"),
          _vm.isLoading
            ? _c("Loader", { staticClass: "mr-2 mt-6" })
            : _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "font-weight-bold" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t("$vuetify.email_label_txt")
                              ) +
                              ": "
                          ),
                        ]
                      ),
                      _c("v-list-item-content", { staticClass: "align-end" }, [
                        _c(
                          "div",
                          { staticClass: "body-1" },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.selectedMember.email) + " "
                            ),
                            _vm.selectedMember.email_verified
                              ? _c(
                                  "v-icon",
                                  { attrs: { small: "", color: "green" } },
                                  [_vm._v(" mdi-check-decagram ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "font-weight-bold" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t(
                                  "$vuetify.first_name_label_txt"
                                )
                              ) +
                              ": "
                          ),
                        ]
                      ),
                      _c("v-list-item-content", { staticClass: "align-end" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.selectedMember.first_name
                                ? _vm.selectedMember.first_name
                                : "N/A"
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "font-weight-bold" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t(
                                  "$vuetify.last_name_label_txt"
                                )
                              ) +
                              ": "
                          ),
                        ]
                      ),
                      _c("v-list-item-content", { staticClass: "align-end" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.selectedMember.last_name
                                ? _vm.selectedMember.last_name
                                : "N/A"
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "font-weight-bold" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t("$vuetify.member.role")
                              ) +
                              ": "
                          ),
                        ]
                      ),
                      _c(
                        "v-list-item-content",
                        { staticClass: "align-end text-uppercase" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0" },
                                [
                                  _c(
                                    "v-chip",
                                    {
                                      attrs: { color: "primary", outlined: "" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.selectedMember.role) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "font-weight-bold" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t(
                                  "$vuetify.status_header_txt"
                                )
                              ) +
                              ": "
                          ),
                        ]
                      ),
                      _c(
                        "v-list-item-content",
                        { staticClass: "align-end" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0" },
                                [
                                  _c(
                                    "v-chip",
                                    {
                                      staticClass: "text-capitalize",
                                      attrs: {
                                        outlined: "",
                                        color: _vm.getColorOfStatus(
                                          _vm.selectedMember.status
                                        ),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.selectedMember.status ==
                                              "created"
                                              ? "Invited"
                                              : _vm.selectedMember.status
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "font-weight-bold" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t(
                                  "$vuetify.profile_verify_tab"
                                )
                              ) +
                              ": "
                          ),
                        ]
                      ),
                      _c(
                        "v-list-item-content",
                        { staticClass: "align-end" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0" },
                                [
                                  _c(
                                    "v-chip",
                                    {
                                      staticClass: "text-capitalize",
                                      attrs: {
                                        outlined: "",
                                        color: _vm.getColorOfStatus(
                                          _vm.selectedMember.id_status
                                        ),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.selectedMember.id_status) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "font-weight-bold" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t("$vuetify.gender_label_txt")
                              ) +
                              ": "
                          ),
                        ]
                      ),
                      _c("v-list-item-content", { staticClass: "align-end" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.selectedMember.gender
                                ? _vm.selectedMember.gender
                                : "N/A"
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "font-weight-bold" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t(
                                  "$vuetify.phone_no_label_txt"
                                )
                              ) +
                              ": "
                          ),
                        ]
                      ),
                      _c("v-list-item-content", { staticClass: "align-end" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.selectedMember.phone
                                ? _vm.selectedMember.phone
                                : "N/A"
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "font-weight-bold" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t(
                                  "$vuetify.address_label_txt"
                                )
                              ) +
                              ": "
                          ),
                        ]
                      ),
                      _c("v-list-item-content", { staticClass: "align-end" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.selectedMember.address
                                ? _vm.selectedMember.address
                                : "N/A"
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "font-weight-bold" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t("$vuetify.created_at_txt")
                              ) +
                              ": "
                          ),
                        ]
                      ),
                      _c("v-list-item-content", { staticClass: "align-end" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.selectedMember.created_at
                                ? _vm.formatDateTime(
                                    _vm.selectedMember.created_at
                                  )
                                : "N/A"
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "font-weight-bold" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t("$vuetify.last_update_txt")
                              ) +
                              ": "
                          ),
                        ]
                      ),
                      _c("v-list-item-content", { staticClass: "align-end" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.selectedMember.updated_at
                                ? _vm.formatDateTime(
                                    _vm.selectedMember.updated_at
                                  )
                                : "N/A"
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }