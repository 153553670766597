var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "500", persistent: _vm.persistent },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { class: _vm.headlineClass, attrs: { "primary-title": "" } },
            [_vm._v(" " + _vm._s(_vm.title) + " ")]
          ),
          _c(
            "v-layout",
            { staticClass: "px-5 py-5" },
            [
              _c("v-row", { staticClass: "mx-2 my-2" }, [
                _c("span", [_vm._v(_vm._s(_vm.message))]),
              ]),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm.cancelButton
                ? _c("Button", {
                    attrs: {
                      buttonType: _vm.TEXT_BUTTON,
                      buttonText: _vm.cancelButtonText,
                      color: "error",
                    },
                    on: {
                      onClick: function ($event) {
                        return _vm.onCancel()
                      },
                    },
                  })
                : _vm._e(),
              _c("Button", {
                attrs: {
                  buttonType: _vm.RAISED_BUTTON,
                  buttonText: _vm.confirmButtonText,
                  color: "primary",
                },
                on: {
                  onClick: function ($event) {
                    return _vm.onConfirmation()
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }