var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showAccountInfo
    ? _c(
        "v-card",
        { staticClass: "elevation-0" },
        [
          _c("v-card-title", { staticClass: "font-weight-bold" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$vuetify.lang.t("$vuetify.acc_info_header_txt")) +
                " "
            ),
          ]),
          _c("v-divider"),
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.account_type_label_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.accountType
                            ? _vm.capitalizeFirstLetter(_vm.accountType)
                            : "N/A"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.account_name_label_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.accountName ? _vm.accountName : "N/A") +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _vm.accountType === "organization"
                ? _c(
                    "div",
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            { staticClass: "font-weight-bold" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    `${_vm.$vuetify.lang.t(
                                      "$vuetify.org_name_label_txt"
                                    )}:`
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-list-item-content",
                            { staticClass: "align-end" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.organization.name
                                      ? _vm.organization.name
                                      : "N/A"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.isVisible
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "font-weight-bold" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        `${_vm.$vuetify.lang.t(
                                          "$vuetify.vat_no_label_txt"
                                        )}:`
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-list-item-content",
                                { staticClass: "align-end" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.organization.vat_number
                                          ? _vm.organization.vat_number
                                          : "N/A"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isVisible
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "font-weight-bold" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        `${_vm.$vuetify.lang.t(
                                          "$vuetify.reg_no_label_txt"
                                        )}:`
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-list-item-content",
                                { staticClass: "align-end" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.organization.registration_number
                                          ? _vm.organization.registration_number
                                          : "N/A"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            { staticClass: "font-weight-bold" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    `${_vm.$vuetify.lang.t(
                                      "$vuetify.ceo_label_txt"
                                    )}:`
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-list-item-content",
                            { staticClass: "align-end" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.organization.director_name
                                      ? _vm.organization.director_name
                                      : "N/A"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            { staticClass: "font-weight-bold" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    `${_vm.$vuetify.lang.t(
                                      "$vuetify.org_email_label_txt"
                                    )}:`
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-list-item-content",
                            { staticClass: "align-end" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.organization.email
                                      ? _vm.organization.email
                                      : "N/A"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.isVisible
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "font-weight-bold" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        `${_vm.$vuetify.lang.t(
                                          "$vuetify.org_phone_label_txt"
                                        )}:`
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-list-item-content",
                                { staticClass: "align-end" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.organization.phone
                                          ? _vm.organization.phone
                                          : "N/A"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.organization.website
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "font-weight-bold" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        `${_vm.$vuetify.lang.t(
                                          "$vuetify.org_website_label_txt"
                                        )}:`
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-list-item-content",
                                { staticClass: "align-end" },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.organization.website) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.organization.size
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "font-weight-bold" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        `${_vm.$vuetify.lang.t(
                                          "$vuetify.org_size_label_txt"
                                        )}:`
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-list-item-content",
                                { staticClass: "align-end" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.oraganizationSizeParams.filter(
                                          (obj) =>
                                            obj.value === _vm.organization.size
                                        )[0].text
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.organization.industry
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "font-weight-bold" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        `${_vm.$vuetify.lang.t(
                                          "$vuetify.org_industry_label_txt"
                                        )}:`
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-list-item-content",
                                { staticClass: "align-end" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.organization.industry.name) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.organization.country
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "font-weight-bold" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        `${_vm.$vuetify.lang.t(
                                          "$vuetify.country_label_txt"
                                        )}:`
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-list-item-content",
                                { staticClass: "align-end" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.organization.country.countryName
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.organization.region
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "font-weight-bold" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        `${_vm.$vuetify.lang.t(
                                          "$vuetify.state_label_txt"
                                        )}:`
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-list-item-content",
                                { staticClass: "align-end" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.organization.region.name) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.organization.city
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "font-weight-bold" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        `${_vm.$vuetify.lang.t(
                                          "$vuetify.city_label_txt"
                                        )}:`
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-list-item-content",
                                { staticClass: "align-end" },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.organization.city) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.organization.postal_code
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "font-weight-bold" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        `${_vm.$vuetify.lang.t(
                                          "$vuetify.post_code_label_txt"
                                        )}:`
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-list-item-content",
                                { staticClass: "align-end" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.organization.postal_code) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.organization.address
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "font-weight-bold" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        `${_vm.$vuetify.lang.t(
                                          "$vuetify.address_label_txt"
                                        )}:`
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-list-item-content",
                                { staticClass: "align-end" },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.organization.address) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }