var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-stepper",
            {
              attrs: { horizontal: "" },
              model: {
                value: _vm.step,
                callback: function ($$v) {
                  _vm.step = $$v
                },
                expression: "step",
              },
            },
            [
              _c(
                "v-stepper-header",
                [
                  _c(
                    "v-stepper-step",
                    { attrs: { complete: _vm.step > 1, step: "1" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.project.select_file"
                            )}`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-divider"),
                  _c(
                    "v-stepper-step",
                    { attrs: { complete: _vm.step > 2, step: "2" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.project.map_data"
                            )}`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-divider"),
                  _c(
                    "v-stepper-step",
                    { attrs: { complete: _vm.step > 3, step: "3" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.project.rank_settings"
                            )}`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-divider"),
                  _c(
                    "v-stepper-step",
                    { attrs: { complete: _vm.step > 4, step: "4" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.project.progress"
                            )}`
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-items",
                [
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "1" } },
                    [
                      _c("v-file-input", {
                        attrs: {
                          label: _vm.$vuetify.lang.t(
                            "$vuetify.project.select_file"
                          ),
                          accept: ".csv",
                          outlined: "",
                          clearable: "",
                        },
                        model: {
                          value: _vm.selectedFile,
                          callback: function ($$v) {
                            _vm.selectedFile = $$v
                          },
                          expression: "selectedFile",
                        },
                      }),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12", md: "8" } }),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-right",
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleFileParsing()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.continue_btn_txt"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "2" } },
                    [
                      _c(
                        "v-form",
                        { ref: "mapper_form" },
                        _vm._l(_vm.fields, function (field, index) {
                          return _c(
                            "v-row",
                            { key: index, attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-col", { attrs: { cols: "2" } }, [
                                _c("span", [_vm._v(_vm._s(field.name))]),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.mandatoryFields.includes(
                                          field.name
                                        ),
                                        expression:
                                          "mandatoryFields.includes(field.name)",
                                      },
                                    ],
                                    staticStyle: { color: "red" },
                                  },
                                  [_vm._v("*")]
                                ),
                              ]),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { right: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      { attrs: { icon: "" } },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          size: "medium",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " mdi-help-circle "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c(
                                        "span",
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                `${_vm.$vuetify.lang.t(
                                                  "$vuetify.project.key_type"
                                                )}`
                                              ) +
                                              " " +
                                              _vm._s(field.key_type)
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                `${_vm.$vuetify.lang.t(
                                                  "$vuetify.project.value_type"
                                                )}`
                                              ) +
                                              ": " +
                                              _vm._s(field.value_type)
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$vuetify.lang.t(
                                                  "$vuetify.description_label_txt"
                                                )
                                              ) +
                                              ": "
                                          ),
                                          _vm._l(
                                            _vm.formatDescription(
                                              field.description,
                                              10
                                            ),
                                            function (line, index) {
                                              return _c("div", { key: index }, [
                                                _vm._v(
                                                  " " + _vm._s(line) + " "
                                                ),
                                              ])
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  field.key_type === "str" ||
                                  field.key_type === "Optional[str]"
                                    ? _c("v-autocomplete", {
                                        attrs: {
                                          items: _vm.headersFromCSV,
                                          dense: "",
                                          "hide-selected": "",
                                          outlined: "",
                                          rules: [
                                            (v) =>
                                              _vm.validateMandatoryFields(
                                                field.name,
                                                field.mapped_data
                                              ) ||
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.project.field_required"
                                              ),
                                            (v) =>
                                              _vm.validateNoDuplicates(
                                                field.name,
                                                field.mapped_data
                                              ) ||
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.project.already_assigned_to_another_field"
                                              ),
                                          ],
                                        },
                                        model: {
                                          value: field.mapped_data,
                                          callback: function ($$v) {
                                            _vm.$set(field, "mapped_data", $$v)
                                          },
                                          expression: "field.mapped_data",
                                        },
                                      })
                                    : field.name === "image_url_type"
                                    ? _c("v-autocomplete", {
                                        attrs: {
                                          items: _vm.typeSelector,
                                          dense: "",
                                          "hide-selected": "",
                                          outlined: "",
                                          rules: [
                                            (v) =>
                                              _vm.validateMandatoryFields(
                                                field.name,
                                                field.mapped_data
                                              ) ||
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.project.field_required"
                                              ),
                                          ],
                                        },
                                        model: {
                                          value: field.mapped_data,
                                          callback: function ($$v) {
                                            _vm.$set(field, "mapped_data", $$v)
                                          },
                                          expression: "field.mapped_data",
                                        },
                                      })
                                    : field.name === "categories" ||
                                      field.name === "custom"
                                    ? [
                                        _c(
                                          "div",
                                          [
                                            _vm._l(
                                              field.mapped_data,
                                              function (mapped_elem, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass: "side-by-side",
                                                  },
                                                  [
                                                    _c("v-autocomplete", {
                                                      attrs: {
                                                        items:
                                                          _vm.headersFromCSV,
                                                        dense: "",
                                                        "hide-selected": "",
                                                        outlined: "",
                                                        rules: [
                                                          (v) =>
                                                            _vm.validateMandatoryFields(
                                                              field.name,
                                                              mapped_elem.name
                                                            ) ||
                                                            _vm.$vuetify.lang.t(
                                                              "$vuetify.project.field_required"
                                                            ),
                                                          (v) => {
                                                            if (
                                                              field.name ===
                                                              "categories"
                                                            ) {
                                                              return (
                                                                _vm.validateNoDuplicatesOnSameFieldMappedData(
                                                                  mapped_elem.name,
                                                                  index
                                                                ) ||
                                                                _vm.$vuetify.lang.t(
                                                                  "$vuetify.project.already_assigned_to_another_field"
                                                                )
                                                              )
                                                            } else {
                                                              return (
                                                                _vm.validateNoDuplicates(
                                                                  field.name,
                                                                  mapped_elem.name
                                                                ) ||
                                                                _vm.$vuetify.lang.t(
                                                                  "$vuetify.project.already_assigned_to_another_field"
                                                                )
                                                              )
                                                            }
                                                          },
                                                        ],
                                                      },
                                                      model: {
                                                        value: mapped_elem.name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            mapped_elem,
                                                            "name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "mapped_elem.name",
                                                      },
                                                    }),
                                                    field.name === "categories"
                                                      ? _c("v-text-field", {
                                                          attrs: {
                                                            outlined: "",
                                                            dense: "",
                                                            label: "Separator",
                                                          },
                                                          model: {
                                                            value:
                                                              mapped_elem.separator,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                mapped_elem,
                                                                "separator",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "mapped_elem.separator",
                                                          },
                                                        })
                                                      : field.name === "custom"
                                                      ? _c("v-autocomplete", {
                                                          attrs: {
                                                            items: [
                                                              "INT",
                                                              "FLOAT",
                                                              "STR",
                                                            ],
                                                            outlined: "",
                                                            dense: "",
                                                            "hide-selected": "",
                                                            label: "Data Type",
                                                          },
                                                          model: {
                                                            value:
                                                              mapped_elem.data_type,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                mapped_elem,
                                                                "data_type",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "mapped_elem.data_type",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    field.mapped_data.length > 1
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              outlined: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.removeSubfield(
                                                                  field,
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v(" Remove ")]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: { outlined: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addSubfield(
                                                      field
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      `${_vm.$vuetify.lang.t(
                                                        "$vuetify.project.add_more"
                                                      )}`
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          2
                                        ),
                                      ]
                                    : field.key_type === "List[str]"
                                    ? _c("v-autocomplete", {
                                        attrs: {
                                          items: _vm.headersFromCSV,
                                          dense: "",
                                          "hide-selected": "",
                                          outlined: "",
                                          "small-chips": "",
                                          multiple: "",
                                          "deletable-chips": "",
                                          rules: [
                                            (v) =>
                                              _vm.validateMandatoryFields(
                                                field.name,
                                                field.mapped_data
                                              ) ||
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.project.field_required"
                                              ),
                                            (v) =>
                                              _vm.validateNoDuplicates(
                                                field.name,
                                                field.mapped_data
                                              ) ||
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.project.already_assigned_to_another_field"
                                              ),
                                          ],
                                        },
                                        model: {
                                          value: field.mapped_data,
                                          callback: function ($$v) {
                                            _vm.$set(field, "mapped_data", $$v)
                                          },
                                          expression: "field.mapped_data",
                                        },
                                      })
                                    : field.key_type === "boolean" ||
                                      field.key_type === "Optional[boolean]"
                                    ? _c("v-autocomplete", {
                                        attrs: {
                                          items: ["true", "false"],
                                          dense: "",
                                          "hide-selected": "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: field.mapped_data,
                                          callback: function ($$v) {
                                            _vm.$set(field, "mapped_data", $$v)
                                          },
                                          expression: "field.mapped_data",
                                        },
                                      })
                                    : field.name === "search_settings"
                                    ? [
                                        _c(
                                          "div",
                                          [
                                            _c("v-autocomplete", {
                                              attrs: {
                                                items:
                                                  _vm.getSelectedCategoryForPrioritize(),
                                                label: "Prioritize Category",
                                                dense: "",
                                                "hide-selected": "",
                                                outlined: "",
                                                rules: [
                                                  (v) =>
                                                    _vm.validatePrioritizeCategory(
                                                      field.mapped_data
                                                    ) ||
                                                    _vm.$vuetify.lang.t(
                                                      "$vuetify.project.field_required_for_prioritize_category"
                                                    ),
                                                ],
                                              },
                                              model: {
                                                value:
                                                  field.mapped_data
                                                    .prioritize_category,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    field.mapped_data,
                                                    "prioritize_category",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "field.mapped_data.prioritize_category",
                                              },
                                            }),
                                            _c("div", [
                                              field.mapped_data
                                                .prioritize_values.length > 0
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-wrap mb-2",
                                                    },
                                                    _vm._l(
                                                      field.mapped_data
                                                        .prioritize_values,
                                                      function (item, index) {
                                                        return _c(
                                                          "v-chip",
                                                          {
                                                            key: index,
                                                            staticClass: "ma-1",
                                                            attrs: {
                                                              close: "",
                                                              small: "",
                                                            },
                                                            on: {
                                                              "click:close":
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.handleRemovePrioritizeValue(
                                                                    index
                                                                  )
                                                                },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(item) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "mb-0 pb-0",
                                                    attrs: {
                                                      label:
                                                        "Prioritize Values",
                                                      dense: "",
                                                      "hide-selected": "",
                                                      outlined: "",
                                                      "small-chips": "",
                                                      clearable: "",
                                                      rules: [
                                                        (v) =>
                                                          _vm.validatePrioritizeValues(
                                                            field.mapped_data
                                                          ) ||
                                                          _vm.$vuetify.lang.t(
                                                            "$vuetify.project.field_required_for_prioritize_values"
                                                          ),
                                                      ],
                                                    },
                                                    on: {
                                                      keyup: function ($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        )
                                                          return null
                                                        return _vm.handleAddInputPrioritizeValue.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.inputPrioritizeValue,
                                                      callback: function ($$v) {
                                                        _vm.inputPrioritizeValue =
                                                          $$v
                                                      },
                                                      expression:
                                                        "inputPrioritizeValue",
                                                    },
                                                  }),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "mb-8",
                                                      attrs: { outlined: "" },
                                                      on: {
                                                        click:
                                                          _vm.handleAddInputPrioritizeValue,
                                                      },
                                                    },
                                                    [_vm._v(" Add Value ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]),
                                            _c("div", [
                                              _c("div", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "side-by-side",
                                                  },
                                                  [
                                                    _c("v-autocomplete", {
                                                      attrs: {
                                                        items:
                                                          _vm.getSelectedFlagForPrioritize(),
                                                        label:
                                                          "Prioritize Flag",
                                                        dense: "",
                                                        "hide-selected": "",
                                                        outlined: "",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.handleInputPrioritizeFlag,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.selectPrioritizeFlag,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.selectPrioritizeFlag =
                                                            $$v
                                                        },
                                                        expression:
                                                          "selectPrioritizeFlag",
                                                      },
                                                    }),
                                                    _c("v-autocomplete", {
                                                      attrs: {
                                                        items: [
                                                          "true",
                                                          "false",
                                                        ],
                                                        label: "Flag Value",
                                                        dense: "",
                                                        "hide-selected": "",
                                                        outlined: "",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.handleInputPrioritizeFlag,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.setPrioritizeFlagValue,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.setPrioritizeFlagValue =
                                                            $$v
                                                        },
                                                        expression:
                                                          "setPrioritizeFlagValue",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]),
                                              _c(
                                                "div",
                                                [
                                                  field.mapped_data
                                                    .downgrade_values.length > 0
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-wrap mb-2",
                                                        },
                                                        _vm._l(
                                                          field.mapped_data
                                                            .downgrade_values,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "v-chip",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "ma-1",
                                                                attrs: {
                                                                  close: "",
                                                                  small: "",
                                                                },
                                                                on: {
                                                                  "click:close":
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleRemoveDowngradeValue(
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c("v-text-field", {
                                                    staticClass: "mb-0 pb-0",
                                                    attrs: {
                                                      label: "Downgrade Values",
                                                      dense: "",
                                                      "hide-selected": "",
                                                      outlined: "",
                                                      "small-chips": "",
                                                      clearable: "",
                                                    },
                                                    on: {
                                                      keyup: function ($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        )
                                                          return null
                                                        return _vm.handleAddInputDowngradeValue.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.inputDowngradeValue,
                                                      callback: function ($$v) {
                                                        _vm.inputDowngradeValue =
                                                          $$v
                                                      },
                                                      expression:
                                                        "inputDowngradeValue",
                                                    },
                                                  }),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "mb-8",
                                                      attrs: { outlined: "" },
                                                      on: {
                                                        click:
                                                          _vm.handleAddInputDowngradeValue,
                                                      },
                                                    },
                                                    [_vm._v(" Add Value ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]),
                                            _c(
                                              "div",
                                              { staticClass: "side-by-side" },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "Cluster Size",
                                                    type: "number",
                                                    dense: "",
                                                    outlined: "",
                                                    min: 1,
                                                    max: 5000,
                                                    rules: [
                                                      _vm.validateMinMax(
                                                        1,
                                                        5000
                                                      ),
                                                    ],
                                                  },
                                                  model: {
                                                    value:
                                                      field.mapped_data
                                                        .cluster_size,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        field.mapped_data,
                                                        "cluster_size",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "field.mapped_data.cluster_size",
                                                  },
                                                }),
                                                _c("v-select", {
                                                  attrs: {
                                                    items: ["true", "false"],
                                                    label: "Is Keyword Enabled",
                                                    dense: "",
                                                    "hide-selected": "",
                                                    outlined: "",
                                                  },
                                                  model: {
                                                    value:
                                                      field.mapped_data
                                                        .is_keyword_enabled,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        field.mapped_data,
                                                        "is_keyword_enabled",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "field.mapped_data.is_keyword_enabled",
                                                  },
                                                }),
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "Top K",
                                                    type: "number",
                                                    dense: "",
                                                    outlined: "",
                                                    min: 1,
                                                    max: 50,
                                                    rules: [
                                                      _vm.validateMinMax(1, 50),
                                                    ],
                                                  },
                                                  model: {
                                                    value:
                                                      field.mapped_data.top_k,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        field.mapped_data,
                                                        "top_k",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "field.mapped_data.top_k",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-4",
                          attrs: { color: "primary", loading: false },
                          on: {
                            click: function ($event) {
                              return _vm.previousStep(_vm.step)
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$vuetify.lang.t("$vuetify.prev_btn_txt"))
                          ),
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-4",
                          attrs: { color: "primary", loading: false },
                          on: {
                            click: function ($event) {
                              return _vm.nextStep(_vm.step)
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$vuetify.lang.t("$vuetify.continue_btn_txt")
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "3" } },
                    [
                      _c(
                        "v-form",
                        { ref: "rank_form" },
                        [
                          _c(
                            "v-row",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-col", { attrs: { cols: "2" } }, [
                                _c("span", [_vm._v("Interval")]),
                              ]),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      items: _vm.intervalOptions,
                                      dense: "",
                                      "hide-selected": "",
                                      outlined: "",
                                      "small-chips": "",
                                      multiple: "",
                                      "deletable-chips": "",
                                    },
                                    model: {
                                      value: _vm.interval,
                                      callback: function ($$v) {
                                        _vm.interval = $$v
                                      },
                                      expression: "interval",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-col", { attrs: { cols: "2" } }, [
                                _c("span", [_vm._v("Split Size")]),
                              ]),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      type: "number",
                                      outlined: "",
                                      dense: "",
                                      min: 0,
                                      max: 1,
                                      step: 0.01,
                                      decimal: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleNonEmptyValue(
                                          "splitSize"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.splitSize,
                                      callback: function ($$v) {
                                        _vm.splitSize = $$v
                                      },
                                      expression: "splitSize",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-col", { attrs: { cols: "2" } }, [
                                _c("span", [_vm._v("Epoch")]),
                              ]),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      type: "number",
                                      outlined: "",
                                      dense: "",
                                      min: 1,
                                      decimal: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleNonEmptyValue("epoch")
                                      },
                                    },
                                    model: {
                                      value: _vm.epoch,
                                      callback: function ($$v) {
                                        _vm.epoch = $$v
                                      },
                                      expression: "epoch",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-4",
                          attrs: {
                            color: "primary",
                            outlined: "",
                            loading: false,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.previousStep(_vm.step)
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$vuetify.lang.t("$vuetify.prev_btn_txt"))
                          ),
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-4",
                          attrs: { color: "primary", loading: false },
                          on: {
                            click: function ($event) {
                              return _vm.handleTrain()
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$vuetify.lang.t("$vuetify.continue_btn_txt")
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "4" } },
                    [
                      [
                        _c(
                          "v-container",
                          { attrs: { fluid: "" } },
                          [
                            _c(
                              "v-row",
                              { attrs: { "no-gutters": "" } },
                              _vm._l(_vm.states, function (state, index) {
                                return _c(
                                  "v-col",
                                  {
                                    key: index,
                                    staticClass: "list-item-col",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "v-list",
                                      [
                                        _c(
                                          "v-list-item",
                                          [
                                            _c(
                                              "v-list-item-icon",
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color:
                                                        state.status ===
                                                        "success"
                                                          ? "green"
                                                          : state.status ===
                                                            "failed"
                                                          ? "red"
                                                          : undefined,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          state.status ===
                                                            "success"
                                                            ? "mdi-check-circle"
                                                            : state.status ===
                                                              "failed"
                                                            ? "mdi-close-circle"
                                                            : "mdi-checkbox-blank-circle-outline"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        state.message ===
                                                          "Creating mapper"
                                                          ? _vm.$vuetify.lang.t(
                                                              "$vuetify.setup_messages.creating_mapper"
                                                            )
                                                          : state.message ===
                                                            "Creating Indices"
                                                          ? _vm.$vuetify.lang.t(
                                                              "$vuetify.setup_messages.creating_indices"
                                                            )
                                                          : state.message ===
                                                            "Creating Rank settings"
                                                          ? _vm.$vuetify.lang.t(
                                                              "$vuetify.setup_messages.creating_rank_settings"
                                                            )
                                                          : state.message ===
                                                            "Uploading CSV"
                                                          ? _vm.$vuetify.lang.t(
                                                              "$vuetify.setup_messages.uploading_data"
                                                            )
                                                          : state.message ===
                                                            "Error in completing the step"
                                                          ? _vm.$vuetify.lang.t(
                                                              "$vuetify.setup_messages.error_in_completing_the_step"
                                                            )
                                                          : state.message ===
                                                            "Mapper created successfully"
                                                          ? _vm.$vuetify.lang.t(
                                                              "$vuetify.setup_messages.mapper_created_successfully"
                                                            )
                                                          : state.message ===
                                                            "Mapper updated successfully"
                                                          ? _vm.$vuetify.lang.t(
                                                              "$vuetify.setup_messages.mapper_updated_successfully"
                                                            )
                                                          : state.message.includes(
                                                              "Index-Create Task finished successfully"
                                                            )
                                                          ? _vm.$vuetify.lang.t(
                                                              "$vuetify.setup_messages.index_created_successfully"
                                                            )
                                                          : state.message ===
                                                            "Rank settings created successfully"
                                                          ? _vm.$vuetify.lang.t(
                                                              "$vuetify.setup_messages.rank_settings_created_successfully"
                                                            )
                                                          : state.message.includes(
                                                              "Csv-Upload Task finished successfully"
                                                            )
                                                          ? _vm.$vuetify.lang.t(
                                                              "$vuetify.setup_messages.data_uploaded_successfully"
                                                            )
                                                          : state.message ===
                                                            "Flag value should be boolean"
                                                          ? _vm.$vuetify.lang.t(
                                                              "$vuetify.setup_messages.flag_value_should_be_boolean"
                                                            )
                                                          : state.message
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                            _c(
                              "v-row",
                              {
                                staticStyle: { "pointer-events": "none" },
                                attrs: { "no-gutters": "" },
                              },
                              _vm._l(_vm.states, function (state, index) {
                                return _c(
                                  "v-col",
                                  {
                                    key: index,
                                    staticClass: "progress-col",
                                    attrs: { cols: "1.5" },
                                  },
                                  [
                                    _c("v-progress-linear", {
                                      attrs: {
                                        height: "25",
                                        color: "primary",
                                        "background-color": "#e0e0e0",
                                      },
                                      model: {
                                        value: state.progress_value,
                                        callback: function ($$v) {
                                          _vm.$set(state, "progress_value", $$v)
                                        },
                                        expression: "state.progress_value",
                                      },
                                    }),
                                    _c("div", { staticClass: "vertical-line" }),
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  [
                                    this.setupStatus === "done"
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "mr-4",
                                            attrs: {
                                              color: "primary",
                                              loading: false,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.setupCompleted()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                `${_vm.$vuetify.lang.t(
                                                  "$vuetify.project.finish"
                                                )}`
                                              ) + " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    this.setupLoader &&
                                    this.setupStatus !== "failed"
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "mr-4 secondary-disabled-button",
                                            attrs: { color: "primary" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  `${_vm.$vuetify.lang.t(
                                                    "$vuetify.project.setup_in_progress"
                                                  )}`
                                                ) +
                                                " "
                                            ),
                                            _c("v-progress-circular", {
                                              attrs: {
                                                indeterminate: "",
                                                size: 20,
                                                width: 2,
                                                color: "white",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    this.setupStatus === "failed"
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "mr-4",
                                            attrs: {
                                              color: "error",
                                              outlined: "",
                                              loading: false,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.tryAgain()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$vuetify.lang.t(
                                                  "$vuetify.common.try_again"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }