import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import vuetify from "./plugins/vuetify";
import getEnv from "@/config/env";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import VueCardFormat from 'vue-credit-card-validation';
import sentry from "./plugins/sentry";
import './assets/css/custom.css';
import VueGtag from "vue-gtag";
import TokenRefreshPlugin from "@/plugins/tokenRefreshPlugin";
import {CAPTCHA_SITE_KEY} from "@/global/_constants";
import {reCaptchaPlugin} from "@/plugins/recaptchaPlugin";

// Use the reCaptcha plugin
Vue.use(reCaptchaPlugin, { siteKey: CAPTCHA_SITE_KEY });

// add vuetify phone input
Vue.use(VueTelInputVuetify, {
  vuetify,
});

// add vue credit card validator
Vue.use(VueCardFormat);

Vue.use(VueGtag, {
  config: { id: "G-1D4B3FSF7Z" },
  enabled: getEnv("NODE_ENV") === 'production',
});

Vue.config.productionTip = false;

Vue.use(TokenRefreshPlugin);

new Vue({
  router,
  store,
  vuetify,
  sentry,
  render: (h) => h(App),
}).$mount("#app");
