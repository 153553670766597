var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "mx-auto rounded", attrs: { outlined: "" } },
    [
      _c("v-img", {
        staticClass: "white--text align-end",
        attrs: {
          src: _vm.itemData.cover_image
            ? _vm.itemData.cover_image
            : _vm.segmentImg(_vm.itemData),
          "aspect-ratio": "2",
        },
      }),
      _c(
        "v-btn",
        {
          staticClass: "white--text",
          staticStyle: { bottom: "7em" },
          attrs: {
            absolute: "",
            color: _vm.color && _vm.color,
            fab: "",
            small: "",
            right: "",
          },
          on: {
            click: function ($event) {
              return _vm.redirectDetails(_vm.itemData)
            },
          },
        },
        [_c("v-icon", [_vm._v("mdi-chevron-right")])],
        1
      ),
      _c("v-card-title", [
        _vm._v(" " + _vm._s(_vm.itemData.name && _vm.itemData.name) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }