var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Navbar",
        {
          attrs: {
            isProfile:
              _vm.$route.path === "/profile-settings" ||
              _vm.$route.path === "/account-settings",
          },
        },
        [
          _c(
            "v-container",
            {
              class: {
                singleAppbar: _vm.$vuetify.breakpoint.smAndDown,
                doubleAppbar: _vm.$vuetify.breakpoint.mdAndUp,
              },
              attrs: { "pt-2": "" },
            },
            [
              _c(
                "v-main",
                { staticClass: "pt-1 pl-1" },
                [
                  _c("v-breadcrumbs", {
                    attrs: { items: _vm.crumbs },
                    scopedSlots: _vm._u([
                      {
                        key: "divider",
                        fn: function () {
                          return [_c("v-icon", [_vm._v("mdi-forward")])]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("router-view"),
                ],
                1
              ),
              _c("MainFooter"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }