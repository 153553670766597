<template>
  <v-app :key="locale">
    <Snackbar />
    <router-view :key="this.$route.path"> </router-view>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import Snackbar from "@/components/common/Snackbar.vue";
import storageMixin from "@/mixins/storageMixin";

export default {
  name: "App",
  components: {Snackbar},
  mixins: [storageMixin],
  computed: {
    ...mapState({
      // user: (state) => state.account.user,
      locale: (state) => state.alert.locale,
    }),
  },
};
</script>
