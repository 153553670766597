var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "" } },
    [
      _c(
        "v-snackbar",
        {
          attrs: { color: _vm.snackbarColor, top: "", timeout: _vm.timeout },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [_vm._v(" " + _vm._s(_vm.snackbarMessage) + " ")]
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", xs: "12", md: "4" } },
            [
              _c(
                "v-menu",
                {
                  ref: "menuDates",
                  attrs: {
                    "close-on-content-click": false,
                    "return-value": _vm.dates,
                    transition: "scale-transition",
                    "offset-y": "",
                    "min-width": "auto",
                  },
                  on: {
                    "update:returnValue": function ($event) {
                      _vm.dates = $event
                    },
                    "update:return-value": function ($event) {
                      _vm.dates = $event
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-text-field",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    placeholder: _vm.$vuetify.lang.t(
                                      "$vuetify.daterange_placeholder_txt"
                                    ),
                                    "prepend-icon": "mdi-calendar",
                                    readonly: "",
                                    outlined: "",
                                    dense: "",
                                    "hide-details": "auto",
                                  },
                                  model: {
                                    value: _vm.dateRangeText,
                                    callback: function ($$v) {
                                      _vm.dateRangeText = $$v
                                    },
                                    expression: "dateRangeText",
                                  },
                                },
                                "v-text-field",
                                attrs,
                                false
                              ),
                              on
                            )
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.menuDates,
                    callback: function ($$v) {
                      _vm.menuDates = $$v
                    },
                    expression: "menuDates",
                  },
                },
                [
                  _c(
                    "v-date-picker",
                    {
                      attrs: {
                        color: "primary",
                        "no-title": "",
                        scrollable: "",
                        range: "",
                        max: _vm.maxDate,
                      },
                      model: {
                        value: _vm.dates,
                        callback: function ($$v) {
                          _vm.dates = $$v
                        },
                        expression: "dates",
                      },
                    },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "error" },
                          on: {
                            click: function ($event) {
                              _vm.menuDates = false
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t("$vuetify.cancel_btn_txt")
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "primary" },
                          on: { click: _vm.setDates },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t("$vuetify.OK_btn_txt")
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.last_insights_cached_at !== ""
            ? _c("v-col", { attrs: { cols: "12", xs: "12", md: "8" } }, [
                _c("div", { staticClass: "float-end" }, [
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-end text-caption ma-0 font-weight-bold",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            this.$vuetify.lang.t(
                              "$vuetify.insights.last_updated_at"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("p", { staticClass: "text-end text-caption ma-0" }, [
                    _vm._v(_vm._s(_vm.last_insights_cached_at)),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm.selectedProject.segment.name === "Personalizer"
        ? [
            _vm.isLoading
              ? _c("Loader")
              : _c(
                  "v-row",
                  { staticClass: "d-flex justify-space-between" },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "3" } },
                      [
                        _c(
                          "v-card",
                          { attrs: { outlined: "", flat: "" } },
                          [
                            _c(
                              "v-card-subtitle",
                              { staticClass: "font-weight-bold" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      this.$vuetify.lang.t(
                                        "$vuetify.insights.search"
                                      )
                                    ) +
                                    " "
                                ),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    { attrs: { small: "" } },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    " mdi-help-circle-outline "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      166549224
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          this.$vuetify.lang.t(
                                            "$vuetify.insights.search_txt"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-card-text",
                              { staticClass: "text-center text-h4" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.totalNumberOfSearch().toLocaleString()
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "v-card-text",
                              {
                                staticClass:
                                  "d-flex align-center justify-center",
                              },
                              [
                                _c("span", { staticClass: "mr-2" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formattedPercentages.percentageSearch
                                    )
                                  ),
                                ]),
                                _vm.percentageIcon("percentageSearch")
                                  ? _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          color:
                                            _vm.percentageColor(
                                              "percentageSearch"
                                            ),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.percentageIcon(
                                                "percentageSearch"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "3" } },
                      [
                        _c(
                          "v-card",
                          { attrs: { outlined: "", flat: "" } },
                          [
                            _c(
                              "v-card-subtitle",
                              { staticClass: "font-weight-bold" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      this.$vuetify.lang.t(
                                        "$vuetify.insights.recommend"
                                      )
                                    ) +
                                    " "
                                ),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    { attrs: { small: "" } },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    " mdi-help-circle-outline "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      166549224
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          this.$vuetify.lang.t(
                                            "$vuetify.insights.recommend_txt"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-card-text",
                              { staticClass: "text-center text-h4" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.totalRecommendValue().toLocaleString()
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "v-card-text",
                              {
                                staticClass:
                                  "d-flex align-center justify-center",
                              },
                              [
                                _c("span", { staticClass: "mr-2" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formattedPercentages
                                        .percentageRecommend
                                    )
                                  ),
                                ]),
                                _vm.percentageIcon("percentageRecommend")
                                  ? _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          color: _vm.percentageColor(
                                            "percentageRecommend"
                                          ),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.percentageIcon(
                                                "percentageRecommend"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "3" } },
                      [
                        _c(
                          "v-card",
                          { attrs: { outlined: "", flat: "" } },
                          [
                            _c(
                              "v-card-subtitle",
                              { staticClass: "font-weight-bold" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      this.$vuetify.lang.t(
                                        "$vuetify.insights.orders"
                                      )
                                    ) +
                                    " "
                                ),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    { attrs: { small: "" } },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    " mdi-help-circle-outline "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      166549224
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          this.$vuetify.lang.t(
                                            "$vuetify.insights.orders_txt"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-card-text",
                              { staticClass: "text-center text-h4" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.kpiSummary &&
                                        _vm.kpiSummary.purchase &&
                                        _vm.kpiSummary.purchase.number_of_orders
                                        ? _vm.kpiSummary.purchase.number_of_orders.toLocaleString()
                                        : "0"
                                    )
                                ),
                              ]
                            ),
                            _c(
                              "v-card-text",
                              {
                                staticClass:
                                  "d-flex align-center justify-center",
                              },
                              [
                                _c("span", { staticClass: "mr-2" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formattedPercentages.percentageOrders
                                    )
                                  ),
                                ]),
                                _vm.percentageIcon("percentageOrders")
                                  ? _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          color:
                                            _vm.percentageColor(
                                              "percentageOrders"
                                            ),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.percentageIcon(
                                                "percentageOrders"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "3" } },
                      [
                        _c(
                          "v-card",
                          { attrs: { outlined: "", flat: "" } },
                          [
                            _c(
                              "v-card-subtitle",
                              { staticClass: "font-weight-bold" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      this.$vuetify.lang.t(
                                        "$vuetify.insights.revenue"
                                      )
                                    ) +
                                    " "
                                ),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    { attrs: { small: "" } },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    " mdi-help-circle-outline "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      166549224
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          this.$vuetify.lang.t(
                                            "$vuetify.insights.revenue_txt"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-card-text",
                              { staticClass: "text-center text-h4" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.kpiSummary &&
                                      _vm.kpiSummary.purchase &&
                                      _vm.kpiSummary.purchase
                                        .total_purchased_amounts
                                      ? _vm.kpiSummary.purchase.total_purchased_amounts.toLocaleString()
                                      : "0"
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "v-card-text",
                              {
                                staticClass:
                                  "d-flex align-center justify-center",
                              },
                              [
                                _c("span", { staticClass: "mr-2" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formattedPercentages.percentageRevenue
                                    )
                                  ),
                                ]),
                                _vm.percentageIcon("percentageRevenue")
                                  ? _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          color:
                                            _vm.percentageColor(
                                              "percentageRevenue"
                                            ),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.percentageIcon(
                                                "percentageRevenue"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "3" } },
                      [
                        _c(
                          "v-card",
                          { attrs: { outlined: "", flat: "" } },
                          [
                            _c(
                              "v-card-subtitle",
                              { staticClass: "font-weight-bold" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      this.$vuetify.lang.t(
                                        "$vuetify.insights.average_cart_size"
                                      )
                                    ) +
                                    " "
                                ),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    { attrs: { small: "" } },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    " mdi-help-circle-outline "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      166549224
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          this.$vuetify.lang.t(
                                            "$vuetify.insights.average_cart_size_txt"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-card-text",
                              { staticClass: "text-center text-h4" },
                              [
                                _vm._v(
                                  _vm._s(_vm.averageCartSize().toLocaleString())
                                ),
                              ]
                            ),
                            _c(
                              "v-card-text",
                              {
                                staticClass:
                                  "d-flex align-center justify-center",
                              },
                              [
                                _c("span", { staticClass: "mr-2" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formattedPercentages
                                        .percentageAvgCartSize
                                    )
                                  ),
                                ]),
                                _vm.percentageIcon("percentageAvgCartSize")
                                  ? _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          color: _vm.percentageColor(
                                            "percentageAvgCartSize"
                                          ),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.percentageIcon(
                                                "percentageAvgCartSize"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "3" } },
                      [
                        _c(
                          "v-card",
                          { attrs: { outlined: "", flat: "" } },
                          [
                            _c(
                              "v-card-subtitle",
                              { staticClass: "font-weight-bold" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      this.$vuetify.lang.t(
                                        "$vuetify.insights.average_order_value"
                                      )
                                    ) +
                                    " "
                                ),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    { attrs: { small: "" } },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    " mdi-help-circle-outline "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      166549224
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          this.$vuetify.lang.t(
                                            "$vuetify.insights.average_sales_orders_txt"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-card-text",
                              { staticClass: "text-center text-h4" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.kpiSummary &&
                                      _vm.kpiSummary.purchase &&
                                      _vm.kpiSummary.purchase
                                        .avg_purchase_value_by_orders
                                      ? _vm.kpiSummary.purchase.avg_purchase_value_by_orders.toLocaleString(
                                          undefined,
                                          { maximumFractionDigits: 2 }
                                        )
                                      : "0"
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "v-card-text",
                              {
                                staticClass:
                                  "d-flex align-center justify-center",
                              },
                              [
                                _c("span", { staticClass: "mr-2" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formattedPercentages
                                        .percentageAvgSaleOrder
                                    )
                                  ),
                                ]),
                                _vm.percentageIcon("percentageAvgSaleOrder")
                                  ? _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          color: _vm.percentageColor(
                                            "percentageAvgSaleOrder"
                                          ),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.percentageIcon(
                                                "percentageAvgSaleOrder"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "3" } },
                      [
                        _c(
                          "v-card",
                          { attrs: { outlined: "", flat: "" } },
                          [
                            _c(
                              "v-card-subtitle",
                              { staticClass: "font-weight-bold" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      this.$vuetify.lang.t(
                                        "$vuetify.insights.average_sales_users"
                                      )
                                    ) +
                                    " "
                                ),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    { attrs: { small: "" } },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    " mdi-help-circle-outline "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      166549224
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          this.$vuetify.lang.t(
                                            "$vuetify.insights.average_sales_users_txt"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-card-text",
                              { staticClass: "text-center text-h4" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.kpiSummary &&
                                      _vm.kpiSummary.purchase &&
                                      _vm.kpiSummary.purchase
                                        .avg_purchase_value_by_users
                                      ? _vm.kpiSummary.purchase.avg_purchase_value_by_users.toLocaleString()
                                      : "0"
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "v-card-text",
                              {
                                staticClass:
                                  "d-flex align-center justify-center",
                              },
                              [
                                _c("span", { staticClass: "mr-2" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formattedPercentages
                                        .percentageAvgSaleUser
                                    )
                                  ),
                                ]),
                                _vm.percentageIcon("percentageAvgSaleUser")
                                  ? _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          color: _vm.percentageColor(
                                            "percentageAvgSaleUser"
                                          ),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.percentageIcon(
                                                "percentageAvgSaleUser"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "3" } },
                      [
                        _c(
                          "v-card",
                          { attrs: { outlined: "", flat: "" } },
                          [
                            _c(
                              "v-card-subtitle",
                              { staticClass: "font-weight-bold" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      this.$vuetify.lang.t(
                                        "$vuetify.insights.average_sales_items"
                                      )
                                    ) +
                                    " "
                                ),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    { attrs: { small: "" } },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    " mdi-help-circle-outline "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      166549224
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          this.$vuetify.lang.t(
                                            "$vuetify.insights.average_sales_items_txt"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-card-text",
                              { staticClass: "text-center text-h4" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.kpiSummary &&
                                      _vm.kpiSummary.purchase &&
                                      typeof _vm.kpiSummary.purchase
                                        .avg_purchase_value_by_items
                                      ? _vm.kpiSummary.purchase.avg_purchase_value_by_items
                                          .toFixed(2)
                                          .toLocaleString()
                                      : "0"
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "v-card-text",
                              {
                                staticClass:
                                  "d-flex align-center justify-center",
                              },
                              [
                                _c("span", { staticClass: "mr-2" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formattedPercentages
                                        .percentageAvgSaleItems
                                    )
                                  ),
                                ]),
                                _vm.percentageIcon("percentageAvgSaleItems")
                                  ? _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          color: _vm.percentageColor(
                                            "percentageAvgSaleItems"
                                          ),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.percentageIcon(
                                                "percentageAvgSaleItems"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
          ]
        : _vm._e(),
      _vm.selectedProject.segment.name === "Personalizer"
        ? _c(
            "v-row",
            { staticClass: "d-flex justify-space-between" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "v-btn-toggle",
                    {
                      attrs: { "active-class": "white" },
                      model: {
                        value: _vm.activeTab,
                        callback: function ($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: _vm.activeTab === "item",
                            value: "item",
                          },
                        },
                        [
                          _c("span", { staticClass: "tab-card-title" }, [
                            _vm._v(
                              _vm._s(
                                this.$vuetify.lang.t("$vuetify.insights.item")
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: _vm.activeTab === "user",
                            value: "user",
                          },
                        },
                        [
                          _c("span", { staticClass: "tab-card-title" }, [
                            _vm._v(
                              _vm._s(
                                this.$vuetify.lang.t("$vuetify.insights.user")
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.activeTab === "item" &&
      _vm.selectedProject.segment.name === "Personalizer"
        ? _c(
            "v-row",
            { staticClass: "d-flex justify-space-between" },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  { staticStyle: { display: "inline-block" } },
                  [
                    _c("v-switch", {
                      attrs: {
                        label: this.$vuetify.lang.t(
                          "$vuetify.insights.line_chart"
                        ),
                      },
                      model: {
                        value: _vm.isLineChart,
                        callback: function ($$v) {
                          _vm.isLineChart = $$v
                        },
                        expression: "isLineChart",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "v-card",
                    { attrs: { height: "100%", outlined: "", flat: "" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column justify-space-between",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { id: "chartContainer" },
                            },
                            [
                              _vm.isLoading
                                ? _c("Loader", { staticClass: "mx-auto my-6" })
                                : !_vm.isLineChart && _vm.itemChartData
                                ? _c("apexchart", {
                                    ref: "barChart",
                                    attrs: {
                                      width: "100%",
                                      type: "bar",
                                      options: _vm.itemBarChart,
                                      series: [{ data: _vm.itemChartData }],
                                    },
                                  })
                                : _vm.isLineChart && _vm.itemChartData
                                ? _c("apexchart", {
                                    ref: "lineChart",
                                    attrs: {
                                      type: "line",
                                      options: _vm.itemlineChart,
                                      series: [{ data: _vm.itemChartData }],
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("TopPurchasedItems", {
                    attrs: {
                      itemList:
                        (_vm.kpiSummary &&
                          _vm.kpiSummary.purchase &&
                          _vm.kpiSummary.purchase.top_items) ||
                        [],
                      isLoading: _vm.isLoading,
                      "start-date": _vm.dates[0] || "",
                      "end-date": _vm.dates[1] || "",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("TopBrowsedItems", {
                    attrs: {
                      itemList:
                        (_vm.kpiSummary &&
                          _vm.kpiSummary.browse &&
                          _vm.kpiSummary.browse.top_items) ||
                        [],
                      isLoading: _vm.isLoading,
                      "start-date": _vm.dates[0] || "",
                      "end-date": _vm.dates[1] || "",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("TopRatedItems", {
                    attrs: {
                      itemList:
                        (_vm.kpiSummary &&
                          _vm.kpiSummary.ratings &&
                          _vm.kpiSummary.ratings.top_positive_rated_items) ||
                        [],
                      isLoading: _vm.isLoading,
                      "start-date": _vm.dates[0] || "",
                      "end-date": _vm.dates[1] || "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.activeTab === "user"
        ? _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  { staticStyle: { display: "inline-block" } },
                  [
                    _c("v-switch", {
                      attrs: {
                        label: this.$vuetify.lang.t(
                          "$vuetify.insights.line_chart"
                        ),
                      },
                      model: {
                        value: _vm.isLineChart,
                        callback: function ($$v) {
                          _vm.isLineChart = $$v
                        },
                        expression: "isLineChart",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "v-card",
                    { attrs: { outlined: "", flat: "", height: "100%" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column justify-space-between",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { id: "chartContainer" },
                            },
                            [
                              _vm.isLoading
                                ? _c("Loader", { staticClass: "mx-auto my-6" })
                                : !_vm.isLineChart
                                ? _c("apexchart", {
                                    key: _vm.isLineChart,
                                    ref: "barChart",
                                    attrs: {
                                      width: "100%",
                                      type: "bar",
                                      options: _vm.userBarChart,
                                      series: [{ data: _vm.userChartData }],
                                    },
                                  })
                                : _vm.isLineChart
                                ? _c("apexchart", {
                                    ref: "lineChart",
                                    attrs: {
                                      type: "line",
                                      options: _vm.userlineChart,
                                      series: [{ data: _vm.userChartData }],
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "mx-auto",
                      attrs: { rounded: "", outlined: "", height: "100%" },
                    },
                    [
                      _c(
                        "v-card-subtitle",
                        {
                          staticClass:
                            "font-weight-bold d-flex justify-space-between align-center",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    this.$vuetify.lang.t(
                                      "$vuetify.insights.top_users"
                                    )
                                  ) +
                                  " "
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "ml-1",
                                                    attrs: { small: "" },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  " mdi-help-circle-outline "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2369368821
                                  ),
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        this.$vuetify.lang.t(
                                          "$vuetify.insights.top_users"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                small: "",
                                color: "primary",
                                loading: _vm.downloading,
                                disabled:
                                  _vm.downloading || _vm.topUsers.length === 0,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.downloadAsCSV(
                                    "top_users",
                                    _vm.userHeaders
                                  )
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { left: "", small: "" } }, [
                                _vm._v("mdi-download"),
                              ]),
                              _vm._v(" CSV "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c("v-data-table", {
                        attrs: {
                          headers: _vm.userHeaders,
                          items: _vm.topUsers,
                          "items-per-page": _vm.itemsPerPage,
                          "sort-by": ["purchased_amount"],
                          "sort-desc": [true],
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function ({ item }) {
                                return [
                                  _c("tr", [
                                    _c("td", [_vm._v(_vm._s(item.user_id))]),
                                    _c("td", [
                                      _vm._v(_vm._s(item.member_id || "N/A")),
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          item.purchased_amount.toLocaleString()
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1074973283
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _vm.selectedProject.segment.name === _vm.PROJECT_SEGMENTS.Maira ||
          _vm.selectedProject.segment.name === _vm.PROJECT_SEGMENTS.PERSONALIZER
            ? _c(
                "v-col",
                { staticClass: "pa-0 px-3", attrs: { xs: "12", md: "12" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "mx-auto", attrs: { outlined: "" } },
                    [
                      _vm.isLoading
                        ? _c("Loader", { staticClass: "mr-2 mt-6" })
                        : [
                            _c(
                              "v-card-subtitle",
                              {
                                staticClass:
                                  "font-weight-bold d-flex justify-space-between align-center",
                              },
                              [
                                _c("div", { staticClass: "primary--text" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.gpt_conversation_history_title"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _vm.isDownloadButtonVisible
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "float-right",
                                        attrs: {
                                          color: "primary",
                                          dark: "",
                                          small: "",
                                          loading: _vm.downloading,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.downloadCSV(
                                              "gpt_history",
                                              _vm.gptHeaders,
                                              _vm.gptDetails,
                                              _vm.filteredGptDetails
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "v-btn__content" },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { left: "", small: "" },
                                              },
                                              [_vm._v("mdi-download")]
                                            ),
                                            _vm._v(" CSV "),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "v-btn",
                                      {
                                        staticClass: "float-right",
                                        attrs: {
                                          loading: _vm.downloading,
                                          small: "",
                                          disabled: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "v-btn__content" },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { left: "", small: "" },
                                              },
                                              [_vm._v("mdi-download")]
                                            ),
                                            _vm._v(" CSV "),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                              ],
                              1
                            ),
                            _c("v-divider"),
                            _c(
                              "v-row",
                              {
                                staticClass:
                                  "d-flex flex-column flex-md-row pa-4 mb-n6",
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pa-0 pr-0 pr-md-3",
                                    attrs: { width: "" },
                                  },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: _vm.$vuetify.lang.t(
                                          "$vuetify.gpt_history_search_user_id"
                                        ),
                                        outlined: "",
                                        dense: "",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.userFilter,
                                        callback: function ($$v) {
                                          _vm.userFilter = $$v
                                        },
                                        expression: "userFilter",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { staticClass: "pa-0 pr-0 pr-md-3" },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: _vm.$vuetify.lang.t(
                                          "$vuetify.gpt_history_search_query"
                                        ),
                                        outlined: "",
                                        dense: "",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.promptFilter,
                                        callback: function ($$v) {
                                          _vm.promptFilter = $$v
                                        },
                                        expression: "promptFilter",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { staticClass: "pa-0 pr-0 pr-md-3" },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        items: _vm.feedbackOptions,
                                        label: _vm.$vuetify.lang.t(
                                          "$vuetify.gpt_history_filter_feedback"
                                        ),
                                        outlined: "",
                                        dense: "",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.feedbackFilter,
                                        callback: function ($$v) {
                                          _vm.feedbackFilter = $$v
                                        },
                                        expression: "feedbackFilter",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { staticClass: "pa-0 pr-0" },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        items: _vm.endpointOptions,
                                        label: _vm.$vuetify.lang.t(
                                          "$vuetify.gpt_history_filter_endpoints"
                                        ),
                                        outlined: "",
                                        dense: "",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.endpointFilter,
                                        callback: function ($$v) {
                                          _vm.endpointFilter = $$v
                                        },
                                        expression: "endpointFilter",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("v-divider"),
                            _c("v-data-table", {
                              attrs: {
                                headers: _vm.gptHeaders,
                                items: _vm.gptConversations,
                                options: _vm.gptConversationHistoryOptions,
                                "server-items-length":
                                  _vm.gptTotalConversations,
                                "sort-by": "createdAt",
                                "sort-desc": true,
                              },
                              on: {
                                "update:options": function ($event) {
                                  _vm.gptConversationHistoryOptions = $event
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: `item.requestBody.userId`,
                                    fn: function ({ item }) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.requestBody.userId
                                                ? item.requestBody.userId.split(
                                                    "-"
                                                  )[0]
                                                : ""
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                  {
                                    key: `item.requestBody.query`,
                                    fn: function ({ item }) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.requestBody.query > 50
                                                ? item.requestBody.query.slice(
                                                    0,
                                                    50
                                                  ) + "..."
                                                : item.requestBody.query
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                  {
                                    key: `item.feedback`,
                                    fn: function ({ item }) {
                                      return [
                                        _c(
                                          "span",
                                          [
                                            item.feedback && item.feedback.type
                                              ? [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.feedback.type
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              : [_vm._v(" N/A ")],
                                          ],
                                          2
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: `item.tokens`,
                                    fn: function ({ item }) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(item.tokens)),
                                        ]),
                                      ]
                                    },
                                  },
                                  {
                                    key: `item.requestUrl`,
                                    fn: function ({ item }) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.determineEndpointType(
                                                  item.requestUrl
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                  {
                                    key: `item.createdAt`,
                                    fn: function ({ item }) {
                                      return [
                                        _c("div", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formatDateTime(
                                                  item.createdAt
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                  {
                                    key: `item.actions`,
                                    fn: function ({ item }) {
                                      return [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "mr-1",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.detail_modal(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [_vm._v("mdi-eye")]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$vuetify.lang.t(
                                                    "$vuetify.common.details"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: `item.gpt_train`,
                                    fn: function ({ item }) {
                                      return [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "mr-1",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.train_modal(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            "mdi-plus-circle"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$vuetify.lang.t(
                                                    "$vuetify.add_to_training_data"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _vm.selectedProject.segment.name === _vm.PROJECT_SEGMENTS.PERSONALIZER
            ? _c(
                "v-col",
                { staticClass: "pa-0 px-3", attrs: { xs: "12", md: "12" } },
                [
                  _c("QuestionnaireLog", {
                    attrs: { startDate: _vm.start_date, endDate: _vm.end_date },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.selectedProject.segment.name === "Personalizer"
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { xs: "12", md: "12" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "mx-auto px-2",
                      attrs: { rounded: "", outlined: "" },
                    },
                    [
                      _c("v-col", { attrs: { md: "6" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "primary--text font-weight-bold ml-n1",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$vuetify.lang.t(
                                    "$vuetify.api_call_response_summary"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c("v-divider"),
                      _vm.isLoading
                        ? _c("Loader", { staticClass: "mr-2 mt-6" })
                        : _c("v-data-table", {
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.requestDetails,
                              "items-per-page": _vm.itemsPerPage,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: `item.request_path`,
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "span",
                                                      _vm._g(
                                                        _vm._b(
                                                          {},
                                                          "span",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.request_path
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.request_path)),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: `item.number_of_request`,
                                  fn: function ({ item }) {
                                    return [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.number_of_request) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                                {
                                  key: `item.avg_processing_time`,
                                  fn: function ({ item }) {
                                    return [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.avg_processing_time.toFixed(
                                                5
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.selectedProject.segment.name === "Personalizer"
        ? [
            _c(
              "v-container",
              { attrs: { fluid: "" } },
              [
                _c(
                  "v-row",
                  { attrs: { justify: "center" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6", md: "4" } },
                      [
                        _c(
                          "v-card",
                          { attrs: { outlined: "" } },
                          [
                            _c(
                              "v-card-subtitle",
                              { staticClass: "font-weight-bold" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.device_ratio_txt"
                                      )
                                    ) +
                                    " "
                                ),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    { attrs: { small: "" } },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    " mdi-help-circle-outline"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1353681384
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.show_device_ratio_txt"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-card-text",
                              [
                                _vm.isLoading
                                  ? _c("Loader")
                                  : _vm.deviceRatio &&
                                    Object.keys(_vm.deviceRatio).length > 0 &&
                                    Object.values(_vm.deviceRatio).some(
                                      (value) => value !== 0
                                    )
                                  ? _c(
                                      "v-container",
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "div",
                                                  { attrs: { id: "chart" } },
                                                  [
                                                    _c("apexchart", {
                                                      attrs: {
                                                        type: "pie",
                                                        width: "100%",
                                                        options:
                                                          _vm.deviceRatioOptions,
                                                        series:
                                                          _vm.deviceRatioValue,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "v-card",
                                      { attrs: { flat: "", height: "250" } },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            staticClass: "fill-height",
                                            attrs: {
                                              align: "center",
                                              justify: "center",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "grey--text" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$vuetify.lang.t(
                                                        "$vuetify.no_data_available"
                                                      )
                                                    )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6", md: "4" } },
                      [
                        _c(
                          "v-card",
                          { attrs: { outlined: "" } },
                          [
                            _c(
                              "v-card-subtitle",
                              { staticClass: "font-weight-bold" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.browser_ratio_txt"
                                      )
                                    ) +
                                    " "
                                ),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    { attrs: { small: "" } },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    " mdi-help-circle-outline"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1353681384
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.show_browser_ratio_txt"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-card-text",
                              [
                                _vm.isLoading
                                  ? _c("Loader")
                                  : _vm.browserRatio &&
                                    Object.keys(_vm.browserRatio).length > 0 &&
                                    Object.values(_vm.browserRatio).some(
                                      (value) => value !== 0
                                    )
                                  ? _c(
                                      "v-container",
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "div",
                                                  { attrs: { id: "chart" } },
                                                  [
                                                    _c("apexchart", {
                                                      attrs: {
                                                        type: "pie",
                                                        width: "100%",
                                                        options:
                                                          _vm.browserRatioOptions,
                                                        series:
                                                          _vm.browserRatioValue,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "v-card",
                                      { attrs: { flat: "", height: "250" } },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            staticClass: "fill-height",
                                            attrs: {
                                              align: "center",
                                              justify: "center",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "grey--text" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$vuetify.lang.t(
                                                        "$vuetify.no_data_available"
                                                      )
                                                    )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6", md: "4" } },
                      [
                        _c(
                          "v-card",
                          { attrs: { outlined: "" } },
                          [
                            _c(
                              "v-card-subtitle",
                              { staticClass: "font-weight-bold" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.os_ratio_txt"
                                      )
                                    ) +
                                    " "
                                ),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    { attrs: { small: "" } },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    "mdi-help-circle-outline"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      722434280
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.show_os_ratio_txt"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-card-text",
                              [
                                _vm.isLoading
                                  ? _c("Loader")
                                  : _vm.osRatio &&
                                    Object.keys(_vm.osRatio).length > 0 &&
                                    Object.values(_vm.osRatio).some(
                                      (value) => value !== 0
                                    )
                                  ? _c(
                                      "v-container",
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "div",
                                                  { attrs: { id: "Chart" } },
                                                  [
                                                    _c("apexchart", {
                                                      attrs: {
                                                        type: "pie",
                                                        width: "102%",
                                                        options:
                                                          _vm.osRatioOptions,
                                                        series:
                                                          _vm.osRatioValue,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "v-card",
                                      { attrs: { flat: "", height: "250" } },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            staticClass: "fill-height",
                                            attrs: {
                                              align: "center",
                                              justify: "center",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "grey--text" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$vuetify.lang.t(
                                                      "$vuetify.no_data_available"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.openModal
        ? [
            _c(
              "v-dialog",
              {
                attrs: { "max-width": "100%" },
                model: {
                  value: _vm.openModal,
                  callback: function ($$v) {
                    _vm.openModal = $$v
                  },
                  expression: "openModal",
                },
              },
              [
                _c(
                  "v-card",
                  {
                    staticClass: "d-flex flex-column",
                    staticStyle: {
                      "overflow-y": "scroll",
                      "overflow-x": "hidden",
                    },
                  },
                  [
                    _c(
                      "v-card-text",
                      [
                        _c(
                          "v-container",
                          { attrs: { fluid: "" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          [
                                            _c(
                                              "v-list",
                                              [
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          {
                                                            staticClass:
                                                              "response-message-header",
                                                          },
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  this.$vuetify.lang.t(
                                                                    "$vuetify.gpt_history_query"
                                                                  )
                                                                ) + ":"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "response-message",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .modalContent
                                                                        .requestBody
                                                                        .query
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c("v-divider"),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  this.$vuetify.lang.t(
                                                                    "$vuetify.gpt_history_user_id"
                                                                  )
                                                                ) + ":"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "response-details-text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .modalContent
                                                                        .requestBody
                                                                        .userId
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  this.$vuetify.lang.t(
                                                                    "$vuetify.gpt_history_response"
                                                                  )
                                                                ) + ":"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "response-message response-details-text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .modalContent
                                                                        .response
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  this.$vuetify.lang.t(
                                                                    "$vuetify.gpt_history_conversation_id"
                                                                  )
                                                                ) + ":"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "response-details-text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .modalContent
                                                                        .id
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  this.$vuetify.lang.t(
                                                                    "$vuetify.gpt_history_date"
                                                                  )
                                                                ) + ":"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "response-details-text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.formatDateTime(
                                                                        _vm
                                                                          .modalContent
                                                                          .createdAt
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  this.$vuetify.lang.t(
                                                                    "$vuetify.gpt_history_processing_time"
                                                                  )
                                                                ) + ":"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "response-details-text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .modalContent
                                                                        .processTime
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  this.$vuetify.lang.t(
                                                                    "$vuetify.gpt_history_model"
                                                                  )
                                                                ) + ":"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "response-details-text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .modalContent
                                                                        .requestBody
                                                                        .model
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                "Chat History Length:"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "response-details-text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .modalContent
                                                                        .requestBody
                                                                        .chatHistoryLength
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  this.$vuetify.lang.t(
                                                                    "$vuetify.gpt_history_temperature"
                                                                  )
                                                                ) + ":"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "response-details-text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .modalContent
                                                                        .requestBody
                                                                        .temperature
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c("strong", [
                                                              _vm._v("Top P:"),
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "response-details-text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .modalContent
                                                                        .requestBody
                                                                        .topP
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c("strong", [
                                                              _vm._v("Top K:"),
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "response-details-text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .modalContent
                                                                        .requestBody
                                                                        .topK
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  this.$vuetify.lang.t(
                                                                    "$vuetify.gpt_history_search_max_token"
                                                                  )
                                                                ) + ":"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "response-details-text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .modalContent
                                                                        .requestBody
                                                                        .searchMaxToken
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  this.$vuetify.lang.t(
                                                                    "$vuetify.gpt_history_frequency_penalty"
                                                                  )
                                                                ) + ":"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "response-details-text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .modalContent
                                                                        .requestBody
                                                                        .frequencyPenalty
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  this.$vuetify.lang.t(
                                                                    "$vuetify.gpt_history_presence_penalty"
                                                                  )
                                                                ) + ":"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "response-details-text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .modalContent
                                                                        .requestBody
                                                                        .presencePenalty
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c("strong", [
                                                              _vm._v("Stop:"),
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "response-details-text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .modalContent
                                                                        .requestBody
                                                                        .stop &&
                                                                        _vm.modalContent.requestBody.stop.join(
                                                                          ", "
                                                                        )
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  this.$vuetify.lang.t(
                                                                    "$vuetify.gpt_history_result_includes"
                                                                  )
                                                                ) + ":"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "response-details-text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .modalContent
                                                                        .requestBody
                                                                        .resultIncludes &&
                                                                        _vm.modalContent.requestBody.resultIncludes.join(
                                                                          ", "
                                                                        )
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                "Session ID:"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "response-details-text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .modalContent
                                                                        .requestBody
                                                                        .sessionId
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                "GPT Profile Id:"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "response-details-text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .modalContent
                                                                        .gptProfileId
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  this.$vuetify.lang.t(
                                                                    "$vuetify.gpt_history_profile_name"
                                                                  )
                                                                ) + ":"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "response-details-text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .modalContent
                                                                        .requestBody
                                                                        .name
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c("strong", [
                                                              _vm._v("Intro:"),
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "response-details-text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .modalContent
                                                                        .requestBody
                                                                        .intro
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  this.$vuetify.lang.t(
                                                                    "$vuetify.gpt_history_system"
                                                                  )
                                                                ) + ":"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "response-details-text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .modalContent
                                                                        .requestBody
                                                                        .system
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  this.$vuetify.lang.t(
                                                                    "$vuetify.gpt_history_conversation_type"
                                                                  )
                                                                ) + ":"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "response-details-text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .modalContent
                                                                        .requestBody
                                                                        .conversationType
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c(
                                                              "v-row",
                                                              {
                                                                staticClass:
                                                                  "mt-0",
                                                              },
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    staticClass:
                                                                      "py-0 pr-0",
                                                                    attrs: {
                                                                      cols: "12",
                                                                      md: "auto",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "font-weight-bold pt-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              this.$vuetify.lang.t(
                                                                                "$vuetify.gpt_history_feedback_message"
                                                                              )
                                                                            ) +
                                                                            ": "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                !_vm.isEditing
                                                                  ? [
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          staticClass:
                                                                            "py-0 w-100",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "response-details-text pt-2 d-inline",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .modalContent
                                                                                      .feedback &&
                                                                                      _vm
                                                                                        .modalContent
                                                                                        .feedback
                                                                                        .message !==
                                                                                        undefined &&
                                                                                      _vm
                                                                                        .modalContent
                                                                                        .feedback
                                                                                        .message !==
                                                                                        null &&
                                                                                      _vm
                                                                                        .modalContent
                                                                                        .feedback
                                                                                        .message !==
                                                                                        ""
                                                                                      ? _vm
                                                                                          .modalContent
                                                                                          .feedback
                                                                                          .message
                                                                                      : _vm
                                                                                          .updatedData
                                                                                          .message !==
                                                                                          undefined &&
                                                                                        _vm
                                                                                          .updatedData
                                                                                          .message !==
                                                                                          null &&
                                                                                        _vm
                                                                                          .updatedData
                                                                                          .message !==
                                                                                          ""
                                                                                      ? _vm
                                                                                          .updatedData
                                                                                          .message
                                                                                      : "N/A"
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  icon: "",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  _vm.toggleEditing,
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-pencil"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  : [
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          staticClass:
                                                                            "py-2 w-100",
                                                                        },
                                                                        [
                                                                          _vm.isEditing
                                                                            ? _c(
                                                                                "v-textarea",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      label:
                                                                                        "",
                                                                                      placeholder:
                                                                                        "Write your feedback here...",
                                                                                      "auto-grow":
                                                                                        "",
                                                                                      rows: _vm.dynamicRowCount,
                                                                                      "max-rows":
                                                                                        "8",
                                                                                      outlined:
                                                                                        "",
                                                                                      dense:
                                                                                        "",
                                                                                    },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        _vm.editedFeedback,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.editedFeedback =
                                                                                            $$v
                                                                                        },
                                                                                      expression:
                                                                                        "editedFeedback",
                                                                                    },
                                                                                }
                                                                              )
                                                                            : _vm._e(),
                                                                          _c(
                                                                            "v-form",
                                                                            {
                                                                              ref: "feedbackTextarea",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-flex flex-column flex-sm-row justify-space-between align-center",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    [
                                                                                      _vm.isEditing
                                                                                        ? _c(
                                                                                            "v-radio-group",
                                                                                            {
                                                                                              staticClass:
                                                                                                "response-details-text mt-0 flex-column flex-sm-row",
                                                                                              attrs:
                                                                                                {
                                                                                                  row: "",
                                                                                                  rules:
                                                                                                    [
                                                                                                      (
                                                                                                        v
                                                                                                      ) =>
                                                                                                        !!v ||
                                                                                                        _vm.$vuetify.lang.t(
                                                                                                          "$vuetify.insights.feedback_type_required"
                                                                                                        ),
                                                                                                    ],
                                                                                                },
                                                                                              model:
                                                                                                {
                                                                                                  value:
                                                                                                    _vm.editedFeedbackType,
                                                                                                  callback:
                                                                                                    function (
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.editedFeedbackType =
                                                                                                        $$v
                                                                                                    },
                                                                                                  expression:
                                                                                                    "editedFeedbackType",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-radio",
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      label:
                                                                                                        _vm.$vuetify.lang.t(
                                                                                                          "$vuetify.insights.positive"
                                                                                                        ),
                                                                                                      value:
                                                                                                        "positive",
                                                                                                    },
                                                                                                }
                                                                                              ),
                                                                                              _c(
                                                                                                "v-radio",
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      label:
                                                                                                        _vm.$vuetify.lang.t(
                                                                                                          "$vuetify.insights.negative"
                                                                                                        ),
                                                                                                      value:
                                                                                                        "negative",
                                                                                                    },
                                                                                                }
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    [
                                                                                      _vm.isEditing
                                                                                        ? _c(
                                                                                            "v-row",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  align:
                                                                                                    "center",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-col",
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-btn",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "mr-2",
                                                                                                      attrs:
                                                                                                        {
                                                                                                          outlined:
                                                                                                            "",
                                                                                                          color:
                                                                                                            "red",
                                                                                                        },
                                                                                                      on: {
                                                                                                        click:
                                                                                                          _vm.cancelEditing,
                                                                                                      },
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          _vm.$vuetify.lang.t(
                                                                                                            "$vuetify.common.cancel"
                                                                                                          )
                                                                                                        )
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "v-btn",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          color:
                                                                                                            "primary",
                                                                                                          outlined:
                                                                                                            "",
                                                                                                        },
                                                                                                      on: {
                                                                                                        click:
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            $event.preventDefault()
                                                                                                            return _vm.saveEditedFeedback.apply(
                                                                                                              null,
                                                                                                              arguments
                                                                                                            )
                                                                                                          },
                                                                                                      },
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          _vm.$vuetify.lang.t(
                                                                                                            "$vuetify.common.save"
                                                                                                          )
                                                                                                        )
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                              ],
                                                              2
                                                            ),
                                                            [
                                                              _c(
                                                                "v-snackbar",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "success",
                                                                    top: "",
                                                                    timeout:
                                                                      "1000",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.snackbar,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.snackbar =
                                                                          $$v
                                                                      },
                                                                    expression:
                                                                      "snackbar",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.snackbarMessage
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                "Sections:"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "response-details-text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .modalContent
                                                                        .sections &&
                                                                        _vm.modalContent.sections.join(
                                                                          ", "
                                                                        )
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  this.$vuetify.lang.t(
                                                                    "$vuetify.gpt_history_context_preference"
                                                                  )
                                                                ) + ":"
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "20px",
                                                                  "font-weight":
                                                                    "500",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Preferred: "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "40px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Dataset ID:"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "response-details-text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .modalContent
                                                                        .requestBody
                                                                        .contextPreference &&
                                                                        _vm
                                                                          .modalContent
                                                                          .requestBody
                                                                          .contextPreference
                                                                          .preferred &&
                                                                        _vm
                                                                          .modalContent
                                                                          .requestBody
                                                                          .contextPreference
                                                                          .preferred
                                                                          .datasetId
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("p"),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "40px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Project ID:"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "response-details-text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .modalContent
                                                                        .requestBody
                                                                        .contextPreference &&
                                                                        _vm
                                                                          .modalContent
                                                                          .requestBody
                                                                          .contextPreference
                                                                          .preferred &&
                                                                        _vm
                                                                          .modalContent
                                                                          .requestBody
                                                                          .contextPreference
                                                                          .preferred
                                                                          .projectId
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "20px",
                                                                  "font-weight":
                                                                    "500",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Excludes: "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "40px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Client ID:"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "response-details-text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .modalContent
                                                                        .requestBody
                                                                        .contextPreference &&
                                                                        _vm
                                                                          .modalContent
                                                                          .requestBody
                                                                          .contextPreference
                                                                          .excludes &&
                                                                        _vm
                                                                          .modalContent
                                                                          .requestBody
                                                                          .contextPreference
                                                                          .excludes
                                                                          .clientId
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  this.$vuetify.lang.t(
                                                                    "$vuetify.gpt_history_conversation_metadata"
                                                                  )
                                                                ) + ":"
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "20px",
                                                                  "font-weight":
                                                                    "500",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Client ID:"
                                                                ),
                                                              ]
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .modalContent
                                                                      .requestBody
                                                                      .conversationMetadata &&
                                                                      _vm
                                                                        .modalContent
                                                                        .requestBody
                                                                        .conversationMetadata
                                                                        .clientId
                                                                  )
                                                              ),
                                                            ]),
                                                            _c("p"),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "20px",
                                                                  "font-weight":
                                                                    "500",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Project ID:"
                                                                ),
                                                              ]
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .modalContent
                                                                      .requestBody
                                                                      .conversationMetadata &&
                                                                      _vm
                                                                        .modalContent
                                                                        .requestBody
                                                                        .conversationMetadata
                                                                        .projectId
                                                                  )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c("strong", [
                                                              _vm._v("Usage:"),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "20px",
                                                                  "font-weight":
                                                                    "500",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    this.$vuetify.lang.t(
                                                                      "$vuetify.gpt_history_completion_tokens"
                                                                    )
                                                                  ) + ":"
                                                                ),
                                                              ]
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .modalContent
                                                                      .usage &&
                                                                      _vm
                                                                        .modalContent
                                                                        .usage
                                                                        .completionTokens
                                                                  )
                                                              ),
                                                            ]),
                                                            _c("p"),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "20px",
                                                                  "font-weight":
                                                                    "500",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    this.$vuetify.lang.t(
                                                                      "$vuetify.gpt_history_prompt_tokens"
                                                                    )
                                                                  ) + ":"
                                                                ),
                                                              ]
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .modalContent
                                                                      .usage &&
                                                                      _vm
                                                                        .modalContent
                                                                        .usage
                                                                        .promptTokens
                                                                  )
                                                              ),
                                                            ]),
                                                            _c("p"),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "20px",
                                                                  "font-weight":
                                                                    "500",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    this.$vuetify.lang.t(
                                                                      "$vuetify.gpt_history_query_tokens"
                                                                    )
                                                                  ) + ":"
                                                                ),
                                                              ]
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .modalContent
                                                                      .usage &&
                                                                      _vm
                                                                        .modalContent
                                                                        .usage
                                                                        .queryTokens
                                                                  )
                                                              ),
                                                            ]),
                                                            _c("p"),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "20px",
                                                                  "font-weight":
                                                                    "500",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    this.$vuetify.lang.t(
                                                                      "$vuetify.gpt_history_total_tokens"
                                                                    )
                                                                  ) + ":"
                                                                ),
                                                              ]
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .modalContent
                                                                      .usage &&
                                                                      _vm
                                                                        .modalContent
                                                                        .usage
                                                                        .totalTokens
                                                                  )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  this.$vuetify.lang.t(
                                                                    "$vuetify.gpt_history_vision_settings"
                                                                  )
                                                                ) + ":"
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "20px",
                                                                  "font-weight":
                                                                    "500",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    this.$vuetify.lang.t(
                                                                      "$vuetify.gpt_history_image_context_enabled"
                                                                    )
                                                                  ) + ":"
                                                                ),
                                                              ]
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .modalContent
                                                                      .requestBody
                                                                      .visionSettings &&
                                                                      _vm
                                                                        .modalContent
                                                                        .requestBody
                                                                        .visionSettings
                                                                        .is_image_context_enabled
                                                                  )
                                                              ),
                                                            ]),
                                                            _c("p"),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "20px",
                                                                  "font-weight":
                                                                    "500",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    this.$vuetify.lang.t(
                                                                      "$vuetify.gpt_history_resolution"
                                                                    )
                                                                  ) + ":"
                                                                ),
                                                              ]
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .modalContent
                                                                      .requestBody
                                                                      .visionSettings &&
                                                                      _vm
                                                                        .modalContent
                                                                        .requestBody
                                                                        .visionSettings
                                                                        .resolution
                                                                  )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  this.$vuetify.lang.t(
                                                                    "$vuetify.gpt_history_references"
                                                                  )
                                                                ) + ":"
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm.modalContent.references
                                                  ? [
                                                      _c("v-data-table", {
                                                        attrs: {
                                                          headers:
                                                            _vm.gptReferenceHeaders,
                                                          items:
                                                            _vm.getUniqueReferences(
                                                              _vm.modalContent
                                                                .references
                                                            ),
                                                          "items-per-page": 5,
                                                          "single-expand":
                                                            _vm.singleExpand,
                                                          expanded:
                                                            _vm.expanded,
                                                          "show-expand": "",
                                                          "item-key":
                                                            "section_id",
                                                        },
                                                        on: {
                                                          "update:expanded":
                                                            function ($event) {
                                                              _vm.expanded =
                                                                $event
                                                            },
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: `item.content`,
                                                              fn: function ({
                                                                item,
                                                              }) {
                                                                return [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.content &&
                                                                          item.content.substring(
                                                                            0,
                                                                            60
                                                                          )
                                                                      ) + " ..."
                                                                    ),
                                                                  ]),
                                                                ]
                                                              },
                                                            },
                                                            {
                                                              key: "expanded-item",
                                                              fn: function ({
                                                                headers,
                                                                item,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      attrs: {
                                                                        colspan:
                                                                          headers.length,
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            item.content
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      }),
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("v-divider"),
                    _c(
                      "v-card-actions",
                      { staticClass: "justify-end" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary", text: "" },
                            on: { click: _vm.close },
                          },
                          [_vm._v("Close")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      [
        _c(
          "v-dialog",
          {
            attrs: { persistent: "", "max-width": "60%" },
            model: {
              value: _vm.openTrainModal,
              callback: function ($$v) {
                _vm.openTrainModal = $$v
              },
              expression: "openTrainModal",
            },
          },
          [
            _c(
              "v-card",
              {
                staticStyle: { "overflow-y": "scroll", "overflow-x": "hidden" },
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex flex-column modalWrapper" },
                  [
                    _c("p", { staticClass: "training-header" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$vuetify.lang.t(
                              "$vuetify.add_gpt_response_to_training_data"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c(
                      "label",
                      { staticClass: "text-subtitle-1 text-medium-emphasis" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$vuetify.lang.t(
                              "$vuetify.gpt_history_conversation_id"
                            )
                          ) + ": "
                        ),
                        _c("span", { staticClass: "conversation_id" }, [
                          _vm._v(
                            " " + _vm._s(_vm.trainingData.conversation_id)
                          ),
                        ]),
                      ]
                    ),
                    _c("v-form", { ref: "documentForm" }, [
                      _c(
                        "div",
                        { staticClass: "inputWrapper" },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.datasets,
                              "item-text": "name",
                              label: _vm.$vuetify.lang.t(
                                "$vuetify.select_dataset"
                              ),
                              outlined: "",
                              "item-value": "dataset_id",
                              rules: _vm.getRules(
                                _vm.$vuetify.lang.t("$vuetify.select_dataset")
                              ),
                            },
                            on: { change: _vm.handleDataset },
                            model: {
                              value: _vm.selectedDataset,
                              callback: function ($$v) {
                                _vm.selectedDataset = $$v
                              },
                              expression: "selectedDataset",
                            },
                          }),
                          !_vm.newDataSet
                            ? _c("div", { staticClass: "dummy-width" })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm.newDataSet
                      ? [
                          _c(
                            "div",
                            [
                              _c(
                                "v-form",
                                { ref: "datasetForm" },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "12" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              type: "text",
                                              label: _vm.$vuetify.lang.t(
                                                "$vuetify.dataset_name"
                                              ),
                                              rules: [
                                                _vm.required(
                                                  _vm.$vuetify.lang.t(
                                                    "$vuetify.dataset_name"
                                                  )
                                                ),
                                              ],
                                              outlined: "",
                                            },
                                            model: {
                                              value: _vm.newDatasetName,
                                              callback: function ($$v) {
                                                _vm.newDatasetName = $$v
                                              },
                                              expression: "newDatasetName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              type: "text",
                                              label: _vm.$vuetify.lang.t(
                                                "$vuetify.index_column_name"
                                              ),
                                              rules: [
                                                _vm.required(
                                                  _vm.$vuetify.lang.t(
                                                    "$vuetify.index_column_name"
                                                  )
                                                ),
                                              ],
                                              outlined: "",
                                            },
                                            model: {
                                              value: _vm.indexColumnName,
                                              callback: function ($$v) {
                                                _vm.indexColumnName = $$v
                                              },
                                              expression: "indexColumnName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              type: "text",
                                              label: _vm.$vuetify.lang.t(
                                                "$vuetify.secondary_index_column"
                                              ),
                                              outlined: "",
                                            },
                                            model: {
                                              value: _vm.secondaryIndex,
                                              callback: function ($$v) {
                                                _vm.secondaryIndex = $$v
                                              },
                                              expression: "secondaryIndex",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              type: "text",
                                              label: _vm.$vuetify.lang.t(
                                                "$vuetify.image_url_column"
                                              ),
                                              outlined: "",
                                            },
                                            model: {
                                              value: _vm.imageColumn,
                                              callback: function ($$v) {
                                                _vm.imageColumn = $$v
                                              },
                                              expression: "imageColumn",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              type: "text",
                                              label: _vm.$vuetify.lang.t(
                                                "$vuetify.description_label_txt"
                                              ),
                                              outlined: "",
                                            },
                                            model: {
                                              value: _vm.description,
                                              callback: function ($$v) {
                                                _vm.description = $$v
                                              },
                                              expression: "description",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("v-checkbox", {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          label: _vm.$vuetify.lang.t(
                                            "$vuetify.fill_up_index_if_empty"
                                          ),
                                        },
                                        model: {
                                          value: _vm.fillUpEmptyIndex,
                                          callback: function ($$v) {
                                            _vm.fillUpEmptyIndex = $$v
                                          },
                                          expression: "fillUpEmptyIndex",
                                        },
                                      }),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "12" } },
                                        [
                                          _c("v-file-input", {
                                            attrs: {
                                              outlined: "",
                                              accept:
                                                ".json, .csv, application/json, text/csv",
                                              label: _vm.$vuetify.lang.t(
                                                "$vuetify.dataset_file"
                                              ),
                                            },
                                            on: {
                                              change: _vm.handleFileChange,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "btn-wrapper",
                                  staticStyle: { "margin-top": "20px" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "red--text font-weight-bold",
                                      staticStyle: { "margin-right": "14px" },
                                      attrs: {
                                        type: "reset",
                                        text: "",
                                        outlined: "",
                                      },
                                      on: { click: _vm.closeTrainModal },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            this.$vuetify.lang.t(
                                              "$vuetify.cancel_btn_txt"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "add-btn",
                                      attrs: { color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.createDataset()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.create_dataset"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      : [
                          _c(
                            "div",
                            { staticClass: "inputWrapper" },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  type: "text",
                                  "auto-grow": "",
                                  label: _vm.$vuetify.lang.t(
                                    "$vuetify.gpt_history_query"
                                  ),
                                  outlined: "",
                                },
                                model: {
                                  value: _vm.trainingData.query,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.trainingData, "query", $$v)
                                  },
                                  expression: "trainingData.query",
                                },
                              }),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedResponseFields.query,
                                    expression: "selectedResponseFields.query",
                                  },
                                ],
                                staticClass: "checkbox",
                                attrs: { type: "checkbox", name: "myCheckbox" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.selectedResponseFields.query
                                  )
                                    ? _vm._i(
                                        _vm.selectedResponseFields.query,
                                        null
                                      ) > -1
                                    : _vm.selectedResponseFields.query,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.selectedResponseFields.query,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.selectedResponseFields,
                                            "query",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.selectedResponseFields,
                                            "query",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.selectedResponseFields,
                                        "query",
                                        $$c
                                      )
                                    }
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "inputWrapper" },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  type: "text",
                                  "auto-grow": "",
                                  label: _vm.$vuetify.lang.t(
                                    "$vuetify.gpt_history_response"
                                  ),
                                  outlined: "",
                                },
                                model: {
                                  value: _vm.trainingData.response,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.trainingData, "response", $$v)
                                  },
                                  expression: "trainingData.response",
                                },
                              }),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedResponseFields.response,
                                    expression:
                                      "selectedResponseFields.response",
                                  },
                                ],
                                staticClass: "checkbox",
                                attrs: { type: "checkbox", name: "myCheckbox" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.selectedResponseFields.response
                                  )
                                    ? _vm._i(
                                        _vm.selectedResponseFields.response,
                                        null
                                      ) > -1
                                    : _vm.selectedResponseFields.response,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a =
                                        _vm.selectedResponseFields.response,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.selectedResponseFields,
                                            "response",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.selectedResponseFields,
                                            "response",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.selectedResponseFields,
                                        "response",
                                        $$c
                                      )
                                    }
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "inputWrapper" },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  type: "text",
                                  "auto-grow": "",
                                  label: _vm.$vuetify.lang.t(
                                    "$vuetify.gpt_history_feedback"
                                  ),
                                  outlined: "",
                                },
                                model: {
                                  value: _vm.trainingData.feedback,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.trainingData, "feedback", $$v)
                                  },
                                  expression: "trainingData.feedback",
                                },
                              }),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedResponseFields.feedback,
                                    expression:
                                      "selectedResponseFields.feedback",
                                  },
                                ],
                                staticClass: "checkbox",
                                attrs: { type: "checkbox", name: "myCheckbox" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.selectedResponseFields.feedback
                                  )
                                    ? _vm._i(
                                        _vm.selectedResponseFields.feedback,
                                        null
                                      ) > -1
                                    : _vm.selectedResponseFields.feedback,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a =
                                        _vm.selectedResponseFields.feedback,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.selectedResponseFields,
                                            "feedback",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.selectedResponseFields,
                                            "feedback",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.selectedResponseFields,
                                        "feedback",
                                        $$c
                                      )
                                    }
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c("v-form", { ref: "conversationMetadata" }, [
                            _c(
                              "div",
                              { staticClass: "inputWrapper" },
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    type: "text",
                                    "auto-grow": "",
                                    label: _vm.$vuetify.lang.t(
                                      "$vuetify.gpt_history_conversation_metadata"
                                    ),
                                    outlined: "",
                                    rules: _vm.selectedResponseFields
                                      .conversation_metadata
                                      ? [_vm.validateConversationMetadata]
                                      : [],
                                  },
                                  model: {
                                    value:
                                      _vm.trainingData.conversation_metadata,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.trainingData,
                                        "conversation_metadata",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "trainingData.conversation_metadata",
                                  },
                                }),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.selectedResponseFields
                                          .conversation_metadata,
                                      expression:
                                        "selectedResponseFields.conversation_metadata",
                                    },
                                  ],
                                  staticClass: "checkbox",
                                  attrs: {
                                    type: "checkbox",
                                    name: "myCheckbox",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.selectedResponseFields
                                        .conversation_metadata
                                    )
                                      ? _vm._i(
                                          _vm.selectedResponseFields
                                            .conversation_metadata,
                                          null
                                        ) > -1
                                      : _vm.selectedResponseFields
                                          .conversation_metadata,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.selectedResponseFields
                                            .conversation_metadata,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.selectedResponseFields,
                                              "conversation_metadata",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.selectedResponseFields,
                                              "conversation_metadata",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.selectedResponseFields,
                                          "conversation_metadata",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "btn-wrapper" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "red--text font-weight-bold",
                                  staticStyle: { "margin-right": "14px" },
                                  attrs: {
                                    type: "reset",
                                    text: "",
                                    outlined: "",
                                  },
                                  on: { click: _vm.closeTrainModal },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        this.$vuetify.lang.t(
                                          "$vuetify.cancel_btn_txt"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "add-btn",
                                  staticStyle: {
                                    "margin-right": "44px",
                                    "min-width": "120px",
                                  },
                                  attrs: { color: "primary" },
                                  on: { click: _vm.addTrainingData },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$vuetify.lang.t("$vuetify.common.add")
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                  ],
                  2
                ),
              ]
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }