import { getCurrentUser } from "@/helpers/helper";

export default {
  methods: {
    checkUserLoggedOut(event) {
      if (event.key !== 'doozie_user') return;
      if (!getCurrentUser()) {
        this.$router.push('/login');
      }
    },
  },
  mounted() {
    window.addEventListener('storage', this.checkUserLoggedOut);
  },
  beforeDestroy() {
    window.removeEventListener('storage', this.checkUserLoggedOut);
  },
};