var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-main",
        [
          _c(
            "v-container",
            { attrs: { "text-center": "", id: "login-page" } },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    "align-center": "",
                    "justify-center": "",
                    row: "",
                    wrap: "",
                  },
                },
                [
                  _c("v-flex", { attrs: { xs12: "", sm8: "", md4: "" } }, [
                    !_vm.verificationComplete
                      ? _c("h2", { staticClass: "primary--text mt-16" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t("$vuetify.verify_msg_txt")
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }