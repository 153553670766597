var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "400", persistent: "" },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "font-weight-bold" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$vuetify.lang.t("$vuetify.wallet.enter_passcode")) +
                " "
            ),
          ]),
          _vm.message
            ? _c("v-card-subtitle", [_vm._v(_vm._s(_vm.message))])
            : _vm._e(),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "ValidationObserver",
                        { ref: "passcodeConfirm" },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "passcodeForm",
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.confirmClick()
                                },
                              },
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: _vm.$vuetify.lang.t(
                                        "$vuetify.wallet.passcode"
                                      ),
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c("v-text-field", {
                                              class: {
                                                "mask-password":
                                                  !_vm.showPasscode,
                                              },
                                              attrs: {
                                                label: _vm.$vuetify.lang.t(
                                                  "$vuetify.wallet.passcode"
                                                ),
                                                value: _vm.value,
                                                placeholder:
                                                  _vm.$vuetify.lang.t(
                                                    "$vuetify.wallet.passcode"
                                                  ),
                                                autocomplete: "off",
                                                "prepend-inner-icon": "lock",
                                                outlined: "",
                                                "append-icon": _vm.showPasscode
                                                  ? "mdi-eye"
                                                  : "mdi-eye-off",
                                                type: "text",
                                                "error-messages": errors,
                                              },
                                              on: {
                                                "click:append": function (
                                                  $event
                                                ) {
                                                  _vm.showPasscode =
                                                    !_vm.showPasscode
                                                },
                                                input: function ($event) {
                                                  return _vm.$emit(
                                                    "input",
                                                    $event
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm.isCancelBtn
                ? _c(
                    "v-btn",
                    {
                      attrs: { disabled: _vm.isLoading, color: "error" },
                      on: {
                        click: function ($event) {
                          return _vm.clickCancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$vuetify.lang.t("$vuetify.common.cancel"))
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", loading: _vm.isLoading },
                  on: {
                    click: function ($event) {
                      return _vm.confirmClick()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.common.confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }