var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("Loader", {
            staticClass: "mr-2 mt-6",
            attrs: { loaderType: "card-avatar, article, actions" },
          })
        : _c(
            "v-container",
            {
              staticClass: "ma-0 pa-0",
              attrs: { "fill-height": "", fluid: "" },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-tabs",
                        {
                          attrs: { "show-arrows": "" },
                          model: {
                            value: _vm.tab,
                            callback: function ($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab",
                          },
                        },
                        [
                          _c("v-tabs-slider"),
                          _vm.isDeveloper() && !_vm.isAdmin()
                            ? [
                                _c(
                                  "v-tab",
                                  {
                                    key: "all",
                                    staticClass: "tab-option",
                                    attrs: { href: "#all" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.solutions_users_tab"
                                          )
                                        ) +
                                        " "
                                    ),
                                    _vm.icons
                                      ? _c("v-icon", [_vm._v("mdi-phone")])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-tab",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.isAdmin(),
                                        expression: "isAdmin()",
                                      },
                                    ],
                                    key: "projects",
                                    staticClass: "tab-option",
                                    attrs: { href: "#projects" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.solutions_projects_tab"
                                          )
                                        ) +
                                        " "
                                    ),
                                    _vm.icons
                                      ? _c("v-icon", [_vm._v("mdi-phone")])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-tab",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.isDeveloper(),
                                        expression: "isDeveloper()",
                                      },
                                    ],
                                    key: "mysolutions",
                                    staticClass: "tab-option",
                                    attrs: { href: "#mysolutions" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.solutions_developers_tab"
                                          )
                                        ) +
                                        " "
                                    ),
                                    _vm.icons
                                      ? _c("v-icon", [_vm._v("mdi-phone")])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            : [
                                _c(
                                  "v-tab",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.isAdmin(),
                                        expression: "isAdmin()  ",
                                      },
                                    ],
                                    key: "projects",
                                    staticClass: "tab-option",
                                    attrs: { href: "#projects" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.solutions_projects_tab"
                                          )
                                        ) +
                                        " "
                                    ),
                                    _vm.icons
                                      ? _c("v-icon", [_vm._v("mdi-phone")])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-tab",
                                  {
                                    key: "all",
                                    staticClass: "tab-option",
                                    attrs: { href: "#all" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.solutions_users_tab"
                                          )
                                        ) +
                                        " "
                                    ),
                                    _vm.icons
                                      ? _c("v-icon", [_vm._v("mdi-phone")])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-tab",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.isDeveloper(),
                                        expression: "isDeveloper()",
                                      },
                                    ],
                                    key: "mysolutions",
                                    staticClass: "tab-option",
                                    attrs: { href: "#mysolutions" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.solutions_developers_tab"
                                          )
                                        ) +
                                        " "
                                    ),
                                    _vm.icons
                                      ? _c("v-icon", [_vm._v("mdi-phone")])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              this.tab !== "projects"
                ? _c(
                    "v-row",
                    [
                      this.tab !== "projects"
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", md: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  label: _vm.$vuetify.lang.t(
                                    "$vuetify.search_label_txt"
                                  ),
                                  "prepend-inner-icon": "search",
                                },
                                on: {
                                  keypress: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.search.apply(null, arguments)
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "text-capitalize font-weight-bold mt-n3 mr-n2",
                                              attrs: {
                                                large: "",
                                                color: "primary",
                                                "append-icon": "search",
                                              },
                                              on: { click: _vm.search },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-none d-sm-flex",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$vuetify.lang.t(
                                                        "$vuetify.search_btn_txt"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "d-flex d-sm-none",
                                                },
                                                [_vm._v("search")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3800956296
                                ),
                                model: {
                                  value: _vm.searchText,
                                  callback: function ($$v) {
                                    _vm.searchText = $$v
                                  },
                                  expression: "searchText",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _c("v-row", { staticClass: "pt-3" }),
              _c(
                "v-row",
                { staticClass: "mt-n9" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", xs: "12", md: "12" } },
                    [
                      _c(
                        "v-tabs",
                        {
                          attrs: {
                            centered: _vm.centered,
                            grow: _vm.grow,
                            vertical: _vm.vertical,
                            right: _vm.right,
                            "prev-icon": _vm.prevIcon
                              ? "mdi-arrow-left-bold-box-outline"
                              : undefined,
                            "next-icon": _vm.nextIcon
                              ? "mdi-arrow-right-bold-box-outline"
                              : undefined,
                            "icons-and-text": _vm.icons,
                          },
                          model: {
                            value: _vm.tab,
                            callback: function ($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab",
                          },
                        },
                        [
                          _c(
                            "v-tab-item",
                            { key: "all", attrs: { value: "all" } },
                            [
                              _vm.allSolution && _vm.allSolution.length !== 0
                                ? _c(
                                    "v-row",
                                    _vm._l(_vm.allSolution, function (item) {
                                      return _c(
                                        "v-col",
                                        {
                                          key: item.id,
                                          attrs: {
                                            cols: "12",
                                            xs: "12",
                                            sm: "6",
                                            md: "6",
                                            lg: "4",
                                            xl: "4",
                                          },
                                        },
                                        [
                                          _c("SolutionCard", {
                                            attrs: { itemData: item },
                                          }),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                : _c(
                                    "v-row",
                                    {
                                      staticClass:
                                        "justify-center align-center mt-16",
                                    },
                                    [
                                      _c("h1", [
                                        _vm._v("Sorry, No result found :("),
                                      ]),
                                    ]
                                  ),
                            ],
                            1
                          ),
                          _vm.isDeveloper()
                            ? _c(
                                "v-tab-item",
                                {
                                  key: "mysolutions",
                                  attrs: { value: "mysolutions" },
                                },
                                [
                                  _c("DeveloperSolution", {
                                    attrs: {
                                      isDevSolLoading: _vm.isDevSolLoading,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-tab-item",
                            { key: "projects", attrs: { value: "projects" } },
                            [
                              _c("ProjectLists", {
                                attrs: { solution_type: _vm.$route.query.type },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }