var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-text-field", {
    attrs: {
      label: _vm.label,
      placeholder: _vm.placeholder,
      hint: _vm.hint,
      "prepend-icon": _vm.prependIcon,
      type: _vm.showPassword ? "text" : "password",
      "append-icon": _vm.showPassword ? "mdi-eye" : "mdi-eye-off",
      rules: _vm.rules,
      disabled: _vm.disabled,
      dense: _vm.dense,
    },
    on: {
      "click:append": function ($event) {
        _vm.showPassword = !_vm.showPassword
      },
      change: _vm.onChange,
    },
    model: {
      value: _vm.inputVal,
      callback: function ($$v) {
        _vm.inputVal = $$v
      },
      expression: "inputVal",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }