import { render, staticRenderFns } from "./Button.vue?vue&type=template&id=1359323b"
import script from "./Button.vue?vue&type=script&lang=js"
export * from "./Button.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1359323b')) {
      api.createRecord('1359323b', component.options)
    } else {
      api.reload('1359323b', component.options)
    }
    module.hot.accept("./Button.vue?vue&type=template&id=1359323b", function () {
      api.rerender('1359323b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/button/Button.vue"
export default component.exports