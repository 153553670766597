var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex justify-center align-center" }, [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("@/assets/images/logo_horizontal.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }