var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    { attrs: { row: "" } },
    [
      _vm.detailsButton
        ? _c("Button", {
            attrs: {
              buttonType: _vm.ICON_BUTTON,
              iconName: "mdi-eye",
              size: "small",
              buttonText: _vm.$vuetify.lang.t("$vuetify.details_header_txt"),
              disabled: _vm.disabledDetailsButton,
            },
            on: {
              onClick: function ($event) {
                return _vm.onDetailsClick()
              },
            },
          })
        : _vm._e(),
      _vm.editButton
        ? _c("Button", {
            attrs: {
              buttonType: _vm.ICON_BUTTON,
              iconName: "mdi-pencil",
              size: "small",
              buttonText: _vm.$vuetify.lang.t("$vuetify.tooltip.edit"),
              disabled: _vm.disabledEditButton,
            },
            on: {
              onClick: function ($event) {
                return _vm.onEditClick()
              },
            },
          })
        : _vm._e(),
      _vm.addButton
        ? _c("Button", {
            attrs: {
              buttonType: _vm.ICON_BUTTON,
              iconName: "mdi-plus",
              size: "small",
              buttonText: "Add to common example",
              disabled: _vm.disabledAddButton,
            },
            on: {
              onClick: function ($event) {
                return _vm.onAddClick()
              },
            },
          })
        : _vm._e(),
      _vm.stopButton
        ? _c("Button", {
            attrs: {
              buttonType: _vm.ICON_BUTTON,
              iconName: "mdi-stop",
              size: "small",
              buttonText: _vm.$vuetify.lang.t("$vuetify.tooltip.stop"),
              disabled: _vm.disabledStopButton,
            },
            on: {
              onClick: function ($event) {
                return _vm.onStopClick()
              },
            },
          })
        : _vm._e(),
      _vm.startButton
        ? _c("Button", {
            attrs: {
              buttonType: _vm.ICON_BUTTON,
              iconName: "mdi-reload",
              size: "small",
              buttonText: _vm.$vuetify.lang.t("$vuetify.tooltip.start"),
              disabled: _vm.disabledStartButton,
            },
            on: {
              onClick: function ($event) {
                return _vm.onStartClick()
              },
            },
          })
        : _vm._e(),
      _vm.documentButton
        ? _c("Button", {
            attrs: {
              buttonType: _vm.ICON_BUTTON,
              iconName: "mdi-file-document",
              size: "small",
              buttonText: _vm.$vuetify.lang.t("$vuetify.tooltip.document"),
              disabled: _vm.disabledDocumentButton,
            },
            on: {
              onClick: function ($event) {
                return _vm.onDocumentClick()
              },
            },
          })
        : _vm._e(),
      _vm.deleteButton
        ? _c("Button", {
            attrs: {
              buttonType: _vm.ICON_BUTTON,
              iconName: "mdi-delete",
              size: "small",
              buttonText: _vm.$vuetify.lang.t("$vuetify.tooltip.delete"),
              disabled: _vm.disabledDeleteButton,
            },
            on: {
              onClick: function ($event) {
                return _vm.onDeleteClick()
              },
            },
          })
        : _vm._e(),
      _vm.downloadButton
        ? _c("Button", {
            attrs: {
              buttonType: _vm.ICON_BUTTON,
              iconName: "get_app",
              size: "small",
              buttonText: "Download",
              disabled: _vm.disabledDownloadButton,
            },
            on: {
              onClick: function ($event) {
                return _vm.onDownloadClick()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }