var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { "fill-height": "", fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { xs: "12", md: "12" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "mx-auto px-2", attrs: { outlined: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { md: "6" } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "px-2 primary--text font-weight-bold",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.gpt_dataset.dataset_header_txt"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "v-col",
                            { attrs: { md: "6" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "float-right text-capitalize",
                                  attrs: { color: "primary", dark: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.createNewDatasetClick()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "hidden-sm-and-down" },
                                    [
                                      _c("v-icon", { attrs: { left: "" } }, [
                                        _vm._v("mdi-plus-circle-outline"),
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$vuetify.lang.t(
                                              "$vuetify.create_dataset"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "hidden-md-and-up" },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-plus-circle-outline"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", xs: "12", md: "4" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "append-icon": "mdi-magnify",
                                  label: _vm.$vuetify.lang.t(
                                    "$vuetify.search_btn_txt"
                                  ),
                                  outlined: "",
                                  dense: "",
                                  "single-line": "",
                                  "hide-details": "",
                                },
                                on: { change: _vm.handleQueryOnDatasets },
                                model: {
                                  value: _vm.search,
                                  callback: function ($$v) {
                                    _vm.search = $$v
                                  },
                                  expression: "search",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", xs: "12", md: "3" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.states,
                                  "item-text": "state",
                                  "item-value": "value",
                                  "persistent-hint": "",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: "",
                                },
                                on: { change: _vm.handleQueryOnDatasets },
                                model: {
                                  value: _vm.selected_dataset_status,
                                  callback: function ($$v) {
                                    _vm.selected_dataset_status = $$v
                                  },
                                  expression: "selected_dataset_status",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _vm.isLoading
                        ? _c("Loader", { staticClass: "mr-2 mt-6" })
                        : _c("v-data-table", {
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.gptDatasets,
                              "hide-default-footer": "",
                              "custom-sort": _vm.handleSort,
                              "must-sort": true,
                              "sort-by": _vm.sortBy,
                              "sort-desc": _vm.sortDesc,
                              "items-per-page": _vm.itemsPerPage,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: `item.dataset_id`,
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "span",
                                                      _vm._g(
                                                        _vm._b(
                                                          {},
                                                          "span",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.dataset_id.split(
                                                              "-"
                                                            )[0]
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.dataset_id)),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: `item.name`,
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "primary--text text--darken-3",
                                              attrs: { text: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.detailsModal(item)
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(item.name))]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: `item.created_at`,
                                  fn: function ({ item }) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatDateTime(item.created_at)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: `item.actions`,
                                  fn: function ({ item }) {
                                    return [
                                      _c("ButtonGroup", {
                                        staticClass: "d-flex justify-center",
                                        attrs: {
                                          groupType: _vm.ACTION_BUTTON_GROUP,
                                          addButton: false,
                                          startButton: false,
                                          stopButton: false,
                                          documentButton: true,
                                          disabledDeleteButton:
                                            _vm.disable_crud_operation,
                                          disabledEditButton:
                                            _vm.disable_crud_operation,
                                        },
                                        on: {
                                          onDetailsClick: function ($event) {
                                            return _vm.detailsModal(item)
                                          },
                                          onEditClick: function ($event) {
                                            return _vm.updateItem(item)
                                          },
                                          onDocumentClick: function ($event) {
                                            return _vm.redirectDocument(item)
                                          },
                                          onDeleteClick: function ($event) {
                                            return _vm.deleteItem(item)
                                          },
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", md: "12" },
                            },
                            [_c("v-divider")],
                            1
                          ),
                          _c("v-col", {
                            attrs: { cols: "12", xs: "12", sm: "3", md: "3" },
                          }),
                          _c(
                            "v-col",
                            {
                              attrs: { cols: "12", xs: "12", sm: "6", md: "6" },
                            },
                            [
                              _c("v-pagination", {
                                staticClass: "text-center",
                                attrs: { length: _vm.pageCount },
                                model: {
                                  value: _vm.page,
                                  callback: function ($$v) {
                                    _vm.page = $$v
                                  },
                                  expression: "page",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                "offset-sm": "1",
                                "offset-md": "1",
                                xs: "3",
                                sm: "2",
                                md: "2",
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.rows,
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: "",
                                },
                                model: {
                                  value: _vm.itemsPerPage,
                                  callback: function ($$v) {
                                    _vm.itemsPerPage = $$v
                                  },
                                  expression: "itemsPerPage",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.detailsDialog
        ? [
            _c(
              "v-dialog",
              {
                attrs: {
                  "max-width": "800px",
                  persistent: "",
                  scrollable: "",
                  "hide-overlay": "",
                  transition: "dialog-bottom-transition",
                  "content-class": "rounded-lg",
                  elevation: "24",
                  rounded: "",
                },
                model: {
                  value: _vm.detailsDialog,
                  callback: function ($$v) {
                    _vm.detailsDialog = $$v
                  },
                  expression: "detailsDialog",
                },
              },
              [
                !_vm.detailsLoader
                  ? _c(
                      "v-card",
                      {
                        staticClass: "rounded-lg",
                        attrs: { elevation: "24", rounded: "" },
                      },
                      [
                        _c("v-card-title", [
                          _c("div", [
                            _c(
                              "span",
                              { staticClass: "headline text-capitalize" },
                              [_vm._v(_vm._s(_vm.dataset_summary.name))]
                            ),
                            _c("span", { staticClass: "caption d-block" }, [
                              _vm._v(_vm._s(_vm.dataset_summary.dataset_id)),
                            ]),
                          ]),
                        ]),
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "6" } },
                                  [
                                    _c("div", [
                                      _c("strong", [_vm._v("Description:")]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.dataset_summary.description ||
                                              "No description available"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("div", [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$vuetify.lang.t(
                                              "$vuetify.created_at_txt"
                                            )
                                          ) + ":"
                                        ),
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            new Date(
                                              _vm.dataset_summary.created_at
                                            ).toLocaleString()
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("div", [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$vuetify.lang.t(
                                              "$vuetify.gpt_dataset.document_count"
                                            )
                                          ) + ":"
                                        ),
                                      ]),
                                      _c("ul", [
                                        _c("li", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.total_label_txt"
                                              )
                                            ) +
                                              ": " +
                                              _vm._s(
                                                _vm.dataset_summary
                                                  .documents_count.total
                                              )
                                          ),
                                        ]),
                                        _c("li", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.dashboard.active"
                                              )
                                            ) +
                                              ": " +
                                              _vm._s(
                                                _vm.dataset_summary
                                                  .documents_count.active
                                              )
                                          ),
                                        ]),
                                        _c("li", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.gpt_dataset.text_trained"
                                              )
                                            ) +
                                              ": " +
                                              _vm._s(
                                                _vm.dataset_summary
                                                  .documents_count.text_trained
                                              )
                                          ),
                                        ]),
                                        _c("li", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.gpt_dataset.image_trained"
                                              )
                                            ) +
                                              ": " +
                                              _vm._s(
                                                _vm.dataset_summary
                                                  .documents_count.image_trained
                                              )
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                    _c("div", [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$vuetify.lang.t(
                                              "$vuetify.gpt_dataset.index_column_name"
                                            )
                                          ) + ":"
                                        ),
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.dataset_summary.idx_column_name
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("div", [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$vuetify.lang.t(
                                              "$vuetify.gpt_dataset.image_url_column"
                                            )
                                          ) + ":"
                                        ),
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.dataset_summary
                                              .image_url_column ||
                                              "Not available"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("div", [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$vuetify.lang.t(
                                              "$vuetify.gpt_dataset.secondary_index_column"
                                            )
                                          ) + ":"
                                        ),
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.dataset_summary
                                              .secondary_idx_column ||
                                              "Not available"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          { staticClass: "d-flex justify-end" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "", color: "error" },
                                on: {
                                  click: function ($event) {
                                    _vm.detailsDialog = false
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.close_btn_txt"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c("Loader"),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.is_task_running
        ? [
            _c(
              "v-dialog",
              {
                attrs: {
                  "max-width": "400px",
                  persistent: "",
                  transition: "dialog-bottom-transition",
                  "content-class": "rounded-lg",
                  elevation: "24",
                  rounded: "",
                },
                model: {
                  value: _vm.is_task_running,
                  callback: function ($$v) {
                    _vm.is_task_running = $$v
                  },
                  expression: "is_task_running",
                },
              },
              [
                _c(
                  "v-card",
                  {
                    staticClass: "rounded-lg",
                    attrs: { elevation: "24", rounded: "" },
                  },
                  [
                    _c("v-card-title", [
                      _c("div", [
                        _c(
                          "span",
                          { staticClass: "headline text-capitalize" },
                          [_vm._v(" Deleting Dataset ")]
                        ),
                      ]),
                    ]),
                    _c(
                      "v-card-text",
                      [
                        _c(
                          "v-list-item",
                          [
                            _c(
                              "v-list-item-icon",
                              [
                                _c("v-progress-circular", {
                                  attrs: {
                                    indeterminate: "",
                                    color: "primary",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.gpt_dataset.delete_dataset_progress_txt"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "d-flex justify-end" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { text: "", color: "error" },
                            on: {
                              click: function ($event) {
                                _vm.is_task_running = false
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$vuetify.lang.t("$vuetify.close_btn_txt")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c("DialogModal", {
        attrs: {
          title: _vm.$vuetify.lang.t("$vuetify.confirm_alert_title_txt"),
          message: _vm.$vuetify.lang.t(
            "$vuetify.gpt_dataset.confirm_alert_msg_txt_1"
          ),
          confirmButtonText: _vm.$vuetify.lang.t("$vuetify.confirm_btn_txt"),
          cancelButtonText: _vm.$vuetify.lang.t("$vuetify.cancel_btn_txt"),
        },
        on: {
          onConfirmation: function ($event) {
            return _vm.deleteDataset()
          },
          onCancel: function ($event) {
            _vm.deleteDialog = false
          },
        },
        model: {
          value: _vm.deleteDialog,
          callback: function ($$v) {
            _vm.deleteDialog = $$v
          },
          expression: "deleteDialog",
        },
      }),
      _c("DialogModal", {
        attrs: {
          title: _vm.$vuetify.lang.t("$vuetify.error_title_txt"),
          message: _vm.alertMessage,
          confirmButtonText: _vm.$vuetify.lang.t("$vuetify.ok_btn_txt"),
          cancelButton: false,
        },
        on: {
          onConfirmation: function ($event) {
            _vm.alertDialog = false
          },
        },
        model: {
          value: _vm.alertDialog,
          callback: function ($$v) {
            _vm.alertDialog = $$v
          },
          expression: "alertDialog",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }