var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { "fill-height": "", fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { xs: "12", md: "12" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "mx-auto px-2", attrs: { outlined: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "6", md: "6" } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "px-2 primary--text font-weight-bold",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.wallet.buy_history"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("v-spacer"),
                          _c("v-col", { attrs: { cols: "6", md: "3" } }, [
                            _c(
                              "div",
                              { staticClass: "d-block float-right" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.buyClick()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.wallet.buy_xlm_btn"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _vm.isLoading
                        ? _c("Loader", { staticClass: "mr-2 mt-6" })
                        : _c("v-data-table", {
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.allBuyHistoryByAccount.items,
                              search: _vm.search,
                              page: _vm.page,
                              "items-per-page": _vm.itemsPerPage,
                              "hide-default-footer": "",
                            },
                            on: {
                              "update:page": function ($event) {
                                _vm.page = $event
                              },
                              "page-count": function ($event) {
                                _vm.pageCount = $event
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: `item.wyre_created_at`,
                                  fn: function ({ item }) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            new Date(
                                              item.wyre_created_at
                                            ).toLocaleDateString("en-us", {
                                              hour: "numeric",
                                              minute: "numeric",
                                              second: "numeric",
                                              year: "numeric",
                                              month: "short",
                                              day: "numeric",
                                            })
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: `item.purchase_amount`,
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-chip",
                                        {
                                          staticClass: "text-capitalize",
                                          attrs: {
                                            outlined: "",
                                            small: "",
                                            color: "primary",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.purchase_amount +
                                                  " " +
                                                  item.source_currency
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: `item.source_amount`,
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-chip",
                                        {
                                          staticClass: "text-capitalize",
                                          attrs: {
                                            outlined: "",
                                            small: "",
                                            color: "primary",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.source_amount +
                                                  " " +
                                                  item.source_currency
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: `item.dest_amount`,
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-chip",
                                        {
                                          staticClass: "text-capitalize",
                                          attrs: {
                                            outlined: "",
                                            small: "",
                                            color: "primary",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.dest_amount +
                                                  " " +
                                                  item.dest_currency
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: `item.transaction_fee`,
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-chip",
                                        {
                                          staticClass: "text-capitalize",
                                          attrs: {
                                            outlined: "",
                                            small: "",
                                            color: "primary",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.transaction_fee +
                                                  " " +
                                                  item.source_currency
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: `item.rate`,
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-chip",
                                        {
                                          staticClass: "text-capitalize",
                                          attrs: {
                                            outlined: "",
                                            small: "",
                                            color: "primary",
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(item.rate) + " ")]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: `item.status`,
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-chip",
                                        {
                                          staticClass: "text-capitalize",
                                          attrs: {
                                            outlined: "",
                                            small: "",
                                            color: `${
                                              item.status === "COMPLETE"
                                                ? "primary"
                                                : "orange"
                                            }`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.status) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: `item.transfer_id`,
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              small: "",
                                                              color: "primary",
                                                              href: `${_vm.wyreTrackUrl}track/${item.transfer_id}`,
                                                              target: "_blank",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleTrackClick(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$vuetify.lang.t(
                                                                "$vuetify.wallet.track"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$vuetify.lang.t(
                                                  "$vuetify.wallet.track_buy"
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                      !_vm.allBuyHistoryByAccount.total
                        ? _c("div")
                        : _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "py-0",
                                  attrs: { cols: "12", md: "12" },
                                },
                                [_c("v-divider")],
                                1
                              ),
                              _c("v-col", {
                                attrs: {
                                  cols: "12",
                                  xs: "12",
                                  sm: "3",
                                  md: "3",
                                },
                              }),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    xs: "12",
                                    sm: "6",
                                    md: "6",
                                  },
                                },
                                [
                                  _c("v-pagination", {
                                    staticClass: "text-center",
                                    attrs: {
                                      length: Math.ceil(
                                        _vm.allBuyHistoryByAccount.total /
                                          _vm.itemsPerPage
                                      ),
                                    },
                                    on: { input: _vm.handlePageChange },
                                    model: {
                                      value: _vm.page,
                                      callback: function ($$v) {
                                        _vm.page = $$v
                                      },
                                      expression: "page",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    "offset-sm": "1",
                                    "offset-md": "1",
                                    xs: "3",
                                    sm: "2",
                                    md: "2",
                                  },
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.rows,
                                      "hide-details": "auto",
                                      outlined: "",
                                      dense: "",
                                    },
                                    model: {
                                      value: _vm.itemsPerPage,
                                      callback: function ($$v) {
                                        _vm.itemsPerPage = $$v
                                      },
                                      expression: "itemsPerPage",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500", persistent: "" },
          model: {
            value: _vm.minBuyAmountModal,
            callback: function ($$v) {
              _vm.minBuyAmountModal = $$v
            },
            expression: "minBuyAmountModal",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", [
                _c(
                  "p",
                  {
                    staticClass:
                      "pt-10 font-weight-bold red--text text-center subtitle-1",
                  },
                  [
                    _c("v-icon", { attrs: { color: "error" } }, [
                      _vm._v("mdi-alert"),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(
                          this.$vuetify.lang
                            .t("$vuetify.wallet.minimum_buy_amount_text")
                            .replace(
                              "{_source_currency_}",
                              this.source_currency
                            )
                        ) +
                        " " +
                        _vm._s(_vm.minimum_buy_amount) +
                        ". "
                    ),
                  ],
                  1
                ),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error" },
                      on: {
                        click: function ($event) {
                          _vm.minBuyAmountModal = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$vuetify.lang.t("$vuetify.OK_btn_txt")) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "900", persistent: "" },
          model: {
            value: _vm.buyModal,
            callback: function ($$v) {
              _vm.buyModal = $$v
            },
            expression: "buyModal",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$vuetify.lang.t("$vuetify.wallet.buy_lumens_txt")
                    ) +
                    " "
                ),
              ]),
              _c(
                "v-card-subtitle",
                { staticClass: "font-weight-bold text-subtitle-1" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$vuetify.lang.t(
                          "$vuetify.wallet.buy_lumens_by_credit_card"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "3", md: "3" } },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              name: _vm.$vuetify.lang.t(
                                "$vuetify.wallet.asset"
                              ),
                              rules: "required",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c("v-select", {
                                      staticClass: "mr-2 mr-md-3",
                                      attrs: {
                                        items: _vm.allAsset,
                                        label: _vm.$vuetify.lang.t(
                                          "$vuetify.wallet.asset"
                                        ),
                                        "item-text": "name",
                                        "item-value": "name",
                                        outlined: "",
                                        "error-messages": errors,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "selection",
                                            fn: function (data) {
                                              return [
                                                _c(
                                                  "v-chip",
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        color: "white",
                                                        "input-value":
                                                          data.selected,
                                                      },
                                                      on: {
                                                        click: data.select,
                                                      },
                                                    },
                                                    "v-chip",
                                                    data.attrs,
                                                    false
                                                  ),
                                                  [
                                                    _c(
                                                      "v-avatar",
                                                      { attrs: { left: "" } },
                                                      [
                                                        _c("v-img", {
                                                          attrs: {
                                                            src: data.item.img,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(data.item.name) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item",
                                            fn: function (data) {
                                              return [
                                                [
                                                  _c("v-list-item-avatar", [
                                                    _c("img", {
                                                      attrs: {
                                                        src: data.item.img,
                                                      },
                                                    }),
                                                  ]),
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            data.item.name
                                                          ),
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.dest_curr,
                                        callback: function ($$v) {
                                          _vm.dest_curr = $$v
                                        },
                                        expression: "dest_curr",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3", md: "3" } },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              name: _vm.$vuetify.lang.t(
                                "$vuetify.wallet.currency"
                              ),
                              rules: "required",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c("v-select", {
                                      staticClass: "mr-3",
                                      attrs: {
                                        items: _vm.allFiatCurrencies,
                                        label: _vm.$vuetify.lang.t(
                                          "$vuetify.wallet.currency"
                                        ),
                                        "item-text": "name",
                                        "item-value": "value",
                                        outlined: "",
                                        "error-messages": errors,
                                      },
                                      model: {
                                        value: _vm.source_currency,
                                        callback: function ($$v) {
                                          _vm.source_currency = $$v
                                        },
                                        expression: "source_currency",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3", md: "3" } },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              name: _vm.$vuetify.lang.t(
                                "$vuetify.wallet.amount"
                              ),
                              rules: {
                                required: true,
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c("vuetify-money", {
                                      staticClass: "mr-3",
                                      attrs: {
                                        label: _vm.$vuetify.lang.t(
                                          "$vuetify.wallet.amount"
                                        ),
                                        placeholder: `${_vm.$vuetify.lang.t(
                                          "$vuetify.wallet.minimum_buy"
                                        )} ${_vm.source_currency} ${
                                          _vm.minimum_buy_amount
                                        }`,
                                        outlined: "",
                                        clearable: "",
                                        "value-when-is-empty": "",
                                        "error-messages": errors,
                                        options: _vm.numberOptions,
                                        properties: {
                                          hint: `${_vm.$vuetify.lang.t(
                                            "$vuetify.wallet.minimum_buy"
                                          )} ${_vm.source_currency} ${
                                            _vm.minimum_buy_amount
                                          }`,
                                        },
                                      },
                                      model: {
                                        value: _vm.buy_amount,
                                        callback: function ($$v) {
                                          _vm.buy_amount = $$v
                                        },
                                        expression: "buy_amount",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3", md: "3" } },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              name: _vm.$vuetify.lang.t(
                                "$vuetify.wallet.gateway"
                              ),
                              rules: "required",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c("v-select", {
                                      staticClass: "md-3",
                                      attrs: {
                                        items: _vm.allGateway,
                                        label: _vm.$vuetify.lang.t(
                                          "$vuetify.wallet.gateway"
                                        ),
                                        "item-text": "name",
                                        "item-value": "value",
                                        outlined: "",
                                        "error-messages": errors,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "selection",
                                            fn: function (data) {
                                              return [
                                                _c(
                                                  "v-chip",
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        color: "white",
                                                        "input-value":
                                                          data.selected,
                                                      },
                                                      on: {
                                                        click: data.select,
                                                      },
                                                    },
                                                    "v-chip",
                                                    data.attrs,
                                                    false
                                                  ),
                                                  [
                                                    _c(
                                                      "v-avatar",
                                                      { attrs: { left: "" } },
                                                      [
                                                        _c("v-img", {
                                                          attrs: {
                                                            src: data.item.img,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(data.item.name) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item",
                                            fn: function (data) {
                                              return [
                                                [
                                                  _c("v-list-item-avatar", [
                                                    _c("img", {
                                                      attrs: {
                                                        src: data.item.img,
                                                      },
                                                    }),
                                                  ]),
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            data.item.name
                                                          ),
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.dest_gateway,
                                        callback: function ($$v) {
                                          _vm.dest_gateway = $$v
                                        },
                                        expression: "dest_gateway",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _vm.applicableRewardInfoLoading
                            ? _c("v-progress-linear", {
                                staticClass: "mt-0 mb-2",
                                attrs: {
                                  color: "primary lighten-2",
                                  indeterminate: "",
                                  rounded: "",
                                  height: "5",
                                },
                              })
                            : _vm._e(),
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "v-alert",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.applicable_reward_percentage > 0,
                                        expression:
                                          "applicable_reward_percentage > 0",
                                      },
                                    ],
                                    staticClass: "pl-6",
                                    attrs: {
                                      dense: "",
                                      text: "",
                                      type: "success",
                                      icon: "mdi-gift",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          this.$vuetify.lang.t(
                                            "$vuetify.wallet.dzt_holding_balance_text"
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("strong", [
                                      _vm._v(_vm._s(_vm.holding_balance)),
                                    ]),
                                    _vm._v(". "),
                                    _c("br"),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          this.$vuetify.lang.t(
                                            "$vuetify.wallet.dzt_reward_percentage_text_1"
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.applicable_reward_percentage
                                        ) + "%"
                                      ),
                                    ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          this.$vuetify.lang.t(
                                            "$vuetify.wallet.dzt_reward_percentage_text_2"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-alert",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.applicable_reward_percentage <= 0,
                                        expression:
                                          "applicable_reward_percentage <= 0",
                                      },
                                    ],
                                    staticClass: "pl-6",
                                    attrs: {
                                      dense: "",
                                      text: "",
                                      type: "warning",
                                      icon: "mdi-gift-off",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          this.$vuetify.lang.t(
                                            "$vuetify.wallet.dzt_holding_balance_text"
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("strong", [
                                      _vm._v(_vm._s(_vm.holding_balance)),
                                    ]),
                                    _vm._v(". "),
                                    _c("br"),
                                    _vm._v(
                                      _vm._s(
                                        this.$vuetify.lang.t(
                                          "$vuetify.wallet.dzt_increase_text"
                                        )
                                      ) + " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "v-expansion-panels",
                                  [
                                    _c(
                                      "v-expansion-panel",
                                      [
                                        _c(
                                          "v-expansion-panel-header",
                                          {
                                            scopedSlots: _vm._u([
                                              {
                                                key: "actions",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                      },
                                                      [_vm._v(" $expand ")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "ma-0 font-weight-bold text--secondary subtitle-2",
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { color: "info" } },
                                                  [_vm._v(" mdi-alert-circle ")]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      this.$vuetify.lang.t(
                                                        "$vuetify.wallet.reward_percentage_details"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-expansion-panel-content",
                                          [
                                            _c("v-data-table", {
                                              attrs: {
                                                headers:
                                                  _vm.reward_percentage_headers,
                                                items: _vm.reward_percentages,
                                                dense: "",
                                                sortBy: "percentage",
                                                "fixed-header": "",
                                                height: "150px",
                                                "hide-default-footer": "",
                                                "disable-pagination": "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "pb-5" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "error",
                        disabled: _vm.buyConfirmLoading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.cancelBuy()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$vuetify.lang.t("$vuetify.common.cancel")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        color: "primary",
                        disabled: _vm.applicableRewardInfoLoading,
                        loading: _vm.buyConfirmLoading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.continueBuy()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$vuetify.lang.t("$vuetify.common.continue"))
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }