var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c(
        "v-container",
        { attrs: { "fill-height": "", fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { align: "center", justify: "center" } },
            [
              _c(
                "v-col",
                {
                  attrs: {
                    "align-self": "center",
                    sm: "8",
                    md: "6",
                    lg: "4",
                    xl: "4",
                  },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "elevation-2 mx-auto pa-4",
                      attrs: { outlined: "" },
                    },
                    [
                      _c("Logo"),
                      _c("v-card-title", { staticClass: "font-weight-bold" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$vuetify.lang
                                .t("$vuetify.login_msg_txt")
                                .concat(" ", "Gigalogy AI Platform")
                            ) +
                            " "
                        ),
                      ]),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-form",
                            {
                              ref: "loginForm",
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleLoginSubmitClick()
                                },
                              },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: _vm.$vuetify.lang.t(
                                    "$vuetify.email_label_txt"
                                  ),
                                  type: "email",
                                  placeholder: "adam@gigalogy.com",
                                  "prepend-inner-icon": "email",
                                  outlined: "",
                                  rules: [
                                    _vm.required(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.email_label_txt"
                                      )
                                    ),
                                    _vm.email(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.email_label_txt"
                                      )
                                    ),
                                  ],
                                  autocomplete: "off",
                                },
                                on: { focus: _vm.handlePass },
                                model: {
                                  value: _vm.loginForm.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.loginForm, "email", $$v)
                                  },
                                  expression: "loginForm.email",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  label: _vm.$vuetify.lang.t(
                                    "$vuetify.password_label_txt"
                                  ),
                                  placeholder: _vm.$vuetify.lang.t(
                                    "$vuetify.password_label_txt"
                                  ),
                                  autocomplete: "off",
                                  "prepend-inner-icon": "lock",
                                  outlined: "",
                                  "append-icon": _vm.showPassword
                                    ? "mdi-eye"
                                    : "mdi-eye-off",
                                  type: _vm.showPassword
                                    ? "text"
                                    : _vm.passType
                                    ? "text"
                                    : "password",
                                  rules: [
                                    _vm.required(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.password_label_txt"
                                      )
                                    ),
                                  ],
                                },
                                on: {
                                  "click:append": function ($event) {
                                    _vm.showPassword = !_vm.showPassword
                                  },
                                  focus: _vm.handlePass,
                                },
                                model: {
                                  value: _vm.loginForm.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.loginForm, "password", $$v)
                                  },
                                  expression: "loginForm.password",
                                },
                              }),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    type: "submit",
                                    large: "",
                                    block: "",
                                    loading: _vm.loading,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.login_btn_txt"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "font-weight-medium pt-5" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "text-decoration-none",
                                  attrs: { to: "/forgot-password" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.forgot_password_msg_txt"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("v-divider", { staticClass: "my-5" }),
                          _c(
                            "div",
                            { staticClass: "font-weight-medium pb-5" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$vuetify.lang.t(
                                      "$vuetify.registration_msg_txt"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                large: "",
                                block: "",
                                outlined: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleRegisterClick()
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$vuetify.lang.t(
                                    "$vuetify.registration_btn_txt"
                                  )
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "text-center font-weight-medium pt-4" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "text-decoration-none",
                          attrs: { to: "/" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t("$vuetify.back_to_home_txt")
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }