var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c(
        "v-container",
        { attrs: { "fill-height": "", fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { align: "center", justify: "center" } },
            [
              _c(
                "v-col",
                {
                  attrs: {
                    "align-self": "center",
                    sm: "8",
                    md: "6",
                    lg: "4",
                    xl: "4",
                  },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "elevation-2 mx-auto pa-4",
                      attrs: { outlined: "" },
                    },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "text-center" },
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "success", size: "100" } },
                            [_vm._v("task_alt")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        {
                          staticClass:
                            "font-weight-bold success--text headline",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t(
                                  "$vuetify.registration_success_msg_txt_1"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c("v-card-text", { staticClass: "text-subtitle-1" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$vuetify.lang.t(
                                "$vuetify.registration_success_msg_txt_2"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("v-divider", { staticClass: "ml-4 mr-4" }),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "p",
                            { staticClass: "body-2" },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mx-0",
                                  attrs: { slot: "icon", size: "20" },
                                  slot: "icon",
                                },
                                [_vm._v(" info ")]
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$vuetify.lang.t(
                                      "$vuetify.resend_verification_email_msg_txt_1"
                                    )
                                  ) +
                                  " "
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                large: "",
                                block: "",
                                loading: _vm.loading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleSubmitClick()
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$vuetify.lang.t(
                                    "$vuetify.resend_verification_email_btn_txt"
                                  )
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }