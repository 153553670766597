var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { "fill-height": "", fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { xs: "12", md: "12" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "mx-auto px-2", attrs: { outlined: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "6", md: "6" } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "px-2 primary--text font-weight-bold",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.wallet.dzt_rewards"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("v-spacer"),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-tabs",
                                {
                                  attrs: { "show-arrows": "" },
                                  model: {
                                    value: _vm.activeTab,
                                    callback: function ($$v) {
                                      _vm.activeTab = $$v
                                    },
                                    expression: "activeTab",
                                  },
                                },
                                _vm._l(_vm.tabs, function (tab) {
                                  return _c(
                                    "v-tab",
                                    {
                                      key: tab.id,
                                      attrs: { to: tab.route, exact: "" },
                                    },
                                    [
                                      tab.icon
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticClass: "me-3",
                                              attrs: { size: "20" },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(tab.icon) + " "
                                              ),
                                            ]
                                          )
                                        : _c("v-img", {
                                            staticClass: "mr-2",
                                            attrs: { src: tab.img },
                                          }),
                                      _c("span", [_vm._v(_vm._s(tab.title))]),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("router-view", {
                        attrs: { item: _vm.item, allAssets: _vm.allAssets },
                        on: { openPasscode: _vm.openPasscode },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }