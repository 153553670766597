var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { app: "", "clipped-left": "", dark: "", color: "primary" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6", xs: "6", md: "6" } },
                [
                  _c(
                    "v-toolbar-title",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "headline",
                          staticStyle: { cursor: "pointer" },
                          attrs: { to: "/public/solutions", tag: "span" },
                        },
                        [
                          _c("img", {
                            staticClass: "logo",
                            attrs: {
                              src: require("@/assets/images/logo_white_horizontal.png"),
                              alt: "Gigalogy",
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-end",
                  attrs: { cols: "3", xs: "3", md: "3" },
                },
                [_c("switch-language")],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "get-started-icon",
                  attrs: { cols: "3", xs: "3", md: "3" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-uppercase font-weight-bold",
                      attrs: { large: "", outlined: "", block: "" },
                      on: {
                        click: function ($event) {
                          return _vm.goToLogin()
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "d-none d-sm-flex" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$vuetify.lang.t("$vuetify.get_started_btn_txt")
                          )
                        ),
                      ]),
                      _c("v-icon", { staticClass: "d-flex d-sm-none" }, [
                        _vm._v("account_circle"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-spacer"),
      _c(
        "div",
        { staticClass: "pa-4 mt-12" },
        [
          _vm.isLoading
            ? _c(
                "v-container",
                { attrs: { "fill-height": "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12", lg: "12" } },
                                [
                                  _c("v-skeleton-loader", {
                                    staticClass: "mr-2 mt-6",
                                    attrs: { type: "card-heading, actions" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    xs: "12",
                                    md: "6",
                                    lg: "4",
                                  },
                                },
                                [
                                  _c("v-skeleton-loader", {
                                    attrs: {
                                      type: "image, card-heading, actions",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    xs: "12",
                                    md: "6",
                                    lg: "4",
                                  },
                                },
                                [
                                  _c("v-skeleton-loader", {
                                    attrs: {
                                      type: "image, card-heading, actions",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    xs: "12",
                                    md: "6",
                                    lg: "4",
                                  },
                                },
                                [
                                  _c("v-skeleton-loader", {
                                    attrs: {
                                      type: "image, card-heading, actions",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-container",
                { attrs: { "fill-height": "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-container",
                        {
                          staticClass: "hidden-sm-and-down",
                          attrs: { fluid: "" },
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "border-right:none",
                                  attrs: { cols: "12", md: "2", lg: "2" },
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      items: _vm.typeStates,
                                      "item-text": "state",
                                      "item-value": "value",
                                      "persistent-hint": "",
                                    },
                                    on: { change: _vm.search },
                                    model: {
                                      value: _vm.solution_type,
                                      callback: function ($$v) {
                                        _vm.solution_type = $$v
                                      },
                                      expression: "solution_type",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-2",
                                  attrs: { cols: "12", md: "10", lg: "10" },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      outlined: "",
                                      label: _vm.$vuetify.lang.t(
                                        "$vuetify.search_label_txt"
                                      ),
                                      "prepend-inner-icon": "search",
                                    },
                                    on: {
                                      keypress: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.search.apply(null, arguments)
                                      },
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "append",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "text-capitalize font-weight-bold mt-n3 mr-n2",
                                                attrs: {
                                                  large: "",
                                                  color: "primary",
                                                  "append-icon": "search",
                                                },
                                                on: { click: _vm.search },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "d-none d-sm-flex",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$vuetify.lang.t(
                                                          "$vuetify.search_btn_txt"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass:
                                                      "d-flex d-sm-none",
                                                  },
                                                  [_vm._v("search")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.searchText,
                                      callback: function ($$v) {
                                        _vm.searchText = $$v
                                      },
                                      expression: "searchText",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      [
                        _c(
                          "v-container",
                          {
                            staticClass: "hidden-md-and-up",
                            attrs: { fluid: "" },
                          },
                          [
                            _c(
                              "v-row",
                              { attrs: { align: "center" } },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "12" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        outlined: "",
                                        label: _vm.$vuetify.lang.t(
                                          "$vuetify.search_label_txt"
                                        ),
                                        "prepend-inner-icon": "search",
                                      },
                                      on: {
                                        keypress: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return _vm.search.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "append",
                                          fn: function () {
                                            return [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "text-capitalize font-weight-bold mt-n3 mr-n2",
                                                  attrs: {
                                                    large: "",
                                                    color: "primary",
                                                    "append-icon": "search",
                                                  },
                                                  on: { click: _vm.search },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "d-none d-sm-flex",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$vuetify.lang.t(
                                                            "$vuetify.search_btn_txt"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "d-flex d-sm-none",
                                                    },
                                                    [_vm._v("search")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                      model: {
                                        value: _vm.searchText,
                                        callback: function ($$v) {
                                          _vm.searchText = $$v
                                        },
                                        expression: "searchText",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "border-right:none",
                                    attrs: { cols: "12", sm: "12" },
                                  },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        outlined: "",
                                        items: _vm.typeStates,
                                        "item-text": "state",
                                        "item-value": "value",
                                        "persistent-hint": "",
                                      },
                                      on: { change: _vm.search },
                                      model: {
                                        value: _vm.solution_type,
                                        callback: function ($$v) {
                                          _vm.solution_type = $$v
                                        },
                                        expression: "solution_type",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                    ],
                    2
                  ),
                  _c(
                    "v-row",
                    _vm._l(_vm.solutions, function (item) {
                      return _c(
                        "v-col",
                        {
                          key: item.id,
                          attrs: {
                            cols: "12",
                            xs: "12",
                            sm: "6",
                            md: "6",
                            lg: "4",
                            xl: "4",
                          },
                        },
                        [
                          _c("SolutionCard", {
                            attrs: { itemData: item, isPublic: true },
                          }),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }