var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _vm.setupStateLoading
        ? [_c("Loader", { staticClass: "mr-2 mt-6" })]
        : [
            _c(
              "v-stepper",
              {
                model: {
                  value: _vm.step,
                  callback: function ($$v) {
                    _vm.step = $$v
                  },
                  expression: "step",
                },
              },
              [
                _c(
                  "v-stepper-header",
                  [
                    _c(
                      "v-stepper-step",
                      { attrs: { complete: _vm.step > 1, step: "1" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              `${_vm.$vuetify.lang.t(
                                "$vuetify.profile_menu_txt"
                              )}`
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c("v-divider"),
                    _c(
                      "v-stepper-step",
                      { attrs: { complete: _vm.step > 2, step: "2" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              `${_vm.$vuetify.lang.t(
                                "$vuetify.project.upload_dataset"
                              )}`
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c("v-divider"),
                    _c("v-stepper-step", { attrs: { step: "3" } }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t("$vuetify.project.train")}`
                          ) +
                          " "
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-stepper-items",
                  [
                    _c(
                      "v-stepper-content",
                      { attrs: { step: "1" } },
                      [
                        _c("CreateGptProfile", { ref: "profile" }),
                        _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { cols: "12", md: "8" } }),
                            _c(
                              "v-col",
                              {
                                staticClass: "text-right",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _vm.gptProfiles.length > 0
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "mr-4 primary--text",
                                        attrs: { outlined: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.skip(_vm.step)
                                          },
                                        },
                                      },
                                      [_vm._v(" Skip Update >> ")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "white--text",
                                    attrs: {
                                      loading: _vm.loading,
                                      color: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.nextStep(_vm.step)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.continue_btn_txt"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-stepper-content",
                      { attrs: { step: "2" } },
                      [
                        _c("UploadDataset", {
                          ref: "dataset",
                          attrs: { propName: "Test props" },
                        }),
                        _c(
                          "v-col",
                          { staticClass: "d-flex justify-space-between" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mr-4 primary--text",
                                attrs: { outlined: "", loading: false },
                                on: {
                                  click: function ($event) {
                                    return _vm.previousStep(_vm.step)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.prev_btn_txt"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              [
                                _vm.gptSetupState.dataset.completed
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "mr-4 primary--text",
                                        attrs: { outlined: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.skip(_vm.step)
                                          },
                                        },
                                      },
                                      [_vm._v(" Skip Update >> ")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-4",
                                    attrs: {
                                      loading: _vm.loading,
                                      color: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.nextStep(_vm.step)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.continue_btn_txt"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-stepper-content",
                      { attrs: { step: "3" } },
                      [
                        _vm.gptSetupState.dataset.error
                          ? _c(
                              "v-card",
                              {
                                staticClass:
                                  "pa-2 d-flex flex-column align-center justify-center",
                                attrs: { height: "200px" },
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass:
                                      "d-flex align-center justify-center",
                                    attrs: { cols: "auto" },
                                  },
                                  [
                                    _c("v-icon", { attrs: { color: "red" } }, [
                                      _vm._v("mdi-close-circle-outline"),
                                    ]),
                                    _c(
                                      "v-list-item-title",
                                      { staticClass: "ml-2" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.gptSetupState.dataset.message
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mt-1 primary--text",
                                    attrs: { outlined: "", loading: false },
                                    on: { click: _vm.datasetErrorTryAgain },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.common.try_again"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm.gptSetupState.training.error
                          ? _c(
                              "v-card",
                              {
                                staticClass:
                                  "pa-2 d-flex flex-column align-center justify-center",
                                attrs: { height: "200px" },
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass:
                                      "d-flex align-center justify-center",
                                    attrs: { cols: "auto" },
                                  },
                                  [
                                    _c("v-icon", { attrs: { color: "red" } }, [
                                      _vm._v("mdi-close-circle-outline"),
                                    ]),
                                    _c(
                                      "v-list-item-title",
                                      { staticClass: "ml-2" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.gptSetupState.training.message
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-4 mt-1 primary--text",
                                    attrs: { outlined: "", loading: false },
                                    on: { click: _vm.trainingErrorTryAgain },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.common.try_again"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm.dataSetProcessCompleted
                          ? _c(
                              "div",
                              [
                                _vm.trainingInProgress
                                  ? _c(
                                      "v-card",
                                      {
                                        staticClass:
                                          "d-flex justify-center align-center",
                                        attrs: { height: "200px" },
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c(
                                              "v-list-item",
                                              [
                                                _c(
                                                  "v-list-item-icon",
                                                  [
                                                    _c("v-progress-circular", {
                                                      attrs: {
                                                        indeterminate: "",
                                                        color: "primary",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c("v-list-item-title", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$vuetify.lang.t(
                                                              "$vuetify.training_in_progress"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "v-form",
                                      { ref: "train_form" },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass:
                                              "d-flex justify-center align-center",
                                            staticStyle: {
                                              "box-shadow": "none",
                                            },
                                            attrs: { height: "200px" },
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", md: "6" },
                                              },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    label: _vm.$vuetify.lang.t(
                                                      "$vuetify.gpt_dataset.training_type"
                                                    ),
                                                    items: ["text", "image"],
                                                    outlined: "",
                                                    rules: [
                                                      _vm.required(
                                                        _vm.$vuetify.lang.t(
                                                          "$vuetify.gpt_dataset.training_type"
                                                        )
                                                      ),
                                                    ],
                                                  },
                                                  model: {
                                                    value: _vm.trainType,
                                                    callback: function ($$v) {
                                                      _vm.trainType = $$v
                                                    },
                                                    expression: "trainType",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", md: "6" },
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: _vm.$vuetify.lang.t(
                                                      "$vuetify.gpt_dataset.batch_size"
                                                    ),
                                                    outlined: "",
                                                    type: "number",
                                                    rules: _vm.batchSizeRules,
                                                    disabled: "",
                                                  },
                                                  model: {
                                                    value: _vm.batchSize,
                                                    callback: function ($$v) {
                                                      _vm.batchSize = $$v
                                                    },
                                                    expression: "batchSize",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                !_vm.trainingInProgress
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-space-between",
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mr-4 primary--text",
                                            attrs: {
                                              outlined: "",
                                              loading: false,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.previousStep(
                                                  _vm.step
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$vuetify.lang.t(
                                                    "$vuetify.prev_btn_txt"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mr-4 pr-8 pl-8",
                                            attrs: {
                                              loading: _vm.loading,
                                              color: "primary",
                                            },
                                            on: { click: _vm.handleTraining },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$vuetify.lang.t(
                                                    "$vuetify.project.train"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : [
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "d-flex justify-center align-center",
                                  staticStyle: { "box-shadow": "none" },
                                  attrs: { height: "200px" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-progress-circular", {
                                                attrs: {
                                                  indeterminate: "",
                                                  color: "primary",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("v-list-item-content", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$vuetify.lang.t(
                                                    "$vuetify.gpt_dataset.dataset_process_progress"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-4 primary--text",
                                  attrs: { outlined: "", loading: false },
                                  on: {
                                    click: function ($event) {
                                      return _vm.previousStep(_vm.step)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.prev_btn_txt"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }