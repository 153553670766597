var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.profile
    ? _c("v-skeleton-loader", {
        staticClass: "mr-2 mt-6",
        attrs: { type: "list-item-three-line" },
      })
    : _c(
        "v-form",
        { ref: "profile_form" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "12", xs: "12" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      type: "text",
                      rules: [
                        _vm.required(
                          _vm.$vuetify.lang.t("$vuetify.name_header_txt")
                        ),
                      ],
                      label: _vm.$vuetify.lang.t("$vuetify.name_header_txt"),
                      placeholder: _vm.$vuetify.lang.t(
                        "$vuetify.gpt_profile.profileNamePlaceholder"
                      ),
                      outlined: "",
                    },
                    model: {
                      value: _vm.profile.profileName,
                      callback: function ($$v) {
                        _vm.$set(_vm.profile, "profileName", $$v)
                      },
                      expression: "profile.profileName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", xs: "12" } },
                [
                  _c("v-textarea", {
                    attrs: {
                      rules: [
                        _vm.required(
                          _vm.$vuetify.lang.t(
                            "$vuetify.gpt_profile.introduction"
                          )
                        ),
                      ],
                      label: _vm.$vuetify.lang.t(
                        "$vuetify.gpt_profile.introduction"
                      ),
                      outlined: "",
                      placeholder: _vm.$vuetify.lang.t(
                        "$vuetify.gpt_profile.introductionPlaceholder"
                      ),
                      type: "text",
                    },
                    model: {
                      value: _vm.profile.intro,
                      callback: function ($$v) {
                        _vm.$set(_vm.profile, "intro", $$v)
                      },
                      expression: "profile.intro",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", xs: "12" } },
                [
                  _c("v-textarea", {
                    attrs: {
                      rules: [
                        _vm.required(
                          _vm.$vuetify.lang.t("$vuetify.gpt_history_system")
                        ),
                      ],
                      label: _vm.$vuetify.lang.t("$vuetify.gpt_history_system"),
                      placeholder: _vm.$vuetify.lang.t(
                        "$vuetify.gpt_profile.systemPlaceholder"
                      ),
                      required: "",
                      outlined: "",
                    },
                    model: {
                      value: _vm.profile.system,
                      callback: function ($$v) {
                        _vm.$set(_vm.profile, "system", $$v)
                      },
                      expression: "profile.system",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6", xs: "12" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.profile.modelList,
                      label: _vm.$vuetify.lang.t("$vuetify.gpt_history_model"),
                      outlined: "",
                      required: "",
                      rules: [
                        _vm.required(
                          _vm.$vuetify.lang.t("$vuetify.gpt_history_model")
                        ),
                      ],
                    },
                    model: {
                      value: _vm.profile.model,
                      callback: function ($$v) {
                        _vm.$set(_vm.profile, "model", $$v)
                      },
                      expression: "profile.model",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6", xs: "12" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      rules: _vm.temperatureRules,
                      label: _vm.$vuetify.lang.t(
                        "$vuetify.gpt_history_temperature"
                      ),
                      outlined: "",
                      type: "number",
                    },
                    model: {
                      value: _vm.profile.temperature,
                      callback: function ($$v) {
                        _vm.$set(_vm.profile, "temperature", $$v)
                      },
                      expression: "profile.temperature",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      rules: _vm.topPRules,
                      label: _vm.$vuetify.lang.t("$vuetify.gpt_profile.top_p"),
                      outlined: "",
                      type: "number",
                    },
                    model: {
                      value: _vm.profile.top_p,
                      callback: function ($$v) {
                        _vm.$set(_vm.profile, "top_p", $$v)
                      },
                      expression: "profile.top_p",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      rules: _vm.temperatureRules,
                      label: _vm.$vuetify.lang.t(
                        "$vuetify.gpt_history_frequency_penalty"
                      ),
                      outlined: "",
                      type: "number",
                    },
                    model: {
                      value: _vm.profile.frequencyPenalty,
                      callback: function ($$v) {
                        _vm.$set(_vm.profile, "frequencyPenalty", $$v)
                      },
                      expression: "profile.frequencyPenalty",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      rules: _vm.temperatureRules,
                      label: _vm.$vuetify.lang.t(
                        "$vuetify.gpt_history_presence_penalty"
                      ),
                      outlined: "",
                      type: "number",
                    },
                    model: {
                      value: _vm.profile.presencePenalty,
                      callback: function ($$v) {
                        _vm.$set(_vm.profile, "presencePenalty", $$v)
                      },
                      expression: "profile.presencePenalty",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("v-select", {
                    attrs: {
                      clearable: "",
                      "small-chips": "",
                      label: _vm.$vuetify.lang.t("$vuetify.tooltip.stop"),
                      items: ["AI", "Human"],
                      multiple: "",
                      outlined: "",
                    },
                    model: {
                      value: _vm.profile.stop,
                      callback: function ($$v) {
                        _vm.$set(_vm.profile, "stop", $$v)
                      },
                      expression: "profile.stop",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      rules: _vm.searchTokenRules,
                      label: _vm.$vuetify.lang.t(
                        "$vuetify.gpt_history_search_max_token"
                      ),
                      outlined: "",
                      type: "number",
                    },
                    model: {
                      value: _vm.profile.searchMaxToken,
                      callback: function ($$v) {
                        _vm.$set(_vm.profile, "searchMaxToken", $$v)
                      },
                      expression: "profile.searchMaxToken",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      rules: _vm.completionTokenRules,
                      label: _vm.$vuetify.lang.t(
                        "$vuetify.gpt_history_completion_tokens"
                      ),
                      outlined: "",
                      type: "number",
                    },
                    model: {
                      value: _vm.profile.completionToken,
                      callback: function ($$v) {
                        _vm.$set(_vm.profile, "completionToken", $$v)
                      },
                      expression: "profile.completionToken",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "8", md: "4" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      rules: _vm.chatHistoryLengthRules,
                      label: "Chat history length",
                      outlined: "",
                      type: "number",
                    },
                    model: {
                      value: _vm.profile.chat_history_length,
                      callback: function ($$v) {
                        _vm.$set(_vm.profile, "chat_history_length", $$v)
                      },
                      expression: "profile.chat_history_length",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "8", md: "4" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.personalizerOnlyItems,
                      label: "Is Personalizer only",
                      outlined: "",
                    },
                    model: {
                      value: _vm.profile.is_personalizer_only,
                      callback: function ($$v) {
                        _vm.$set(_vm.profile, "is_personalizer_only", $$v)
                      },
                      expression: "profile.is_personalizer_only",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "8", md: "4" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.isAutoEvaluation,
                      label: "Is Auto Evaluation",
                      outlined: "",
                    },
                    model: {
                      value: _vm.profile.is_auto_evaluation,
                      callback: function ($$v) {
                        _vm.$set(_vm.profile, "is_auto_evaluation", $$v)
                      },
                      expression: "profile.is_auto_evaluation",
                    },
                  }),
                ],
                1
              ),
              _c(
                "p",
                {
                  staticStyle: {
                    "padding-left": "12px",
                    "margin-bottom": "0px !important",
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$vuetify.lang.t("$vuetify.gpt_profile.dataset_tags")
                    ) + ":"
                  ),
                ]
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "12" } },
                [
                  _c("v-combobox", {
                    staticClass: "no-dropdown-icon",
                    attrs: {
                      items: [],
                      label: _vm.$vuetify.lang.t(
                        "$vuetify.gpt_profile.includes"
                      ),
                      variant: "solo",
                      chips: "",
                      clearable: "",
                      outlined: "",
                      multiple: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "selection",
                        fn: function ({ attrs, item, select, selected }) {
                          return [
                            _c(
                              "v-chip",
                              _vm._b(
                                {
                                  attrs: {
                                    "model-value": selected,
                                    closable: "",
                                  },
                                  on: {
                                    click: select,
                                    "click:close": function ($event) {
                                      return _vm.remove(item)
                                    },
                                  },
                                },
                                "v-chip",
                                attrs,
                                false
                              ),
                              [_c("span", [_vm._v(_vm._s(item))]), _vm._v("  ")]
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.profile.datasetTags.includes,
                      callback: function ($$v) {
                        _vm.$set(_vm.profile.datasetTags, "includes", $$v)
                      },
                      expression: "profile.datasetTags.includes",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "12" } },
                [
                  _c("v-combobox", {
                    staticClass: "no-dropdown-icon",
                    attrs: {
                      items: [],
                      label: _vm.$vuetify.lang.t(
                        "$vuetify.gpt_profile.excludes"
                      ),
                      variant: "solo",
                      chips: "",
                      clearable: "",
                      outlined: "",
                      multiple: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "selection",
                        fn: function ({ attrs, item, select, selected }) {
                          return [
                            _c(
                              "v-chip",
                              _vm._b(
                                {
                                  attrs: {
                                    "model-value": selected,
                                    closable: "",
                                  },
                                  on: {
                                    click: select,
                                    "click:close": function ($event) {
                                      return _vm.remove(item)
                                    },
                                  },
                                },
                                "v-chip",
                                attrs,
                                false
                              ),
                              [_c("span", [_vm._v(_vm._s(item))]), _vm._v("  ")]
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.profile.datasetTags.excludes,
                      callback: function ($$v) {
                        _vm.$set(_vm.profile.datasetTags, "excludes", $$v)
                      },
                      expression: "profile.datasetTags.excludes",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.profile.model === _vm.VISION_MODELS.VISION_PREVIEW ||
          _vm.profile.model === _vm.VISION_MODELS.VISION_4O
            ? _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.$vuetify.lang.t("$vuetify.gpt_history_vision_settings")
                  ) + ":"
                ),
              ])
            : _vm._e(),
          _vm.profile.model === _vm.VISION_MODELS.VISION_PREVIEW ||
          _vm.profile.model === _vm.VISION_MODELS.VISION_4O
            ? _c("v-select", {
                attrs: {
                  items: ["low", "high"],
                  label: _vm.$vuetify.lang.t("$vuetify.gpt_history_resolution"),
                  outlined: "",
                  required: "",
                  rules: [
                    _vm.required(
                      _vm.$vuetify.lang.t("$vuetify.gpt_history_resolution")
                    ),
                  ],
                },
                model: {
                  value: _vm.profile.visionSettings.resolution,
                  callback: function ($$v) {
                    _vm.$set(_vm.profile.visionSettings, "resolution", $$v)
                  },
                  expression: "profile.visionSettings.resolution",
                },
              })
            : _vm._e(),
          _vm.profile.model === _vm.VISION_MODELS.VISION_4O ||
          _vm.profile.model === _vm.VISION_MODELS.VISION_PREVIEW
            ? _c("v-checkbox", {
                attrs: {
                  label: _vm.$vuetify.lang.t(
                    "$vuetify.gpt_history_image_context_enabled"
                  ),
                },
                model: {
                  value: _vm.profile.visionSettings.imageContextEnabled,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.profile.visionSettings,
                      "imageContextEnabled",
                      $$v
                    )
                  },
                  expression: "profile.visionSettings.imageContextEnabled",
                },
              })
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }