var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.size === "small"
    ? _c(
        "v-btn",
        {
          attrs: {
            small: "",
            color: _vm.color,
            loading: _vm.loading,
            disabled: _vm.disabled,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.onClick()
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
      )
    : _vm.size === "x-small"
    ? _c(
        "v-btn",
        {
          attrs: {
            "x-small": "",
            color: _vm.color,
            loading: _vm.loading,
            disabled: _vm.disabled,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.onClick()
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
      )
    : _vm.size === "x-large"
    ? _c(
        "v-btn",
        {
          attrs: {
            "x-large": "",
            color: _vm.color,
            loading: _vm.loading,
            disabled: _vm.disabled,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.onClick()
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
      )
    : _vm.size === "large"
    ? _c(
        "v-btn",
        {
          attrs: {
            large: "",
            color: _vm.color,
            loading: _vm.loading,
            disabled: _vm.disabled,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.onClick()
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
      )
    : _c(
        "v-btn",
        {
          attrs: {
            color: _vm.color,
            loading: _vm.loading,
            disabled: _vm.disabled,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.onClick()
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }