var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { "fill-height": "", fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { xs: "12", md: "12" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "mx-auto px-2", attrs: { outlined: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { md: "6" } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "px-2 primary--text font-weight-bold",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.members_nav_txt"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "v-col",
                            { attrs: { md: "6" } },
                            [
                              _vm.is_admin
                                ? _c(
                                    "v-dialog",
                                    {
                                      attrs: {
                                        persistent: "",
                                        "max-width": "700px",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "float-right text-capitalize",
                                                        attrs: {
                                                          color: "primary",
                                                          dark: "",
                                                        },
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$vuetify.lang.t(
                                                            "$vuetify.member.invite_btn_txt"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2886899326
                                      ),
                                      model: {
                                        value: _vm.invitationModal,
                                        callback: function ($$v) {
                                          _vm.invitationModal = $$v
                                        },
                                        expression: "invitationModal",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "px-3 py-3 primary--text font-weight-bold",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$vuetify.lang.t(
                                                      "$vuetify.member.modal_title"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c("v-divider"),
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "v-container",
                                                { staticClass: "px-0 pb-0" },
                                                [
                                                  _c(
                                                    "v-form",
                                                    {
                                                      ref: "invitationForm",
                                                      on: {
                                                        submit: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.sendInvitation()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        { staticClass: "mt-0" },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "pb-0",
                                                              attrs: {
                                                                cols: "4",
                                                                sm: "4",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-subheader",
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$vuetify.lang.t(
                                                                        "$vuetify.email_label_txt"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "pb-0",
                                                              attrs: {
                                                                cols: "8",
                                                                sm: "8",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    type: "email",
                                                                    label:
                                                                      _vm.$vuetify.lang.t(
                                                                        "$vuetify.email_label_txt"
                                                                      ),
                                                                    placeholder:
                                                                      "adam@gigalogy.com",
                                                                    rules: [
                                                                      _vm.required(
                                                                        _vm.$vuetify.lang.t(
                                                                          "$vuetify.email_label_txt"
                                                                        )
                                                                      ),
                                                                      _vm.email(
                                                                        "Email"
                                                                      ),
                                                                    ],
                                                                    autocomplete:
                                                                      "off",
                                                                    outlined:
                                                                      "",
                                                                    dense: "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .invitationForm
                                                                        .email,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.invitationForm,
                                                                          "email",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "invitationForm.email",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        { staticClass: "mt-0" },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "py-0",
                                                              attrs: {
                                                                cols: "4",
                                                                sm: "4",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-subheader",
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$vuetify.lang.t(
                                                                        "$vuetify.member.role"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "py-0",
                                                              attrs: {
                                                                cols: "8",
                                                                sm: "8",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "form",
                                                                {
                                                                  attrs: {
                                                                    autocomplete:
                                                                      "off",
                                                                  },
                                                                  on: {
                                                                    submit:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.preventDefault()
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-autocomplete",
                                                                    {
                                                                      attrs: {
                                                                        items:
                                                                          _vm.role_list,
                                                                        label:
                                                                          _vm.$vuetify.lang.t(
                                                                            "$vuetify.member.role"
                                                                          ),
                                                                        "item-text":
                                                                          "text",
                                                                        "item-value":
                                                                          "value",
                                                                        placeholder:
                                                                          _vm.$vuetify.lang.t(
                                                                            "$vuetify.choose_one_placeholder_txt"
                                                                          ),
                                                                        rules: [
                                                                          _vm.required(
                                                                            _vm.$vuetify.lang.t(
                                                                              "$vuetify.member.role"
                                                                            )
                                                                          ),
                                                                        ],
                                                                        outlined:
                                                                          "",
                                                                        dense:
                                                                          "",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .invitationForm
                                                                            .role,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.invitationForm,
                                                                              "role",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "invitationForm.role",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        { staticClass: "mt-0" },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "py-0",
                                                              attrs: {
                                                                cols: "4",
                                                                sm: "4",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-subheader",
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      this.$vuetify.lang.t(
                                                                        "$vuetify.first_name_label_txt"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "py-0",
                                                              attrs: {
                                                                cols: "8",
                                                                sm: "8",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    placeholder:
                                                                      "John",
                                                                    label:
                                                                      this.$vuetify.lang.t(
                                                                        "$vuetify.first_name_label_txt"
                                                                      ),
                                                                    autocomplete:
                                                                      "off",
                                                                    outlined:
                                                                      "",
                                                                    dense: "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .invitationForm
                                                                        .first_name,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.invitationForm,
                                                                          "first_name",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "invitationForm.first_name",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        { staticClass: "mt-0" },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "py-0",
                                                              attrs: {
                                                                cols: "4",
                                                                sm: "4",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-subheader",
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      this.$vuetify.lang.t(
                                                                        "$vuetify.last_name_label_txt"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "py-0",
                                                              attrs: {
                                                                cols: "8",
                                                                sm: "8",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    placeholder:
                                                                      "Doe",
                                                                    label:
                                                                      this.$vuetify.lang.t(
                                                                        "$vuetify.last_name_label_txt"
                                                                      ),
                                                                    autocomplete:
                                                                      "off",
                                                                    outlined:
                                                                      "",
                                                                    dense: "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .invitationForm
                                                                        .last_name,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.invitationForm,
                                                                          "last_name",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "invitationForm.last_name",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("v-divider"),
                                          _c(
                                            "v-card-actions",
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "red--text font-weight-bold",
                                                  attrs: {
                                                    type: "reset",
                                                    text: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.cancelInvitation.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        this.$vuetify.lang.t(
                                                          "$vuetify.cancel_btn_txt"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "font-weight-bold",
                                                  attrs: {
                                                    color: "primary",
                                                    loading:
                                                      _vm.enable_btn_loading,
                                                    disabled:
                                                      _vm.invitationForm
                                                        .email &&
                                                      _vm.invitationForm.role
                                                        ? false
                                                        : true,
                                                  },
                                                  on: {
                                                    click: _vm.sendInvitation,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        this.$vuetify.lang.t(
                                                          "$vuetify.send_btn_txt"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", xs: "12", md: "4" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "append-icon": "mdi-magnify",
                                  label: _vm.$vuetify.lang.t(
                                    "$vuetify.search_btn_txt"
                                  ),
                                  outlined: "",
                                  dense: "",
                                  "single-line": "",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.search,
                                  callback: function ($$v) {
                                    _vm.search = $$v
                                  },
                                  expression: "search",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _vm.isLoading
                        ? _c("Loader", { staticClass: "mr-2 mt-6" })
                        : _c("v-data-table", {
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.allMembers.items,
                              "sort-by": ["created_at"],
                              "sort-desc": [true],
                              search: _vm.search,
                              page: _vm.page,
                              "items-per-page": _vm.itemsPerPage,
                              "hide-default-footer": "",
                            },
                            on: {
                              "update:page": function ($event) {
                                _vm.page = $event
                              },
                              "page-count": function ($event) {
                                _vm.pageCount = $event
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: `item.id`,
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "span",
                                                      _vm._g(
                                                        _vm._b(
                                                          {},
                                                          "span",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.id.split(
                                                              "-"
                                                            )[0]
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [_c("span", [_vm._v(_vm._s(item.id))])]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: `item.first_name`,
                                  fn: function ({ item }) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(item.first_name) +
                                            " " +
                                            _vm._s(item.last_name)
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                                {
                                  key: `item.email`,
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "span",
                                        { staticClass: "d-inline-inherit" },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.email) + " "
                                          ),
                                          item.email_verified
                                            ? _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color: "green",
                                                  },
                                                },
                                                [_vm._v(" mdi-check-decagram ")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: `item.created_at`,
                                  fn: function ({ item }) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.created_at
                                              ? new Date(
                                                  item.created_at
                                                ).toLocaleDateString("en-us", {
                                                  year: "numeric",
                                                  month: "short",
                                                  day: "numeric",
                                                })
                                              : "N/A"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: `item.role`,
                                  fn: function ({ item }) {
                                    return [
                                      !_vm.is_admin ||
                                      (_vm.is_admin &&
                                        _vm.loggedin_user === item.id)
                                        ? _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                color: "primary text-uppercase",
                                                outlined: "",
                                                small: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.role) + " "
                                              ),
                                            ]
                                          )
                                        : _c("v-select", {
                                            staticClass:
                                              "custom-width text-uppercase",
                                            attrs: {
                                              items: _vm.role_list,
                                              placeholder:
                                                item.role.toUpperCase(),
                                              "item-text": "text",
                                              "item-value": "value",
                                              dense: "",
                                              outlined: "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.changeEvent(
                                                  "role",
                                                  item,
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value: item.role,
                                              callback: function ($$v) {
                                                _vm.$set(item, "role", $$v)
                                              },
                                              expression: "item.role",
                                            },
                                          }),
                                    ]
                                  },
                                },
                                {
                                  key: `item.status`,
                                  fn: function ({ item }) {
                                    return [
                                      !_vm.is_admin ||
                                      (_vm.is_admin &&
                                        _vm.loggedin_user === item.id)
                                        ? _c(
                                            "v-chip",
                                            {
                                              staticClass: "text-capitalize",
                                              attrs: {
                                                small: "",
                                                outlined: "",
                                                color: _vm.getColorOfStatus(
                                                  item.status
                                                ),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.status === "created"
                                                      ? "Invited"
                                                      : item.status
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _c("v-select", {
                                            staticClass: "custom-width",
                                            attrs: {
                                              items:
                                                item.status === "created"
                                                  ? []
                                                  : _vm.status_list,
                                              "item-text": "text",
                                              "item-value": "value",
                                              placeholder:
                                                item.status === "created"
                                                  ? "Invited"
                                                  : item.status,
                                              dense: "",
                                              outlined: "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.changeEvent(
                                                  "status",
                                                  item,
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value: item.status,
                                              callback: function ($$v) {
                                                _vm.$set(item, "status", $$v)
                                              },
                                              expression: "item.status",
                                            },
                                          }),
                                    ]
                                  },
                                },
                                {
                                  key: `item.action`,
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: { icon: "" },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.memberDetails(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          },
                                                          [_vm._v("mdi-eye")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$vuetify.lang.t(
                                                  "$vuetify.details_step_txt"
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", md: "12" },
                            },
                            [_c("v-divider")],
                            1
                          ),
                          _c("v-col", {
                            attrs: { cols: "12", xs: "12", sm: "3", md: "3" },
                          }),
                          _c(
                            "v-col",
                            {
                              attrs: { cols: "12", xs: "12", sm: "6", md: "6" },
                            },
                            [
                              _c("v-pagination", {
                                staticClass: "text-center",
                                attrs: { length: _vm.pageCount },
                                model: {
                                  value: _vm.page,
                                  callback: function ($$v) {
                                    _vm.page = $$v
                                  },
                                  expression: "page",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                "offset-sm": "1",
                                "offset-md": "1",
                                xs: "3",
                                sm: "2",
                                md: "2",
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.rows,
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: "",
                                },
                                model: {
                                  value: _vm.itemsPerPage,
                                  callback: function ($$v) {
                                    _vm.itemsPerPage = $$v
                                  },
                                  expression: "itemsPerPage",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("DialogModal", {
        attrs: {
          title: this.$vuetify.lang.t("$vuetify.confirm_alert_title_txt"),
          message: _vm.dialog_msg,
        },
        on: {
          onCancel: function ($event) {
            return _vm.cancelUpdate()
          },
          onConfirmation: function ($event) {
            return _vm.onUpdateConfirmed()
          },
        },
        model: {
          value: _vm.confirm_dialog,
          callback: function ($$v) {
            _vm.confirm_dialog = $$v
          },
          expression: "confirm_dialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }