var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panels",
    [
      _c(
        "v-expansion-panel",
        {
          model: {
            value: _vm.panel,
            callback: function ($$v) {
              _vm.panel = $$v
            },
            expression: "panel",
          },
        },
        [
          _c("v-expansion-panel-header", [
            _c("div", { staticClass: "text-h6 font-weight-bold ml-n2" }, [
              _vm._v(
                _vm._s(
                  `${_vm.$vuetify.lang.t("$vuetify.project.update_mapper")}`
                ) + " "
              ),
            ]),
          ]),
          _c(
            "v-expansion-panel-content",
            [
              _c(
                "v-form",
                { ref: "update_form" },
                [
                  _c(
                    "v-row",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("span", [_vm._v("item_id*")]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", dense: "" },
                            model: {
                              value: _vm.itemId,
                              callback: function ($$v) {
                                _vm.itemId = $$v
                              },
                              expression: "itemId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("span", [_vm._v("parent_item_id")]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", dense: "" },
                            model: {
                              value: _vm.parentItemId,
                              callback: function ($$v) {
                                _vm.parentItemId = $$v
                              },
                              expression: "parentItemId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("span", [_vm._v("title*")]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", dense: "" },
                            model: {
                              value: _vm.title,
                              callback: function ($$v) {
                                _vm.title = $$v
                              },
                              expression: "title",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("span", [_vm._v("second_title")]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", dense: "" },
                            model: {
                              value: _vm.secondTitle,
                              callback: function ($$v) {
                                _vm.secondTitle = $$v
                              },
                              expression: "secondTitle",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("span", [_vm._v("third_title")]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", dense: "" },
                            model: {
                              value: _vm.thirdTitle,
                              callback: function ($$v) {
                                _vm.thirdTitle = $$v
                              },
                              expression: "thirdTitle",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("span", [_vm._v("fourth_title")]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", dense: "" },
                            model: {
                              value: _vm.fourthTitle,
                              callback: function ($$v) {
                                _vm.fourthTitle = $$v
                              },
                              expression: "fourthTitle",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("span", [_vm._v("availability*")]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", dense: "" },
                            model: {
                              value: _vm.availability,
                              callback: function ($$v) {
                                _vm.availability = $$v
                              },
                              expression: "availability",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("span", [_vm._v("description")]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", dense: "" },
                            model: {
                              value: _vm.description,
                              callback: function ($$v) {
                                _vm.description = $$v
                              },
                              expression: "description",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("span", [_vm._v("image_url")]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", dense: "" },
                            model: {
                              value: _vm.imageUrl,
                              callback: function ($$v) {
                                _vm.imageUrl = $$v
                              },
                              expression: "imageUrl",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("span", [_vm._v("image_url_type")]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              items: ["STR", "LIST_STR", "LIST_DICT", "DICT"],
                              outlined: "",
                              dense: "",
                              "hide-selected": "",
                            },
                            model: {
                              value: _vm.imageUrlType,
                              callback: function ($$v) {
                                _vm.imageUrlType = $$v
                              },
                              expression: "imageUrlType",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("span", [_vm._v("item_url*")]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", dense: "" },
                            model: {
                              value: _vm.itemUrl,
                              callback: function ($$v) {
                                _vm.itemUrl = $$v
                              },
                              expression: "itemUrl",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("span", [_vm._v("price*")]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", dense: "" },
                            model: {
                              value: _vm.price,
                              callback: function ($$v) {
                                _vm.price = $$v
                              },
                              expression: "price",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("span", [_vm._v("categories*")]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _vm._l(_vm.categories, function (category, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "side-by-side" },
                              [
                                _c("v-text-field", {
                                  attrs: { outlined: "", dense: "" },
                                  model: {
                                    value: category.name,
                                    callback: function ($$v) {
                                      _vm.$set(category, "name", $$v)
                                    },
                                    expression: "category.name",
                                  },
                                }),
                                _c("v-text-field", {
                                  attrs: { outlined: "", dense: "" },
                                  model: {
                                    value: category.separator,
                                    callback: function ($$v) {
                                      _vm.$set(category, "separator", $$v)
                                    },
                                    expression: "category.separator",
                                  },
                                }),
                                _vm.categories.length > 1
                                  ? _c(
                                      "v-btn",
                                      {
                                        attrs: { outlined: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeSubfield(
                                              "categories",
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" Remove ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                          _c(
                            "v-btn",
                            {
                              attrs: { outlined: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.addSubfield("categories")
                                },
                              },
                            },
                            [_vm._v(" Add More ")]
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("span", [_vm._v("flag*")]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _vm._l(_vm.flag, function (elem, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "side-by-side" },
                              [
                                _c("v-text-field", {
                                  attrs: { outlined: "", dense: "" },
                                  model: {
                                    value: _vm.flag[index],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.flag, index, $$v)
                                    },
                                    expression: "flag[index]",
                                  },
                                }),
                                _vm.flag.length > 1
                                  ? _c(
                                      "v-btn",
                                      {
                                        attrs: { outlined: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeSubfield(
                                              "flag",
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" Remove ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                          _c(
                            "v-btn",
                            {
                              attrs: { outlined: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.addSubfield("flag")
                                },
                              },
                            },
                            [_vm._v(" Add More ")]
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("span", [_vm._v("average_rating")]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", dense: "" },
                            model: {
                              value: _vm.averageRating,
                              callback: function ($$v) {
                                _vm.averageRating = $$v
                              },
                              expression: "averageRating",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("span", [_vm._v("user_ratings_total")]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", dense: "" },
                            model: {
                              value: _vm.userRatingsTotal,
                              callback: function ($$v) {
                                _vm.userRatingsTotal = $$v
                              },
                              expression: "userRatingsTotal",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("span", [_vm._v("custom")]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _vm._l(_vm.custom, function (elem, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "side-by-side" },
                              [
                                _c("v-text-field", {
                                  attrs: { outlined: "", dense: "" },
                                  model: {
                                    value: elem.name,
                                    callback: function ($$v) {
                                      _vm.$set(elem, "name", $$v)
                                    },
                                    expression: "elem.name",
                                  },
                                }),
                                _c("v-autocomplete", {
                                  attrs: {
                                    items: ["INT", "FLOAT"],
                                    outlined: "",
                                    dense: "",
                                    "hide-selected": "",
                                  },
                                  model: {
                                    value: elem.data_type,
                                    callback: function ($$v) {
                                      _vm.$set(elem, "data_type", $$v)
                                    },
                                    expression: "elem.data_type",
                                  },
                                }),
                                _vm.custom.length > 0
                                  ? _c(
                                      "v-btn",
                                      {
                                        attrs: { outlined: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeSubfield(
                                              "custom",
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" Remove ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                          _c(
                            "v-btn",
                            {
                              attrs: { outlined: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.addSubfield("custom")
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.custom.length == 0
                                      ? "Add Field"
                                      : "Add More"
                                  )
                              ),
                            ]
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("span", [_vm._v("item_nearby_calculation")]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              items: [true, false],
                              outlined: "",
                              dense: "",
                              "hide-selected": "",
                            },
                            model: {
                              value: _vm.itemNearbyCalculation,
                              callback: function ($$v) {
                                _vm.itemNearbyCalculation = $$v
                              },
                              expression: "itemNearbyCalculation",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("span", [_vm._v("keywords_group_by")]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", dense: "" },
                            model: {
                              value: _vm.keywordsGroupBy,
                              callback: function ($$v) {
                                _vm.keywordsGroupBy = $$v
                              },
                              expression: "keywordsGroupBy",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("span", [_vm._v("gpt_enabled")]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              items: [true, false],
                              outlined: "",
                              dense: "",
                              "hide-selected": "",
                            },
                            model: {
                              value: _vm.gptEnabled,
                              callback: function ($$v) {
                                _vm.gptEnabled = $$v
                              },
                              expression: "gptEnabled",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("span", [_vm._v("search_settings")]),
                      ]),
                      _c("v-col", { attrs: { cols: "6" } }, [
                        _c(
                          "div",
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                items: _vm.categories.map(
                                  (category) => category.name
                                ),
                                label: "Prioritize Category",
                                dense: "",
                                "hide-selected": "",
                                outlined: "",
                                rules: [
                                  (v) =>
                                    _vm.validatePrioritizeCategory() ||
                                    _vm.$vuetify.lang.t(
                                      "$vuetify.project.field_required_for_prioritize_category"
                                    ),
                                ],
                              },
                              model: {
                                value:
                                  _vm.updateSearchSettings.prioritize_category,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.updateSearchSettings,
                                    "prioritize_category",
                                    $$v
                                  )
                                },
                                expression:
                                  "updateSearchSettings.prioritize_category",
                              },
                            }),
                            _c("div", [
                              _vm.updateSearchSettings.prioritize_values
                                .length > 0
                                ? _c(
                                    "div",
                                    { staticClass: "d-flex flex-wrap mb-2" },
                                    _vm._l(
                                      _vm.updateSearchSettings
                                        .prioritize_values,
                                      function (item, index) {
                                        return _c(
                                          "v-chip",
                                          {
                                            key: index,
                                            staticClass: "ma-1",
                                            attrs: { close: "", small: "" },
                                            on: {
                                              "click:close": function ($event) {
                                                return _vm.handleRemovePrioritizeValue(
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" " + _vm._s(item) + " ")]
                                        )
                                      }
                                    ),
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                [
                                  _c("v-text-field", {
                                    staticClass: "mb-0 pb-0",
                                    attrs: {
                                      label: "Prioritize Values",
                                      dense: "",
                                      outlined: "",
                                      "small-chips": "",
                                      clearable: "",
                                      rules: [
                                        (v) =>
                                          _vm.validatePrioritizeValues() ||
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.project.field_required_for_prioritize_values"
                                          ),
                                      ],
                                    },
                                    on: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.handleAddUpdatePrioritizeValue.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.inputUpdatePrioritizeValue,
                                      callback: function ($$v) {
                                        _vm.inputUpdatePrioritizeValue = $$v
                                      },
                                      expression: "inputUpdatePrioritizeValue",
                                    },
                                  }),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mb-8",
                                      attrs: { outlined: "" },
                                      on: {
                                        click:
                                          _vm.handleAddUpdatePrioritizeValue,
                                      },
                                    },
                                    [_vm._v(" Add Value ")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "side-by-side" },
                              [
                                _c("v-autocomplete", {
                                  attrs: {
                                    items: _vm.flag.map((flag) => flag),
                                    label: "Prioritize Flag",
                                    dense: "",
                                    "hide-selected": "",
                                    outlined: "",
                                  },
                                  on: {
                                    change: _vm.handleUpdateInputPrioritizeFlag,
                                  },
                                  model: {
                                    value: _vm.selectUpdatePrioritizeFlag,
                                    callback: function ($$v) {
                                      _vm.selectUpdatePrioritizeFlag = $$v
                                    },
                                    expression: "selectUpdatePrioritizeFlag",
                                  },
                                }),
                                _c("v-autocomplete", {
                                  attrs: {
                                    items: ["true", "false"],
                                    label: "Flag Value",
                                    dense: "",
                                    "hide-selected": "",
                                    outlined: "",
                                  },
                                  on: {
                                    change: _vm.handleUpdateInputPrioritizeFlag,
                                  },
                                  model: {
                                    value: _vm.setUpdatePrioritizeFlagValue,
                                    callback: function ($$v) {
                                      _vm.setUpdatePrioritizeFlagValue = $$v
                                    },
                                    expression: "setUpdatePrioritizeFlagValue",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", [
                              _vm.updateSearchSettings.downgrade_values.length >
                              0
                                ? _c(
                                    "div",
                                    { staticClass: "d-flex flex-wrap mb-2" },
                                    _vm._l(
                                      _vm.updateSearchSettings.downgrade_values,
                                      function (item, index) {
                                        return _c(
                                          "v-chip",
                                          {
                                            key: index,
                                            staticClass: "ma-1",
                                            attrs: { close: "", small: "" },
                                            on: {
                                              "click:close": function ($event) {
                                                return _vm.handleRemoveDowngradeValue(
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" " + _vm._s(item) + " ")]
                                        )
                                      }
                                    ),
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                [
                                  _c("v-text-field", {
                                    staticClass: "mb-0 pb-0",
                                    attrs: {
                                      label: "Downgrade Values",
                                      dense: "",
                                      outlined: "",
                                      "small-chips": "",
                                      clearable: "",
                                    },
                                    on: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.handleAddUpdateDowngradeValue.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.inputUpdateDowngradeValue,
                                      callback: function ($$v) {
                                        _vm.inputUpdateDowngradeValue = $$v
                                      },
                                      expression: "inputUpdateDowngradeValue",
                                    },
                                  }),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mb-8",
                                      attrs: { outlined: "" },
                                      on: {
                                        click:
                                          _vm.handleAddUpdateDowngradeValue,
                                      },
                                    },
                                    [_vm._v(" Add Value ")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "side-by-side" },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "Cluster Size",
                                    type: "number",
                                    dense: "",
                                    outlined: "",
                                    min: 1,
                                    max: 5000,
                                    rules: [_vm.validateMinMax(1, 5000)],
                                  },
                                  model: {
                                    value:
                                      _vm.updateSearchSettings.cluster_size,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.updateSearchSettings,
                                        "cluster_size",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "updateSearchSettings.cluster_size",
                                  },
                                }),
                                _c("v-select", {
                                  attrs: {
                                    items: [true, false],
                                    label: "Is Keyword Enabled",
                                    dense: "",
                                    "hide-selected": "",
                                    outlined: "",
                                  },
                                  model: {
                                    value:
                                      _vm.updateSearchSettings
                                        .is_keyword_enabled,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.updateSearchSettings,
                                        "is_keyword_enabled",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "updateSearchSettings.is_keyword_enabled",
                                  },
                                }),
                                _c("v-text-field", {
                                  attrs: {
                                    label: "Top K",
                                    type: "number",
                                    dense: "",
                                    outlined: "",
                                    min: 1,
                                    max: 50,
                                    rules: [_vm.validateMinMax(1, 50)],
                                  },
                                  model: {
                                    value: _vm.updateSearchSettings.top_k,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.updateSearchSettings,
                                        "top_k",
                                        $$v
                                      )
                                    },
                                    expression: "updateSearchSettings.top_k",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                loading: _vm.isLoading,
                              },
                              on: { click: _vm.updateMapper },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  `${_vm.$vuetify.lang.t(
                                    "$vuetify.project.update"
                                  )}`
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }