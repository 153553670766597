var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("Loader", {
            staticClass: "mr-2 mt-6",
            attrs: { loaderType: "card-avatar, article, actions" },
          })
        : _c(
            "v-container",
            { attrs: { "fill-height": "", fluid: "" } },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", xs: "12", md: "7" } },
                    [
                      _vm.isDeveloper() && _vm.selectedSolution.status
                        ? _c(
                            "div",
                            { staticClass: "d-flex justify-space-between" },
                            [
                              _c("h1", [
                                _vm._v(_vm._s(_vm.selectedSolution.name)),
                              ]),
                              _c(
                                "div",
                                { staticClass: "mt-3" },
                                [
                                  [
                                    _vm.selectedSolution.status !== "deleted"
                                      ? _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                small: "",
                                                                icon: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.redirectEdit(
                                                                      _vm.selectedSolution
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            "v-btn",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  _vm
                                                                    .selectedSolution
                                                                    .segment &&
                                                                  _vm
                                                                    .selectedSolution
                                                                    .segment
                                                                    .color,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-pencil-circle-outline"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              295703636
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$vuetify.lang.t(
                                                    "$vuetify.tooltip.edit"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.selectedSolution.status !==
                                      "published" &&
                                    _vm.selectedSolution.status !== "deleted"
                                      ? _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                small: "",
                                                                icon: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.publishSolution(
                                                                      $event,
                                                                      _vm.selectedSolution
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            "v-btn",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  _vm
                                                                    .selectedSolution
                                                                    .segment &&
                                                                  _vm
                                                                    .selectedSolution
                                                                    .segment
                                                                    .color,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .selectedSolution
                                                                      .status &&
                                                                      _vm
                                                                        .selectedSolution
                                                                        .status ===
                                                                        "drafted"
                                                                      ? "mdi-check-circle-outline"
                                                                      : "mdi-file-outline"
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              30813050
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.publicationTooltip
                                                    ? _vm.publicationTooltip
                                                    : _vm.setTooltip(
                                                        _vm.selectedSolution
                                                          .status
                                                      )
                                                )
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                ],
                                2
                              ),
                            ]
                          )
                        : _c("v-sheet", { staticClass: "pb-3" }, [
                            _c("h1", [
                              _vm._v(_vm._s(_vm.selectedSolution.name)),
                            ]),
                          ]),
                      _c(
                        "v-chip",
                        {
                          staticClass: "ma2",
                          attrs: {
                            color: "primary",
                            label: "",
                            outlined: "",
                            pill: "",
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v(" mdi-account-outline "),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.selectedSolution.account
                                  ? _vm.selectedSolution.account.account_name
                                  : "None"
                              ) +
                              " "
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-chip",
                        {
                          staticClass: "ma-2",
                          attrs: {
                            color:
                              _vm.selectedSolution.segment &&
                              _vm.selectedSolution.segment.color,
                            label: "",
                            outlined: "",
                            pill: "",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.selectedSolution.segment &&
                                  _vm.selectedSolution.segment.name
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c("v-sheet", { staticClass: "pt-3" }, [
                        _c("h2", [
                          _vm._v(
                            _vm._s(
                              _vm.$vuetify.lang.t("$vuetify.overview_label_txt")
                            )
                          ),
                        ]),
                      ]),
                      _vm.selectedSolution && _vm.selectedSolution.overview
                        ? _c(
                            "p",
                            [
                              _c("show-rich-text-data", {
                                attrs: {
                                  editorId: "selectedSolutionOverview",
                                  editorData: _vm.selectedSolution.overview,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-sheet", { staticClass: "pt-3" }, [
                        _c("h2", [
                          _vm._v(
                            _vm._s(
                              _vm.$vuetify.lang.t(
                                "$vuetify.description_label_txt"
                              )
                            )
                          ),
                        ]),
                      ]),
                      _vm.selectedSolution && _vm.selectedSolution.description
                        ? _c(
                            "p",
                            [
                              _c("show-rich-text-data", {
                                attrs: {
                                  editorId: "selectedSolutionDesc",
                                  editorData: _vm.selectedSolution.description,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-sheet", { staticClass: "pt-3" }, [
                        _c("h2", [
                          _vm._v(
                            _vm._s(
                              _vm.$vuetify.lang.t("$vuetify.use_case_label_txt")
                            )
                          ),
                        ]),
                      ]),
                      _vm.selectedSolution && _vm.selectedSolution.use_case
                        ? _c(
                            "p",
                            [
                              _c("show-rich-text-data", {
                                attrs: {
                                  editorId: "selectedSolutionUseCases",
                                  editorData: _vm.selectedSolution.use_case,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-sheet", { staticClass: "pb-3" }, [
                        _c("h2", [
                          _vm._v(
                            _vm._s(
                              _vm.$vuetify.lang.t("$vuetify.language_label_txt")
                            )
                          ),
                        ]),
                      ]),
                      _vm._l(
                        _vm.selectedSolution.languages,
                        function (element) {
                          return _c(
                            "v-chip",
                            {
                              key: element,
                              staticClass: "ma-2",
                              attrs: {
                                color: "success",
                                label: "",
                                outlined: "",
                                pill: "",
                              },
                            },
                            [_vm._v(" " + _vm._s(element) + " ")]
                          )
                        }
                      ),
                    ],
                    2
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", xs: "12", md: "5" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "elevation-0" },
                        [
                          _c("v-img", {
                            staticClass: "rounded my-3",
                            attrs: {
                              src: _vm.selectedSolution.cover_image
                                ? _vm.selectedSolution.cover_image
                                : _vm.segmentImg(_vm.selectedSolution),
                              "aspect-ratio": "2",
                            },
                          }),
                          _c("v-sheet", { staticClass: "py-3" }, [
                            _c("h2", [
                              _vm._v(
                                _vm._s(
                                  _vm.$vuetify.lang.t(
                                    "$vuetify.industry_label_txt"
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _vm._l(
                            _vm.selectedSolution.categories,
                            function (element) {
                              return _c(
                                "v-chip",
                                {
                                  key: element.id,
                                  staticClass: "ma-2",
                                  attrs: {
                                    color:
                                      _vm.selectedSolution.segment &&
                                      _vm.selectedSolution.segment.color,
                                    outlined: "",
                                    label: "",
                                    pill: "",
                                  },
                                },
                                [_vm._v(" " + _vm._s(element.name) + " ")]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("br"),
              _vm.hasLinks()
                ? _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c("v-col", [
                        _c("h2", [
                          _vm._v(
                            _vm._s(
                              _vm.$vuetify.lang.t(
                                "$vuetify.linked_solution_label_txt_3"
                              )
                            )
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                _vm._l(_vm.selectedSolution.links, function (element) {
                  return _c(
                    "v-col",
                    {
                      key: element.id,
                      attrs: {
                        cols: "12",
                        xs: "12",
                        sm: "6",
                        md: "6",
                        lg: "4",
                        xl: "4",
                      },
                    },
                    [
                      element
                        ? _c("LinkSolutionCard", {
                            attrs: {
                              itemData: element,
                              color:
                                _vm.selectedSolution.segment &&
                                _vm.selectedSolution.segment.color,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }