var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "elevation-0" },
    [
      _c("v-card-title", { staticClass: "font-weight-bold" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$vuetify.lang.t("$vuetify.basic_info_header_txt")) +
            " "
        ),
      ]),
      _c("v-divider"),
      _c(
        "v-list",
        [
          _c(
            "v-list-item",
            [
              _c("v-list-item-content", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      `${_vm.$vuetify.lang.t("$vuetify.first_name_label_txt")}:`
                    ) +
                    " "
                ),
              ]),
              _c("v-list-item-content", { staticClass: "align-end" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.user.first_name ? _vm.user.first_name : "N/A") +
                    " "
                ),
              ]),
            ],
            1
          ),
          _vm.isVisible
            ? _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.middle_name_label_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.user.additional_name
                            ? _vm.user.additional_name
                            : "N/A"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-list-item",
            [
              _c("v-list-item-content", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      `${_vm.$vuetify.lang.t("$vuetify.last_name_label_txt")}:`
                    ) +
                    " "
                ),
              ]),
              _c("v-list-item-content", { staticClass: "align-end" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.user.last_name ? _vm.user.last_name : "N/A") +
                    " "
                ),
              ]),
            ],
            1
          ),
          _vm.isVisible
            ? _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.gender_label_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.user.gender
                            ? _vm.capitalizeFirstLetter(_vm.user.gender)
                            : "N/A"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.isVisible
            ? _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t("$vuetify.dob_label_txt")}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.user.date_of_birth
                            ? _vm.user.date_of_birth
                            : "N/A"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.isVisible
            ? _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.phone_no_label_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.user.phoneInt ? _vm.user.phoneInt : "N/A") +
                        " "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.isVisible
            ? _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.country_label_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.user.country
                            ? _vm.user.country.countryName
                            : "N/A"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.isVisible
            ? _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.state_label_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.user.region ? _vm.user.region.name : "N/A") +
                        " "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.isVisible
            ? _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t("$vuetify.city_label_txt")}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _vm._v(
                      " " + _vm._s(_vm.user.city ? _vm.user.city : "N/A") + " "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.isVisible
            ? _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.post_code_label_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.user.postal_code ? _vm.user.postal_code : "N/A"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.isVisible
            ? _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.address_label_txt"
                            )}:`
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-list-item-content", { staticClass: "align-end" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.user.address ? _vm.user.address : "N/A") +
                        " "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }