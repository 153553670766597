var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-radio-group",
    {
      scopedSlots: _vm._u([
        {
          key: "label",
          fn: function () {
            return [_c("div", [_vm._v(_vm._s(_vm.label))])]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.inputVal,
        callback: function ($$v) {
          _vm.inputVal = $$v
        },
        expression: "inputVal",
      },
    },
    _vm._l(this.radioOption, function (item) {
      return _c(
        "div",
        { key: item.value },
        [
          _c("v-radio", {
            attrs: { value: item.value },
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function () {
                    return [_c("div", [_vm._v(_vm._s(item.text))])]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }