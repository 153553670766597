<template>
  <v-container>
    <template v-if="setupStateLoading" >
      <Loader  class="mr-2 mt-6" />
    </template>
    <template v-else>
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">
            {{ `${$vuetify.lang.t("$vuetify.profile_menu_txt")}` }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2">
            {{ `${$vuetify.lang.t("$vuetify.project.upload_dataset")}` }}
          </v-stepper-step>
          <v-divider></v-divider>
  
          <v-stepper-step step="3">
            {{ `${$vuetify.lang.t("$vuetify.project.train")}` }}
          </v-stepper-step>
        </v-stepper-header>
  
        <v-stepper-items>
          <v-stepper-content step="1">
            <CreateGptProfile ref="profile" />
            <v-row>
              <v-col cols="12" md="8"> </v-col>
              <v-col cols="12" md="4" class="text-right">
                  <v-btn
                    v-if="gptProfiles.length > 0"
                    class="mr-4 primary--text"
                    outlined
                    @click="skip(step)"
                  >
                    Skip Update >>
                  </v-btn>
                  <v-btn :loading="loading" color="primary" class="white--text"  @click="nextStep(step)">
                    {{ $vuetify.lang.t("$vuetify.continue_btn_txt") }}
                  </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
  
          <v-stepper-content step="2">
            <UploadDataset ref="dataset" propName="Test props" />
            <v-col class="d-flex justify-space-between">
              <v-btn
                class="mr-4 primary--text"
                outlined
                :loading="false"
                @click="previousStep(step)"
              >
                {{ $vuetify.lang.t("$vuetify.prev_btn_txt") }}
              </v-btn>
              <div>
                <v-btn
                  outlined
                  v-if="gptSetupState.dataset.completed"
                  class="mr-4 primary--text"
                  
                  @click="skip(step)"
                >
                  Skip Update >>
                </v-btn>
                <v-btn
                  class="mr-4"
                  :loading="loading"
                  @click="nextStep(step)"
                  color="primary"
                >
                  {{ $vuetify.lang.t("$vuetify.continue_btn_txt") }}
                </v-btn>
              </div>
            </v-col>
          </v-stepper-content>
  
          <v-stepper-content step="3">
            <v-card
              v-if="gptSetupState.dataset.error"
              class="pa-2 d-flex flex-column align-center justify-center"
              height="200px"
            >
              <v-col class="d-flex align-center justify-center" cols="auto">
                <v-icon color="red">mdi-close-circle-outline</v-icon>
                <v-list-item-title class="ml-2">
                  {{ gptSetupState.dataset.message }}
                </v-list-item-title>
              </v-col>
              <v-btn
                class="mt-1 primary--text"
                outlined
                :loading="false"
                @click="datasetErrorTryAgain"
              >
                {{$vuetify.lang.t('$vuetify.common.try_again')}}
              </v-btn>
            </v-card>
            <!-- training error -->
            <v-card
              v-else-if="gptSetupState.training.error"
              class="pa-2 d-flex flex-column align-center justify-center"
              height="200px"
            >
              <v-col class="d-flex align-center justify-center" cols="auto">
                <v-icon color="red">mdi-close-circle-outline</v-icon>
                <v-list-item-title class="ml-2">
                  {{ gptSetupState.training.message }}
                </v-list-item-title>
              </v-col>
              <v-btn
                class="mr-4 mt-1 primary--text"
                outlined
                :loading="false"
                @click="trainingErrorTryAgain"
              >
                {{$vuetify.lang.t('$vuetify.common.try_again')}}
              </v-btn>
            </v-card>
  
            <div v-else-if="dataSetProcessCompleted">
              <v-card
                v-if="trainingInProgress"
                height="200px"
                class="d-flex justify-center align-center"
              >
                <v-col cols="6">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-progress-circular indeterminate color="primary">
                      </v-progress-circular>
                    </v-list-item-icon>
  
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $vuetify.lang.t("$vuetify.training_in_progress") }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-card>
              <v-form ref="train_form" v-else>
                <v-card
                  height="200px"
                  style="box-shadow: none;"
                  class="d-flex justify-center align-center"
                >
                  <v-col cols="12" md="6">
                    <v-select
                      :label="
                        $vuetify.lang.t('$vuetify.gpt_dataset.training_type')
                      "
                      :items="['text', 'image']"
                      v-model="trainType"
                      outlined
                      :rules="[
                        required(
                          $vuetify.lang.t('$vuetify.gpt_dataset.training_type')
                        ),
                      ]"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="batchSize"
                      :label="$vuetify.lang.t('$vuetify.gpt_dataset.batch_size')"
                      outlined
                      type="number"
                      :rules="batchSizeRules"
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-card>
              </v-form>
  
              <div
                v-if="!trainingInProgress"
                class="d-flex justify-space-between"
              >
                <v-btn
                  class="mr-4 primary--text"
                  outlined
                  :loading="false"
                  @click="previousStep(step)"
                >
                  {{ $vuetify.lang.t("$vuetify.prev_btn_txt") }}
                </v-btn>
                <v-btn
                  @click="handleTraining"
                  class="mr-4 pr-8 pl-8 "
                  :loading="loading"
                  color="primary"
                >
                  {{ $vuetify.lang.t("$vuetify.project.train") }}
                </v-btn>
              </div>
            </div>
            <template v-else>
              <v-card
                style="box-shadow: none;"
                height="200px"
                class="d-flex justify-center align-center"
              >
                <v-col cols="6">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-progress-circular indeterminate color="primary">
                      </v-progress-circular>
                    </v-list-item-icon>
  
                    <v-list-item-content>
                      {{
                        $vuetify.lang.t(
                          "$vuetify.gpt_dataset.dataset_process_progress"
                        )
                      }}
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-card>
              <v-btn
                class="mr-4 primary--text"
                outlined
                :loading="false"
                @click="previousStep(step)"
              >
                {{ $vuetify.lang.t("$vuetify.prev_btn_txt") }}
              </v-btn>
            </template>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import UploadDataset from "@/components/Projects/UploadDataset/UploadDataset";
import CreateGptProfile from "@/components/Projects/CreateGptProfile/CreateGptProfile"
import Loader from "@/components/common/loader/Loader";
import {
  GET_GPT_PROFILE_BY_ID,
  CREATE_GPT_PROFILE,
  UPDATE_GPT_PROFILE_STATE,
  UPDATE_GPT_PROFILE_API,
  ADD_ALERT,
  GET_GPT_DATASET_BY_ID,
  CREATE_GPT_DATASET,
  UPDATE_GPT_DATASET_API,
  TRAIN_GPT_DATASET,
  CREATE_GPT_SETTINGS,
  UPDATE_GPT_SETUP_STATE,
  GET_TASK_STATUS,
  UPDATE_GPT_DATASET_STATE,
} from "@/store/_actiontypes";
import { GptProfileMapper } from "@/helpers/dataMappers/gptProfile";
import { GptDatasetMapper } from "@/helpers/dataMappers/gptDataset";
import {GPT_MODELS} from "@/global/_constants";
import getEnv from "@/config/env";

export default {
  components: {
    UploadDataset,
    CreateGptProfile,
    Loader
  },
  data() {
    return {
      loading: false,
      step: 1,
      trainingInProgress: false,
      trainType: "",
      batchSize: 1,
      dataSetProcessCompleted: false,
      intervalId: null,
      setupStateLoading: false,
      batchSizeRules: [
        value => value >= 1 || 'Min value is 1',
        value => value <= 10 || 'Max value is 10'
      ]

    };
  },
  computed: {
    ...mapState({
      gptProfiles: (state) => state.project.gptProfiles.profiles,
      selectedGptProfile: (state) => state.project.selectedGptProfile,
      gptDatasets: (state) => state.project.gptDatasets.datasets,
      selectedGptDataset: (state) => state.project.selectedGptDataset,
      profileExist: (state) => state.project.gptProfiles.profiles.length > 0,
      datasetExist: (state) => state.project.gptSetupState.dataset.completed,
      gptSetupState: (state) => state.project.gptSetupState,
      selectedProject: (state) => state.project.selectedProject,
    }),
  },
  watch: {
    gptSetupState: {
      handler(setupState) {
        //for dataset task
        if (
          setupState.dataset?.task_id &&
          !this.intervalId &&
          !setupState.dataset?.error
        ) {
          this.startInterval(setupState.dataset?.task_id, "dataset");
        }
        //for training task
        if (
          setupState.training?.task_id &&
          !this.intervalId &&
          !setupState.training?.error
        ) {
          this.startInterval(setupState.training?.task_id, "train");
          this.trainingInProgress = true;
        }

        if (setupState.dataset?.completed) {
          this.dataSetProcessCompleted = true;
        }
        if (setupState.training?.completed) {
          this.trainingInProgress = false;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.loadGptSetupInitialData();
  },

  beforeDestroy() {
    this.stopInterval();
    this.resetGptProfile();
    this.resetGptDataset();
  },

  methods: {
    ...mapActions("project", [
      GET_GPT_PROFILE_BY_ID,
      CREATE_GPT_PROFILE,
      UPDATE_GPT_PROFILE_STATE,
      UPDATE_GPT_PROFILE_API,
      GET_GPT_DATASET_BY_ID,
      CREATE_GPT_DATASET,
      UPDATE_GPT_DATASET_API,
      TRAIN_GPT_DATASET,
      CREATE_GPT_SETTINGS,
      UPDATE_GPT_SETUP_STATE,
      UPDATE_GPT_DATASET_STATE,
    ]),
    ...mapActions("task", [GET_TASK_STATUS]),
    stopInterval() {
      clearInterval(this.intervalId);
      this.intervalId = null;
    },
    resetGptProfile() {
      this.UPDATE_GPT_PROFILE_STATE({
        profile: GptProfileMapper.mapBackendToFrontend({
          name: "",
          intro: "",
          system: "",
          model: "",
          modelList: GPT_MODELS,
          temperature: 0,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
          stop: [],
          search_max_token: 2500,
          completion_token: 2000,
          vision_settings: {
            resolution: "low",
            is_image_context_enabled: true,
          },
          id: undefined,
          chat_history_length: 3,
          is_personalizer_only: false,
          is_auto_evaluation: false
        }),
      });
    },
    loadGptSetupInitialData() {
      if (this.gptProfiles.length > 0) {
        this.step = 2;
        this.setupStateLoading = true
        this.GET_GPT_PROFILE_BY_ID({
          project_id: this.$route.params.project_id,
          profile_id: this.gptProfiles[0]?.id,
        }).then(
          (res) => {
            this.step = 2;
            if (this.gptSetupState.dataset.error) {
              this.step = 3;
              this.setupStateLoading = false
            }

            if (this.gptDatasets.length > 0) {
              this.GET_GPT_DATASET_BY_ID({
                project_id: this.$route.params.project_id,
                dataset_id: this.gptDatasets[0]?.dataset_id,
              }).then(
                (res) => {
                  if (res.data.response?.dataset?.documents_count?.total > 0) {
                      this.step = 3;
                    } 
                },
                (err) => {
                  this.$store.dispatch(
                    `alert/${ADD_ALERT}`,
                    {
                      message: this.$vuetify.lang.t(
                        "$vuetify.gpt_dataset.dataset_fetch_error"
                      ),
                      color: "error",
                    },
                    { root: true }
                  );
                }
              )
              .finally(()=> {
                this.setupStateLoading = false
              })
            } else {
              this.setupStateLoading = false
              this.resetGptDataset();
            }
          },
          (err) => {
            this.setupStateLoading = false
            this.$store.dispatch(
              `alert/${ADD_ALERT}`,
              {
                message: this.$vuetify.lang.t(
                  "$vuetify.gpt_profile.profile_fetch_error"
                ),
                color: "error",
              },
              { root: true }
            );
          }
        )
        .finally(()=> {
        })
      } else {
        this.setupStateLoading = false
      }
    },
    resetGptDataset() {
      this.UPDATE_GPT_DATASET_STATE({
        dataset: GptDatasetMapper.mapBackendToFrontend({
          dataset_id: "",
          description: "",
          idx_column_name: "",
          image_url_column: "",
          is_idx_fillup_if_empty: true,
          name: "",
          secondary_idx_column: "",
        }),
      });
    },
    startInterval(task_id, type) {
      this.intervalId = setInterval(() => {
        if (type === "dataset") {
          this.GET_TASK_STATUS({
            project_id: this.selectedProject.id,
            task_id: task_id,
          })
            .then((res) => {
              if (res.data.detail?.response?.status === "success") {
                this.$store.dispatch(
                  `alert/${ADD_ALERT}`,
                  {
                    message: "Dataset Processing Successful",
                    color: "success",
                  },
                  { root: true }
                );
                this.stopInterval();
                this.dataSetProcessCompleted = true;
                this.UPDATE_GPT_SETUP_STATE({
                  ...this.gptSetupState,
                  dataset: {
                    ...this.gptSetupState.dataset,
                    completed: true,
                    dataset_id: this.gptSetupState.dataset.dataset_id,
                    task_id: null,
                    error: false,
                  },
                });
              } else if (
                res.data?.detail?.response?.status !== "pending" &&
                res.data?.detail?.response?.status !== "claimable"
              ) {
                this.stopInterval();
                this.UPDATE_GPT_SETUP_STATE({
                  ...this.gptSetupState,
                  dataset: {
                    ...this.gptSetupState.dataset,
                    status: res.data.detail?.response.status,
                    message: res.data.detail?.response?.message,
                    error: true,
                  },
                });
              } else {
                this.UPDATE_GPT_SETUP_STATE({
                  ...this.gptSetupState,
                  dataset: {
                    ...this.gptSetupState.dataset,
                    status: res.data.detail.response.status,
                    error: false,
                  },
                });
              }
            })
            .catch((err) => {
              console.log("error", err);
            });
        }
        if (type === "train") {
          this.GET_TASK_STATUS({
            project_id: this.selectedProject.id,
            task_id: task_id,
          })
            .then((res) => {
              if (res.data.detail.response.status === "success") {
                this.stopInterval();
                this.trainingInProgress = false;
                this.UPDATE_GPT_SETUP_STATE({
                  ...this.gptSetupState,
                  // completed: true,
                  training: {
                    ...this.gptSetupState.training,
                    completed: true,
                    task_id: null,
                    status: "success",
                    error: false,
                  },
                });
              } else if (
                res.data.detail.response.status !== "pending" &&
                res.data?.detail?.response?.status !== "claimable"
              ) {
                this.stopInterval();
                this.trainingInProgress = false;
                this.UPDATE_GPT_SETUP_STATE({
                  ...this.gptSetupState,
                  training: {
                    ...this.gptSetupState.training,
                    status: res.data.detail.response.status,
                    message: res.data?.detail?.response?.message,
                    error: true,
                  },
                });
                // updated training state
              } else {
                this.UPDATE_GPT_SETUP_STATE({
                  ...this.gptSetupState,
                  training: {
                    ...this.gptSetupState.training,
                    status: res.data.detail.response.status,
                    error: false,
                  },
                });

              }
            })
            .catch((err) => {
              console.log("error", err);
            });
        }
      }, getEnv("VUE_APP_POLLING_INTERVAL"));
    },
    trainingErrorTryAgain() {
      this.UPDATE_GPT_SETUP_STATE({
        ...this.gptSetupState,
        training: {
          ...this.gptSetupState.training,
          task_id: null,
          status: null,
          message: null,
          error: false,
        },
      });
    },
    datasetErrorTryAgain() {
      this.step = 2;
      this.UPDATE_GPT_SETUP_STATE({
        ...this.gptSetupState,
        dataset: {
          ...this.gptSetupState.dataset,
          task_id: null,
          status: null,
          message: null,
          error: false,
        },
      });
    },

    previousStep(step) {
      this.step = step - 1;
      if (step === 1) this.step = 3;
    },
    required: (fieldName) => {
      return (value) => !!value || `${fieldName} is required`;
    },
    skip(step) {
      this.step = step + 1;
    },
    nextStep(step) {
      if (step === 1) {
        const valid = this.$refs.profile.validateForm();
        if (!valid) return;
        if (!this.profileExist) {
          this.loading = true
          this.CREATE_GPT_PROFILE({
            project_id: this.$route.params.project_id,
            profile: this.selectedGptProfile,
          }).then(
            (res) => {
              this.step = step + 1;
              this.UPDATE_GPT_SETUP_STATE({
                ...this.gptSetupState,
                gpt_profile: {
                  completed: true,
                  profile_id: res.data.detail?.profile_id,
                },
              });
              this.$store.dispatch(
                `alert/${ADD_ALERT}`,
                {
                  message: this.$vuetify.lang.t(
                    "$vuetify.gpt_profile.profile_creation_success"
                  ),
                  color: "success",
                },
                { root: true }
              );
              // set default profile, don't remove
              this.CREATE_GPT_SETTINGS({
                project_id: this.$route.params.project_id,
                default_profile: res.data?.detail?.profile_id,
              })
                .then((res) => {})
                .catch((err) => {
                  console.error("default profile creation error", err);
                });
            },
            (err) => {
              console.error("Err ", err);
              this.$store.dispatch(
                `alert/${ADD_ALERT}`,
                {
                  message:
                    err.response?.data?.detail?.response ||
                    this.$vuetify.lang.t(
                      "$vuetify.gpt_profile.profile_creation_error"
                    ),
                  color: "error",
                },
                { root: true }
              );
            }
          ).finally(()=> {
            this.loading = false
          })
        } else {
          this.loading = true
          this.UPDATE_GPT_PROFILE_API({
            project_id: this.$route.params.project_id,
            profile: this.selectedGptProfile,
          }).then(
            (res) => {
              this.step = step + 1;
              this.$store.dispatch(
                `alert/${ADD_ALERT}`,
                {
                  message: this.$vuetify.lang.t(
                    "$vuetify.gpt_profile.profile_update_success"
                  ),
                  color: "success",
                },
                { root: true }
              );
            },
            (err) => {
              this.step = step + 1;
              this.$store.dispatch(
                `alert/${ADD_ALERT}`,
                {
                  message: this.$vuetify.lang.t(
                    "$vuetify.gpt_profile.profile_update_error"
                  ),
                  color: "error",
                },
                { root: true }
              );
            }
          ).finally(()=> {
            this.loading = false
          })
        }
      } else if (step === 2) {
        const valid = this.$refs.dataset.validateForm();
        if (!valid) return;
        if (!this.datasetExist) {
          this.loading = true
          this.CREATE_GPT_DATASET({
            project_id: this.$route.params.project_id,
            dataset: this.selectedGptDataset,
          }).then(
            (res) => {
              this.UPDATE_GPT_SETUP_STATE({
                ...this.gptSetupState,
                dataset: {
                  ...this.gptSetupState.dataset,
                  dataset_id: res.data.detail?.dataset_id,
                },
              });
              //updating dataset with dataset file
              this.UPDATE_GPT_DATASET_API({
                project_id: this.$route.params.project_id,
                dataset: {
                  ...this.selectedGptDataset,
                  datasetId: res.data.detail?.dataset_id,
                },
              }).then(
                (res) => {
                  this.UPDATE_GPT_SETUP_STATE({
                    ...this.gptSetupState,
                    dataset: {
                      ...this.gptSetupState.dataset,
                      task_id: res.data.detail?.task_id,
                    },
                  });
                  this.step = step + 1;
                  this.$store.dispatch(
                    `alert/${ADD_ALERT}`,
                    {
                      message: this.$vuetify.lang.t(
                        "$vuetify.gpt_dataset.dataset_submission_success"
                      ),
                      color: "success",
                    },
                    { root: true }
                  );
                },
                (err) => {
                  this.$store.dispatch(
                    `alert/${ADD_ALERT}`,
                    {
                      message: this.$vuetify.lang.t(
                        "$vuetify.gpt_dataset.dataset_submission_error"
                      ),
                      color: "error",
                    },
                    { root: true }
                  );
                }
              );
            },
            (err) => {
              console.log("dataset submission err", err);
              this.$store.dispatch(
                `alert/${ADD_ALERT}`,
                {
                  message: err.response?.message,
                  color: "error",
                },
                { root: true }
              );
            }
          ).finally(()=> {
            this.loading = false
          })
        } else {
          this.loading = true
          this.UPDATE_GPT_DATASET_API({
            project_id: this.$route.params.project_id,
            dataset: this.selectedGptDataset,
          })
            .then(
              (res) => {
                this.UPDATE_GPT_SETUP_STATE({
                  ...this.gptSetupState,
                  dataset: {
                    ...this.gptSetupState.dataset,
                    task_id: res.data.detail?.task_id,
                  },
                });
                this.$store.dispatch(
                  `alert/${ADD_ALERT}`,
                  {
                    message: this.$vuetify.lang.t(
                      "$vuetify.gpt_dataset.dataset_update_success"
                    ),
                    color: "success",
                  },
                  { root: true }
                );
              },
              (err) => {
                this.$store.dispatch(
                  `alert/${ADD_ALERT}`,
                  {
                    message: this.$vuetify.lang.t(
                      "$vuetify.gpt_dataset.dataset_update_error"
                    ),
                    color: "error",
                  },
                  { root: true }
                );
              }
            )
            .finally(() => {
              this.step = step + 1;
              this.loading = false
            });
        }
      }
      if (step === 3) this.step = 1;
    },
    handleTraining() {
      const valid = this.validateForm();
      if (!valid) return;
      this.loading = true
      this.TRAIN_GPT_DATASET({
        project_id: this.$route.params.project_id,
        datasetId: this.selectedGptDataset.datasetId,
        trainType: this.trainType,
        batchSize: this.batchSize,
      }).then(
        (res) => {
          this.UPDATE_GPT_SETUP_STATE({
            ...this.gptSetupState,
            training: {
              ...this.gptSetupState.training,
              task_id: res.data?.detail?.task_id,
            },
          });
          //res.data.detail.response <-- contains the train task id
          this.trainingInProgress = true;
        },
        (err) => {
          console.log("train err", err.response);
          this.$store.dispatch(
            `alert/${ADD_ALERT}`,
            {
              message:
                err.response?.data?.detail?.response ||
                "Dataset training error",
              color: "error",
            },
            { root: true }
          );
        }
      ).finally(()=> {
        this.loading = false
      })
    },
    validateForm() {
      return this.$refs.train_form.validate();
    },
  },
};
</script>

<style>
.v-stepper__header {
  box-shadow: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
