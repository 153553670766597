var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "mx-auto rounded",
      attrs: { outlined: "", exact: "", tile: "" },
    },
    [
      _c("v-img", {
        staticClass: "white--text align-end",
        attrs: {
          src: _vm.itemData.cover_image
            ? _vm.itemData.cover_image
            : _vm.segmentImg(_vm.itemData),
          "aspect-ratio": "2",
        },
      }),
      _vm.isDeveloper && _vm.itemData.status
        ? [
            _vm.itemData.status !== "deleted"
              ? _c(
                  "v-tooltip",
                  {
                    attrs: { top: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      class:
                                        _vm.itemData.status === "published"
                                          ? "white--text mt-7 rounded-circle"
                                          : "white--text mr-13 mt-7 rounded-circle",
                                      attrs: {
                                        absolute: "",
                                        color:
                                          _vm.itemData.segment.color &&
                                          _vm.itemData.segment.color,
                                        rounded: "",
                                        fab: "",
                                        small: "",
                                        top: "",
                                        right: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editSolution(
                                            $event,
                                            _vm.itemData
                                          )
                                        },
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_c("v-icon", [_vm._v("mdi-pencil-outline")])],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      397168822
                    ),
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$vuetify.lang.t("$vuetify.tooltip.edit"))
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm.itemData.status !== "published" &&
            _vm.itemData.status !== "deleted"
              ? _c(
                  "v-tooltip",
                  {
                    attrs: { top: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass:
                                        "white--text mt-7 rounded-circle",
                                      attrs: {
                                        absolute: "",
                                        color:
                                          _vm.itemData.segment.color &&
                                          _vm.itemData.segment.color,
                                        rounded: "",
                                        fab: "",
                                        small: "",
                                        top: "",
                                        right: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.publishSolution(
                                            $event,
                                            _vm.itemData
                                          )
                                        },
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.itemData.status &&
                                          _vm.itemData.status === "drafted"
                                          ? "mdi-check"
                                          : "mdi-file-outline"
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      525124704
                    ),
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.publicationTooltip
                            ? _vm.publicationTooltip
                            : _vm.setTooltip(_vm.itemData.status)
                        )
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
          ]
        : _vm._e(),
      _c("v-card-subtitle", { staticClass: "pb-0" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$vuetify.lang.t("$vuetify.solution.developed_by")) +
            " " +
            _vm._s(_vm.itemData.account.account_name) +
            " "
        ),
      ]),
      _c("v-card-title", [_vm._v(_vm._s(_vm.itemData.name))]),
      _c(
        "v-card-actions",
        [
          _c(
            "v-chip",
            {
              staticClass: "ma-2 py-3",
              attrs: {
                color: _vm.itemData.segment.color,
                label: "",
                outlined: "",
                pill: "",
              },
            },
            [_vm._v(" " + _vm._s(_vm.itemData.segment.name) + " ")]
          ),
          _c(
            "v-btn",
            {
              staticClass:
                "px-3 py-5 ml-auto mb-2 mr-2 outlined-default details-btn",
              attrs: { outlined: "" },
              on: {
                click: function ($event) {
                  return _vm.redirectDetails(_vm.itemData)
                },
              },
            },
            [
              _c("span", { staticClass: "ml-1" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$vuetify.lang.t("$vuetify.solution.preview")) +
                    " "
                ),
              ]),
              _c("v-icon", { staticClass: "ml-2" }, [_vm._v("arrow_forward")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }