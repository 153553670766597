var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { class: `${_vm.color}--text` }, [
    _c("span", { staticClass: "font-weight-bold whole" }, [
      _vm._v(
        " " +
          _vm._s(_vm.prefix && _vm.prefix) +
          _vm._s(`${_vm.amount.split(".")[0]}`) +
          ". "
      ),
      _c("span", { staticClass: "decimal" }),
    ]),
    _c("span", { staticClass: "fraction" }, [
      _vm._v(
        " " +
          _vm._s(
            `${_vm.amount.split(".")[1] ? _vm.amount.split(".")[1] : "0000000"}`
          ) +
          " "
      ),
    ]),
    _vm.asset_code
      ? _c("span", { staticClass: "font-weight-bold" }, [
          _vm._v(" " + _vm._s(`${_vm.asset_code}`) + " "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }