var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-footer",
    { attrs: { app: "", absolute: "" } },
    [
      _c("v-col", { staticClass: "text-center" }, [
        _c("span", [
          _vm._v("Gigalogy © " + _vm._s(new Date().getFullYear()) + " "),
          _vm.isTargetUser ? _c("span", [_vm._v("事業再構築")]) : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }