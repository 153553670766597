var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-layout",
            { attrs: { row: "", "justify-space-between": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", "justify-space-between": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", md4: "" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "pa-2 mr-2",
                              attrs: {
                                flat: "",
                                "min-height": "340px",
                                height: "100%",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "blue--text px-2 py-1 text-capitalize font-weight-medium",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.settings_header_txt"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("v-divider"),
                              _c(
                                "v-form",
                                { staticClass: "xs12" },
                                [
                                  _c(
                                    "v-container",
                                    [
                                      _c("v-text-field", {
                                        staticClass: "ma-0 pa-0 form-label",
                                        attrs: {
                                          label: _vm.$vuetify.lang.t(
                                            "$vuetify.sys_name_label_txt"
                                          ),
                                          required: "",
                                          dense: "",
                                        },
                                        model: {
                                          value: _vm.settings.systemName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.settings,
                                              "systemName",
                                              $$v
                                            )
                                          },
                                          expression: "settings.systemName",
                                        },
                                      }),
                                      _c("v-autocomplete", {
                                        staticClass: "ma-0 pa-0 form-label",
                                        attrs: {
                                          items: _vm.currencies,
                                          "item-text": "name",
                                          "item-value": "id",
                                          label: _vm.$vuetify.lang.t(
                                            "$vuetify.currency_label_txt"
                                          ),
                                          dense: "",
                                        },
                                        model: {
                                          value:
                                            _vm.settings.currencyRegionName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.settings,
                                              "currencyRegionName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "settings.currencyRegionName",
                                        },
                                      }),
                                      _c("v-switch", {
                                        staticClass: "ma-0 pa-0 form-label",
                                        attrs: {
                                          color: "grey",
                                          label: _vm.$vuetify.lang.t(
                                            "$vuetify.theme_label_txt"
                                          ),
                                          dense: "",
                                        },
                                        model: {
                                          value: _vm.settings.useDarkMode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.settings,
                                              "useDarkMode",
                                              $$v
                                            )
                                          },
                                          expression: "settings.useDarkMode",
                                        },
                                      }),
                                      _c(
                                        "v-row",
                                        { staticClass: "justify-end" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "blue--text font-weight-bold",
                                              attrs: {
                                                outlined: "",
                                                small: "",
                                                loading: _vm.loading,
                                              },
                                              on: { click: _vm.handleSubmit },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$vuetify.lang.t(
                                                    "$vuetify.submit_btn_txt"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", md8: "" } },
                        [
                          _c("v-card", {
                            class: {
                              "pa-2 mr-2 mt-2":
                                _vm.$vuetify.breakpoint.smAndDown,
                              "pa-2 mr-2": _vm.$vuetify.breakpoint.mdAndUp,
                            },
                            attrs: {
                              flat: "",
                              "min-height": "340px",
                              height: "100%",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-container",
                    { attrs: { "px-0": "", "pb-0": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", "justify-space-between": "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("v-card", {
                                staticClass: "pa-2 mr-2",
                                attrs: {
                                  flat: "",
                                  "min-height": "340px",
                                  height: "100%",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }