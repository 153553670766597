<template>
  <v-app>
    <v-main>
      <v-container text-center id="login-page">
        <v-layout align-center justify-center row wrap>
          <v-flex xs12 sm8 md4>
            <h2 class="primary--text mt-16" v-if="!verificationComplete">
              {{ $vuetify.lang.t("$vuetify.verify_msg_txt") }}
            </h2>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import getEnv from "@/config/env";
import { VERIFY_EMAIL, LOAD_ORGANIZATION } from "@/store/_actiontypes";
import router from "@/router/index";
import jwt_decode from "jwt-decode";

export default {
  components: {},
  data() {
    return {
      verificationComplete: false,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loader.loading,
      user: (state) => state.account.user,
      organization: (state) => state.account.organization,
    }),
  },
  mounted() {
    this.verifyResetPassword();
  },
  methods: {
    ...mapActions("account", [VERIFY_EMAIL]),
    ...mapActions("account", [LOAD_ORGANIZATION]),
    verifyResetPassword() {
      if (getEnv("VUE_APP_CAPTCHA_ENABLED")) {
        setTimeout(
          () => {
            this.$recaptcha.execute("verify_email")
              .then((recaptcha_token) => {
                this.verifyResetPasswordLink(recaptcha_token);
              })
              .catch(() => {
                // Handle error or fallback
                this.verifyResetPassword();
              });
          },
          10
        );
      } else {
        this.verifyResetPasswordLink(null);
      }
    },
    verifyResetPasswordLink(recaptcha_token) {
      this.verificationComplete = false;
      this.VERIFY_EMAIL({
        token: this.$route.params.token,
        verify_method: this.$route.params.verify_method,
        recaptcha_token: recaptcha_token
      })
        .then(
          (res) => {
            if (res.status === 200) {
              this.verificationComplete = true;
              if (this.user) {
                this.LOAD_ORGANIZATION()
                  .then((res) => {
                    let parsedJWTJson = jwt_decode(this.user.access_token);
                    let user_id = parsedJWTJson["sub"];
                    let user_hash = parsedJWTJson["iuh"];
                    router.push("/setup-accounts").catch(() => {});
                  })
                  .catch(() => {});
              }
            }
          },
          (error) => {
            this.verificationComplete = true;
            router.push("/404");
          }
        )
        .catch((err) => {
          this.verificationComplete = true;
          router.push("/404");
        });
    },
  },
};
</script>
