<script>
import { mapState } from "vuex";

export default {
  name: "Snackbar",
  computed: {
    ...mapState({
      alert: (state) => state.alert.alert,
    }),
  },
};
</script>

<template>
  <v-snackbar v-model="alert.show" :color="alert.color" top>
    <v-row class="py-0 my-0">
      <v-col cols="12" md="11" class="py-0 my-0">
        {{ alert.message }}
      </v-col>
      <v-col cols="12" md="1" class="py-0 my-0">
        <v-icon small dark @click="alert.show = false"> close </v-icon>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<style scoped>

</style>