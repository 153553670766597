var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-4" },
    [
      _c(
        "v-stepper",
        {
          model: {
            value: _vm.e1,
            callback: function ($$v) {
              _vm.e1 = $$v
            },
            expression: "e1",
          },
        },
        [
          _c(
            "v-stepper-header",
            [
              _vm._l(_vm.steps, function (n) {
                return [
                  _c(
                    "v-stepper-step",
                    {
                      key: `${n}-step`,
                      attrs: { complete: _vm.e1 > n, step: n },
                    },
                    [
                      n === 1
                        ? _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$vuetify.lang.t("$vuetify.details_step_txt")
                              )
                            ),
                          ])
                        : _vm._e(),
                      n === 2
                        ? _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$vuetify.lang.t("$vuetify.pricing_step_txt")
                              )
                            ),
                          ])
                        : _vm._e(),
                      n === 3
                        ? _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$vuetify.lang.t(
                                  "$vuetify.metadata_step_txt"
                                )
                              )
                            ),
                          ])
                        : _vm._e(),
                      n === 4
                        ? _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$vuetify.lang.t("$vuetify.review_step_txt")
                              )
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  n !== _vm.steps ? _c("v-divider", { key: n }) : _vm._e(),
                ]
              }),
            ],
            2
          ),
          _c(
            "v-stepper-items",
            _vm._l(_vm.steps, function (n) {
              return _c(
                "v-stepper-content",
                { key: `${n}-content`, attrs: { step: n } },
                [
                  _c(
                    "ValidationObserver",
                    { ref: "firstStep", refInFor: true },
                    [
                      n === 1
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-form",
                                { ref: "form1", refInFor: true },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "6" } },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "Content Language",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c("v-autocomplete", {
                                                        attrs: {
                                                          items:
                                                            _vm.contentLanguages,
                                                          "item-text": "text",
                                                          "item-value": "value",
                                                          label:
                                                            _vm.$vuetify.lang.t(
                                                              "$vuetify.content_lang_header_txt"
                                                            ),
                                                          "error-messages":
                                                            _vm.localizeErrorMsg(
                                                              errors,
                                                              "content_lang"
                                                            ),
                                                          outlined: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contentLanguage,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.contentLanguage =
                                                              $$v
                                                          },
                                                          expression:
                                                            "contentLanguage",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            xs: "12",
                                            md: "6",
                                          },
                                        },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "Solution Name",
                                              rules:
                                                _vm.contentLanguage == "en"
                                                  ? "required|is_numeric"
                                                  : "is_numeric",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _vm.contentLanguage ==
                                                      "en"
                                                        ? _c("v-text-field", {
                                                            attrs: {
                                                              label:
                                                                _vm.$vuetify.lang.t(
                                                                  "$vuetify.solution_name_label_txt"
                                                                ),
                                                              outlined: "",
                                                              "error-messages":
                                                                _vm.localizeErrorMsg(
                                                                  errors,
                                                                  "solution_name",
                                                                  _vm.solutions
                                                                    .name
                                                                ),
                                                              disabled:
                                                                _vm.solutionHasProject,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.solutions
                                                                  .name,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.solutions,
                                                                    "name",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "solutions.name",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      _vm.contentLanguage ==
                                                      "ja"
                                                        ? _c("v-text-field", {
                                                            attrs: {
                                                              label:
                                                                _vm.$vuetify.lang.t(
                                                                  "$vuetify.solution_name_label_txt"
                                                                ),
                                                              "error-messages":
                                                                _vm.localizeErrorMsg(
                                                                  errors,
                                                                  "solution_name",
                                                                  _vm.solutions
                                                                    .name_ja
                                                                ),
                                                              disabled:
                                                                _vm.solutionHasProject,
                                                              outlined: "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.solutions
                                                                  .name_ja,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.solutions,
                                                                    "name_ja",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "solutions.name_ja",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            xs: "12",
                                            md: "6",
                                          },
                                        },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "Solution Type",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c("v-autocomplete", {
                                                        attrs: {
                                                          items:
                                                            _vm.allSegments,
                                                          "item-text": "name",
                                                          "item-value": "id",
                                                          label:
                                                            _vm.$vuetify.lang.t(
                                                              "$vuetify.solution_header_txt"
                                                            ),
                                                          "error-messages":
                                                            _vm.localizeErrorMsg(
                                                              errors,
                                                              "solution_type"
                                                            ),
                                                          disabled:
                                                            _vm.solutionHasProject,
                                                          "return-object": "",
                                                          outlined: "",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "selection",
                                                              fn: function (
                                                                data
                                                              ) {
                                                                return [
                                                                  _c("div", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        data
                                                                          .item
                                                                          .name
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              },
                                                            },
                                                            {
                                                              key: "item",
                                                              fn: function (
                                                                data
                                                              ) {
                                                                return [
                                                                  _c("div", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        data
                                                                          .item
                                                                          .name
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.solutions.type,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.solutions,
                                                              "type",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "solutions.type",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "12" } },
                                        [
                                          !_vm.isLoading
                                            ? _c("text-editor", {
                                                class:
                                                  _vm.contentLanguage == "ja"
                                                    ? "d-none"
                                                    : "",
                                                attrs: {
                                                  editorId: "overview-editor",
                                                  isRequired: true,
                                                  label: _vm.$vuetify.lang.t(
                                                    "$vuetify.solution_overview_label_txt"
                                                  ),
                                                  placeHolder:
                                                    _vm.$vuetify.lang.t(
                                                      "$vuetify.solution_overview_label_txt"
                                                    ),
                                                  isError: _vm.overviewErr,
                                                  errorMsg: _vm.overviewErrMsg,
                                                  editorData:
                                                    _vm.solutions.overview,
                                                },
                                                on: {
                                                  setEditor:
                                                    _vm.setSolutionOverviewEditor,
                                                  setIsError:
                                                    _vm.setOverviewErr,
                                                },
                                              })
                                            : _vm._e(),
                                          !_vm.isLoading
                                            ? _c("text-editor", {
                                                class:
                                                  _vm.contentLanguage == "en"
                                                    ? "d-none"
                                                    : "",
                                                attrs: {
                                                  editorId:
                                                    "overview-ja-editor",
                                                  isRequired: false,
                                                  label: _vm.$vuetify.lang.t(
                                                    "$vuetify.solution_overview_label_txt"
                                                  ),
                                                  placeHolder:
                                                    _vm.$vuetify.lang.t(
                                                      "$vuetify.solution_overview_label_txt"
                                                    ),
                                                  isError: false,
                                                  editorData:
                                                    _vm.solutions.overview_ja,
                                                },
                                                on: {
                                                  setEditor:
                                                    _vm.setSolutionOverviewEditorJa,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "12" } },
                                        [
                                          !_vm.isLoading
                                            ? _c("text-editor", {
                                                class:
                                                  _vm.contentLanguage == "ja"
                                                    ? "d-none"
                                                    : "",
                                                attrs: {
                                                  editorId:
                                                    "description-editor",
                                                  isRequired: false,
                                                  label: _vm.$vuetify.lang.t(
                                                    "$vuetify.solution_description_label_txt"
                                                  ),
                                                  placeHolder:
                                                    _vm.$vuetify.lang.t(
                                                      "$vuetify.solution_description_placeholder_txt"
                                                    ),
                                                  isError: false,
                                                  editorData:
                                                    _vm.solutions.description,
                                                },
                                                on: {
                                                  setEditor:
                                                    _vm.setSolutionDescEditor,
                                                },
                                              })
                                            : _vm._e(),
                                          !_vm.isLoading
                                            ? _c("text-editor", {
                                                class:
                                                  _vm.contentLanguage == "en"
                                                    ? "d-none"
                                                    : "",
                                                attrs: {
                                                  editorId:
                                                    "description-ja-editor",
                                                  isRequired: false,
                                                  label: _vm.$vuetify.lang.t(
                                                    "$vuetify.solution_description_label_txt"
                                                  ),
                                                  placeHolder:
                                                    _vm.$vuetify.lang.t(
                                                      "$vuetify.solution_description_placeholder_txt"
                                                    ),
                                                  isError: false,
                                                  editorData:
                                                    _vm.solutions
                                                      .description_ja,
                                                },
                                                on: {
                                                  setEditor:
                                                    _vm.setSolutionDescEditorJa,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            xs: "12",
                                            md: "12",
                                          },
                                        },
                                        [
                                          _c("ValidationProvider", {
                                            ref: "industryRef",
                                            refInFor: true,
                                            attrs: {
                                              name: "Industries",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c("v-autocomplete", {
                                                        attrs: {
                                                          items:
                                                            _vm.allCategory,
                                                          "item-text": "name",
                                                          label:
                                                            _vm.$vuetify.lang.t(
                                                              "$vuetify.industry_label_txt"
                                                            ),
                                                          "search-input":
                                                            _vm.searchIndustry,
                                                          "error-messages":
                                                            _vm.localizeErrorMsg(
                                                              errors,
                                                              "solution_industry"
                                                            ),
                                                          multiple: "",
                                                          "small-chips": "",
                                                          "return-object": "",
                                                          outlined: "",
                                                        },
                                                        on: {
                                                          "update:searchInput":
                                                            function ($event) {
                                                              _vm.searchIndustry =
                                                                $event
                                                            },
                                                          "update:search-input":
                                                            function ($event) {
                                                              _vm.searchIndustry =
                                                                $event
                                                            },
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "append-outer",
                                                              fn: function () {
                                                                return [
                                                                  _c(
                                                                    "v-slide-x-reverse-transition",
                                                                    {
                                                                      attrs: {
                                                                        mode: "out-in",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "primary",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              textContent:
                                                                                _vm._s(
                                                                                  "mdi-plus-circle-outline"
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.createIndustry()
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                              proxy: true,
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.solutions
                                                              .industry,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.solutions,
                                                              "industry",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "solutions.industry",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "12" } },
                                        [
                                          !_vm.isLoading
                                            ? _c("text-editor", {
                                                class:
                                                  _vm.contentLanguage == "ja"
                                                    ? "d-none"
                                                    : "",
                                                attrs: {
                                                  editorId: "use-case-editor",
                                                  isRequired: true,
                                                  label: _vm.$vuetify.lang.t(
                                                    "$vuetify.use_case_label_txt"
                                                  ),
                                                  placeHolder:
                                                    _vm.$vuetify.lang.t(
                                                      "$vuetify.use_case_placeholder_txt"
                                                    ),
                                                  isError: _vm.useCaseErr,
                                                  errorMsg: _vm.useCaseErrMsg,
                                                  editorData:
                                                    _vm.solutions.use_cases,
                                                },
                                                on: {
                                                  setEditor:
                                                    _vm.setSolutionUseCaseEditor,
                                                  setIsError: _vm.setUseCaseErr,
                                                },
                                              })
                                            : _vm._e(),
                                          !_vm.isLoading
                                            ? _c("text-editor", {
                                                class:
                                                  _vm.contentLanguage == "en"
                                                    ? "d-none"
                                                    : "",
                                                attrs: {
                                                  editorId:
                                                    "use-case-ja-editor",
                                                  isRequired: false,
                                                  label: _vm.$vuetify.lang.t(
                                                    "$vuetify.use_case_label_txt"
                                                  ),
                                                  placeHolder:
                                                    _vm.$vuetify.lang.t(
                                                      "$vuetify.use_case_placeholder_txt"
                                                    ),
                                                  isError: false,
                                                  editorData:
                                                    _vm.solutions.use_cases_ja,
                                                },
                                                on: {
                                                  setEditor:
                                                    _vm.setSolutionUseCaseEditorJa,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            xs: "12",
                                            md: "12",
                                          },
                                        },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "Supported languages",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c("v-autocomplete", {
                                                        attrs: {
                                                          items:
                                                            _vm.AllLanguage,
                                                          "error-messages":
                                                            _vm.localizeErrorMsg(
                                                              errors,
                                                              "solution_language"
                                                            ),
                                                          disabled:
                                                            _vm.solutionHasProject,
                                                          "search-input":
                                                            _vm.searchLanguage,
                                                          "item-text": "name",
                                                          label:
                                                            _vm.$vuetify.lang.t(
                                                              "$vuetify.language_label_txt"
                                                            ),
                                                          multiple: "",
                                                          "small-chips": "",
                                                          "return-object": "",
                                                          outlined: "",
                                                        },
                                                        on: {
                                                          "update:searchInput":
                                                            function ($event) {
                                                              _vm.searchLanguage =
                                                                $event
                                                            },
                                                          "update:search-input":
                                                            function ($event) {
                                                              _vm.searchLanguage =
                                                                $event
                                                            },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.solutions
                                                              .language,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.solutions,
                                                              "language",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "solutions.language",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  n === 2
                    ? _c(
                        "div",
                        [
                          _c(
                            "ValidationObserver",
                            { ref: "stepTwo", refInFor: true },
                            [
                              _c(
                                "v-form",
                                { ref: "form2", refInFor: true },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "primary--text text-h6" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$vuetify.lang.t(
                                              "$vuetify.resource_header_txt"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("v-divider", { staticClass: "mb-3" }),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "Minimum CPU",
                                              rules: "required|min_value:0",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c("vuetify-money", {
                                                        attrs: {
                                                          label:
                                                            _vm.$vuetify.lang.t(
                                                              "$vuetify.cpu_label_txt"
                                                            ),
                                                          placeholder: "2",
                                                          errorMessages:
                                                            _vm.localizeErrorMsg(
                                                              errors,
                                                              "solution_cpu"
                                                            ),
                                                          disabled:
                                                            _vm.solutionHasProject,
                                                          outlined: "",
                                                          clearable: "",
                                                          "value-when-is-empty":
                                                            "",
                                                          options:
                                                            _vm.numberOptions,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.solutions
                                                              .min_cpu,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.solutions,
                                                              "min_cpu",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "solutions.min_cpu",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "Min Memory",
                                              rules: "required|min_value:0",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c("vuetify-money", {
                                                        attrs: {
                                                          label:
                                                            _vm.$vuetify.lang.t(
                                                              "$vuetify.memory_label_txt"
                                                            ),
                                                          placeholder: "4",
                                                          errorMessages:
                                                            _vm.localizeErrorMsg(
                                                              errors,
                                                              "solution_memory"
                                                            ),
                                                          disabled:
                                                            _vm.solutionHasProject,
                                                          outlined: "",
                                                          clearable: "",
                                                          "value-when-is-empty":
                                                            "",
                                                          options:
                                                            _vm.numberOptions,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.solutions
                                                              .min_memory,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.solutions,
                                                              "min_memory",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "solutions.min_memory",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "Min GPU",
                                              rules: "min_value:0",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c("vuetify-money", {
                                                        attrs: {
                                                          label:
                                                            _vm.$vuetify.lang.t(
                                                              "$vuetify.gpu_label_txt"
                                                            ),
                                                          placeholder: "8",
                                                          disabled:
                                                            _vm.solutionHasProject,
                                                          errorMessages: errors,
                                                          outlined: "",
                                                          clearable: "",
                                                          "value-when-is-empty":
                                                            "",
                                                          options:
                                                            _vm.numberOptions,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.solutions
                                                              .min_gpu,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.solutions,
                                                              "min_gpu",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "solutions.min_gpu",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "primary--text text-h6" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$vuetify.lang.t(
                                              "$vuetify.performance_header_txt"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("v-divider", { staticClass: "mb-3" }),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            xs: "12",
                                            md: "6",
                                          },
                                        },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "Benchmark request / month",
                                              rules:
                                                "required|min_value:1|max_value:2147483647",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c("vuetify-money", {
                                                        attrs: {
                                                          label:
                                                            _vm.$vuetify.lang.t(
                                                              "$vuetify.benchmark_request_label_txt"
                                                            ),
                                                          placeholder:
                                                            "25,000,000",
                                                          outlined: "",
                                                          clearable: "",
                                                          "value-when-is-empty":
                                                            "",
                                                          options:
                                                            _vm.numberOptions,
                                                          errorMessages:
                                                            _vm.localizeErrorMsg(
                                                              errors,
                                                              "solution_request"
                                                            ),
                                                          disabled:
                                                            _vm.solutionHasProject,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.solutions
                                                              .page_view,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.solutions,
                                                              "page_view",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "solutions.page_view",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            xs: "12",
                                            md: "6",
                                          },
                                        },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "Benchmark RPS",
                                              rules: "min_value:0",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c("vuetify-money", {
                                                        attrs: {
                                                          label:
                                                            _vm.$vuetify.lang.t(
                                                              "$vuetify.benchmark_rps_label_txt"
                                                            ),
                                                          placeholder: "10",
                                                          outlined: "",
                                                          clearable: "",
                                                          "value-when-is-empty":
                                                            "",
                                                          options:
                                                            _vm.numberOptions,
                                                          disabled:
                                                            _vm.solutionHasProject,
                                                          errorMessages: errors,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.solutions.rps,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.solutions,
                                                              "rps",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "solutions.rps",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "primary--text text-h6" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$vuetify.lang.t(
                                              "$vuetify.price_header_txt"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("v-divider", { staticClass: "mb-3" }),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "Solution Base Price",
                                              rules: "required|min_value:1",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c("vuetify-money", {
                                                        attrs: {
                                                          label:
                                                            _vm.$vuetify.lang.t(
                                                              "$vuetify.base_price_label_txt_1"
                                                            ),
                                                          placeholder: "3,000",
                                                          outlined: "",
                                                          clearable: "",
                                                          "value-when-is-empty":
                                                            "",
                                                          options:
                                                            _vm.numberOptions,
                                                          errorMessages:
                                                            _vm.localizeErrorMsg(
                                                              errors,
                                                              "solution_base_price"
                                                            ),
                                                          disabled:
                                                            _vm.solutionHasProject,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.solutions.price,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.solutions,
                                                              "price",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "solutions.price",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "Additional Solution Price",
                                              rules: {
                                                required:
                                                  _vm.solutions.linked_solution
                                                    .length !== 0,
                                                min_value:
                                                  _vm.solutions.linked_solution
                                                    .length !== 0
                                                    ? 1
                                                    : 0,
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c("vuetify-money", {
                                                        attrs: {
                                                          label:
                                                            _vm.$vuetify.lang.t(
                                                              "$vuetify.additional_price_label_txt"
                                                            ),
                                                          placeholder: "500",
                                                          errorMessages:
                                                            _vm.localizeErrorMsg(
                                                              errors,
                                                              "solution_additional_price"
                                                            ),
                                                          disabled:
                                                            _vm.solutionHasProject,
                                                          outlined: "",
                                                          clearable: "",
                                                          "value-when-is-empty":
                                                            "",
                                                          options:
                                                            _vm.numberOptions,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.solutions
                                                              .additional_price,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.solutions,
                                                              "additional_price",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "solutions.additional_price",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  n === 3
                    ? _c(
                        "div",
                        [
                          _c(
                            "ValidationObserver",
                            { ref: "stepThree", refInFor: true },
                            [
                              _c(
                                "v-form",
                                { ref: "form3", refInFor: true },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "Version Number",
                                              rules: "required|is_not:-1",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label:
                                                            _vm.$vuetify.lang.t(
                                                              "$vuetify.version_label_txt"
                                                            ),
                                                          placeholder: "1.0.0",
                                                          "error-messages":
                                                            _vm.localizeErrorMsg(
                                                              errors,
                                                              "solution_version",
                                                              _vm.solutions
                                                                .version
                                                            ),
                                                          outlined: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.solutions
                                                              .version,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.solutions,
                                                              "version",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "solutions.version",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "Source Code URL",
                                              rules: "required|url",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label:
                                                            _vm.$vuetify.lang.t(
                                                              "$vuetify.source_code_label_txt"
                                                            ),
                                                          placeholder:
                                                            "https://github.com/gigalogy/recommender.git",
                                                          "error-messages":
                                                            _vm.localizeErrorMsg(
                                                              errors,
                                                              "solution_sourcecode_url",
                                                              _vm.solutions
                                                                .source_url
                                                            ),
                                                          outlined: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.solutions
                                                              .source_url,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.solutions,
                                                              "source_url",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "solutions.source_url",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "Sandbox URL",
                                              rules: "url",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label:
                                                            _vm.$vuetify.lang.t(
                                                              "$vuetify.sandbox_label_txt"
                                                            ),
                                                          "error-messages":
                                                            _vm.localizeErrorMsg(
                                                              errors,
                                                              "solution_sourcecode_url",
                                                              _vm.solutions
                                                                .sanbdbox_url
                                                            ),
                                                          outlined: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.solutions
                                                              .sanbdbox_url,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.solutions,
                                                              "sanbdbox_url",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "solutions.sanbdbox_url",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "API Docs URL",
                                              rules: "url",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label:
                                                            _vm.$vuetify.lang.t(
                                                              "$vuetify.api_docs_label_txt"
                                                            ),
                                                          "error-messages":
                                                            _vm.localizeErrorMsg(
                                                              errors,
                                                              "solution_sourcecode_url",
                                                              _vm.solutions
                                                                .document_url
                                                            ),
                                                          outlined: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.solutions
                                                              .document_url,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.solutions,
                                                              "document_url",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "solutions.document_url",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "Tutorial URL",
                                              rules: "url",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label:
                                                            _vm.$vuetify.lang.t(
                                                              "$vuetify.tutorial_label_txt"
                                                            ),
                                                          "error-messages":
                                                            _vm.localizeErrorMsg(
                                                              errors,
                                                              "solution_sourcecode_url",
                                                              _vm.solutions
                                                                .tutorial_location
                                                            ),
                                                          outlined: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.solutions
                                                              .tutorial_location,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.solutions,
                                                              "tutorial_location",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "solutions.tutorial_location",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("v-divider", {
                                        attrs: {
                                          vertical:
                                            _vm.$vuetify.breakpoint.mdAndUp,
                                        },
                                      }),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { "no-gutters": "" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "12",
                                                  },
                                                },
                                                [
                                                  _c("v-file-input", {
                                                    attrs: {
                                                      "show-size": "",
                                                      label:
                                                        _vm.$vuetify.lang.t(
                                                          "$vuetify.select_img_label_txt"
                                                        ),
                                                      accept: "image/*",
                                                      outlined: "",
                                                    },
                                                    on: {
                                                      change: _vm.selectImage,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _vm.previewImage
                                                ? _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _vm.progress
                                                        ? _c("div", [
                                                            _c(
                                                              "div",
                                                              [
                                                                _c(
                                                                  "v-progress-linear",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                      height:
                                                                        "25",
                                                                      reactive:
                                                                        "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.progress,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.progress =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "progress",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "strong",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.progress
                                                                          ) +
                                                                            " %"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                      _c(
                                                        "div",
                                                        [
                                                          _c("v-img", {
                                                            staticClass:
                                                              "preview my-3",
                                                            attrs: {
                                                              "aspect-ratio":
                                                                "2",
                                                              contain: "",
                                                              src: _vm.previewImage,
                                                              alt: "",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              !_vm.previewImage
                                                ? _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "mx-auto",
                                                        },
                                                        [
                                                          _c(
                                                            "v-menu",
                                                            {
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                        attrs,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-img",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  staticClass:
                                                                                    "d-block lang-menu-activator ma-auto",
                                                                                  attrs:
                                                                                    {
                                                                                      "aspect-ratio":
                                                                                        "2",
                                                                                      contain:
                                                                                        "",
                                                                                      src: _vm.getIcon(),
                                                                                    },
                                                                                },
                                                                                "v-img",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            )
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _c(
                                                                "v-list",
                                                                _vm._l(
                                                                  _vm.imageParams,
                                                                  function (
                                                                    img,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "v-list-item",
                                                                      {
                                                                        key: index,
                                                                        staticClass:
                                                                          "img-menu-item",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.setImg(
                                                                                img.value
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "d-flex align-center",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-img",
                                                                              {
                                                                                staticClass:
                                                                                  "mr-2 d-block",
                                                                                attrs:
                                                                                  {
                                                                                    "aspect-ratio":
                                                                                      "2",
                                                                                    contain:
                                                                                      "",
                                                                                    src: img.img,
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    img.text
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-center mt-4 mr-4",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Select Cover Image"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  n === 4
                    ? _c(
                        "div",
                        [
                          _c("Review", {
                            attrs: {
                              solutions: _vm.solutions,
                              solutionImg: _vm.previewImage
                                ? _vm.previewImage
                                : _vm.getIcon(),
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("br"),
                  _c(
                    "v-row",
                    { attrs: { align: "center", justify: "start" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          n !== 1
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mr-4",
                                  attrs: {
                                    color: "primary",
                                    large: "",
                                    outlined: "",
                                    loading: false,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.previousStep(n)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.prev_btn_txt"
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                          n === 1
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mr-4",
                                  attrs: {
                                    color: "error",
                                    large: "",
                                    outlined: "",
                                    loading: false,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goBack()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.cancel_btn_txt"
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                          n === 4 &&
                          _vm.solutions.status &&
                          _vm.solutions.status === "published"
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    color: "primary",
                                    large: "",
                                    loading: _vm.isSaving,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.compareImpactData(
                                        _vm.solutions.status
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.save_btn_txt"
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                          n === 4 && _vm.solutions.status !== "published"
                            ? _c("v-overflow-btn", {
                                staticClass: "mr-4 d-inline-block",
                                style: _vm.isLoading
                                  ? "top: -10px; width: 232px;"
                                  : "",
                                attrs: {
                                  "background-color": "primary",
                                  color: "primary",
                                  items: _vm.saveOptions,
                                  label: _vm.isLoading
                                    ? ""
                                    : _vm.$vuetify.lang.t(
                                        "$vuetify.submit_review_btn_txt"
                                      ),
                                  placeholder: _vm.isLoading
                                    ? ""
                                    : _vm.$vuetify.lang.t(
                                        "$vuetify.submit_review_btn_txt"
                                      ),
                                  value: _vm.isLoading ? "" : "pending",
                                  segmented: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prepend-inner",
                                      fn: function () {
                                        return [
                                          _vm.isLoading
                                            ? _c("v-progress-circular", {
                                                staticStyle: {
                                                  transform:
                                                    "translate(36px, 4px)",
                                                },
                                                attrs: {
                                                  color: "#FFFFFF",
                                                  size: "26",
                                                  indeterminate: "",
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              })
                            : _vm._e(),
                          n < 4
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mr-4",
                                  attrs: {
                                    color: "primary",
                                    large: "",
                                    loading: false,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.nextStep(n)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.continue_btn_txt"
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "600" },
          model: {
            value: _vm.createIndustryDialog,
            callback: function ($$v) {
              _vm.createIndustryDialog = $$v
            },
            expression: "createIndustryDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline primary white--text",
                  attrs: { "primary-title": "" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$vuetify.lang.t(
                          "$vuetify.create_industry_header_txt"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "pa-5" },
                [
                  _c(
                    "v-form",
                    { ref: "categoryForm" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          type: "text",
                          label: _vm.$vuetify.lang.t(
                            "$vuetify.industry_name_label_txt"
                          ),
                          outlined: "",
                          counter: "80",
                          maxlength: "80",
                          rules: [
                            _vm.alphaSpaceHyphen(
                              _vm.$vuetify.lang.t(
                                "$vuetify.industry_name_label_txt"
                              )
                            ),
                            _vm.required(
                              _vm.$vuetify.lang.t(
                                "$vuetify.industry_name_label_txt"
                              )
                            ),
                          ],
                        },
                        model: {
                          value: _vm.industryName,
                          callback: function ($$v) {
                            _vm.industryName = $$v
                          },
                          expression: "industryName",
                        },
                      }),
                      _c("v-text-field", {
                        attrs: {
                          type: "text",
                          label: _vm.$vuetify.lang.t(
                            "$vuetify.industry_code_label_txt"
                          ),
                          rules: [
                            _vm.lowercase(
                              _vm.$vuetify.lang.t(
                                "$vuetify.industry_code_label_txt"
                              )
                            ),
                            _vm.alphaSpace(
                              _vm.$vuetify.lang.t(
                                "$vuetify.industry_code_label_txt"
                              )
                            ),
                            _vm.required(
                              _vm.$vuetify.lang.t(
                                "$vuetify.industry_code_label_txt"
                              )
                            ),
                          ],
                          counter: "80",
                          maxlength: "80",
                          outlined: "",
                        },
                        model: {
                          value: _vm.industryCode,
                          callback: function ($$v) {
                            _vm.industryCode = $$v
                          },
                          expression: "industryCode",
                        },
                      }),
                      _c("v-textarea", {
                        attrs: {
                          label: _vm.$vuetify.lang.t(
                            "$vuetify.description_label_txt"
                          ),
                          outlined: "",
                          rules: [
                            _vm.required(
                              _vm.$vuetify.lang.t(
                                "$vuetify.description_label_txt"
                              )
                            ),
                          ],
                        },
                        model: {
                          value: _vm.industryDescription,
                          callback: function ($$v) {
                            _vm.industryDescription = $$v
                          },
                          expression: "industryDescription",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c("Button", {
                    staticClass: "mr-2",
                    attrs: {
                      buttonType: _vm.TEXT_BUTTON,
                      buttonText: _vm.$vuetify.lang.t("$vuetify.close_btn_txt"),
                      color: "error",
                    },
                    on: {
                      onClick: function ($event) {
                        _vm.createIndustryDialog = false
                      },
                    },
                  }),
                  _c("Button", {
                    attrs: {
                      buttonType: _vm.RAISED_BUTTON,
                      buttonText: _vm.$vuetify.lang.t("$vuetify.save_btn_txt"),
                      color: "primary",
                      loading: _vm.isCreatingInd,
                    },
                    on: {
                      onClick: function ($event) {
                        return _vm.saveIndustry()
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("DialogModal", {
        attrs: {
          title: _vm.$vuetify.lang.t("$vuetify.error_title_txt"),
          message: _vm.alertMessage,
          confirmButtonText: _vm.$vuetify.lang.t("$vuetify.ok_btn_txt"),
          cancelButton: false,
        },
        on: {
          onConfirmation: function ($event) {
            _vm.alertDialog = false
          },
        },
        model: {
          value: _vm.alertDialog,
          callback: function ($$v) {
            _vm.alertDialog = $$v
          },
          expression: "alertDialog",
        },
      }),
      _c("DialogModal", {
        attrs: {
          title: _vm.$vuetify.lang.t("$vuetify.warning_title_txt"),
          message: _vm.alertMessage,
          confirmButtonText: _vm.$vuetify.lang.t("$vuetify.yes_btn_txt"),
          cancelButtonText: _vm.$vuetify.lang.t("$vuetify.no_btn_txt"),
          headlineClass: "headline warning white--text",
          persistent: true,
        },
        on: {
          onCancel: function ($event) {
            return _vm.cancelDialog()
          },
          onConfirmation: function ($event) {
            return _vm.resetMonthlyPV()
          },
        },
        model: {
          value: _vm.warningDialog,
          callback: function ($$v) {
            _vm.warningDialog = $$v
          },
          expression: "warningDialog",
        },
      }),
      _c("DialogModal", {
        attrs: {
          title: _vm.$vuetify.lang.t("$vuetify.confirm_alert_title_txt"),
          message: _vm.$vuetify.lang.t("$vuetify.confirm_alert_msg_txt_2"),
          confirmButtonText: _vm.$vuetify.lang.t(
            "$vuetify.submit_review_btn_txt"
          ),
          headlineClass: "headline red white--text",
          persistent: true,
        },
        on: {
          onCancel: function ($event) {
            return _vm.onCancel()
          },
          onConfirmation: function ($event) {
            return _vm.saveSolutionWithImg(_vm.solutions.status)
          },
        },
        model: {
          value: _vm.confirmDialog,
          callback: function ($$v) {
            _vm.confirmDialog = $$v
          },
          expression: "confirmDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }