var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pa-0", attrs: { xs: "12", md: "12" } },
            [
              _c(
                "v-card",
                { staticClass: "mx-auto px-2", attrs: { outlined: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { xs: "12", md: "6" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "px-2 primary--text font-weight-bold",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$vuetify.lang.t(
                                    "$vuetify.gpt_profile.profiles"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { xs: "12", md: "6" } },
                        [
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "float-right",
                                attrs: { color: "primary", dark: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.openCreateProfileModal = true
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.gpt_profile.create_profile"
                                      )
                                    ) +
                                    " "
                                ),
                                _c(
                                  "v-icon",
                                  { staticClass: "ml-2", attrs: { right: "" } },
                                  [_vm._v("add_circle_outline")]
                                ),
                              ],
                              1
                            ),
                          ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c("v-row"),
                  _c("v-divider"),
                  _vm.isLoading
                    ? _c("Loader", { staticClass: "mr-2 mt-6" })
                    : _c("v-data-table", {
                        attrs: {
                          headers: _vm.headers,
                          items: _vm.gptProfiles.profiles,
                          search: _vm.search,
                          page: _vm.page,
                          "items-per-page": _vm.itemsPerPage,
                          "hide-default-footer": "",
                        },
                        on: {
                          "update:page": function ($event) {
                            _vm.page = $event
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: `item.id`,
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "span",
                                                  _vm._g(
                                                    _vm._b(
                                                      {},
                                                      "span",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.id.split("-")[0]
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [_c("span", [_vm._v(_vm._s(item.id))])]
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.name`,
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "primary--text text--darken-3",
                                          attrs: { text: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onDetailsClick(item)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.created_at`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.created_at
                                          ? new Date(
                                              item.created_at
                                            ).toLocaleDateString("en-us", {
                                              year: "numeric",
                                              month: "short",
                                              day: "numeric",
                                            })
                                          : "N/A"
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.actions`,
                              fn: function ({ item }) {
                                return [
                                  _c("ButtonGroup", {
                                    attrs: {
                                      groupType: _vm.ACTION_BUTTON_GROUP,
                                      addButton: false,
                                      stopButton: false,
                                      startButton: false,
                                    },
                                    on: {
                                      onDeleteClick: function ($event) {
                                        return _vm.deleteItem(item)
                                      },
                                      onEditClick: function ($event) {
                                        return _vm.onEditClick(item)
                                      },
                                      onDetailsClick: function ($event) {
                                        return _vm.onDetailsClick(item)
                                      },
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0",
                          attrs: { cols: "12", md: "12" },
                        },
                        [_c("v-divider")],
                        1
                      ),
                      _c("v-col", {
                        attrs: { cols: "12", xs: "12", sm: "3", md: "3" },
                      }),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", xs: "12", sm: "6", md: "6" } },
                        [
                          _c("v-pagination", {
                            staticClass: "text-center",
                            attrs: { length: _vm.pageCount },
                            model: {
                              value: _vm.page,
                              callback: function ($$v) {
                                _vm.page = $$v
                              },
                              expression: "page",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            "offset-sm": "1",
                            "offset-md": "1",
                            xs: "3",
                            sm: "2",
                            md: "2",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.rows,
                              "hide-details": "auto",
                              outlined: "",
                              dense: "",
                            },
                            model: {
                              value: _vm.itemsPerPage,
                              callback: function ($$v) {
                                _vm.itemsPerPage = $$v
                              },
                              expression: "itemsPerPage",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("DialogModal", {
        attrs: {
          title: _vm.$vuetify.lang.t("$vuetify.confirm_alert_title_txt"),
          message: _vm.$vuetify.lang.t("$vuetify.confirm_alert_msg_txt_6"),
          confirmButtonText: _vm.$vuetify.lang.t("$vuetify.confirm_btn_txt"),
          cancelButtonText: _vm.$vuetify.lang.t("$vuetify.cancel_btn_txt"),
        },
        on: {
          onConfirmation: function ($event) {
            return _vm.deleteProfile()
          },
          onCancel: function ($event) {
            _vm.deleteDialog = false
          },
        },
        model: {
          value: _vm.deleteDialog,
          callback: function ($$v) {
            _vm.deleteDialog = $$v
          },
          expression: "deleteDialog",
        },
      }),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "100%" },
          model: {
            value: _vm.openCreateProfileModal,
            callback: function ($$v) {
              _vm.openCreateProfileModal = $$v
            },
            expression: "openCreateProfileModal",
          },
        },
        [
          _c(
            "v-card",
            {
              staticClass: "d-flex flex-column",
              staticStyle: { "overflow-y": "scroll", "overflow-x": "hidden" },
            },
            [
              _vm.profileEdit && _vm.profileLoading
                ? _c("Loader", { staticClass: "mr-2 mt-6" })
                : _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "font-weight-bold" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.profileEdit
                                      ? _vm.$vuetify.lang.t(
                                          "$vuetify.gpt_profile.update_profile"
                                        )
                                      : _vm.$vuetify.lang.t(
                                          "$vuetify.gpt_profile.create_profile"
                                        )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [_c("CreateGptProfile", { ref: "profile" })],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-card-actions",
                                { staticClass: "justify-end" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "error",
                                        outlined: "",
                                        text: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.closeProfileModal()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.common.close"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary", dark: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.profileEdit
                                            ? _vm.updateProfile()
                                            : _vm.createProfile()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.profileEdit
                                              ? _vm.$vuetify.lang.t(
                                                  "$vuetify.project.update"
                                                )
                                              : _vm.$vuetify.lang.t(
                                                  "$vuetify.create_action_txt"
                                                )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "100%" },
          model: {
            value: _vm.openProfileViewModal,
            callback: function ($$v) {
              _vm.openProfileViewModal = $$v
            },
            expression: "openProfileViewModal",
          },
        },
        [
          _c(
            "v-card",
            {
              staticClass: "d-flex flex-column",
              staticStyle: { "overflow-y": "scroll", "overflow-x": "hidden" },
              attrs: { outlined: "" },
            },
            [
              _vm.viewProfile && _vm.profileLoading
                ? _c("Loader", { staticClass: "mr-2 mt-6" })
                : _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "font-weight-bold" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$vuetify.lang.t(
                                      "$vuetify.gpt_profile.profile_information"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "font-weight-bold" },
                                [_vm._v(" ID ")]
                              ),
                              _c(
                                "v-list-item-content",
                                {
                                  staticClass:
                                    "d-flex justify-space-between align-center",
                                },
                                [
                                  _c(
                                    "span",
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.selectedGptProfile.id) +
                                          " "
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "hover-blue",
                                                          attrs: {
                                                            icon: "",
                                                            small: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.copyToClipboard(
                                                                _vm
                                                                  .selectedGptProfile
                                                                  .id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-content-copy"
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [_c("span", [_vm._v("Copy")])]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "font-weight-bold" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.name_header_txt"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-list-item-content",
                                { staticClass: "align-end" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.selectedGptProfile.profileName
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "font-weight-bold" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.gpt_profile.introduction"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-list-item-content",
                                { staticClass: "align-end" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.selectedGptProfile.intro) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "font-weight-bold" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.gpt_history_system"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-list-item-content",
                                { staticClass: "align-end" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.selectedGptProfile.system) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "font-weight-bold" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.gpt_history_model"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-list-item-content",
                                { staticClass: "align-end" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.selectedGptProfile.model) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "font-weight-bold" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.gpt_history_temperature"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-list-item-content",
                                { staticClass: "align-end" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.selectedGptProfile.temperature
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "font-weight-bold" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.gpt_profile.top_p"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-list-item-content",
                                { staticClass: "align-end" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.selectedGptProfile.top_p) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "font-weight-bold" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.gpt_profile.search_max_token"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-list-item-content",
                                { staticClass: "align-end" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.selectedGptProfile.searchMaxToken
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "font-weight-bold" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.gpt_history_completion_tokens"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-list-item-content",
                                { staticClass: "align-end" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.selectedGptProfile.completionToken
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "font-weight-bold" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.gpt_history_frequency_penalty"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-list-item-content",
                                { staticClass: "align-end" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.selectedGptProfile.frequencyPenalty
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.selectedGptProfile.model ===
                          "gpt-4-vision-preview"
                            ? _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.gpt_history_vision_settings"
                                      )
                                    ) +
                                    ": "
                                ),
                              ])
                            : _vm._e(),
                          _vm.selectedGptProfile.model ===
                          "gpt-4-vision-preview"
                            ? _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "font-weight-bold" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$vuetify.lang.t(
                                              "$vuetify.gpt_history_resolution"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "align-end" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.selectedGptProfile
                                              .visionSettings &&
                                              _vm.selectedGptProfile
                                                .visionSettings.resolution
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.selectedGptProfile.model ===
                          "gpt-4-vision-preview"
                            ? _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "font-weight-bold" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$vuetify.lang.t(
                                              "$vuetify.gpt_history_image_context_enabled"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "align-end" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.selectedGptProfile
                                              .visionSettings &&
                                              _vm.selectedGptProfile
                                                .visionSettings
                                                .imageContextEnabled
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-card-actions",
                                { staticClass: "justify-end" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "error",
                                        outlined: "",
                                        text: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.closeProfileDetailModal()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.common.close"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }