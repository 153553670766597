var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      staticClass: "mb-5",
      attrs: { "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "div",
                _vm._g({ staticClass: "d-flex align-center" }, on),
                [
                  _c(
                    "v-btn",
                    { staticClass: "px-4 py-5", attrs: { text: "" } },
                    [
                      _c("v-img", {
                        attrs: {
                          "lazy-src": _vm.localeImg,
                          "max-width": "20",
                          src: _vm.localeImg,
                        },
                      }),
                      _c("v-icon", { staticClass: "white--text px-2" }, [
                        _vm._v("expand_more"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-list",
        _vm._l(_vm.localeMenuItems, function (item) {
          return _c(
            "v-list-item",
            {
              key: item.text,
              staticStyle: { cursor: "pointer" },
              attrs: { dense: "" },
            },
            [
              _c(
                "v-list-item-title",
                {
                  on: {
                    click: function ($event) {
                      return _vm.changeLocale(item.text)
                    },
                  },
                },
                [_vm._v(_vm._s(item.text))]
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }