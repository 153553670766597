var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { key: _vm.locale },
    [_c("Snackbar"), _c("router-view", { key: this.$route.path })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }