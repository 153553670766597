var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c(
        "v-container",
        { attrs: { "fill-height": "", fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { align: "center", justify: "center" } },
            [
              _c(
                "v-col",
                {
                  attrs: {
                    "align-self": "center",
                    sm: "8",
                    md: "6",
                    lg: "4",
                    xl: "4",
                  },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "elevation-2 mx-auto pa-4",
                      attrs: { outlined: "" },
                    },
                    [
                      _c("Logo"),
                      _c("v-card-title", { staticClass: "font-weight-bold" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$vuetify.lang.t(
                                "$vuetify.forgot_password_msg_txt_1"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("v-card-subtitle", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$vuetify.lang.t(
                                "$vuetify.forgot_password_msg_txt_2"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-form",
                            { ref: "emailForm" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: _vm.$vuetify.lang.t(
                                    "$vuetify.email_label_txt"
                                  ),
                                  type: "email",
                                  placeholder: "adam@gigalogy.com",
                                  "prepend-inner-icon": "email",
                                  outlined: "",
                                  rules: [
                                    _vm.required(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.email_label_txt"
                                      )
                                    ),
                                    _vm.email(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.email_label_txt"
                                      )
                                    ),
                                  ],
                                },
                                model: {
                                  value: _vm.emailForm.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.emailForm, "email", $$v)
                                  },
                                  expression: "emailForm.email",
                                },
                              }),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    large: "",
                                    block: "",
                                    loading: _vm.loading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleSubmitClick()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.reset_btn_txt"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("v-divider", { staticClass: "my-5" }),
                          _c(
                            "router-link",
                            {
                              staticClass: "text-decoration-none",
                              attrs: { to: "/login" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$vuetify.lang.t(
                                      "$vuetify.login_link_txt"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }