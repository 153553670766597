var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: { right: "", color: "transparent" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "div",
                _vm._b(
                  { staticClass: "text-center d-inline" },
                  "div",
                  attrs,
                  false
                ),
                [
                  _c(
                    "v-chip",
                    _vm._g(
                      {
                        staticClass: "ma-2",
                        attrs: {
                          color: _vm.chipColor,
                          pill: true,
                          "x-small": "",
                        },
                      },
                      on
                    ),
                    [
                      _c("span", { staticClass: "text-capitalize" }, [
                        _vm._v(_vm._s(_vm.chipText)),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c("span", { staticClass: "chathead-shape" }, [
        _vm._v(_vm._s(_vm.toolTipText)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }