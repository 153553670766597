var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-progress-linear", {
    class: {
      singlebar: _vm.$vuetify.breakpoint.smAndDown,
      doublebar: _vm.$vuetify.breakpoint.mdAndUp,
    },
    attrs: {
      active: _vm.loading,
      height: 4,
      indeterminate: true,
      fixed: "",
      color: "primary lighten-2",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }