const reCaptchaPlugin =  {
  install(Vue, options) {
    const recaptchaSiteKey = options.siteKey;
    const scriptId = "recaptcha-script";

    // Load reCaptcha script only once
    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script');
      script.setAttribute("src", `https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`);
      script.setAttribute("async", true);
      script.setAttribute("defer", true);
      script.setAttribute("id", scriptId);
      document.head.appendChild(script);
      // console.log("captcha script loaded")
    }

    // Add function to Vue prototype so all components can access it
    Vue.prototype.$recaptcha = {
      execute(action) {
        return new Promise((resolve, reject) => {
          if (!window.grecaptcha) {
            return reject('reCaptcha not yet loaded');
          }
          window.grecaptcha.ready(() => {
            window.grecaptcha.execute(recaptchaSiteKey, { action }).then(resolve, reject);
            // console.log("grecaptcha recaptcha loaded");
          });
        });
      }
    };
  }
};

export { reCaptchaPlugin };