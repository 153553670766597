var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c(
        "v-container",
        { attrs: { "fill-height": "", fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { align: "center", justify: "center" } },
            [
              _c(
                "v-col",
                {
                  attrs: {
                    "align-self": "center",
                    sm: "8",
                    md: "6",
                    lg: "4",
                    xl: "4",
                  },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "elevation-2 mx-auto pa-4",
                      attrs: { outlined: "" },
                    },
                    [
                      _c("Logo"),
                      _c("v-card-title", { staticClass: "font-weight-bold" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$vuetify.lang.t(
                                "$vuetify.register_header_txt"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-form",
                            { ref: "registerForm" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: _vm.$vuetify.lang.t(
                                    "$vuetify.account_name_label_txt"
                                  ),
                                  type: "text",
                                  placeholder: "Gigalogy",
                                  "prepend-inner-icon": "mdi-rename-box",
                                  outlined: "",
                                  rules: [
                                    _vm.required(
                                      this.$vuetify.lang.t(
                                        "$vuetify.account_name_label_txt"
                                      )
                                    ),
                                    _vm.minLength(
                                      this.$vuetify.lang.t(
                                        "$vuetify.account_name_label_txt"
                                      ),
                                      3
                                    ),
                                  ],
                                },
                                model: {
                                  value: _vm.registerForm.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.registerForm, "name", $$v)
                                  },
                                  expression: "registerForm.name",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  label: _vm.$vuetify.lang.t(
                                    "$vuetify.email_label_txt"
                                  ),
                                  type: "email",
                                  placeholder: "admin@gigalogy.com",
                                  "prepend-inner-icon": "email",
                                  outlined: "",
                                  rules: [
                                    _vm.required(
                                      this.$vuetify.lang.t(
                                        "$vuetify.email_label_txt"
                                      )
                                    ),
                                    _vm.email(
                                      this.$vuetify.lang.t(
                                        "$vuetify.email_label_txt"
                                      )
                                    ),
                                  ],
                                },
                                model: {
                                  value: _vm.registerForm.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.registerForm, "email", $$v)
                                  },
                                  expression: "registerForm.email",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  label: _vm.$vuetify.lang.t(
                                    "$vuetify.password_label_txt"
                                  ),
                                  "prepend-inner-icon": "lock",
                                  placeholder: _vm.$vuetify.lang.t(
                                    "$vuetify.password_label_txt"
                                  ),
                                  outlined: "",
                                  "append-icon": _vm.showPassword
                                    ? "mdi-eye"
                                    : "mdi-eye-off",
                                  type: _vm.showPassword ? "text" : "password",
                                  rules: [
                                    _vm.required(
                                      this.$vuetify.lang.t(
                                        "$vuetify.password_label_txt"
                                      )
                                    ),
                                    _vm.password(
                                      this.$vuetify.lang.t(
                                        "$vuetify.password_label_txt"
                                      )
                                    ),
                                    _vm.passwordWithEmail(
                                      this.$vuetify.lang.t(
                                        "$vuetify.password_label_txt"
                                      ),
                                      _vm.registerForm.email
                                    ),
                                    _vm.passwordWithName(
                                      this.$vuetify.lang.t(
                                        "$vuetify.password_label_txt"
                                      ),
                                      _vm.registerForm.name,
                                      this.$vuetify.lang.t(
                                        "$vuetify.name_header_txt"
                                      )
                                    ),
                                  ],
                                  disabled:
                                    _vm.registerForm.name.length > 2 &&
                                    _vm.registerForm.email
                                      ? false
                                      : true,
                                },
                                on: {
                                  "click:append": function ($event) {
                                    _vm.showPassword = !_vm.showPassword
                                  },
                                },
                                model: {
                                  value: _vm.registerForm.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.registerForm, "password", $$v)
                                  },
                                  expression: "registerForm.password",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  label: _vm.$vuetify.lang.t(
                                    "$vuetify.enter_password_label_txt"
                                  ),
                                  "prepend-inner-icon": "lock",
                                  placeholder: _vm.$vuetify.lang.t(
                                    "$vuetify.enter_password_label_txt"
                                  ),
                                  outlined: "",
                                  "append-icon": _vm.showRePassword
                                    ? "mdi-eye"
                                    : "mdi-eye-off",
                                  type: _vm.showRePassword
                                    ? "text"
                                    : "password",
                                  rules: [
                                    _vm.required(
                                      this.$vuetify.lang.t(
                                        "$vuetify.enter_password_label_txt"
                                      )
                                    ),
                                    _vm.match(_vm.registerForm.password),
                                  ],
                                  disabled: _vm.registerForm.password
                                    ? false
                                    : true,
                                },
                                on: {
                                  "click:append": function ($event) {
                                    _vm.showRePassword = !_vm.showRePassword
                                  },
                                },
                                model: {
                                  value: _vm.registerForm.repassword,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.registerForm,
                                      "repassword",
                                      $$v
                                    )
                                  },
                                  expression: "registerForm.repassword",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "font-weight-medium pb-2" },
                                [
                                  _c("v-checkbox", {
                                    attrs: { rules: [_vm.required("")] },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$vuetify.lang.t(
                                                        "$vuetify.privacy_policy_txt_1"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { top: "" },
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "activator",
                                                        fn: function ({ on }) {
                                                          return [
                                                            _c(
                                                              "a",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "text-decoration-none",
                                                                  attrs: {
                                                                    target:
                                                                      "_blank",
                                                                    href: "https://gigalogy.com/privacy-policy/",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                      },
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$vuetify.lang.t(
                                                                        "$vuetify.privacy_policy_txt_2"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ]),
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$vuetify.lang.t(
                                                            "$vuetify.tooltip.open_window"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" , "),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { top: "" },
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "activator",
                                                        fn: function ({ on }) {
                                                          return [
                                                            _c(
                                                              "a",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "text-decoration-none",
                                                                  attrs: {
                                                                    target:
                                                                      "_blank",
                                                                    href: "https://www.gigalogy.com/terms-and-conditions/",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                      },
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$vuetify.lang.t(
                                                                        "$vuetify.privacy_policy_txt_3"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ]),
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$vuetify.lang.t(
                                                            "$vuetify.tooltip.open_window"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.registerForm.terms_agree,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.registerForm,
                                          "terms_agree",
                                          $$v
                                        )
                                      },
                                      expression: "registerForm.terms_agree",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    large: "",
                                    block: "",
                                    loading: _vm.loading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRegisterSubmitClick()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.continue_btn_txt"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("v-divider", { staticClass: "my-5" }),
                          _c(
                            "div",
                            { staticClass: "font-weight-medium pb-5" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$vuetify.lang.t(
                                      "$vuetify.account_exist_msg_txt"
                                    )
                                  ) +
                                  " "
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "text-decoration-none",
                                  attrs: { to: "/login" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.login_btn_txt"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }