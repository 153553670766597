var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "mx-auto px-2", attrs: { outlined: "" } },
    [
      [
        _c(
          "v-row",
          [
            _c("v-col", { attrs: { xs: "6", md: "6", sm: "6" } }, [
              _c(
                "div",
                { staticClass: "px-2 primary--text font-weight-bold" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$vuetify.lang.t(
                          "$vuetify.logs.questionnaire_logs_title"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
            _c(
              "v-col",
              { attrs: { md: "6" } },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "float-right",
                    attrs: { color: "primary", dark: "", large: "" },
                    on: { click: _vm.downloadCSV },
                  },
                  [
                    _c("span", { staticClass: "v-btn__content" }, [
                      _c("i", {
                        staticClass:
                          "v-icon notranslate mr-1 mdi mdi-download theme--light",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$vuetify.lang.t("$vuetify.common.download")
                          ) +
                          " "
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("v-divider"),
        _c(
          "v-row",
          { staticClass: "d-flex flex-column flex-md-row pa-4 mb-n6" },
          [
            _c(
              "v-col",
              { staticClass: "pa-0 pr-0 pr-md-3", attrs: { width: "" } },
              [
                _c("v-text-field", {
                  attrs: {
                    label: _vm.$vuetify.lang.t(
                      "$vuetify.filter.questionnaire_id"
                    ),
                    outlined: "",
                    dense: "",
                    clearable: "",
                  },
                  model: {
                    value: _vm.questionnaireFilter,
                    callback: function ($$v) {
                      _vm.questionnaireFilter = $$v
                    },
                    expression: "questionnaireFilter",
                  },
                }),
              ],
              1
            ),
            _c(
              "v-col",
              { staticClass: "pa-0 pr-0 pr-md-3", attrs: { width: "" } },
              [
                _c("v-text-field", {
                  attrs: {
                    label: _vm.$vuetify.lang.t("$vuetify.filter.user_id"),
                    outlined: "",
                    dense: "",
                    clearable: "",
                  },
                  model: {
                    value: _vm.userFilter,
                    callback: function ($$v) {
                      _vm.userFilter = $$v
                    },
                    expression: "userFilter",
                  },
                }),
              ],
              1
            ),
            _c(
              "v-col",
              { staticClass: "pa-0 pr-0 pr-md-3" },
              [
                _c("v-text-field", {
                  attrs: {
                    label: _vm.$vuetify.lang.t("$vuetify.filter.search_query"),
                    outlined: "",
                    dense: "",
                    clearable: "",
                  },
                  model: {
                    value: _vm.searchFilter,
                    callback: function ($$v) {
                      _vm.searchFilter = $$v
                    },
                    expression: "searchFilter",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("v-divider"),
        _c("v-data-table", {
          attrs: {
            headers: _vm.questionnairesLogsHeaders,
            items: _vm.questionnaires_logs,
            loading: _vm.isLoading,
            "loading-text": "Loading... Please wait",
            "server-items-length": _vm.totalHits,
            "items-per-page": _vm.itemsPerPage,
            page: _vm.page,
            "footer-props": {
              showFirstLastPage: true,
            },
          },
          on: {
            "update:page": function ($event) {
              _vm.page = $event
            },
            "update:items-per-page": function ($event) {
              _vm.itemsPerPage = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "item.questionnaire_id",
              fn: function ({ item }) {
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "span",
                                  _vm._g(_vm._b({}, "span", attrs, false), on),
                                  [
                                    _vm._v(
                                      _vm._s(
                                        item.questionnaire_id
                                          ? item.questionnaire_id.split("-")[0]
                                          : ""
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(item.questionnaire_id || "N/A")),
                      ]),
                    ]
                  ),
                ]
              },
            },
            {
              key: "item.user_id",
              fn: function ({ item }) {
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "span",
                                  _vm._g(_vm._b({}, "span", attrs, false), on),
                                  [
                                    _vm._v(
                                      _vm._s(
                                        item.user_id
                                          ? item.user_id.split("-")[0]
                                          : ""
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [_c("span", [_vm._v(_vm._s(item.user_id || "N/A"))])]
                  ),
                ]
              },
            },
            {
              key: "item.member_id",
              fn: function ({ item }) {
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "span",
                                  _vm._g(_vm._b({}, "span", attrs, false), on),
                                  [
                                    _vm._v(
                                      _vm._s(
                                        item.member_id
                                          ? item.member_id.split("-")[0]
                                          : ""
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [_c("span", [_vm._v(_vm._s(item.member_id || "N/A"))])]
                  ),
                ]
              },
            },
            {
              key: "item.request_id",
              fn: function ({ item }) {
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "span",
                                  _vm._g(_vm._b({}, "span", attrs, false), on),
                                  [
                                    _vm._v(
                                      _vm._s(
                                        item.request_id
                                          ? item.request_id.split("-")[0]
                                          : ""
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [_c("span", [_vm._v(_vm._s(item.request_id || "N/A"))])]
                  ),
                ]
              },
            },
            {
              key: "item.created_at",
              fn: function ({ item }) {
                return [
                  _vm._v(" " + _vm._s(_vm.formatDate(item.created_at)) + " "),
                ]
              },
            },
            {
              key: "item.prompt",
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        item.prompt
                          ? item.prompt.length > 50
                            ? item.prompt.substring(0, 50) + "..."
                            : item.prompt
                          : "N/A"
                      ) +
                      " "
                  ),
                ]
              },
            },
            {
              key: "item.response",
              fn: function ({ item }) {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c(
                        "v-icon",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mr-1",
                              on: {
                                click: function ($event) {
                                  return _vm.detail_response_modal(item)
                                },
                              },
                            },
                            "v-icon",
                            _vm.attrs,
                            false
                          ),
                          _vm.on
                        ),
                        [_vm._v("mdi-eye")]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        }),
        _vm.detailModal
          ? [
              _c(
                "v-dialog",
                {
                  model: {
                    value: _vm.detailModal,
                    callback: function ($$v) {
                      _vm.detailModal = $$v
                    },
                    expression: "detailModal",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [
                        _c("span", { staticClass: "headline" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t(
                                  "$vuetify.questionnaire.questionnaire_response_title"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c(
                        "v-card-text",
                        {},
                        [
                          _c("h2", { staticClass: "font-weight-black mb-4" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$vuetify.lang.t(
                                  "$vuetify.questionnaire.survey_values"
                                )
                              )
                            ),
                          ]),
                          _c(
                            "v-row",
                            [
                              !Object.keys(
                                _vm.detailResponseModalData.survey_values || {}
                              ).length
                                ? _c("v-col", { attrs: { cols: "12" } }, [
                                    _c("span", [_vm._v("N/A")]),
                                  ])
                                : _vm._l(
                                    _vm.detailResponseModalData.survey_values ||
                                      {},
                                    function (value, key) {
                                      return _c(
                                        "v-col",
                                        { key: key, attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-expansion-panels",
                                            [
                                              _c(
                                                "v-expansion-panel",
                                                [
                                                  _c(
                                                    "v-expansion-panel-header",
                                                    [_vm._v(_vm._s(key))]
                                                  ),
                                                  _c(
                                                    "v-expansion-panel-content",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        _vm._l(
                                                          value,
                                                          function (
                                                            val,
                                                            subkey
                                                          ) {
                                                            return _c(
                                                              "v-col",
                                                              {
                                                                key: subkey,
                                                                attrs: {
                                                                  cols: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-row",
                                                                  [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "6",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                subkey
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "6",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              " : " +
                                                                                _vm._s(
                                                                                  Array.isArray(
                                                                                    val
                                                                                  )
                                                                                    ? val.join(
                                                                                        ", "
                                                                                      ) ||
                                                                                        "N/A"
                                                                                    : typeof val ===
                                                                                        "object" &&
                                                                                      val !==
                                                                                        null
                                                                                    ? Object.values(
                                                                                        val
                                                                                      ).join(
                                                                                        ", "
                                                                                      )
                                                                                    : val ||
                                                                                      "N/A"
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                            ],
                            2
                          ),
                          _c("h2", { staticClass: "font-weight-black my-4" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$vuetify.lang.t(
                                  "$vuetify.questionnaire.prompt"
                                )
                              )
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.detailResponseModalData.prompt || "N/A"
                                ) +
                                " "
                            ),
                          ]),
                          _c("h2", { staticClass: "font-weight-black my-4" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$vuetify.lang.t(
                                  "$vuetify.questionnaire.recommendation"
                                )
                              )
                            ),
                          ]),
                          _c(
                            "v-row",
                            [
                              !Object.keys(
                                _vm.detailResponseModalData.response.detail
                                  .recommendations || {}
                              ).length
                                ? _c("v-col", { attrs: { cols: "12" } }, [
                                    _c("span", [_vm._v("N/A")]),
                                  ])
                                : _vm._l(
                                    _vm.detailResponseModalData.response.detail
                                      .recommendations || {},
                                    function (value, key) {
                                      return _c(
                                        "v-col",
                                        { key: key, attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-expansion-panels",
                                            [
                                              _c(
                                                "v-expansion-panel",
                                                [
                                                  _c(
                                                    "v-expansion-panel-header",
                                                    {
                                                      staticClass:
                                                        "font-weight-black",
                                                    },
                                                    [_vm._v(_vm._s(key))]
                                                  ),
                                                  _c(
                                                    "v-expansion-panel-content",
                                                    [
                                                      _c("v-row", [
                                                        _c(
                                                          "table",
                                                          [
                                                            !Object.keys(
                                                              value || {}
                                                            ).length
                                                              ? _c("tr", [
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      "N/A"
                                                                    ),
                                                                  ]),
                                                                ])
                                                              : _vm._l(
                                                                  value,
                                                                  function (
                                                                    item,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "tr",
                                                                      {
                                                                        key: index,
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "item_btn",
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.openItemDetails(
                                                                                    item
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      Object.values(
                                                                                        item
                                                                                      )[0]
                                                                                    )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                          ],
                                                          2
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                            ],
                            2
                          ),
                          _vm.itemDetailsModal
                            ? [
                                _c(
                                  "v-dialog",
                                  {
                                    model: {
                                      value: _vm.itemDetailsModal,
                                      callback: function ($$v) {
                                        _vm.itemDetailsModal = $$v
                                      },
                                      expression: "itemDetailsModal",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      [
                                        _c("v-card-title", [
                                          _c(
                                            "span",
                                            { staticClass: "headline" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$vuetify.lang.t(
                                                      "$vuetify.insights.item"
                                                    )
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.$vuetify.lang.t(
                                                      "$vuetify.common.details"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c(
                                          "v-card-text",
                                          [
                                            _vm.itemDetailsData
                                              ? _vm._l(
                                                  _vm.itemDetailsData,
                                                  function (value, key) {
                                                    return _c(
                                                      "v-row",
                                                      { key: key },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "3",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(key)
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "9",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                " : " +
                                                                  _vm._s(
                                                                    Array.isArray(
                                                                      value
                                                                    )
                                                                      ? value.join(
                                                                          ", "
                                                                        ) ||
                                                                          "N/A"
                                                                      : typeof value ===
                                                                          "object" &&
                                                                        value !==
                                                                          null
                                                                      ? Object.values(
                                                                          value
                                                                        ).join(
                                                                          ", "
                                                                        )
                                                                      : value ||
                                                                        "N/A"
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                )
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                        _c(
                                          "v-card-actions",
                                          { staticClass: "d-flex justify-end" },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  color: "error",
                                                  text: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.itemDetailsModal = false
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$vuetify.lang.t(
                                                        "$vuetify.close_btn_txt"
                                                      )
                                                    )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "v-card-actions",
                        { staticClass: "d-flex justify-end" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "error", text: "" },
                              on: {
                                click: function ($event) {
                                  _vm.detailModal = false
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$vuetify.lang.t(
                                      "$vuetify.close_btn_txt"
                                    )
                                  )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          : _vm._e(),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }