var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panels",
    [
      _c(
        "v-expansion-panel",
        {
          model: {
            value: _vm.panel,
            callback: function ($$v) {
              _vm.panel = $$v
            },
            expression: "panel",
          },
        },
        [
          !_vm.isPreviouslyRankSettingsCreated()
            ? _c("v-expansion-panel-header", [
                _c("div", { staticClass: "text-h6 font-weight-bold ml-n2" }, [
                  _vm._v(
                    _vm._s(
                      `${_vm.$vuetify.lang.t(
                        "$vuetify.project.create_rank_settings"
                      )}`
                    )
                  ),
                ]),
              ])
            : _c("v-expansion-panel-header", [
                _c("div", { staticClass: "text-h6 font-weight-bold ml-n2" }, [
                  _vm._v(
                    _vm._s(
                      `${_vm.$vuetify.lang.t(
                        "$vuetify.project.update_rank_settings"
                      )}`
                    )
                  ),
                ]),
              ]),
          _c(
            "v-expansion-panel-content",
            [
              _c(
                "v-form",
                { ref: "form" },
                [
                  _c(
                    "v-row",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("span", [_vm._v("Interval")]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              items: _vm.intervalOptions,
                              dense: "",
                              "hide-selected": "",
                              outlined: "",
                              "small-chips": "",
                              multiple: "",
                              "deletable-chips": "",
                            },
                            model: {
                              value: _vm.interval,
                              callback: function ($$v) {
                                _vm.interval = $$v
                              },
                              expression: "interval",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("span", [_vm._v("Split Size")]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              type: "number",
                              outlined: "",
                              dense: "",
                              min: 0,
                              max: 1,
                              step: 0.01,
                              decimal: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleNonEmptyValue("splitSize")
                              },
                            },
                            model: {
                              value: _vm.splitSize,
                              callback: function ($$v) {
                                _vm.splitSize = $$v
                              },
                              expression: "splitSize",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("span", [_vm._v("Epoch")]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              type: "number",
                              outlined: "",
                              dense: "",
                              min: 1,
                              decimal: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleNonEmptyValue("epoch")
                              },
                            },
                            model: {
                              value: _vm.epoch,
                              callback: function ($$v) {
                                _vm.epoch = $$v
                              },
                              expression: "epoch",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.isPreviouslyRankSettingsCreated()
                    ? _c(
                        "v-row",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    loading: _vm.isLoading,
                                  },
                                  on: { click: _vm.updateRankSettings },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      `${_vm.$vuetify.lang.t(
                                        "$vuetify.project.update"
                                      )}`
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "v-row",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    loading: _vm.isLoading,
                                  },
                                  on: { click: _vm.createRankSettings },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.create_action_txt"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }