var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-4" },
    [
      _c(
        "v-stepper",
        {
          model: {
            value: _vm.e1,
            callback: function ($$v) {
              _vm.e1 = $$v
            },
            expression: "e1",
          },
        },
        [
          _c(
            "v-stepper-header",
            [
              _vm._l(_vm.steps, function (n) {
                return [
                  _c(
                    "v-stepper-step",
                    {
                      key: `${n}-step`,
                      attrs: { complete: _vm.e1 > n, step: n },
                    },
                    [
                      n === 1
                        ? _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$vuetify.lang.t(
                                  "$vuetify.profile_basic_tab"
                                )
                              )
                            ),
                          ])
                        : _vm._e(),
                      n === 2
                        ? _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$vuetify.lang.t("$vuetify.review_step_txt")
                              )
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  n !== _vm.steps ? _c("v-divider", { key: n }) : _vm._e(),
                ]
              }),
            ],
            2
          ),
          _c(
            "v-stepper-items",
            _vm._l(_vm.steps, function (n) {
              return _c(
                "v-stepper-content",
                { key: `${n}-content`, attrs: { step: n } },
                [
                  n === 1
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-form",
                            { ref: "form1", refInFor: true },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", xs: "12", md: "12" },
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "text",
                                          label: _vm.$vuetify.lang.t(
                                            "$vuetify.project_name_header_txt"
                                          ),
                                          placeholder: _vm.$vuetify.lang.t(
                                            "$vuetify.project_name_placeholder_text"
                                          ),
                                          outlined: "",
                                          rules: [
                                            _vm.required(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.project_name_header_txt"
                                              )
                                            ),
                                          ],
                                        },
                                        model: {
                                          value: _vm.projectName,
                                          callback: function ($$v) {
                                            _vm.projectName = $$v
                                          },
                                          expression: "projectName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", xs: "12", md: "6" },
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.languageOptions,
                                          label: _vm.$vuetify.lang.t(
                                            "$vuetify.language_header_txt"
                                          ),
                                          disabled: _vm.isUpdate,
                                          rules: [
                                            _vm.required(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.language_header_txt"
                                              )
                                            ),
                                          ],
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.language,
                                          callback: function ($$v) {
                                            _vm.language = $$v
                                          },
                                          expression: "language",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", xs: "12", md: "6" },
                                    },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          items: _vm.allCategory,
                                          "item-text": "name",
                                          "item-value": "id",
                                          label: _vm.$vuetify.lang.t(
                                            "$vuetify.industry_header_txt"
                                          ),
                                          outlined: "",
                                          "search-input": _vm.searchCategory,
                                          rules: [
                                            _vm.required(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.industry_header_txt"
                                              )
                                            ),
                                          ],
                                          disabled: _vm.isUpdate,
                                        },
                                        on: {
                                          "update:searchInput": function (
                                            $event
                                          ) {
                                            _vm.searchCategory = $event
                                          },
                                          "update:search-input": function (
                                            $event
                                          ) {
                                            _vm.searchCategory = $event
                                          },
                                        },
                                        model: {
                                          value: _vm.appCategory,
                                          callback: function ($$v) {
                                            _vm.appCategory = $$v
                                          },
                                          expression: "appCategory",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.PROJECT_SEGMENTS.Maira !==
                                  _vm.solutionType
                                    ? _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            xs: "12",
                                            md: "12",
                                          },
                                        },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: _vm.solutionOptions,
                                              "item-text": "name",
                                              "item-value": "id",
                                              label: _vm.$vuetify.lang.t(
                                                "$vuetify.enable_solution_label_txt"
                                              ),
                                              outlined: "",
                                              "small-chips": "",
                                              multiple: "",
                                              "deletable-chips": "",
                                              rules: [
                                                (v) =>
                                                  !!v.length ||
                                                  _vm.$vuetify.lang.t(
                                                    "$vuetify.enable_solution_error_msg_txt"
                                                  ),
                                              ],
                                            },
                                            model: {
                                              value: _vm.selectedSolutionID,
                                              callback: function ($$v) {
                                                _vm.selectedSolutionID = $$v
                                              },
                                              expression: "selectedSolutionID",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            xs: "12",
                                            md: "12",
                                          },
                                        },
                                        [
                                          _c("v-textarea", {
                                            attrs: {
                                              label: "Purpose of the GPT",
                                              outlined: "",
                                            },
                                            model: {
                                              value: _vm.gpt_purpose,
                                              callback: function ($$v) {
                                                _vm.gpt_purpose = $$v
                                              },
                                              expression: "gpt_purpose",
                                            },
                                          }),
                                          _c("v-col"),
                                          _c("v-autocomplete", {
                                            attrs: {
                                              label: "Randomness",
                                              items: _vm.gptRandomnessOptions,
                                              "item-text": "name",
                                              "item-value": "value",
                                              outlined: "",
                                            },
                                            model: {
                                              value: _vm.gpt_randomness,
                                              callback: function ($$v) {
                                                _vm.gpt_randomness = $$v
                                              },
                                              expression: "gpt_randomness",
                                            },
                                          }),
                                          _c("v-col"),
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: _vm.solutionOptions,
                                              "item-text": "name",
                                              "item-value": "id",
                                              label: _vm.$vuetify.lang.t(
                                                "$vuetify.enable_solution_label_txt"
                                              ),
                                              outlined: "",
                                              "small-chips": "",
                                              multiple: "",
                                              "deletable-chips": "",
                                              rules: [
                                                (v) =>
                                                  !!v.length ||
                                                  _vm.$vuetify.lang.t(
                                                    "$vuetify.enable_solution_error_msg_txt"
                                                  ),
                                              ],
                                            },
                                            model: {
                                              value: _vm.selectedSolutionID,
                                              callback: function ($$v) {
                                                _vm.selectedSolutionID = $$v
                                              },
                                              expression: "selectedSolutionID",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  n === 2
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-card",
                            { staticClass: "elevation-0" },
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "font-weight-bold" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.profile_basic_tab"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("v-divider"),
                              _c(
                                "v-list",
                                [
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-content",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                `${_vm.$vuetify.lang.t(
                                                  "$vuetify.project_name_header_txt"
                                                )}:`
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        { staticClass: "align-end" },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.projectName) + " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-content",
                                        {
                                          staticClass:
                                            "font-weight-bold align-self-start",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                `${_vm.$vuetify.lang.t(
                                                  "$vuetify.enabled_solution_label_txt"
                                                )}:`
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        { staticClass: "align-end" },
                                        _vm._l(
                                          _vm.selectedSolutionNames,
                                          function (item, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass: "py-2",
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-1",
                                                    attrs: {
                                                      color:
                                                        _vm
                                                          .selectedSolutionColor[
                                                          index
                                                        ],
                                                      size: "21",
                                                    },
                                                  },
                                                  [_vm._v(" task_alt ")]
                                                ),
                                                _vm._v(
                                                  " " + _vm._s(item) + " "
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-content",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                `${_vm.$vuetify.lang.t(
                                                  "$vuetify.solution_header_txt"
                                                )}:`
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        { staticClass: "align-end" },
                                        [
                                          _c(
                                            "v-card",
                                            { attrs: { flat: "" } },
                                            [
                                              _vm.selectedSolutionType
                                                ? _c(
                                                    "v-chip",
                                                    {
                                                      staticClass: "ma-0",
                                                      attrs: {
                                                        color:
                                                          _vm
                                                            .selectedSolutionColor[0],
                                                        label: "",
                                                        outlined: "",
                                                        pill: "",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.capitalizeFirstLetter(
                                                              _vm.selectedSolutionType
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-content",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                `${_vm.$vuetify.lang.t(
                                                  "$vuetify.industry_header_txt"
                                                )}:`
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        { staticClass: "align-end" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.selectedIndustryName) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-content",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                `${_vm.$vuetify.lang.t(
                                                  "$vuetify.language_header_txt"
                                                )}:`
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        { staticClass: "align-end" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.language
                                                  ? _vm.language
                                                  : "N/A"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-content",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                `${_vm.$vuetify.lang.t(
                                                  "$vuetify.subs_type_label_txt"
                                                )}:`
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        { staticClass: "align-end" },
                                        [
                                          _c(
                                            "v-card",
                                            { attrs: { flat: "" } },
                                            [
                                              _c(
                                                "v-chip",
                                                {
                                                  staticClass:
                                                    "ma-0 text-capitalize",
                                                  attrs: {
                                                    color:
                                                      _vm.getSubscriptionTypeColor(
                                                        _vm.subscriptionType
                                                      ),
                                                    label: "",
                                                    outlined: "",
                                                    pill: "",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.subscriptionType ===
                                                          "trial"
                                                          ? _vm.capitalizeFirstLetter(
                                                              _vm.subscriptionType
                                                            )
                                                          : "Standard"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card",
                            { staticClass: "elevation-0" },
                            [_c("v-list")],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("br"),
                  _c(
                    "v-row",
                    { attrs: { align: "center", justify: "start" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          n !== 1
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mr-4",
                                  attrs: {
                                    color: "primary",
                                    large: "",
                                    outlined: "",
                                    loading: false,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.previousStep(n)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.prev_btn_txt"
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                          n === 1
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    color: "error",
                                    large: "",
                                    outlined: "",
                                    loading: false,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goBack()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.cancel_btn_txt"
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                          n === 2
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    color: "primary",
                                    large: "",
                                    loading: _vm.isCreatingProject,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.saveProject()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.save_btn_txt"
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    color: "primary",
                                    large: "",
                                    loading: false,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.nextStep(n)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.continue_btn_txt"
                                      )
                                    )
                                  ),
                                ]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "600" },
          model: {
            value: _vm.createCategoryDialog,
            callback: function ($$v) {
              _vm.createCategoryDialog = $$v
            },
            expression: "createCategoryDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline primary white--text",
                  attrs: { "primary-title": "" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$vuetify.lang.t(
                          "$vuetify.create_industry_header_txt"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "pa-5" },
                [
                  _c(
                    "v-form",
                    { ref: "categoryForm" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          type: "text",
                          label: _vm.$vuetify.lang.t(
                            "$vuetify.industry_name_label_txt"
                          ),
                          outlined: "",
                          rules: [
                            _vm.required(
                              _vm.$vuetify.lang.t(
                                "$vuetify.industry_name_label_txt"
                              )
                            ),
                          ],
                        },
                        model: {
                          value: _vm.categoryName,
                          callback: function ($$v) {
                            _vm.categoryName = $$v
                          },
                          expression: "categoryName",
                        },
                      }),
                      _c("v-textarea", {
                        attrs: {
                          label: _vm.$vuetify.lang.t(
                            "$vuetify.description_label_txt"
                          ),
                          rules: [
                            _vm.required(
                              _vm.$vuetify.lang.t(
                                "$vuetify.description_label_txt"
                              )
                            ),
                          ],
                          outlined: "",
                        },
                        model: {
                          value: _vm.categoryDescription,
                          callback: function ($$v) {
                            _vm.categoryDescription = $$v
                          },
                          expression: "categoryDescription",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: { text: "", color: "error" },
                      on: {
                        click: function ($event) {
                          _vm.createCategoryDialog = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$vuetify.lang.t("$vuetify.close_btn_txt"))
                      ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", loading: _vm.isCreatingInd },
                      on: {
                        click: function ($event) {
                          return _vm.saveCategory()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$vuetify.lang.t("$vuetify.save_btn_txt"))
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("DialogModal", {
        attrs: {
          title: "Error",
          message: _vm.alertMessage,
          confirmButtonText: _vm.$vuetify.lang.t("$vuetify.ok_btn_txt"),
          cancelButton: false,
        },
        on: {
          onConfirmation: function ($event) {
            _vm.alertDialog = false
          },
        },
        model: {
          value: _vm.alertDialog,
          callback: function ($$v) {
            _vm.alertDialog = $$v
          },
          expression: "alertDialog",
        },
      }),
      _c("DialogModal", {
        attrs: {
          title: "Warning",
          message: _vm.alertMessage,
          confirmButtonText: _vm.$vuetify.lang.t("$vuetify.yes_btn_txt"),
          cancelButtonText: _vm.$vuetify.lang.t("$vuetify.no_btn_txt"),
          headlineClass: "headline warning white--text",
          persistent: true,
        },
        on: {
          onCancel: function ($event) {
            return _vm.cancelDialog()
          },
          onConfirmation: function ($event) {
            return _vm.resetMonthlyPV()
          },
        },
        model: {
          value: _vm.warningDialog,
          callback: function ($$v) {
            _vm.warningDialog = $$v
          },
          expression: "warningDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }