var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-4" },
    [
      _vm.isCancelBtn
        ? [
            _c(
              "v-stepper",
              {
                model: {
                  value: _vm.e1,
                  callback: function ($$v) {
                    _vm.e1 = $$v
                  },
                  expression: "e1",
                },
              },
              [
                _c(
                  "v-stepper-header",
                  [
                    _vm._l(_vm.steps, function (n) {
                      return [
                        _c(
                          "v-stepper-step",
                          {
                            key: `${n}-step`,
                            attrs: { complete: _vm.e1 > n, step: n },
                          },
                          [
                            n === 1
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.common.add"
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                            n === 2
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.common.review"
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                        n !== _vm.steps
                          ? _c("v-divider", { key: n })
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
                _c(
                  "v-stepper-items",
                  _vm._l(_vm.steps, function (n) {
                    return _c(
                      "v-stepper-content",
                      { key: `${n}-content`, attrs: { step: n } },
                      [
                        n === 1
                          ? _c(
                              "div",
                              [
                                _c(
                                  "ValidationObserver",
                                  { ref: "firstStep", refInFor: true },
                                  [
                                    _c(
                                      "v-form",
                                      {
                                        ref: "form1",
                                        refInFor: true,
                                        on: {
                                          submit: function ($event) {
                                            $event.preventDefault()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: {
                                                  cols: "12",
                                                  xs: "12",
                                                  md: "12",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c(
                                                          "v-alert",
                                                          {
                                                            attrs: {
                                                              type: "info",
                                                              outlined: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$vuetify.lang.t(
                                                                    "$vuetify.wallet.add_account_message"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "3" } },
                                                      [
                                                        _c("Tooltip", {
                                                          attrs: {
                                                            label:
                                                              _vm.$vuetify.lang.t(
                                                                "$vuetify.wallet.action_type"
                                                              ),
                                                            message:
                                                              _vm.$vuetify.lang.t(
                                                                "$vuetify.wallet.action_type_tooltip"
                                                              ),
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "9" } },
                                                      [
                                                        _c(
                                                          "v-btn-toggle",
                                                          {
                                                            attrs: {
                                                              mandatory: "",
                                                              color: "white",
                                                              "active-class":
                                                                "primary",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.actionType,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.actionType =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "actionType",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "import",
                                                                  text: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$vuetify.lang.t(
                                                                        "$vuetify.wallet.import_account"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    "create",
                                                                  text: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$vuetify.lang.t(
                                                                        "$vuetify.wallet.create_account"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm.actionType == "create"
                                              ? [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        xs: "12",
                                                        md: "12",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "3",
                                                              },
                                                            },
                                                            [
                                                              _c("Tooltip", {
                                                                attrs: {
                                                                  label:
                                                                    _vm.$vuetify.lang.t(
                                                                      "$vuetify.wallet.create_account"
                                                                    ),
                                                                  message:
                                                                    _vm.$vuetify.lang.t(
                                                                      "$vuetify.wallet.create_account_tooltip"
                                                                    ),
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "9",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn-toggle",
                                                                {
                                                                  attrs: {
                                                                    mandatory:
                                                                      "",
                                                                    color:
                                                                      "white",
                                                                    "active-class":
                                                                      "primary",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.toggle_account_create,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.toggle_account_create =
                                                                          $$v
                                                                      },
                                                                    expression:
                                                                      "toggle_account_create",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        value: 1,
                                                                        text: "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$vuetify.lang.t(
                                                                              "$vuetify.wallet.create_account_source"
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        value: 2,
                                                                        text: "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$vuetify.lang.t(
                                                                              "$vuetify.wallet.create_account_without_source"
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm.toggle_account_create ===
                                                  1
                                                    ? [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              xs: "12",
                                                              md: "12",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-row",
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "3",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "Tooltip",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            _vm.$vuetify.lang.t(
                                                                              "$vuetify.wallet.account_name"
                                                                            ),
                                                                          message:
                                                                            _vm.$vuetify.lang.t(
                                                                              "$vuetify.wallet.account_name_tooltip"
                                                                            ),
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "9",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "ValidationProvider",
                                                                      {
                                                                        attrs: {
                                                                          name: _vm.$vuetify.lang.t(
                                                                            "$vuetify.wallet.account_name"
                                                                          ),
                                                                          rules:
                                                                            "required|is_numeric",
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "default",
                                                                                fn: function ({
                                                                                  errors,
                                                                                }) {
                                                                                  return [
                                                                                    _c(
                                                                                      "v-text-field",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            placeholder:
                                                                                              "gigalogy",
                                                                                            outlined:
                                                                                              "",
                                                                                            "error-messages":
                                                                                              errors,
                                                                                          },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              _vm.accName,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.accName =
                                                                                                  $$v
                                                                                              },
                                                                                            expression:
                                                                                              "accName",
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              xs: "12",
                                                              md: "12",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-row",
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "3",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "Tooltip",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            _vm.$vuetify.lang.t(
                                                                              "$vuetify.wallet.source_account_secret"
                                                                            ),
                                                                          message:
                                                                            _vm.$vuetify.lang.t(
                                                                              "$vuetify.wallet.source_account_secret_tooltip"
                                                                            ),
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "9",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "ValidationProvider",
                                                                      {
                                                                        attrs: {
                                                                          name: _vm.$vuetify.lang.t(
                                                                            "$vuetify.wallet.secret_key"
                                                                          ),
                                                                          rules:
                                                                            "required",
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "default",
                                                                                fn: function ({
                                                                                  errors,
                                                                                }) {
                                                                                  return [
                                                                                    _c(
                                                                                      "v-text-field",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mask-password",
                                                                                        attrs:
                                                                                          {
                                                                                            placeholder:
                                                                                              "SDIYMRSWN*****************************CVFZIA",
                                                                                            outlined:
                                                                                              "",
                                                                                            "error-messages":
                                                                                              errors,
                                                                                          },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              _vm
                                                                                                .createFromSource
                                                                                                .secretKey,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  _vm.createFromSource,
                                                                                                  "secretKey",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                            expression:
                                                                                              "createFromSource.secretKey",
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              xs: "12",
                                                              md: "12",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-row",
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "3",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "Tooltip",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            _vm.$vuetify.lang.t(
                                                                              "$vuetify.wallet.starting_balance"
                                                                            ),
                                                                          message:
                                                                            _vm.$vuetify.lang.t(
                                                                              "$vuetify.wallet.starting_balance_tooltip"
                                                                            ),
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "9",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "ValidationProvider",
                                                                      {
                                                                        attrs: {
                                                                          name: _vm.$vuetify.lang.t(
                                                                            "$vuetify.wallet.starting_balance"
                                                                          ),
                                                                          rules:
                                                                            "required|min_value:1|is_decimal_allowed",
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "default",
                                                                                fn: function ({
                                                                                  errors,
                                                                                }) {
                                                                                  return [
                                                                                    _c(
                                                                                      "v-text-field",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            type: "number",
                                                                                            min: "0",
                                                                                            placeholder:
                                                                                              "10,000",
                                                                                            outlined:
                                                                                              "",
                                                                                            "error-messages":
                                                                                              errors,
                                                                                          },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              _vm
                                                                                                .createFromSource
                                                                                                .startingBalance,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  _vm.createFromSource,
                                                                                                  "startingBalance",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                            expression:
                                                                                              "createFromSource.startingBalance",
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              xs: "12",
                                                              md: "12",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-row",
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "3",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "Tooltip",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            _vm.$vuetify.lang.t(
                                                                              "$vuetify.wallet.memo"
                                                                            ),
                                                                          message:
                                                                            _vm.$vuetify.lang.t(
                                                                              "$vuetify.wallet.memo_tooltip"
                                                                            ),
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "9",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "ValidationProvider",
                                                                      {
                                                                        attrs: {
                                                                          name: _vm.$vuetify.lang.t(
                                                                            "$vuetify.wallet.memo"
                                                                          ),
                                                                          rules:
                                                                            "",
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "default",
                                                                                fn: function ({
                                                                                  errors,
                                                                                }) {
                                                                                  return [
                                                                                    _c(
                                                                                      "v-text-field",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            placeholder:
                                                                                              _vm.$vuetify.lang.t(
                                                                                                "$vuetify.wallet.memo"
                                                                                              ),
                                                                                            outlined:
                                                                                              "",
                                                                                            "error-messages":
                                                                                              errors,
                                                                                          },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              _vm
                                                                                                .createFromSource
                                                                                                .memo,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  _vm.createFromSource,
                                                                                                  "memo",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                            expression:
                                                                                              "createFromSource.memo",
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                  _vm.toggle_account_create ===
                                                  2
                                                    ? [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              xs: "12",
                                                              md: "12",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-row",
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "3",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "Tooltip",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            _vm.$vuetify.lang.t(
                                                                              "$vuetify.wallet.account_name"
                                                                            ),
                                                                          message:
                                                                            _vm.$vuetify.lang.t(
                                                                              "$vuetify.wallet.account_name_tooltip"
                                                                            ),
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "9",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "ValidationProvider",
                                                                      {
                                                                        attrs: {
                                                                          name: _vm.$vuetify.lang.t(
                                                                            "$vuetify.wallet.account_name"
                                                                          ),
                                                                          rules:
                                                                            "required|is_numeric",
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "default",
                                                                                fn: function ({
                                                                                  errors,
                                                                                }) {
                                                                                  return [
                                                                                    _c(
                                                                                      "v-text-field",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            placeholder:
                                                                                              "gigalogy",
                                                                                            outlined:
                                                                                              "",
                                                                                            "error-messages":
                                                                                              errors,
                                                                                          },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              _vm.accName,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.accName =
                                                                                                  $$v
                                                                                              },
                                                                                            expression:
                                                                                              "accName",
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                ]
                                              : _vm._e(),
                                            _vm.actionType == "import"
                                              ? [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        xs: "12",
                                                        md: "12",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "3",
                                                              },
                                                            },
                                                            [
                                                              _c("Tooltip", {
                                                                attrs: {
                                                                  label:
                                                                    _vm.$vuetify.lang.t(
                                                                      "$vuetify.wallet.account_name"
                                                                    ),
                                                                  message:
                                                                    _vm.$vuetify.lang.t(
                                                                      "$vuetify.wallet.account_name_tooltip"
                                                                    ),
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "9",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "ValidationProvider",
                                                                {
                                                                  attrs: {
                                                                    name: _vm.$vuetify.lang.t(
                                                                      "$vuetify.wallet.account_name"
                                                                    ),
                                                                    rules:
                                                                      "required|is_numeric",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "default",
                                                                          fn: function ({
                                                                            errors,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "v-text-field",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      placeholder:
                                                                                        "gigalogy",
                                                                                      outlined:
                                                                                        "",
                                                                                      "error-messages":
                                                                                        errors,
                                                                                    },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        _vm.accName,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.accName =
                                                                                            $$v
                                                                                        },
                                                                                      expression:
                                                                                        "accName",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        xs: "12",
                                                        md: "12",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "3",
                                                              },
                                                            },
                                                            [
                                                              _c("Tooltip", {
                                                                attrs: {
                                                                  label:
                                                                    _vm.$vuetify.lang.t(
                                                                      "$vuetify.wallet.secret_key"
                                                                    ),
                                                                  message:
                                                                    _vm.$vuetify.lang.t(
                                                                      "$vuetify.wallet.secret_key_tooltip"
                                                                    ),
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "9",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "ValidationProvider",
                                                                {
                                                                  attrs: {
                                                                    name: _vm.$vuetify.lang.t(
                                                                      "$vuetify.wallet.secret_key"
                                                                    ),
                                                                    rules:
                                                                      "required",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "default",
                                                                          fn: function ({
                                                                            errors,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "v-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "mask-password",
                                                                                  attrs:
                                                                                    {
                                                                                      placeholder:
                                                                                        "SDIYMRSWN*****************************CVFZIA",
                                                                                      outlined:
                                                                                        "",
                                                                                      "error-messages":
                                                                                        errors,
                                                                                    },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .importExistingAccount
                                                                                          .secretKey,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.importExistingAccount,
                                                                                            "secretKey",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "importExistingAccount.secretKey",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        n === 2
                          ? _c(
                              "div",
                              [
                                _c(
                                  "v-card",
                                  { staticClass: "elevation-0" },
                                  [
                                    _c(
                                      "v-card-title",
                                      { staticClass: "font-weight-bold" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.common.review"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("v-divider"),
                                    _c(
                                      "v-list",
                                      [
                                        _c(
                                          "v-list-item",
                                          [
                                            _c(
                                              "v-list-item-content",
                                              {
                                                staticClass: "font-weight-bold",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$vuetify.lang.t(
                                                        "$vuetify.wallet.action_type"
                                                      )
                                                    ) +
                                                    ": "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              { staticClass: "align-end" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.actionType) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item",
                                          [
                                            _c(
                                              "v-list-item-content",
                                              {
                                                staticClass: "font-weight-bold",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$vuetify.lang.t(
                                                        "$vuetify.wallet.account_name"
                                                      )
                                                    ) +
                                                    ": "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              { staticClass: "align-end" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.accName
                                                        ? _vm.accName
                                                        : "N/A"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm.toggle_account_create !== 2
                                          ? _c(
                                              "v-list-item",
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$vuetify.lang.t(
                                                            "$vuetify.wallet.secret_key"
                                                          )
                                                        ) +
                                                        ": "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-list-item-content",
                                                  { staticClass: "align-end" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.actionType ===
                                                            "import"
                                                            ? _vm
                                                                .importExistingAccount
                                                                .secretKey &&
                                                                _vm.maskSecret(
                                                                  _vm
                                                                    .importExistingAccount
                                                                    .secretKey
                                                                )
                                                            : _vm
                                                                .createFromSource
                                                                .secretKey &&
                                                                _vm.maskSecret(
                                                                  _vm
                                                                    .createFromSource
                                                                    .secretKey
                                                                )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("br"),
                        _c(
                          "v-row",
                          { attrs: { align: "center", justify: "start" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                n !== 1
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "mr-4",
                                        attrs: {
                                          color: "primary",
                                          large: "",
                                          outlined: "",
                                          loading: false,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.previousStep(n)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.common.previous"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                n === 1
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "mr-2",
                                        attrs: {
                                          color: "error",
                                          large: "",
                                          outlined: "",
                                          loading: false,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.goBack()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.common.cancel"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                n === 2
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "mr-2",
                                        attrs: {
                                          color: "primary",
                                          large: "",
                                          loading: false,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.proceedAction()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.common.proceed"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "v-btn",
                                      {
                                        staticClass: "mr-2",
                                        attrs: {
                                          color: "primary",
                                          large: "",
                                          loading: false,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.nextStep(n)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.common.continue"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                attrs: { persistent: "", width: "500" },
                model: {
                  value: _vm.accCreateDialog,
                  callback: function ($$v) {
                    _vm.accCreateDialog = $$v
                  },
                  expression: "accCreateDialog",
                },
              },
              [
                _c(
                  "v-card",
                  { staticClass: "mx-auto pa-4" },
                  [
                    _c(
                      "v-card-text",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "v-icon",
                          { attrs: { color: "success", size: "80" } },
                          [_vm._v("task_alt")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-card-text",
                      {
                        staticClass:
                          "font-weight-bold success--text headline text-center",
                      },
                      [_vm._v(" " + _vm._s(_vm.message) + " ")]
                    ),
                    _c(
                      "v-card-text",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary" },
                            on: { click: _vm.gotoMyAccount },
                          },
                          [_vm._v("Go to Account")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                attrs: { persistent: "", width: "500" },
                model: {
                  value: _vm.accCreateFailDialog,
                  callback: function ($$v) {
                    _vm.accCreateFailDialog = $$v
                  },
                  expression: "accCreateFailDialog",
                },
              },
              [
                _c(
                  "v-card",
                  { staticClass: "mx-auto pa-4" },
                  [
                    _c(
                      "v-card-text",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "v-icon",
                          { attrs: { color: "error", size: "80" } },
                          [_vm._v("mdi-close-circle-outline")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-card-text",
                      {
                        staticClass:
                          "font-weight-bold error--text headline text-center",
                      },
                      [_vm._v(" " + _vm._s(_vm.errMessage) + " ")]
                    ),
                    _c(
                      "v-card-text",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "error" },
                            on: {
                              click: function ($event) {
                                _vm.accCreateFailDialog = false
                              },
                            },
                          },
                          [_vm._v("Close")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c("PasscodeCheck", {
        attrs: { isCancelBtn: true, isLoading: _vm.isLoading },
        on: { confirm: _vm.proceedClick, cancel: _vm.cancel },
        model: {
          value: _vm.passcode,
          callback: function ($$v) {
            _vm.passcode = $$v
          },
          expression: "passcode",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }