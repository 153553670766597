var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { "fill-height": "", fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { xs: "12", md: "12" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "mx-auto px-2", attrs: { outlined: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { xs: "12", md: "6" } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "px-2 primary--text font-weight-bold",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.projects_header_txt"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", xs: "12", md: "4" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "append-icon": "mdi-magnify",
                                  label: _vm.$vuetify.lang.t(
                                    "$vuetify.search_btn_txt"
                                  ),
                                  outlined: "",
                                  dense: "",
                                  "single-line": "",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.search,
                                  callback: function ($$v) {
                                    _vm.search = $$v
                                  },
                                  expression: "search",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", xs: "12", md: "3" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.states,
                                  "item-text": "state",
                                  "item-value": "value",
                                  "persistent-hint": "",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: "",
                                },
                                model: {
                                  value: _vm.project_status,
                                  callback: function ($$v) {
                                    _vm.project_status = $$v
                                  },
                                  expression: "project_status",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _vm.isLoading
                        ? _c("Loader", { staticClass: "mr-2 mt-6" })
                        : _c("v-data-table", {
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.allProject.filter((project) =>
                                _vm.project_status === ""
                                  ? project.status !== ""
                                  : project.status === _vm.project_status
                              ),
                              search: _vm.search,
                              page: _vm.page,
                              "items-per-page": _vm.itemsPerPage,
                              "hide-default-footer": "",
                            },
                            on: {
                              "update:page": function ($event) {
                                _vm.page = $event
                              },
                              "page-count": function ($event) {
                                _vm.pageCount = $event
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: `item.id`,
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "span",
                                                      _vm._g(
                                                        _vm._b(
                                                          {},
                                                          "span",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.id.split(
                                                              "-"
                                                            )[0]
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [_c("span", [_vm._v(_vm._s(item.id))])]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: `item.name`,
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "primary--text text--darken-3",
                                              attrs: { text: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.redirectDetails(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(item.name))]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: `item.segment`,
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-chip",
                                        {
                                          staticClass: "ma-2 text-capitalize",
                                          attrs: {
                                            color: item.segment.color,
                                            label: "",
                                            outlined: "",
                                            pill: "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.segment.name) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: `item.language`,
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "span",
                                        { staticClass: "text-capitalize" },
                                        [_vm._v(_vm._s(item.language))]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: `item.status`,
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "span",
                                        { staticClass: "text-capitalize" },
                                        [_vm._v(_vm._s(item.status))]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: `item.industry`,
                                  fn: function ({ item }) {
                                    return [
                                      _vm._v(
                                        " " + _vm._s(item.industry.name) + " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: `item.actions`,
                                  fn: function ({ item }) {
                                    return [
                                      _c("ButtonGroup", {
                                        attrs: {
                                          groupType: _vm.ACTION_BUTTON_GROUP,
                                          addButton: false,
                                          disabledDeleteButton:
                                            _vm.disable_crud_operation ||
                                            (item.status !== "stopped" &&
                                              item.status !== "running"),
                                          disabledStartButton:
                                            _vm.disable_crud_operation ||
                                            item.status !== "stopped",
                                          disabledStopButton:
                                            _vm.disable_crud_operation ||
                                            item.status !== "running",
                                          disabledEditButton:
                                            _vm.disable_crud_operation ||
                                            (item.status !== "pending" &&
                                              item.status !== "running"),
                                        },
                                        on: {
                                          onDetailsClick: function ($event) {
                                            return _vm.redirectDetails(item)
                                          },
                                          onEditClick: function ($event) {
                                            return _vm.editItem(item)
                                          },
                                          onStopClick: function ($event) {
                                            return _vm.stopItem(item)
                                          },
                                          onDeleteClick: function ($event) {
                                            return _vm.deleteItem(item)
                                          },
                                          onStartClick: function ($event) {
                                            return _vm.startItem(item)
                                          },
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", md: "12" },
                            },
                            [_c("v-divider")],
                            1
                          ),
                          _c("v-col", {
                            attrs: { cols: "12", xs: "12", sm: "3", md: "3" },
                          }),
                          _c(
                            "v-col",
                            {
                              attrs: { cols: "12", xs: "12", sm: "6", md: "6" },
                            },
                            [
                              _c("v-pagination", {
                                staticClass: "text-center",
                                attrs: { length: _vm.pageCount },
                                model: {
                                  value: _vm.page,
                                  callback: function ($$v) {
                                    _vm.page = $$v
                                  },
                                  expression: "page",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                "offset-sm": "1",
                                "offset-md": "1",
                                xs: "3",
                                sm: "2",
                                md: "2",
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.rows,
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: "",
                                },
                                model: {
                                  value: _vm.itemsPerPage,
                                  callback: function ($$v) {
                                    _vm.itemsPerPage = $$v
                                  },
                                  expression: "itemsPerPage",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("DialogModal", {
        attrs: {
          title: _vm.$vuetify.lang.t("$vuetify.confirm_alert_title_txt"),
          message: _vm.$vuetify.lang.t("$vuetify.confirm_alert_msg_txt_3"),
          confirmButtonText: _vm.$vuetify.lang.t("$vuetify.confirm_btn_txt"),
          cancelButtonText: _vm.$vuetify.lang.t("$vuetify.cancel_btn_txt"),
        },
        on: {
          onConfirmation: function ($event) {
            return _vm.stopProject()
          },
          onCancel: function ($event) {
            _vm.stopDialog = false
          },
        },
        model: {
          value: _vm.stopDialog,
          callback: function ($$v) {
            _vm.stopDialog = $$v
          },
          expression: "stopDialog",
        },
      }),
      _c("DialogModal", {
        attrs: {
          title: _vm.$vuetify.lang.t("$vuetify.confirm_alert_title_txt"),
          message: _vm.$vuetify.lang.t("$vuetify.confirm_alert_msg_txt_4"),
          confirmButtonText: _vm.$vuetify.lang.t("$vuetify.confirm_btn_txt"),
          cancelButtonText: _vm.$vuetify.lang.t("$vuetify.cancel_btn_txt"),
        },
        on: {
          onConfirmation: function ($event) {
            return _vm.terminateProject()
          },
          onCancel: function ($event) {
            _vm.deleteDialog = false
          },
        },
        model: {
          value: _vm.deleteDialog,
          callback: function ($$v) {
            _vm.deleteDialog = $$v
          },
          expression: "deleteDialog",
        },
      }),
      _c("DialogModal", {
        attrs: {
          title: _vm.$vuetify.lang.t("$vuetify.confirm_alert_title_txt"),
          message: _vm.$vuetify.lang.t("$vuetify.confirm_alert_msg_txt_5"),
          confirmButtonText: _vm.$vuetify.lang.t("$vuetify.confirm_btn_txt"),
          cancelButtonText: _vm.$vuetify.lang.t("$vuetify.cancel_btn_txt"),
        },
        on: {
          onConfirmation: function ($event) {
            return _vm.startProject()
          },
          onCancel: function ($event) {
            _vm.startDialog = false
          },
        },
        model: {
          value: _vm.startDialog,
          callback: function ($$v) {
            _vm.startDialog = $$v
          },
          expression: "startDialog",
        },
      }),
      _c("DialogModal", {
        attrs: {
          title: _vm.$vuetify.lang.t("$vuetify.error_title_txt"),
          message: _vm.alertMessage,
          confirmButtonText: _vm.$vuetify.lang.t("$vuetify.ok_btn_txt"),
          cancelButton: false,
        },
        on: {
          onConfirmation: function ($event) {
            _vm.alertDialog = false
          },
        },
        model: {
          value: _vm.alertDialog,
          callback: function ($$v) {
            _vm.alertDialog = $$v
          },
          expression: "alertDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }