var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { "fill-height": "", fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { xs: "12", md: "12" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "mx-auto px-2", attrs: { outlined: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { xs: "12", md: "6" } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "px-2 primary--text font-weight-bold",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.dashboard.projects"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _vm.isLoading
                        ? _c("Loader", { staticClass: "mr-2 mt-6" })
                        : _c(
                            "v-row",
                            { staticClass: "mt-2 mb-2 pl-2 pr-2" },
                            _vm._l(_vm.projectCounts, function (count) {
                              return _c(
                                "v-col",
                                {
                                  key: count.segment,
                                  attrs: {
                                    cols: "12",
                                    xs: "12",
                                    sm: "6",
                                    md: "6",
                                    lg: "4",
                                    xl: "4",
                                  },
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "text-decoration-none",
                                      attrs: {
                                        to: "/solutions?type=" + count.segment,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "mx-auto px-2",
                                          attrs: { rounded: "", outlined: "" },
                                        },
                                        [
                                          _c("v-card-subtitle", [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "font-weight-bold",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(count.segment) +
                                                    " "
                                                ),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { top: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                            attrs,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                      },
                                                                    },
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    " mdi-help-circle-outline "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$vuetify.lang.t(
                                                              "$vuetify.dashboard.click_to_see_projects"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("v-card-text", [
                                            _c(
                                              "span",
                                              { staticClass: "text-h5" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$vuetify.lang.t(
                                                        "$vuetify.dashboard.active"
                                                      )
                                                    ) +
                                                    ": " +
                                                    _vm._s(count.active) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-12" },
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { xs: "12", md: "12" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "mx-auto px-2", attrs: { outlined: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { xs: "12", md: "6" } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "px-2 primary--text font-weight-bold",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.dashboard.recommended_articles"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-row",
                        { staticClass: "mt-2 mb-2 pl-2 pr-2" },
                        _vm._l(_vm.articles, function (article) {
                          return _c(
                            "v-col",
                            {
                              key: article.title,
                              attrs: {
                                cols: "12",
                                xs: "12",
                                sm: "6",
                                md: "6",
                                lg: "4",
                                xl: "4",
                              },
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "mx-auto rounded",
                                  attrs: { outlined: "", exact: "", tile: "" },
                                },
                                [
                                  _c("v-img", {
                                    staticClass: "white--text align-end",
                                    attrs: {
                                      src: require(`../assets/images/${article.image}`),
                                      "aspect-ratio": "2",
                                    },
                                  }),
                                  _c(
                                    "v-card-subtitle",
                                    { staticClass: "pb-0" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(article.publishDate) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-card-title",
                                    {
                                      staticStyle: {
                                        height: "auto !important",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(article.title) + " ")]
                                  ),
                                  _c("v-card-text", [
                                    _vm._v(" " + _vm._s(article.summary) + " "),
                                  ]),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "px-3 py-5 ml-auto mb-2 mr-2 outlined-default read-more-btn",
                                          attrs: {
                                            href: article.link,
                                            target: "_blank",
                                            outlined: "",
                                            color: "primary",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$vuetify.lang.t(
                                                  "$vuetify.common.read_more"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }