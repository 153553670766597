var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { "fill-height": "", fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "pa-0",
                  attrs: { cols: "12", xs: "12", md: "12" },
                },
                [
                  _vm.profileLoading
                    ? _c("Loader", {
                        staticClass: "mr-2 mt-6",
                        attrs: { loaderType: "card-avatar, article, actions" },
                      })
                    : _c(
                        "v-card",
                        { staticClass: "pa-2 mr-2", attrs: { outlined: "" } },
                        [
                          _c(
                            "v-row",
                            { staticClass: "mx-2 mb-3" },
                            [
                              _c(
                                "v-col",
                                { attrs: { xs: "12", md: "6" } },
                                [
                                  _c("v-card-title", [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex flex-column" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "my-2 ml-2" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.account.account_name
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "v-chip",
                                              {
                                                attrs: {
                                                  "x-small": "",
                                                  outlined: "",
                                                  color: "info",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.account.type) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "body-1 ml-2" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.profile.email) +
                                                " "
                                            ),
                                            _vm.profile.email_verified
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      color: "green",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " mdi-check-decagram "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "mx-2 mb-3" },
                            [
                              _c("v-col", { attrs: { xs: "12", md: "6" } }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex-column" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "my-2 ml-2" },
                                      _vm._l(
                                        _vm.accountRoles(),
                                        function (item) {
                                          return _c(
                                            "span",
                                            { key: item },
                                            [
                                              _c(
                                                "v-chip",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    outlined: "",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.toUpperCase()
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _vm._v("  "),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-tabs",
                            {
                              attrs: { "show-arrows": "" },
                              model: {
                                value: _vm.tab,
                                callback: function ($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab",
                              },
                            },
                            _vm._l(_vm.tabs, function (tab) {
                              return _c(
                                "v-tab",
                                {
                                  key: tab.id,
                                  on: {
                                    click: function ($event) {
                                      return _vm.tabClick(tab.id)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "me-3",
                                      attrs: { size: "20" },
                                    },
                                    [_vm._v(" " + _vm._s(tab.icon) + " ")]
                                  ),
                                  _c("span", [_vm._v(_vm._s(tab.title))]),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                          _c(
                            "v-tabs-items",
                            {
                              model: {
                                value: _vm.tab,
                                callback: function ($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab",
                              },
                            },
                            [
                              _c(
                                "v-tab-item",
                                [
                                  _c(
                                    "v-form",
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "pa-4 mt-2",
                                          attrs: { flat: "" },
                                        },
                                        [
                                          _c(
                                            "ValidationObserver",
                                            { ref: "profileStep" },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "mt-4" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        md: "6",
                                                        cols: "12",
                                                      },
                                                    },
                                                    [
                                                      _c("ValidationProvider", {
                                                        attrs: {
                                                          name: "First Name",
                                                          rules:
                                                            "required|is_numeric",
                                                        },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "default",
                                                            fn: function ({
                                                              errors,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        _vm.$vuetify.lang.t(
                                                                          "$vuetify.first_name_label_txt"
                                                                        ),
                                                                      outlined:
                                                                        "",
                                                                      "error-messages":
                                                                        _vm.localizeErrorMsg(
                                                                          errors,
                                                                          "first_name",
                                                                          _vm
                                                                            .profile
                                                                            .first_name
                                                                        ),
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .profile
                                                                          .first_name,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.profile,
                                                                            "first_name",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "profile.first_name",
                                                                    },
                                                                  }
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ]),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        md: "6",
                                                        cols: "12",
                                                      },
                                                    },
                                                    [
                                                      _c("ValidationProvider", {
                                                        attrs: {
                                                          name: "Middle Name",
                                                          rules: "is_numeric",
                                                        },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "default",
                                                            fn: function ({
                                                              errors,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        _vm.$vuetify.lang.t(
                                                                          "$vuetify.middle_name_label_txt"
                                                                        ),
                                                                      outlined:
                                                                        "",
                                                                      "error-messages":
                                                                        _vm.localizeErrorMsg(
                                                                          errors,
                                                                          "middle_name",
                                                                          _vm
                                                                            .profile
                                                                            .middle_name
                                                                        ),
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .profile
                                                                          .middle_name,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.profile,
                                                                            "middle_name",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "profile.middle_name",
                                                                    },
                                                                  }
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ]),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        md: "6",
                                                        cols: "12",
                                                      },
                                                    },
                                                    [
                                                      _c("ValidationProvider", {
                                                        attrs: {
                                                          name: "Last Name",
                                                          rules:
                                                            "required|is_numeric",
                                                        },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "default",
                                                            fn: function ({
                                                              errors,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        _vm.$vuetify.lang.t(
                                                                          "$vuetify.last_name_label_txt"
                                                                        ),
                                                                      outlined:
                                                                        "",
                                                                      "error-messages":
                                                                        _vm.localizeErrorMsg(
                                                                          errors,
                                                                          "last_name",
                                                                          _vm
                                                                            .profile
                                                                            .last_name
                                                                        ),
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .profile
                                                                          .last_name,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.profile,
                                                                            "last_name",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "profile.last_name",
                                                                    },
                                                                  }
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ]),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        md: "6",
                                                        cols: "12",
                                                      },
                                                    },
                                                    [
                                                      _c("ValidationProvider", {
                                                        attrs: {
                                                          name: "Email",
                                                          rules: "required",
                                                        },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "default",
                                                            fn: function ({
                                                              errors,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        _vm.$vuetify.lang.t(
                                                                          "$vuetify.email_label_txt"
                                                                        ),
                                                                      "error-messages":
                                                                        errors,
                                                                      outlined:
                                                                        "",
                                                                      readonly:
                                                                        "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .profile
                                                                          .email,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.profile,
                                                                            "email",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "profile.email",
                                                                    },
                                                                  }
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ]),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        md: "6",
                                                        cols: "12",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-menu",
                                                        {
                                                          ref: "menu",
                                                          attrs: {
                                                            "close-on-content-click": false,
                                                            "return-value":
                                                              _vm.date,
                                                            transition:
                                                              "scale-transition",
                                                            "offset-y": "",
                                                            "min-width": "auto",
                                                          },
                                                          on: {
                                                            "update:returnValue":
                                                              function (
                                                                $event
                                                              ) {
                                                                _vm.date =
                                                                  $event
                                                              },
                                                            "update:return-value":
                                                              function (
                                                                $event
                                                              ) {
                                                                _vm.date =
                                                                  $event
                                                              },
                                                          },
                                                          scopedSlots: _vm._u([
                                                            {
                                                              key: "activator",
                                                              fn: function ({
                                                                on,
                                                                attrs,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "ValidationProvider",
                                                                    {
                                                                      attrs: {
                                                                        name: "Date of Birth",
                                                                        rules:
                                                                          "birth_date",
                                                                      },
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "default",
                                                                              fn: function ({
                                                                                errors,
                                                                              }) {
                                                                                return [
                                                                                  _c(
                                                                                    "v-text-field",
                                                                                    _vm._g(
                                                                                      _vm._b(
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              label:
                                                                                                _vm.$vuetify.lang.t(
                                                                                                  "$vuetify.dob_label_txt"
                                                                                                ),
                                                                                              "prepend-icon":
                                                                                                "",
                                                                                              readonly:
                                                                                                "",
                                                                                              outlined:
                                                                                                "",
                                                                                              "error-messages":
                                                                                                _vm.localizeErrorMsg(
                                                                                                  errors,
                                                                                                  "dob"
                                                                                                ),
                                                                                            },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm.date,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.date =
                                                                                                    $$v
                                                                                                },
                                                                                              expression:
                                                                                                "date",
                                                                                            },
                                                                                        },
                                                                                        "v-text-field",
                                                                                        attrs,
                                                                                        false
                                                                                      ),
                                                                                      on
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              },
                                                                            },
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                    }
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ]),
                                                          model: {
                                                            value: _vm.menu,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.menu = $$v
                                                            },
                                                            expression: "menu",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-date-picker",
                                                            {
                                                              attrs: {
                                                                "no-title": "",
                                                                scrollable: "",
                                                              },
                                                              model: {
                                                                value: _vm.date,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.date =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "date",
                                                              },
                                                            },
                                                            [
                                                              _c("v-spacer"),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    text: "",
                                                                    color:
                                                                      "primary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.menu = false
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$vuetify.lang.t(
                                                                          "$vuetify.cancel_btn_txt"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    text: "",
                                                                    color:
                                                                      "primary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$refs.menu.save(
                                                                          _vm.date
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$vuetify.lang.t(
                                                                          "$vuetify.OK_btn_txt"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        md: "6",
                                                        cols: "12",
                                                      },
                                                    },
                                                    [
                                                      _c("ValidationProvider", {
                                                        attrs: {
                                                          name: "Phone Number",
                                                          rules: "required",
                                                        },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "default",
                                                            fn: function ({
                                                              errors,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "vue-tel-input-vuetify",
                                                                  {
                                                                    attrs: {
                                                                      errorMessages:
                                                                        _vm.localizeErrorMsg(
                                                                          errors,
                                                                          "phone_no",
                                                                          _vm
                                                                            .profile
                                                                            .phone
                                                                        ),
                                                                      mode: "international",
                                                                      placeholder:
                                                                        " ",
                                                                      label:
                                                                        _vm.$vuetify.lang.t(
                                                                          "$vuetify.phone_no_label_txt"
                                                                        ),
                                                                      disabledFetchingCountry: true,
                                                                      outlined:
                                                                        "",
                                                                    },
                                                                    on: {
                                                                      input:
                                                                        _vm.onInput,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .profile
                                                                          .phone,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.profile,
                                                                            "phone",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "profile.phone",
                                                                    },
                                                                  }
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ]),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        md: "6",
                                                        cols: "12",
                                                      },
                                                    },
                                                    [
                                                      _c("ValidationProvider", {
                                                        attrs: {
                                                          name: "Gender",
                                                          rules: "",
                                                        },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "default",
                                                            fn: function ({
                                                              errors,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-autocomplete",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        _vm.$vuetify.lang.t(
                                                                          "$vuetify.gender_label_txt"
                                                                        ),
                                                                      items:
                                                                        _vm.genderParams,
                                                                      "item-text":
                                                                        "text",
                                                                      "item-value":
                                                                        "value",
                                                                      "error-messages":
                                                                        errors,
                                                                      outlined:
                                                                        "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .profile
                                                                          .gender,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.profile,
                                                                            "gender",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "profile.gender",
                                                                    },
                                                                  }
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ]),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("ValidationProvider", {
                                                        attrs: {
                                                          name: "Country",
                                                          rules: "required",
                                                        },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "default",
                                                            fn: function ({
                                                              errors,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "form",
                                                                  {
                                                                    attrs: {
                                                                      autocomplete:
                                                                        "off",
                                                                    },
                                                                    on: {
                                                                      submit:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-autocomplete",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            _vm.$vuetify.lang.t(
                                                                              "$vuetify.country_label_txt"
                                                                            ),
                                                                          items:
                                                                            _vm.AllCountry,
                                                                          "item-text":
                                                                            "countryName",
                                                                          "item-value":
                                                                            "countryCode",
                                                                          "error-messages":
                                                                            _vm.localizeErrorMsg(
                                                                              errors,
                                                                              "country"
                                                                            ),
                                                                          outlined:
                                                                            "",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .profile
                                                                              .country,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.profile,
                                                                                "country",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "profile.country",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ]),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("ValidationProvider", {
                                                        attrs: {
                                                          name: "State",
                                                          rules: "required",
                                                        },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "default",
                                                            fn: function ({
                                                              errors,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "form",
                                                                  {
                                                                    attrs: {
                                                                      autocomplete:
                                                                        "off",
                                                                    },
                                                                    on: {
                                                                      submit:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-autocomplete",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            _vm.$vuetify.lang.t(
                                                                              "$vuetify.state_label_txt"
                                                                            ),
                                                                          items:
                                                                            _vm.AllCountry.filter(
                                                                              (
                                                                                obj
                                                                              ) =>
                                                                                obj.countryCode ===
                                                                                _vm
                                                                                  .profile
                                                                                  .country
                                                                            )[0] &&
                                                                            _vm.AllCountry.filter(
                                                                              (
                                                                                obj
                                                                              ) =>
                                                                                obj.countryCode ===
                                                                                _vm
                                                                                  .profile
                                                                                  .country
                                                                            )[0]
                                                                              .regions,
                                                                          "item-text":
                                                                            "name",
                                                                          "item-value":
                                                                            "name",
                                                                          "error-messages":
                                                                            _vm.localizeErrorMsg(
                                                                              errors,
                                                                              "state"
                                                                            ),
                                                                          outlined:
                                                                            "",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .profile
                                                                              .region,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.profile,
                                                                                "region",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "profile.region",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ]),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("ValidationProvider", {
                                                        attrs: {
                                                          name: "City",
                                                          rules:
                                                            "required|is_numeric",
                                                        },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "default",
                                                            fn: function ({
                                                              errors,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        _vm.$vuetify.lang.t(
                                                                          "$vuetify.city_label_txt"
                                                                        ),
                                                                      "error-messages":
                                                                        _vm.localizeErrorMsg(
                                                                          errors,
                                                                          "city",
                                                                          _vm
                                                                            .profile
                                                                            .city
                                                                        ),
                                                                      outlined:
                                                                        "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .profile
                                                                          .city,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.profile,
                                                                            "city",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "profile.city",
                                                                    },
                                                                  }
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ]),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("ValidationProvider", {
                                                        attrs: {
                                                          name: "Postal Code",
                                                          mode: "eager",
                                                          rules:
                                                            "required|postal_code:@Country",
                                                        },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "default",
                                                            fn: function ({
                                                              errors,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    attrs: {
                                                                      "error-messages":
                                                                        _vm.localizeErrorMsg(
                                                                          errors,
                                                                          "post_code",
                                                                          _vm
                                                                            .profile
                                                                            .postal_code
                                                                        ),
                                                                      label:
                                                                        _vm.$vuetify.lang.t(
                                                                          "$vuetify.post_code_label_txt"
                                                                        ),
                                                                      outlined:
                                                                        "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .profile
                                                                          .postal_code,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.profile,
                                                                            "postal_code",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "profile.postal_code",
                                                                    },
                                                                  }
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ]),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("ValidationProvider", {
                                                        attrs: {
                                                          name: "Address",
                                                          rules:
                                                            "required|is_numeric",
                                                        },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "default",
                                                            fn: function ({
                                                              errors,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    attrs: {
                                                                      "error-messages":
                                                                        _vm.localizeErrorMsg(
                                                                          errors,
                                                                          "address",
                                                                          _vm
                                                                            .profile
                                                                            .address
                                                                        ),
                                                                      label:
                                                                        _vm.$vuetify.lang.t(
                                                                          "$vuetify.address_label_txt"
                                                                        ),
                                                                      outlined:
                                                                        "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .profile
                                                                          .address,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.profile,
                                                                            "address",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "profile.address",
                                                                    },
                                                                  }
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ]),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("v-divider"),
                                              _c(
                                                "v-card-actions",
                                                [
                                                  _c("v-spacer"),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: {
                                                        color: "error",
                                                        outlined: "",
                                                        large: "",
                                                        type: "reset",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.resetForm.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$vuetify.lang.t(
                                                              "$vuetify.cancel_btn_txt"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        large: "",
                                                        loading:
                                                          _vm.isUpdateProfile,
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.updateProfile,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$vuetify.lang.t(
                                                              "$vuetify.save_btn_txt"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.showAccountInfo
                                ? _c("v-tab-item", [
                                    _c("div", [_c("AccountInformation")], 1),
                                  ])
                                : _vm._e(),
                              _vm.showIDVerification
                                ? _c(
                                    "v-tab-item",
                                    [
                                      _c(
                                        "v-form",
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass: "pa-4 mt-2",
                                              attrs: { flat: "" },
                                            },
                                            [
                                              _c(
                                                "ValidationObserver",
                                                { ref: "verifyStep" },
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            md: "12",
                                                            cols: "12",
                                                          },
                                                        },
                                                        [
                                                          _vm.organization
                                                            .id_status ===
                                                          "verified"
                                                            ? _c(
                                                                "v-chip",
                                                                {
                                                                  staticClass:
                                                                    "text-capitalize",
                                                                  attrs: {
                                                                    outlined:
                                                                      "",
                                                                    medium: "",
                                                                    color:
                                                                      "primary",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .organization
                                                                          .id_status
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm.organization
                                                                .id_status ===
                                                              "pending"
                                                            ? _c(
                                                                "v-chip",
                                                                {
                                                                  staticClass:
                                                                    "text-capitalize",
                                                                  attrs: {
                                                                    outlined:
                                                                      "",
                                                                    medium: "",
                                                                    color:
                                                                      "orange",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .organization
                                                                          .id_status
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _c(
                                                                "v-chip",
                                                                {
                                                                  staticClass:
                                                                    "text-capitalize",
                                                                  attrs: {
                                                                    outlined:
                                                                      "",
                                                                    medium: "",
                                                                    color:
                                                                      "red",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .organization
                                                                          .id_status
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            xs: "12",
                                                            md: "12",
                                                          },
                                                        },
                                                        [
                                                          _c("v-label", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$vuetify.lang.t(
                                                                    "$vuetify.id_verify_header_txt"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            md: "6",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              attrs: {
                                                                "no-gutters":
                                                                  "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    md: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "ValidationProvider",
                                                                    {
                                                                      ref: "front_side",
                                                                      attrs: {
                                                                        name: "Front Side",
                                                                        rules:
                                                                          "required|image",
                                                                      },
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "default",
                                                                              fn: function ({
                                                                                validate,
                                                                                errors,
                                                                              }) {
                                                                                return [
                                                                                  _c(
                                                                                    "v-file-input",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          "show-size":
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$vuetify.lang.t(
                                                                                              "$vuetify.upload_img_1_label_txt"
                                                                                            ),
                                                                                          accept:
                                                                                            "image/*",
                                                                                          outlined:
                                                                                            "",
                                                                                          disabled:
                                                                                            _vm.isDisabled(),
                                                                                          "error-messages":
                                                                                            _vm.localizeErrorMsg(
                                                                                              errors,
                                                                                              "upload_img_1"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        change:
                                                                                          _vm.selectImage,
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ]
                                                                              },
                                                                            },
                                                                          ],
                                                                          null,
                                                                          false,
                                                                          541437718
                                                                        ),
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-row",
                                                            [
                                                              _vm.previewImage
                                                                ? _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols: "12",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm.progress
                                                                        ? _c(
                                                                            "div",
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                [
                                                                                  _c(
                                                                                    "v-progress-linear",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          color:
                                                                                            "primary",
                                                                                          height:
                                                                                            "25",
                                                                                          reactive:
                                                                                            "",
                                                                                        },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            _vm.progress,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.progress =
                                                                                                $$v
                                                                                            },
                                                                                          expression:
                                                                                            "progress",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "strong",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm.progress
                                                                                            ) +
                                                                                              " %"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "v-img",
                                                                            {
                                                                              staticClass:
                                                                                "preview my-3",
                                                                              attrs:
                                                                                {
                                                                                  "aspect-ratio":
                                                                                    "2",
                                                                                  contain:
                                                                                    "",
                                                                                  src: _vm.previewImage,
                                                                                  alt: "",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            md: "6",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              attrs: {
                                                                "no-gutters":
                                                                  "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    md: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "ValidationProvider",
                                                                    {
                                                                      ref: "back_side",
                                                                      attrs: {
                                                                        name: "Back Side",
                                                                        rules:
                                                                          "required|image",
                                                                      },
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "default",
                                                                              fn: function ({
                                                                                validate,
                                                                                errors,
                                                                              }) {
                                                                                return [
                                                                                  _c(
                                                                                    "v-file-input",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          "show-size":
                                                                                            "",
                                                                                          label:
                                                                                            _vm.$vuetify.lang.t(
                                                                                              "$vuetify.upload_img_2_label_txt"
                                                                                            ),
                                                                                          accept:
                                                                                            "image/*",
                                                                                          outlined:
                                                                                            "",
                                                                                          disabled:
                                                                                            _vm.isDisabled(),
                                                                                          "error-messages":
                                                                                            _vm.localizeErrorMsg(
                                                                                              errors,
                                                                                              "upload_img_2"
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        change:
                                                                                          _vm.selectImageBack,
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ]
                                                                              },
                                                                            },
                                                                          ],
                                                                          null,
                                                                          false,
                                                                          4089337341
                                                                        ),
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-row",
                                                            [
                                                              _vm.previewImageBack
                                                                ? _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols: "12",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm.progressBack
                                                                        ? _c(
                                                                            "div",
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                [
                                                                                  _c(
                                                                                    "v-progress-linear",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          color:
                                                                                            "primary",
                                                                                          height:
                                                                                            "25",
                                                                                          reactive:
                                                                                            "",
                                                                                        },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            _vm.progressBack,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.progressBack =
                                                                                                $$v
                                                                                            },
                                                                                          expression:
                                                                                            "progressBack",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "strong",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm.progressBack
                                                                                            ) +
                                                                                              " %"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "v-img",
                                                                            {
                                                                              staticClass:
                                                                                "preview my-3",
                                                                              attrs:
                                                                                {
                                                                                  "aspect-ratio":
                                                                                    "2",
                                                                                  contain:
                                                                                    "",
                                                                                  src: _vm.previewImageBack,
                                                                                  alt: "",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-divider"),
                                                  _c(
                                                    "v-card-actions",
                                                    [
                                                      _c("v-spacer"),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: {
                                                            color: "error",
                                                            outlined: "",
                                                            large: "",
                                                            type: "reset",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.resetForm.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$vuetify.lang.t(
                                                                  "$vuetify.cancel_btn_txt"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            large: "",
                                                            disabled:
                                                              _vm.isDisabled(),
                                                            loading:
                                                              _vm.uploading,
                                                          },
                                                          on: {
                                                            click: _vm.upload,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$vuetify.lang.t(
                                                                  "$vuetify.upload_btn_txt"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-tab-item",
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "form",
                                      attrs: { "lazy-validation": "" },
                                      model: {
                                        value: _vm.valid,
                                        callback: function ($$v) {
                                          _vm.valid = $$v
                                        },
                                        expression: "valid",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _c(
                                            "v-row",
                                            { staticClass: "px-5 py-5" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "12",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label:
                                                        _vm.$vuetify.lang.t(
                                                          "$vuetify.current_password_label_txt"
                                                        ),
                                                      placeholder:
                                                        _vm.$vuetify.lang.t(
                                                          "$vuetify.current_password_label_txt"
                                                        ),
                                                      outlined: "",
                                                      "append-icon":
                                                        _vm.showCurrentPassword
                                                          ? "mdi-eye"
                                                          : "mdi-eye-off",
                                                      type: _vm.showCurrentPassword
                                                        ? "text"
                                                        : "password",
                                                      rules: [
                                                        (v) =>
                                                          !!v ||
                                                          _vm.$vuetify.lang.t(
                                                            "$vuetify.current_password_error_msg_txt"
                                                          ),
                                                      ],
                                                    },
                                                    on: {
                                                      "click:append": function (
                                                        $event
                                                      ) {
                                                        _vm.showCurrentPassword =
                                                          !_vm.showCurrentPassword
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.oldPassword,
                                                      callback: function ($$v) {
                                                        _vm.oldPassword = $$v
                                                      },
                                                      expression: "oldPassword",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label:
                                                        _vm.$vuetify.lang.t(
                                                          "$vuetify.new_password_label_txt"
                                                        ),
                                                      placeholder:
                                                        _vm.$vuetify.lang.t(
                                                          "$vuetify.new_password_label_txt"
                                                        ),
                                                      outlined: "",
                                                      "append-icon":
                                                        _vm.showPassword
                                                          ? "mdi-eye"
                                                          : "mdi-eye-off",
                                                      type: _vm.showPassword
                                                        ? "text"
                                                        : "password",
                                                      rules: [
                                                        (v) =>
                                                          !!v ||
                                                          _vm.$vuetify.lang.t(
                                                            "$vuetify.new_password_error_msg_txt"
                                                          ),
                                                        (v) =>
                                                          (v &&
                                                            v !==
                                                              _vm.oldPassword) ||
                                                          _vm.$vuetify.lang.t(
                                                            "$vuetify.new_password_error_msg_txt_2"
                                                          ),
                                                        _vm.password(
                                                          _vm.$vuetify.lang.t(
                                                            "$vuetify.new_password_label_txt"
                                                          )
                                                        ),
                                                        _vm.passwordWithEmail(
                                                          _vm.$vuetify.lang.t(
                                                            "$vuetify.new_password_label_txt"
                                                          ),
                                                          _vm.profile.email
                                                        ),
                                                      ],
                                                    },
                                                    on: {
                                                      "click:append": function (
                                                        $event
                                                      ) {
                                                        _vm.showPassword =
                                                          !_vm.showPassword
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.newPassword,
                                                      callback: function ($$v) {
                                                        _vm.newPassword = $$v
                                                      },
                                                      expression: "newPassword",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label:
                                                        _vm.$vuetify.lang.t(
                                                          "$vuetify.re_password_label_txt"
                                                        ),
                                                      placeholder:
                                                        _vm.$vuetify.lang.t(
                                                          "$vuetify.re_password_label_txt"
                                                        ),
                                                      outlined: "",
                                                      "append-icon":
                                                        _vm.showRePassword
                                                          ? "mdi-eye"
                                                          : "mdi-eye-off",
                                                      type: _vm.showRePassword
                                                        ? "text"
                                                        : "password",
                                                      rules: [
                                                        (v) =>
                                                          !!v ||
                                                          _vm.$vuetify.lang.t(
                                                            "$vuetify.re_password_error_msg_txt"
                                                          ),
                                                        _vm.match(
                                                          _vm.newPassword
                                                        ),
                                                      ],
                                                    },
                                                    on: {
                                                      "click:append": function (
                                                        $event
                                                      ) {
                                                        _vm.showRePassword =
                                                          !_vm.showRePassword
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.confirmNewPassword,
                                                      callback: function ($$v) {
                                                        _vm.confirmNewPassword =
                                                          $$v
                                                      },
                                                      expression:
                                                        "confirmNewPassword",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("v-divider"),
                                          _c(
                                            "v-card-actions",
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    color: "error",
                                                    outlined: "",
                                                    large: "",
                                                    type: "reset",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.dialog = false
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$vuetify.lang.t(
                                                          "$vuetify.cancel_btn_txt"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    large: "",
                                                    loading: _vm.updateDisabled,
                                                  },
                                                  on: {
                                                    click: _vm.updatePassword,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$vuetify.lang.t(
                                                          "$vuetify.save_btn_txt"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("Modal", {
        attrs: {
          title: _vm.alertTitle,
          message: _vm.alertMessage,
          confirmButtonText: "Ok",
          cancelButton: false,
        },
        on: {
          onConfirmation: function ($event) {
            _vm.alertDialog = false
          },
        },
        model: {
          value: _vm.alertDialog,
          callback: function ($$v) {
            _vm.alertDialog = $$v
          },
          expression: "alertDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }