var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { app: "", "clipped-left": "", dark: "", color: "primary" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6", xs: "6", md: "6" } },
                [
                  _c(
                    "v-toolbar-title",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "headline",
                          staticStyle: { cursor: "pointer" },
                          attrs: { to: "/public/solutions", tag: "span" },
                        },
                        [
                          _c("img", {
                            staticClass: "logo",
                            attrs: {
                              src: require("@/assets/images/logo_white_horizontal.png"),
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-end",
                  attrs: { cols: "3", xs: "3", md: "3" },
                },
                [_c("switch-language")],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "get-started-icon",
                  attrs: { cols: "3", xs: "3", md: "3" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-uppercase font-weight-bold",
                      attrs: { color: "", large: "", outlined: "", block: "" },
                      on: {
                        click: function ($event) {
                          return _vm.goToLogin()
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "d-none d-sm-flex" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$vuetify.lang.t("$vuetify.get_started_btn_txt")
                          )
                        ),
                      ]),
                      _c("v-icon", { staticClass: "d-flex d-sm-none" }, [
                        _vm._v("account_circle"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-spacer"),
      _c(
        "div",
        { staticClass: "pa-4 mt-12" },
        [
          _vm.isLoading
            ? _c("Loader", {
                staticClass: "mr-2 mt-6",
                attrs: { loaderType: "card-avatar, article, actions" },
              })
            : _c(
                "v-container",
                { attrs: { "fill-height": "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", xs: "12", md: "7" } },
                        [
                          _c("v-sheet", { staticClass: "pb-3" }, [
                            _c("h1", [
                              _vm._v(_vm._s(_vm.solutionDetails.name)),
                            ]),
                          ]),
                          _c(
                            "v-chip",
                            {
                              staticClass: "ma2",
                              attrs: {
                                color: "primary",
                                label: "",
                                outlined: "",
                                pill: "",
                              },
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v(" mdi-account-outline "),
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.solutionDetails.account
                                      ? _vm.solutionDetails.account.account_name
                                      : "None"
                                  ) +
                                  " "
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-chip",
                            {
                              staticClass: "ma-2",
                              attrs: {
                                color:
                                  _vm.solutionDetails.segment &&
                                  _vm.solutionDetails.segment.color,
                                label: "",
                                outlined: "",
                                pill: "",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.solutionDetails.segment &&
                                      _vm.solutionDetails.segment.name
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c("v-sheet", { staticClass: "pt-3" }, [
                            _c("h2", [
                              _vm._v(
                                _vm._s(
                                  _vm.$vuetify.lang.t(
                                    "$vuetify.overview_label_txt"
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _vm.solutionDetails && _vm.solutionDetails.overview
                            ? _c(
                                "p",
                                [
                                  _c("show-rich-text-data", {
                                    attrs: {
                                      editorId: "selectedSolutionOverview",
                                      editorData: _vm.solutionDetails.overview,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("v-sheet", { staticClass: "pt-3" }, [
                            _c("h2", [
                              _vm._v(
                                _vm._s(
                                  _vm.$vuetify.lang.t(
                                    "$vuetify.description_label_txt"
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _vm.solutionDetails && _vm.solutionDetails.description
                            ? _c(
                                "p",
                                [
                                  _c("show-rich-text-data", {
                                    attrs: {
                                      editorId: "selectedSolutionDesc",
                                      editorData:
                                        _vm.solutionDetails.description,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("v-sheet", { staticClass: "pt-3" }, [
                            _c("h2", [
                              _vm._v(
                                _vm._s(
                                  _vm.$vuetify.lang.t(
                                    "$vuetify.use_case_label_txt"
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _vm.solutionDetails && _vm.solutionDetails.use_case
                            ? _c(
                                "p",
                                [
                                  _c("show-rich-text-data", {
                                    attrs: {
                                      editorId: "selectedSolutionUseCases",
                                      editorData: _vm.solutionDetails.use_case,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("v-sheet", { staticClass: "pb-3" }, [
                            _c("h2", [
                              _vm._v(
                                _vm._s(
                                  _vm.$vuetify.lang.t(
                                    "$vuetify.language_label_txt"
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _vm._l(
                            _vm.solutionDetails.languages,
                            function (element) {
                              return _c(
                                "v-chip",
                                {
                                  key: element,
                                  staticClass: "ma-2",
                                  attrs: {
                                    color: "success",
                                    label: "",
                                    outlined: "",
                                    pill: "",
                                  },
                                },
                                [_vm._v(" " + _vm._s(element) + " ")]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", xs: "12", md: "5" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "elevation-0" },
                            [
                              _c("v-img", {
                                staticClass: "rounded my-3",
                                attrs: {
                                  src: _vm.solutionDetails.cover_image
                                    ? _vm.solutionDetails.cover_image
                                    : _vm.segmentImg(_vm.solutionDetails),
                                  "aspect-ratio": "2",
                                },
                              }),
                              _c("v-sheet", { staticClass: "py-3" }, [
                                _c("h2", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.industry_label_txt"
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                              _vm._l(
                                _vm.solutionDetails.categories,
                                function (element) {
                                  return _c(
                                    "v-chip",
                                    {
                                      key: element.id,
                                      staticClass: "ma-2",
                                      attrs: {
                                        color:
                                          _vm.solutionDetails.segment &&
                                          _vm.solutionDetails.segment.color,
                                        outlined: "",
                                        label: "",
                                        pill: "",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(element.name) + " ")]
                                  )
                                }
                              ),
                              _c(
                                "v-card-actions",
                                { staticClass: "py-5" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "text-capitalize font-weight-bold",
                                      attrs: {
                                        color: "primary",
                                        large: "",
                                        outlined: "",
                                        block: "",
                                        disabled:
                                          _vm.solutionDetails.status &&
                                          _vm.solutionDetails.status ===
                                            "published"
                                            ? false
                                            : true,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goToLogin()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$vuetify.lang.t(
                                              "$vuetify.create_project_btn_txt"
                                            )
                                          ) +
                                          " "
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "ml-2",
                                          attrs: { right: "" },
                                        },
                                        [_vm._v("add_circle_outline")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                { staticClass: "py-5" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "text-capitalize font-weight-bold",
                                      attrs: {
                                        color:
                                          _vm.solutionDetails.segment &&
                                          _vm.solutionDetails.segment.color,
                                        large: "",
                                        outlined: "",
                                        block: "",
                                        href: "https://gigalogy.com/request-demo/",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$vuetify.lang.t(
                                              "$vuetify.contact_sales_btn_txt"
                                            )
                                          ) +
                                          " "
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "ml-2",
                                          attrs: { right: "" },
                                        },
                                        [_vm._v("chat_bubble_outline")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                  _vm.hasLinks()
                    ? _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("v-col", [
                            _c("h2", [
                              _vm._v(
                                _vm._s(
                                  _vm.$vuetify.lang.t(
                                    "$vuetify.linked_solution_label_txt_3"
                                  )
                                )
                              ),
                            ]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    _vm._l(_vm.solutionDetails.links, function (element) {
                      return _c(
                        "v-col",
                        {
                          key: element.id,
                          attrs: {
                            cols: "12",
                            xs: "12",
                            sm: "6",
                            md: "6",
                            lg: "4",
                            xl: "4",
                          },
                        },
                        [
                          element
                            ? _c("LinkSolutionCard", {
                                attrs: {
                                  itemData: element,
                                  color:
                                    _vm.solutionDetails.segment &&
                                    _vm.solutionDetails.segment.color,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }