var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-datetime-picker", {
    attrs: {
      label: _vm.label,
      placeholder: _vm.placeholder,
      hint: _vm.hint,
      disabled: _vm.disabled,
    },
    on: { input: _vm.onInput },
    model: {
      value: _vm.inputVal,
      callback: function ($$v) {
        _vm.inputVal = $$v
      },
      expression: "inputVal",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }