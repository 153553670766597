var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: _vm.width },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "headline primary white--text",
              attrs: { "primary-title": "" },
            },
            [_vm._v(" " + _vm._s(_vm.title) + " ")]
          ),
          _c(
            "v-layout",
            { staticClass: "px-5 py-5" },
            [
              _vm.modalType === _vm.CONFIRMATION_MODAL
                ? _c("v-row", { staticClass: "mx-2 my-2" }, [
                    _c("span", [_vm._v(_vm._s(_vm.message))]),
                  ])
                : _vm._e(),
              _vm.modalType === _vm.MULTI_SELECTION_MODAL
                ? _c(
                    "v-row",
                    _vm._l(this.headers, function (item) {
                      return _c(
                        "v-col",
                        { key: item.value, attrs: { cols: "12", md: "2" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              label: item.text,
                              value: item.value,
                              disabled:
                                item.text == "セグメント名" ||
                                item.text == "広告名",
                            },
                            model: {
                              value: _vm.selected,
                              callback: function ($$v) {
                                _vm.selected = $$v
                              },
                              expression: "selected",
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm.cancelButton
                ? _c("Button", {
                    attrs: {
                      buttonType: _vm.TEXT_BUTTON,
                      buttonText: _vm.cancelButtonText,
                      color: "primary",
                    },
                    on: {
                      onClick: function ($event) {
                        return _vm.onCancel()
                      },
                    },
                  })
                : _vm._e(),
              _c("Button", {
                attrs: {
                  buttonType: _vm.RAISED_BUTTON,
                  buttonText: _vm.confirmButtonText,
                  color: "primary",
                },
                on: {
                  onClick: function ($event) {
                    return _vm.onConfirmation()
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }