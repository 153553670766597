var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-main",
        [
          _c(
            "v-container",
            { attrs: { "text-center": "", id: "login-page" } },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    "align-center": "",
                    "justify-center": "",
                    row: "",
                    wrap: "",
                  },
                },
                [
                  _c("v-flex", { attrs: { xs12: "", sm8: "", md4: "" } }, [
                    _c("h1", [_vm._v("404")]),
                    _c("h2", { staticClass: "primary--text" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$vuetify.lang.t(
                              "$vuetify.page_not_found_msg_txt_1"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("span", { staticClass: "blue--text font-weight-bold" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$vuetify.lang.t(
                            "$vuetify.page_not_found_msg_txt_2"
                          )
                        )
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }