var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pl-12", attrs: { cols: "12", md: "2" } },
            [
              _c("v-card", { staticClass: "pa-2", attrs: { flat: "" } }, [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      `${_vm.$vuetify.lang.t("$vuetify.project.ranks_train")}`
                    )
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "12", md: "8" } }),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "2" } },
            [
              !this.inProgress
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.startTraining },
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-play")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t("$vuetify.tooltip.start")}`
                          ) +
                          " "
                      ),
                    ],
                    1
                  )
                : _c(
                    "v-btn",
                    {
                      staticClass: "mr-4 secondary-disabled-button",
                      attrs: { color: "primary" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$vuetify.lang.t(
                              "$vuetify.project.training"
                            )}`
                          ) +
                          " "
                      ),
                      _c("v-progress-circular", {
                        attrs: {
                          indeterminate: "",
                          size: 15,
                          width: 2,
                          color: "white",
                        },
                      }),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.updateResult !== ""
        ? _c(
            "v-row",
            { staticClass: "pl-11" },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _vm.updateStatus === "success"
                    ? _c("v-icon", { attrs: { color: "#C8E6C9" } }, [
                        _vm._v("mdi-check-circle"),
                      ])
                    : _vm.updateStatus === "failed"
                    ? _c("v-icon", { attrs: { color: "error" } }, [
                        _vm._v("mdi-alert-circle"),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c("v-col", [
                _c("p", [
                  _c(
                    "span",
                    { class: { "error--text": _vm.updateStatus === "failed" } },
                    [_vm._v(" " + _vm._s(_vm.updateResult) + " ")]
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }