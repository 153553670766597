var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { outlined: "", height: "100%", flat: "" } },
    [
      _c(
        "v-card-subtitle",
        {
          staticClass:
            "font-weight-bold d-flex justify-space-between align-center",
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _vm._v(
                " " +
                  _vm._s(
                    this.$vuetify.lang.t(
                      "$vuetify.insights.top_purchased_items"
                    )
                  ) +
                  " "
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-icon",
                            _vm._g(
                              _vm._b(
                                { staticClass: "ml-1", attrs: { small: "" } },
                                "v-icon",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_vm._v(" mdi-help-circle-outline ")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        this.$vuetify.lang.t(
                          "$vuetify.insights.top_purchased_items"
                        )
                      )
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: {
                small: "",
                color: "primary",
                loading: _vm.isDownloading,
                disabled: _vm.isDownloading || _vm.itemList.length === 0,
              },
              on: { click: _vm.downloadAllItemDetails },
            },
            [
              _c("v-icon", { attrs: { left: "", small: "" } }, [
                _vm._v("mdi-download"),
              ]),
              _vm._v(" CSV "),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-card-text",
        { staticStyle: { "max-height": "450px", "overflow-y": "auto" } },
        [
          _vm.itemList.length
            ? _c(
                "div",
                _vm._l(_vm.itemList, function (item, index) {
                  return _c(
                    "p",
                    {
                      key: index,
                      staticClass: "my-2 top_item_btn primary--text",
                      on: {
                        click: function ($event) {
                          return _vm.itemDetailsDialogHandler(item)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "d-inline-block text-truncate",
                          staticStyle: { "max-width": "700px" },
                        },
                        [_vm._v(" " + _vm._s(item.title || item.item_id) + " ")]
                      ),
                    ]
                  )
                }),
                0
              )
            : _c(
                "v-card",
                { attrs: { flat: "", height: "250" } },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "fill-height",
                      attrs: { align: "center", justify: "center" },
                    },
                    [
                      _c("span", { staticClass: "grey--text" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$vuetify.lang.t("$vuetify.no_data_available")
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _vm.itemDetailsDialog
        ? [
            _c(
              "v-dialog",
              {
                attrs: {
                  "max-width": "800px",
                  persistent: "",
                  scrollable: "",
                  "hide-overlay": "",
                  transition: "dialog-bottom-transition",
                  "content-class": "rounded-lg",
                  elevation: "24",
                  rounded: "",
                },
                model: {
                  value: _vm.itemDetailsDialog,
                  callback: function ($$v) {
                    _vm.itemDetailsDialog = $$v
                  },
                  expression: "itemDetailsDialog",
                },
              },
              [
                _vm.itemLoading
                  ? _c("Loader")
                  : _c(
                      "v-card",
                      {
                        staticClass: "rounded-lg",
                        attrs: { elevation: "24", rounded: "" },
                      },
                      [
                        _c("v-card-title", [
                          _c("span", { staticClass: "headline" }, [
                            _c(
                              "span",
                              {
                                staticClass: "d-inline-block text-truncate",
                                staticStyle: { "max-width": "720px" },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.selectedItemTitle) + " "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _c("v-divider"),
                        _c(
                          "v-card-text",
                          _vm._l(_vm.selectedItem, function (value, key) {
                            return _c(
                              "div",
                              { key: key },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "font-weight-bold",
                                        attrs: { cols: "4" },
                                      },
                                      [_vm._v(" " + _vm._s(key) + " ")]
                                    ),
                                    _c("v-col", { attrs: { cols: "8" } }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            Array.isArray(value)
                                              ? value.join(", ")
                                              : typeof value === "object" &&
                                                value !== null
                                              ? Object.values(value).join(", ")
                                              : value
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                        _c(
                          "v-card-actions",
                          { staticClass: "d-flex justify-end" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "", color: "error" },
                                on: {
                                  click: function ($event) {
                                    _vm.itemDetailsDialog = false
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.close_btn_txt"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }