var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "fieldset",
      {
        class: { "text-editor": !_vm.isError, "editor-is-danger": _vm.isError },
      },
      [
        _c(
          "legend",
          {
            staticClass: "subheading pl-1 pr-1 ml-2",
            style: { paddingLeft: "2px", paddingRight: "2px" },
          },
          [_vm._v(" " + _vm._s(_vm.label) + " ")]
        ),
        _c("div", { attrs: { id: _vm.editorId } }),
      ]
    ),
    _vm.isError
      ? _c(
          "div",
          { staticClass: "editor-text-danger text-caption mt-1 mb-3 ml-3" },
          [_vm._v(_vm._s(_vm.errorMsg))]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }