var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "read-only text-editor", attrs: { id: _vm.editorId } },
    [
      _vm._v(
        " " + _vm._s(_vm.initiateEditor(_vm.editorId, _vm.editorData)) + " "
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }