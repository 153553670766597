var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: { top: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _vm.size === "small"
                ? _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            icon: "",
                            color: _vm.color,
                            loading: _vm.loading,
                            disabled: _vm.disabled,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onClick()
                            },
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v(" " + _vm._s(_vm.iconName) + " "),
                      ]),
                    ],
                    1
                  )
                : _vm.size === "x-small"
                ? _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            icon: "",
                            color: _vm.color,
                            loading: _vm.loading,
                            disabled: _vm.disabled,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onClick()
                            },
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { "x-small": "" } }, [
                        _vm._v(" " + _vm._s(_vm.iconName) + " "),
                      ]),
                    ],
                    1
                  )
                : _vm.size === "x-large"
                ? _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            icon: "",
                            color: _vm.color,
                            loading: _vm.loading,
                            disabled: _vm.disabled,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onClick()
                            },
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { "x-large": "" } }, [
                        _vm._v(" " + _vm._s(_vm.iconName) + " "),
                      ]),
                    ],
                    1
                  )
                : _vm.size === "large"
                ? _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            icon: "",
                            color: _vm.color,
                            loading: _vm.loading,
                            disabled: _vm.disabled,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onClick()
                            },
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { large: "" } }, [
                        _vm._v(" " + _vm._s(_vm.iconName) + " "),
                      ]),
                    ],
                    1
                  )
                : _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            icon: "",
                            color: _vm.color,
                            loading: _vm.loading,
                            disabled: _vm.disabled,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onClick()
                            },
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v(" " + _vm._s(_vm.iconName) + " ")])],
                    1
                  ),
            ]
          },
        },
      ]),
    },
    [_c("span", [_vm._v(_vm._s(_vm.tooltipText))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }