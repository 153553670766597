var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.commonState
        ? _c(
            "div",
            [
              _vm.accountLoading
                ? _c("Loader", {
                    staticClass: "mr-2 mt-6",
                    attrs: { loaderType: "card-avatar, article, actions" },
                  })
                : _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "", justify: "end" } },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { cols: "9", md: "7" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c("v-col", { attrs: { cols: "8" } }, [
                                    _c(
                                      "div",
                                      [
                                        _c("v-select", {
                                          staticClass: "mr-2 mr-md-3",
                                          attrs: {
                                            items: _vm.allAccount,
                                            label: _vm.$vuetify.lang.t(
                                              "$vuetify.wallet.selected_account"
                                            ),
                                            "item-text": "account_name",
                                            "item-value": "id",
                                            "return-object": "",
                                            outlined: "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "selection",
                                                fn: function (data) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      _vm._b(
                                                        {
                                                          staticClass: "d-flex",
                                                          attrs: {
                                                            "input-value":
                                                              data.selected,
                                                          },
                                                          on: {
                                                            click: data.select,
                                                          },
                                                        },
                                                        "div",
                                                        data.attrs,
                                                        false
                                                      ),
                                                      [
                                                        _c(
                                                          "v-avatar",
                                                          {
                                                            staticClass: "mr-1",
                                                            attrs: {
                                                              left: "",
                                                              size: "25px",
                                                              color:
                                                                "primary lighten-2",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.item.account_name
                                                                    .substring(
                                                                      0,
                                                                      1
                                                                    )
                                                                    .toUpperCase()
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex flex-column",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "d-block font-weight-bold text--primary text-truncate",
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        data
                                                                          .item
                                                                          .account_name
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]),
                                                                _vm._v("   "),
                                                                _c("small", [
                                                                  _vm._v(
                                                                    " (" +
                                                                      _vm._s(
                                                                        _vm.passChar(
                                                                          data
                                                                            .item
                                                                            .public_key
                                                                        )
                                                                      ) +
                                                                      ") "
                                                                  ),
                                                                ]),
                                                                _c(
                                                                  "v-chip",
                                                                  {
                                                                    staticClass:
                                                                      "text-capitalize",
                                                                    attrs: {
                                                                      outlined:
                                                                        "",
                                                                      "x-small":
                                                                        "",
                                                                      color:
                                                                        data
                                                                          .item
                                                                          .status ===
                                                                        "active"
                                                                          ? "primary"
                                                                          : "error",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          data
                                                                            .item
                                                                            .status
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "item",
                                                fn: function (data) {
                                                  return [
                                                    [
                                                      _c(
                                                        "v-list-item-avatar",
                                                        [
                                                          _c(
                                                            "v-avatar",
                                                            {
                                                              attrs: {
                                                                left: "",
                                                                size: "25px",
                                                                color:
                                                                  "primary lighten-2",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "font-weight-bold",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        data.item.account_name
                                                                          .substring(
                                                                            0,
                                                                            1
                                                                          )
                                                                          .toUpperCase()
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    data.item
                                                                      .account_name
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c(
                                                                "v-chip",
                                                                {
                                                                  staticClass:
                                                                    "text-capitalize",
                                                                  attrs: {
                                                                    outlined:
                                                                      "",
                                                                    "x-small":
                                                                      "",
                                                                    color:
                                                                      data.item
                                                                        .status ===
                                                                      "active"
                                                                        ? "primary"
                                                                        : "error",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        data
                                                                          .item
                                                                          .status
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-list-item-subtitle",
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.passChar(
                                                                      data.item
                                                                        .public_key
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3504771643
                                          ),
                                          model: {
                                            value: _vm.selected_account,
                                            callback: function ($$v) {
                                              _vm.selected_account = $$v
                                            },
                                            expression: "selected_account",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("v-col", { attrs: { cols: "4" } }, [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              outlined: "",
                                              color: "primary",
                                              height: "55px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addAccountClick()
                                              },
                                            },
                                          },
                                          [
                                            _c("v-icon", [_vm._v("mdi-plus")]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$vuetify.lang.t(
                                                    "$vuetify.wallet.add_account"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _vm.selected_acc &&
                          _vm.selected_acc.secret_saved_consent === false
                            ? _c(
                                "v-alert",
                                {
                                  attrs: {
                                    outlined: "",
                                    dense: "",
                                    type: "error",
                                  },
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        align: "center",
                                        "no-gutters": "",
                                      },
                                    },
                                    [
                                      _c("v-col", { staticClass: "grow" }, [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.wallet.save_secret_key_notice"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("div", { staticClass: "mx-2" }),
                                      _c(
                                        "v-col",
                                        { staticClass: "shrink d-flex" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mr-2",
                                              attrs: {
                                                color: "primary",
                                                small: "",
                                                outlined: "",
                                              },
                                              on: { click: _vm.showSecret },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$vuetify.lang.t(
                                                      "$vuetify.wallet.see_secret_key_btn"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                small: "",
                                                outlined: "",
                                              },
                                              on: { click: _vm.saveConsent },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-check"),
                                              ]),
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$vuetify.lang.t(
                                                    "$vuetify.wallet.understood_btn"
                                                  )
                                                ) + " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.selected_acc &&
                          _vm.selected_acc.status === "created"
                            ? _c(
                                "v-alert",
                                { attrs: { outlined: "", type: "error" } },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        align: "center",
                                        "no-gutters": "",
                                      },
                                    },
                                    [
                                      _c("v-col", { staticClass: "grow" }, [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.wallet.activate_account_notice"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c(
                                        "v-col",
                                        { staticClass: "shrink" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "error",
                                                to: "/wallet/buy",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$vuetify.lang.t(
                                                      "$vuetify.wallet.buy"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-tabs",
                            {
                              attrs: { "show-arrows": "" },
                              model: {
                                value: _vm.activeTab,
                                callback: function ($$v) {
                                  _vm.activeTab = $$v
                                },
                                expression: "activeTab",
                              },
                            },
                            _vm._l(_vm.tabs, function (tab) {
                              return _c(
                                "v-tab",
                                { key: tab.id, attrs: { to: tab.route } },
                                [
                                  tab.icon
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass: "me-3",
                                          attrs: { size: "20" },
                                        },
                                        [_vm._v(" " + _vm._s(tab.icon) + " ")]
                                      )
                                    : _c("v-img", {
                                        staticClass: "mr-2",
                                        attrs: { src: tab.img },
                                      }),
                                  _c("span", [_vm._v(_vm._s(tab.title))]),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                          _c("router-view", {
                            ref: "buySection",
                            attrs: {
                              item: _vm.selected_acc,
                              allAsset: _vm.allAsset,
                            },
                            on: {
                              loadEditData: _vm.loadEditData,
                              loadAllAcc: _vm.loadAllAcc,
                              refreshAfterBuy: _vm.loadAllAccount,
                              openPasscode: _vm.openPasscode,
                              showSecret: _vm.showSecret,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400", persistent: "" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$vuetify.lang.t("$vuetify.wallet.enter_passcode")
                    ) +
                    " "
                ),
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-form",
                            {
                              ref: "passcodeForm",
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.confirmClick()
                                },
                              },
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c("v-text-field", {
                                    class: {
                                      "mask-password": !_vm.showPasscode,
                                    },
                                    attrs: {
                                      label: _vm.$vuetify.lang.t(
                                        "$vuetify.wallet.passcode"
                                      ),
                                      placeholder: _vm.$vuetify.lang.t(
                                        "$vuetify.wallet.passcode"
                                      ),
                                      autocomplete: "off",
                                      "prepend-inner-icon": "lock",
                                      outlined: "",
                                      "append-icon": _vm.showPasscode
                                        ? "mdi-eye"
                                        : "mdi-eye-off",
                                      type: "text",
                                      rules: [
                                        _vm.required(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.wallet.passcode"
                                          )
                                        ),
                                      ],
                                    },
                                    on: {
                                      "click:append": function ($event) {
                                        _vm.showPasscode = !_vm.showPasscode
                                      },
                                    },
                                    model: {
                                      value: _vm.passcode,
                                      callback: function ($$v) {
                                        _vm.passcode = $$v
                                      },
                                      expression: "passcode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm.isFromSecret
                    ? _c(
                        "v-btn",
                        {
                          attrs: { disabled: _vm.isLoading, color: "error" },
                          on: {
                            click: function ($event) {
                              return _vm.clickCancel()
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$vuetify.lang.t("$vuetify.common.cancel")
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", loading: _vm.isLoading },
                      on: {
                        click: function ($event) {
                          return _vm.confirmClick()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$vuetify.lang.t("$vuetify.common.confirm"))
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600", persistent: "" },
          model: {
            value: _vm.createDialog,
            callback: function ($$v) {
              _vm.createDialog = $$v
            },
            expression: "createDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      this.$vuetify.lang.t("$vuetify.wallet.create_passcode")
                    ) +
                    " "
                ),
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-alert",
                    {
                      staticClass: "mx-2",
                      attrs: { type: "error", outlined: "", dense: "" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$vuetify.lang.t(
                              "$vuetify.wallet.passcode_message"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-form",
                            {
                              ref: "createPasscodeForm",
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.saveClick()
                                },
                              },
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c("v-text-field", {
                                    class: {
                                      "mask-password": !_vm.showPasscode,
                                    },
                                    attrs: {
                                      label: _vm.$vuetify.lang.t(
                                        "$vuetify.wallet.passcode"
                                      ),
                                      placeholder: _vm.$vuetify.lang.t(
                                        "$vuetify.wallet.passcode"
                                      ),
                                      autocomplete: "off",
                                      "prepend-inner-icon": "lock",
                                      outlined: "",
                                      "append-icon": _vm.showPasscode
                                        ? "mdi-eye"
                                        : "mdi-eye-off",
                                      type: "text",
                                      rules: [
                                        _vm.required(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.wallet.enter_passcode"
                                          )
                                        ),
                                        _vm.password(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.wallet.passcode"
                                          )
                                        ),
                                      ],
                                    },
                                    on: {
                                      "click:append": function ($event) {
                                        _vm.showPasscode = !_vm.showPasscode
                                      },
                                    },
                                    model: {
                                      value: _vm.passcode,
                                      callback: function ($$v) {
                                        _vm.passcode = $$v
                                      },
                                      expression: "passcode",
                                    },
                                  }),
                                  _c("v-text-field", {
                                    class: {
                                      "mask-password": !_vm.showConfirmPasscode,
                                    },
                                    attrs: {
                                      label: _vm.$vuetify.lang.t(
                                        "$vuetify.wallet.confirm_passcode"
                                      ),
                                      placeholder: _vm.$vuetify.lang.t(
                                        "$vuetify.wallet.confirm_passcode"
                                      ),
                                      autocomplete: "off",
                                      "prepend-inner-icon": "lock",
                                      outlined: "",
                                      "append-icon": _vm.showConfirmPasscode
                                        ? "mdi-eye"
                                        : "mdi-eye-off",
                                      type: "text",
                                      rules: [
                                        _vm.required(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.wallet.re_enter_passcode"
                                          )
                                        ),
                                        _vm.match(_vm.passcode),
                                      ],
                                    },
                                    on: {
                                      "click:append": function ($event) {
                                        _vm.showConfirmPasscode =
                                          !_vm.showConfirmPasscode
                                      },
                                    },
                                    model: {
                                      value: _vm.confirmPasscode,
                                      callback: function ($$v) {
                                        _vm.confirmPasscode = $$v
                                      },
                                      expression: "confirmPasscode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c("Button", {
                    attrs: {
                      buttonType: _vm.RAISED_BUTTON,
                      buttonText: this.$vuetify.lang.t("$vuetify.common.save"),
                      color: "primary",
                    },
                    on: {
                      onClick: function ($event) {
                        return _vm.saveClick()
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "600" },
          model: {
            value: _vm.secretKeySaveDialog,
            callback: function ($$v) {
              _vm.secretKeySaveDialog = $$v
            },
            expression: "secretKeySaveDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "headline white--text text--primary mb-4" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$vuetify.lang.t("$vuetify.wallet.secret_key")
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "div",
                    { staticClass: "mb-8" },
                    [
                      _c(
                        "v-alert",
                        { attrs: { type: "error", outlined: "" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t(
                                  "$vuetify.wallet.secret_save_msg"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  [
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.$vuetify.lang.t(
                          "$vuetify.wallet.secret_key"
                        ),
                        readonly: "",
                        outlined: "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "append",
                          fn: function () {
                            return [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.copySecretKey()
                                                  },
                                                },
                                              },
                                              on
                                            ),
                                            [_vm._v(" mdi-content-copy ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.secret_key_copy
                                          ? _vm.$vuetify.lang.t(
                                              "$vuetify.common.copied"
                                            )
                                          : _vm.$vuetify.lang.t(
                                              "$vuetify.common.copy"
                                            )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.secret_key_mod,
                        callback: function ($$v) {
                          _vm.secret_key_mod = $$v
                        },
                        expression: "secret_key_mod",
                      },
                    }),
                  ],
                ],
                2
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.doneClick()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$vuetify.lang.t("$vuetify.common.done")) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }